import { Box, TextField, createTheme } from '@mui/material';
import { GridRowModes, useGridApiContext } from '@mui/x-data-grid-pro';
import { useEffect } from 'react';
import SouthIcon from '@mui/icons-material/South';

export const brushValueList = [
  { number: 110, color: '#37C172' },
  { number: 100, color: '#7ACC6A' },
  { number: 90, color: '#B1D565' },
  { number: 80, color: '#D8DC63' },
  { number: 70, color: '#F9E261' },
  { number: 50, color: '#FCA551' },
  { number: 30, color: '#FD8D4C' },
  { number: 0, color: '#FF3C3D' },
];

export const customCropToPlantCell = (params) => {
  return (
    <Box className="crop-to-plant-box">
      <div>{params.value}</div>
    </Box>
  );
};

export const CustomCropToPlantHeader = (params) => {
  return (
    <p>
      <span>{params.colDef.headerName}</span>
      <SouthIcon />
    </p>
  );
};

export const CustomEditBrushAutomaticEffectCell = ({
  params,
  selectedBrushValue,
  previousRotationLevel = null,
  currentRotationLevel = null,
}) => {
  const { id, field, value } = params;
  const apiRef = useGridApiContext();

  //* in a useEffect so the reset of the Ref is done after the call to processRowUpdate
  useEffect(() => {
    if (previousRotationLevel && currentRotationLevel) {
      if (previousRotationLevel.current !== currentRotationLevel) {
        previousRotationLevel.current = currentRotationLevel;
      }
    }
  }, [currentRotationLevel]);

  const handleChange = async (e) => {
    apiRef.current.setEditCellValue({ id, field, value: selectedBrushValue });
    if (params.cellMode === 'edit') {
      apiRef.current.stopCellEditMode({ id, field, value: selectedBrushValue });
    }
  };

  useEffect(() => {
    if (selectedBrushValue) {
      handleChange();
    }
  }, []);

  return (
    <Box className="brush-edit-cell">
      <TextField value={selectedBrushValue ?? value} autoFocus={true} />
    </Box>
  );
};

export const CustomEffectEditCell = ({
  params,
  errorsEffectFormDefault,
  currentRotationLevel = null,
  setRowModesModel,
  previousRotationLevel = null,
}) => {
  const { id, field, value } = params;
  //* in a useEffect so the reset of the Ref is done after the call to processRowUpdate
  useEffect(() => {
    if (currentRotationLevel && previousRotationLevel) {
      if (previousRotationLevel.current !== currentRotationLevel) {
        previousRotationLevel.current = currentRotationLevel;
      }
    }
  }, [currentRotationLevel]);

  const apiRef = useGridApiContext();
  const handleEffectChange = (e) => {
    const {
      target: { value },
    } = e;
    apiRef.current.setEditCellValue({ id, field, value });
  };

  const handleFocusClick = (id) => () => {
    setRowModesModel((prevRowModesModel) => {
      if (Object.keys(prevRowModesModel).length === 0) {
        return { [id]: { mode: GridRowModes.Edit } };
      } else {
        let resetPrevRowModesModel = {};
        for (const key in prevRowModesModel) {
          if (prevRowModesModel.hasOwnProperty(key)) {
            resetPrevRowModesModel[key] = { mode: GridRowModes.View };
          }
        }
        return { ...resetPrevRowModesModel, [id]: { mode: GridRowModes.Edit } };
      }
    });
  };

  let isError = true;
  if (currentRotationLevel && previousRotationLevel) {
    isError =
      errorsEffectFormDefault?.cellErrors?.[currentRotationLevel].hasOwnProperty(id) &&
      errorsEffectFormDefault?.cellErrors?.[currentRotationLevel]?.[id].includes(parseInt(field));
  } else {
    isError =
      errorsEffectFormDefault?.cellErrors?.hasOwnProperty(id) &&
      errorsEffectFormDefault?.cellErrors?.[id].includes(parseInt(field));
  }

  return (
    <Box className="edit-cell">
      <TextField
        className="edit-cell-textfield"
        inputProps={{
          style: {
            border: isError ? '2px solid #d12d38' : '',
          },
        }}
        onFocus={handleFocusClick(id)}
        onChange={(e) => handleEffectChange(e)}
        value={value}
        autoFocus={true}
      />
    </Box>
  );
};

export const handleRowUpdateValidation = (row, errorsEffectFormDefault) => {
  return new Promise((resolve, reject) => {
    if (errorsEffectFormDefault.formError === false) {
      resolve(row);
    } else {
      reject();
    }
  });
};

export const CustomNoRowsOverlay = () => {
  return <Box className="noRowOverlay">Aucune donnée à afficher</Box>;
};

export const themeRotateEffectsFilter = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '188px',
          border: '1px solid #e69636 !important',
          borderRadius: '5px',
          margin: '5px',
          '.MuiOutlinedInput-root.Mui-focused fieldset': {
            borderColor: '#e69636 !important',
            top: '-2px !important',
          },
          '.MuiOutlinedInput-root.Mui-focused legend': {
            display: 'none !important',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#e69636 !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87) !important',
          maxHeight: '50px !important',
          '.MuiSelect-select.Mui-disabled': {
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.65) !important',
          },
          '.MuiSvgIcon-root.MuiSelect-icon': {
            color: '#277134 !important',
          },
        },
        notchedOutline: {
          borderColor: 'unset !important',
          borderWidth: '0px !important',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#e69636 !important',
          '&.Mui-checked': {
            color: '#277134 !important',
          },
        },
      },
    },
  },
});

export const themeRotateEffectsPalette = createTheme({
  components: {
    MuiBadge: {
      styleOverrides: {
        badge: {
          width: '25px',
          height: '25px',
          right: '10px',
          backgroundColor: '#e69636',
          border: `2px solid white`,
          borderRadius: '100px',
        },
      },
    },
  },
});
