import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from 'components/generics/Button';

function ErrorSimulationResultFallback({ error }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [path, setPath] = useState({
    text: '',
    route: '',
  });
  const regexSimulation = /exploitation\/result/i;
  const regexComparator = /comparator\/result/i;

  useEffect(() => {
    if (regexSimulation.test(location.pathname)) {
      setPath({ text: 'Retour à la simulation', route: '/exploitation/simulation' });
    } else if (regexComparator.test(location.pathname)) {
      setPath({ text: 'Retour au comparateur', route: '/exploitation/comparator' });
    }
  }, [location.pathname]);

  return (
    <div className="error_message">
      <p>{error.message}</p>
      <p>
        En cas d'échange avec nos services, veuillez noter le Job Id ci-dessous pour faciliter notre
        identification de l'erreur
      </p>
      {error.jobId && (
        <p className="text-center" style={{ fontSize: '13px' }}>
          Job Id : {error.jobId}
        </p>
      )}
      <div style={{ margin: '10px 0' }}>
        <Button type="submit" text={path.text} onClick={() => navigate(path.route)} />
      </div>
    </div>
  );
}

export { ErrorSimulationResultFallback };
