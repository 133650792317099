import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v1 } from 'uuid';
import { minimalRoleRequired } from 'utils';
import { mainNav } from 'utils/const';
import useAuth from 'providers/Auth/useAuth';
import Item from 'components/MainNav/Item';
import MenuRight from 'components/icons/MenuRight';
import logo from 'assets/images/logo_white.png';
import './mainNav.style.scss';
import Close from 'components/icons/Close';
import { SimulationContext } from 'components/pages/Exploitation/SimulationContext';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuLeft from 'components/icons/MenuLeft';
import { NavContext } from 'utils/context';

import { Ribbon } from "react-ribbons";

const MainNav = ({ user }) => {
  const [active, setActive] = useState(3);
  const { isNavBarOpen, setIsNavBarOpen } = useContext(NavContext);
  const { logout } = useAuth();
  const [isRibbonVisible, setIsRibbonVisible] = useState(true);
  const env = process.env.REACT_APP_ENV;

  window.addEventListener('setActiveMainNavBar', () => {
    setActive(1);
  });

  useEffect(() => {
    return () => window.removeEventListener('setActiveMainNavBar', null);
  }, []);

  useEffect(() => {
    const currentPath = window.location.pathname;
    setActive(mainNav.filter((x) => currentPath.match('/' + x.path))[0].id);
  }, []);

  const { setSimulationContext } = React.useContext(SimulationContext);

  const logoutApp = () => {
    setSimulationContext();
    logout();
  };

  //Function to get ribbon depending on environment
  const shouldDisplayRibbon = () => {
 
    const ribbonColors = {
      dev: '#E40230',
      preprod: '#b55103',
      test: 'black',
    };
    // If the environment is "prod", do not display the ribbon
    if (env === 'prod') return null;
  
    // Use default "dev" color for unknown environments
    const ribbonColor = ribbonColors[env] || ribbonColors.dev;
  
    return (
      <Ribbon
        side="left"
        type="corner"
        size="large"
        backgroundColor={ribbonColor}
        color="#ccffff"
        fontFamily="rubik"
        withStripes={false}
      >
        {env + ' '}
        <Close
        className='ribbon-close'
          width={10}
          height={10}
          strokeWidth={4}
        />
      </Ribbon>
    );
  };

  return (
    <>
      <div className="mainNav">
        <div className="mainNav-contains">
          {isRibbonVisible && (
            <div className="ribbon"
              role="button"
              onClick={() => {
                setIsRibbonVisible(!isRibbonVisible);
              }}
             >
              {shouldDisplayRibbon()}
            </div>
          )}
          <div className="bar-box">
            <img src={logo} className="nav-logo" alt="logo assolia" />
            <div
              tabIndex={0}
              role="button"
              aria-label="bar"
              className={'bar-close'}
              onClick={() => {
                setIsNavBarOpen(!isNavBarOpen);
              }}
            >
              {isNavBarOpen ? (
                <MenuRight width={30} height={30} />
              ) : (
                <MenuLeft width={30} height={30} color="#FFF" />
              )}
            </div>
          </div>
          <div className="items-contains">
            {mainNav.map(
              (e) =>
                minimalRoleRequired(user?.role, e.minimalRole) && (
                  <Item
                    key={v1()}
                    id={e.id}
                    path={e.path}
                    text={e.text}
                    onChangePath={(id) => setActive(id)}
                    active={e.id === active}
                  />
                )
            )}
          </div>
          <a onClick={() => logoutApp()}>
            <div className="item">
              <LogoutIcon className="logout-icon" />
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

MainNav.propTypes = {
  user: PropTypes.shape({
    cooperative: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    email: PropTypes.string,
    firstname: PropTypes.string,
    id: PropTypes.number,
    lastname: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

export default MainNav;
