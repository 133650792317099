import API from 'services/API';

// SIMULATION

export const simulate = (id, data) => API.post(`exploitations/${id}/simulation`, data);

export const getSimulationResult = (id) => API.get(`simulation/${id}/result`);

export const getSimulationStatus = (id) => API.get(`simulation/${id}/status`);

export const lastSimulate = (id) => API.get(`users/simulations/exploitation/${id}/last`);

export const launch_calculate = (body, exploitationId) =>
  API.post(`/exploitations/${exploitationId}/calculate`, body);

// RESULT

export const sendReport = (report) =>
  API.post('/simulation/send_report', report, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export default { simulate, getSimulationResult, getSimulationStatus, lastSimulate };

//COMPARATOR
export const comparatorSimulate = (exploitationId, data) =>
  API.post(`/exploitations/${exploitationId}/comparator/simulation`, data);

export const comparatorCalculate = (exploitationId, data) =>
  API.post(`/exploitations/${exploitationId}/comparator/calculate`, data);

export const getComparatorResult = (exploitationId, jobId) =>
  API.get(`/exploitations/${exploitationId}/comparator/${jobId}/result`);
