import { ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_TECHNICIAN, ROLE_FARMER, ROLE_USER } from 'utils/const';

export const minimalRoleRequired = (roles, minimal) => {
  const roleRank = {
    SUPER_ADMIN: ROLE_SUPER_ADMIN,
    ADMIN: ROLE_ADMIN,
    TECHNICIAN: ROLE_TECHNICIAN,
    FARMER: ROLE_FARMER,
    USER: ROLE_USER,
  };

  if (!roles || !minimal) {
    return false;
  }

  return roleRank[roles] >= minimal;
};

export const addZero = (num) => (num <= 9 ? `0${num}` : num);

export const formatDate = (dateRAW) => {
  const date = new Date(dateRAW);

  return `${addZero(date.getDate())}/${addZero(date.getMonth() + 1)}/${date.getFullYear()}`;
};

export const formatNumberWithSpaces = (num) => {
  return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ' ');
};
