import './parcelSynthesisTable.style.scss';
import './cultureListTable.style.scss';
import './table.style.scss';
import { v1 } from 'uuid';
import { toLowerCaseWithFirstLetterUpper } from '../Generics/utilsFunctions';

const CultureListTable = ({ truncatedCultures, messageDuplicate }) => {
  let headers = [
    { headerText: 'Nom' },
    { headerText: 'Prix de Vente', unit: '(€/t)' },
    { headerText: 'Rendement', unit: '(t/ha)' },
    { headerText: 'Coût', unit: '(€/ha)' },
    { headerText: 'Coût extra', unit: '(€/ha)' },
    { headerText: 'IFT' },
    { headerText: 'Durée de Présence', unit: '(an)' },
    { headerText: "Durée d'interdiction" },
    { headerText: 'Contraintes' },
  ];
  return (
    <article className="pdf-page">
      <div className="culture_list_wrapper">
        <h6 className="title_h6">
          {truncatedCultures.length > 0 && messageDuplicate !== ''
            ? `Liste des cultures ${messageDuplicate}`
            : `Liste des cultures`}
        </h6>
        <table
          className="table table_full_page_culture"
          style={{ height: truncatedCultures.length <= 5 ? 'auto' : '' }}
        >
          <thead className="table_head">
            <tr className="table_tr">
              {headers.map((header, i) => (
                <th
                  key={i}
                  scope="col"
                  className="column_name_culture"
                  style={{ width: header.headerText === 'Nom' ? '150px' : 'auto' }}
                >
                  <div className="cell_flex_column">
                    <div>{header.headerText}</div>
                    {header?.unit && <div>{header.unit}</div>}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {truncatedCultures?.map((culture, index) => (
              <tr
                className="table_tr"
                key={v1()}
                style={{ backgroundColor: index % 2 !== 0 ? '#F8E4CC' : 'white' }}
              >
                <td className="value_table_culture cell_name">
                  <div
                    className="inline_icon_cell name_and_color_wrapper"
                    style={{ minHeight: truncatedCultures.length <= 5 ? '42px' : 'auto' }}
                  >
                    <span className="text-left">
                      {toLowerCaseWithFirstLetterUpper(culture?.name)}
                    </span>
                    <div className="icons">
                      {culture?.color && (
                        <div className="color" style={{ backgroundColor: culture.color }}></div>
                      )}
                    </div>
                  </div>
                </td>
                <td className="value_table_culture not_name_cell">
                  {culture?.economicAttributeCollection?.price?.currentEconomicValue}
                </td>
                <td className="value_table_culture not_name_cell">{culture?.yield}</td>
                <td className="value_table_culture not_name_cell">
                  {culture?.economicAttributeCollection?.inputCost?.currentEconomicValue}
                </td>
                <td className="value_table_culture not_name_cell">{culture?.extraCost ?? 0}</td>
                <td className="value_table_culture not_name_cell">{culture?.ift}</td>
                <td className="value_table_culture not_name_cell">{culture?.presenceDuration}</td>
                <td className="value_table_culture not_name_cell">
                  {culture?.prohibitionDuration}
                </td>
                {culture?.constraint?.type === 'undefined' ? (
                  <td className="value_table_culture not_name_cell"></td>
                ) : (
                  <td className="value_table_culture align_left not_name_cell">
                    <div className="flex-column">
                      <span>Min : {culture.constraint.min + ' ' + culture.constraint.type}</span>
                      <span>Max : {culture.constraint.max + ' ' + culture.constraint.type}</span>
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </article>
  );
};

export default CultureListTable;
