import React from 'react';
import PropTypes from 'prop-types';

const Close = ({ color = '#000', fill = '#FFF', width = 44.595, height = 44.595, stroke = '#FFF', strokeWidth = 1, className }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 44.595 44.595">
    <path
      fill={fill}
      color={color}
      stroke={stroke}
      strokeWidth={strokeWidth}
      d="M24.76 22.298l19.324-19.32A1.742 1.742 0 0041.622.514l-19.324 19.32L2.972.51A1.743 1.743 0 10.509 2.978l19.325 19.32L.509 41.622a1.741 1.741 0 102.462 2.462l19.326-19.321 19.324 19.321a1.741 1.741 0 002.462-2.462zm0 0"
    />
  </svg>
);

Close.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  strokeWidth: PropTypes.number,
  className: PropTypes.string,
};

export default Close;
