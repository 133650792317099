//materialUI datagrid
import { useCallback } from 'react';
import CustomDataGridResult from '../../../../utils/Datagrid/CustomForecastDataGridResult';
import useDataGridLoading from './useDataGridLoading';
import { Box } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CustomForecastHeader } from '../../../../utils/Datagrid/CustomForecastDataGridUtils';

const DataGridParcelsSynthesis = ({
  exploitationContext,
  comparatorSimulationResult,
  comparatorCalculatorResult,
  updatedSimulationSuggest,
  setUpdatedSimulationSuggest,
  setUpdatedSimulationRepartitionIrrigation,
  setIsSimulationEdited,
  currentYear,
}) => {
  const dataGridLoading = useDataGridLoading(
    comparatorSimulationResult,
    comparatorCalculatorResult
  );
  const filteredContextParcels = exploitationContext?.parcels.filter((parcel) =>
    comparatorCalculatorResult?.parcels
      .map((calculatorParcel) => calculatorParcel.name)
      .includes(parcel.name)
  );

  const processedRows = filteredContextParcels?.map((parcel) => {
    const forecastParcelCulture = exploitationContext?.forecasts?.find(
      (forecast) => forecast.parcel.name === parcel.name
    ).culture.name;

    const suggestParcelCulture = updatedSimulationSuggest.find(
      (simulationParcel) => simulationParcel.name === parcel.name
    ).cultures[0][0].name;

    return {
      id: parcel?.id,
      parcel: parcel?.name,
      surface: parcel?.surfaceTotal ?? parcel?.surface,
      groundType: parcel?.groundType?.name,
      cultureN1: parcel?.cultureN1?.name,
      cultureN: parcel?.cultureN?.name,
      forecast: forecastParcelCulture,
      simulationSuggest: suggestParcelCulture,
      isParent: parcel?.isParent ?? false,
    };
  });

  const columns = [
    {
      field: 'parcel',
      headerName: 'Parcelle',
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <p>
            {params.value} {params.row.isParent && <span>*</span>}
          </p>
        );
      },
    },
    {
      field: 'surface',
      headerName: 'Surface (ha)',
      valueGetter: (value) => value && `${Number(value.toFixed(2))}ha`,
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
    },
    {
      field: 'groundType',
      headerName: 'Type de sols',
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
    },
    {
      field: 'cultureN1',
      headerName: `${currentYear - 2}-${currentYear - 1}`,
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
    },
    {
      field: 'cultureN',
      headerName: `${currentYear - 1}-${currentYear}`,
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
    },
    {
      field: 'forecast',
      headerName: `${currentYear}-${currentYear + 1} (prévisionnel)`,
      renderHeader: () => CustomForecastHeader(currentYear, 'prévsionnel'),
      sortable: false,
      headerClassName: 'forecast-header header-material',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'simulationSuggest',
      headerName: `${currentYear}-${currentYear + 1} (Assolia)`,
      renderHeader: () => CustomForecastHeader(currentYear, 'Assolia'),
      //? uncomment to reanable forecast editing
      // editable: true,
      // renderCell: CustomSimulationCell,
      sortable: false,
      type: 'singleSelect',
      valueOptions: (params) => {
        return culturesSelectValues(params);
      },
      headerClassName: 'forecast-header header-assolia header-material',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
  ];

  const culturesSelectValues = useCallback(
    (params) => {
      if (exploitationContext.cultures) {
        return [...exploitationContext?.cultures?.map((culture) => culture.name)];
      }
    },
    [exploitationContext]
  );

  const calculatorRepartitionIrrigation =
    comparatorSimulationResult?.suggestions.suggestion1.years[0].irrigationDistribution.map(
      (parcelIrrigation) => [parcelIrrigation]
    );

  const processRowUpdate = useCallback(
    (updatedRow, previousRow) => {
      if (previousRow.simulationSuggest !== updatedRow.simulationSuggest) {
        const newCultureId = exploitationContext.cultures.find(
          (culture) => culture.name === updatedRow.simulationSuggest
        ).id;

        const updateSuggest = updatedSimulationSuggest?.map((parcel) => {
          if (parcel.id === updatedRow.id) {
            return {
              ...parcel,
              cultures: [
                {
                  id: newCultureId,
                  name: updatedRow.simulationSuggest,
                },
              ],
            };
          }
          return parcel;
        });

        setUpdatedSimulationSuggest(updateSuggest);
        setUpdatedSimulationRepartitionIrrigation(calculatorRepartitionIrrigation);
        setIsSimulationEdited(true);

        return updatedRow;
      }

      return previousRow;
    },
    [exploitationContext]
  );

  return (
    <CustomDataGridResult
      rows={processedRows}
      columns={columns}
      isLoading={dataGridLoading}
      processRowUpdate={processRowUpdate}
      fullSize={true}
    />
  );
};

export default DataGridParcelsSynthesis;

const CustomSimulationCell = (params) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>{params.value}</div>
      <ArrowDropDownIcon />
    </Box>
  );
};
