import CustomDataGridResult from '../../../../utils/Datagrid/CustomForecastDataGridResult';
import { customDataGridResultSimulation } from '../../../../utils/Datagrid/CustomForecastDataGridUtils';

const DataGridKeyNumber = ({ rows, columns }) => {

  const processedColumns = columns?.map((column, index) => {
    let processedColumn = {
      field: column,
      headerName: column,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    };

    if (index === 0) {
      processedColumn = {
        ...processedColumn,
        align: 'left',
        cellClassName: 'key-number-campain',
        width: 250,
        minWidth: 250,
        maxWidth: 250,
      };
    }
    if (index === 1) {
      processedColumn = { ...processedColumn, cellClassName: 'previous-campain' };
    }

    return processedColumn;
  });

  const processedRows = rows?.map((row, index) => {
    let processedRow = { id: row?.id+`-${index}` };
    processedColumns?.map((column, idx) => {
      if (idx === 0) {
        processedRow[column?.field] = row?.id;
      } else {
        processedRow[column?.field] = row?.data?.[idx - 1]?.value;
      }
    });
    return processedRow;
  });

  const getRowClassName = () => 'Mui-even';

  return (
    <>
      <h2>Chiffres clés</h2>

      <CustomDataGridResult
        rows={processedRows}
        columns={processedColumns}
        isLoading={false}
        theme={customDataGridResultSimulation}
        simulationContext={'simulation-box'}
        fullSize={true}
        getRowClassName={getRowClassName}
      />
    </>
  );
};

export default DataGridKeyNumber;
