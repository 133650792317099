// YARN
import {
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridRowId,
  GridRowsProp,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
// THEMES
import { StyledTooltip } from './CustomGenericDataGridUtils';

const useDataGridCommonListActions = (
  rows: GridRowsProp,
  setModal: React.Dispatch<React.SetStateAction<boolean>>,
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>,
  setEditData: React.Dispatch<React.SetStateAction<GridValidRowModel | null | undefined>>,
  setOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setActionId: React.Dispatch<React.SetStateAction<GridRowId | null>>,
  fromTechnicianSector?: number,
  handleRemoveTechnicianFromSector?: (id: GridRowId) => Promise<void>
) => {
  const handleEditDialogOpen = (id: GridRowId): void => {
    setEditData(rows.find((row) => row.id === id));
    setIsEdit(true);
    setModal(true);
  };
  const handleDeleteDialogOpen = (id: GridRowId): void => {
    setActionId(id);
    setOpenDeleteDialog(true);
  };

  const handleRemoveTechnician = (id: GridRowId): void => {
    handleRemoveTechnicianFromSector && handleRemoveTechnicianFromSector(id);
  };

  const getActions = (
    id: GridRowId
  ): React.ReactElement<GridActionsCellItemProps, string | React.JSXElementConstructor<any>>[] => {
    return [
      <>
        <StyledTooltip placement="top" title="Edition">
          <GridActionsCellItem
            icon={<EditIcon sx={{ paddingLeft: '2px !important' }} />}
            label="Edition"
            className="textPrimary"
            onClick={() => handleEditDialogOpen(id)}
            color="inherit"
          />
        </StyledTooltip>
        <StyledTooltip placement="top" title="Supprimer">
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ paddingLeft: '2px !important' }} />}
            label="Supprimer"
            onClick={() => handleDeleteDialogOpen(id)}
            color="inherit"
          />
        </StyledTooltip>
        {fromTechnicianSector !== 0 && handleRemoveTechnicianFromSector && (
          <StyledTooltip placement="top" title="Supprimer du secteur">
            <GridActionsCellItem
              icon={<PersonRemoveIcon sx={{ paddingLeft: '2px !important' }} />}
              label="Supprimer du secteur"
              onClick={() => handleRemoveTechnician(id)}
              color="inherit"
            />
          </StyledTooltip>
        )}
      </>,
    ];
  };

  return {
    getActions,
  };
};

export default useDataGridCommonListActions;
