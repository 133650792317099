import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { displayErrorMessage } from 'utils/tools_functions';
import { forgotPassword } from 'services/API/User';

import InputLabel from 'components/generics/InputLabel';
import Button from 'components/generics/Button';

const DEFAULT_FORM = {
  email: '',
  action: localStorage.getItem('action_password') ? localStorage.getItem('action_password') : '',
};

const LoginForm = () => {
  const [formData, setFormData] = useState(DEFAULT_FORM);
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    forgotPassword(formData)
      .then(() => {
        toast.success('Un mail vous a été envoyé');
        setLoading(false);
        setFormData(DEFAULT_FORM);
      })
      .catch(() => {
        setLoading(false);
        toast.error(displayErrorMessage('ERR_REINIT_PASSWORD'));
      });
  };

  const updateForm = (key, value) => setFormData({ ...formData, [key]: value });

  return (
    <>
      <form onSubmit={onSubmit}>
        <InputLabel
          name="email"
          value={formData.email}
          onChange={(e) => {
            updateForm('email', e);
          }}
          placeholder="E-mail"
          label="Entrez votre adresse mail"
          defaultStyle="login"
          disabled={loading}
        />
        <Button defaultStyle="d-block" type="submit" text="Envoyer l'e-mail" disabled={loading} />
      </form>
    </>
  );
};

export default LoginForm;
