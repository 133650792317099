import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import './LoadingScreen.style.scss';

const LoadingScreen = ({ text= 'Veuillez patienter pendant la récupération des données', open }) => {
  return (
    <Backdrop className="backdrop-loading" open={open}>
      <div className="loading">
        <CircularProgress color="inherit" />
        <span>{text}</span>
      </div>
    </Backdrop>
  );
};

export default LoadingScreen;
