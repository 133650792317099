import React from 'react';
import PropTypes from 'prop-types';

const SimulationCard = ({ children , title }) => (
  <div style={{ marginBottom: '40px' }} className="card">
    <div className="headerCard">
      <p>{title}</p>
    </div>
    {title === 'Contraintes parcellaires' ? (
      <div className="containCard__restriction">{children}</div>
    ) : (
      <div className="containCard">{children}</div>
    )}
  </div>
);

SimulationCard.propTypes = {
  // children: PropTypes.element.isRequired,
  children: PropTypes.element,
  title: PropTypes.string.isRequired,
};

export default SimulationCard;
