import React from 'react';
import PropTypes from 'prop-types';

const Sector = ({ color = '#000', fill = '#d12d38', width = 38, height = 38 }) => (
  <svg width={width} height={height} viewBox="0 0 38 38">
    <g>
      <path
        color={color}
        fill={fill}
        d="M11.011 19.465a6.3 6.3 0 001.592 6.351 6.158 6.158 0 004.455 1.876 6.639 6.639 0 001.195-.109v4.856a.742.742 0 001.484 0v-4.857a6.649 6.649 0 001.205.11 6.159 6.159 0 004.455-1.876 6.3 6.3 0 001.592-6.351.742.742 0 00-.457-.488 5.277 5.277 0 00-.979-.229 6.306 6.306 0 001.437-6.19.742.742 0 00-.457-.489 6.387 6.387 0 00-3.991.042 6.668 6.668 0 00.185-1.585 6.3 6.3 0 00-3.367-5.621.742.742 0 00-.669-.022 6.281 6.281 0 00-3.409 5.639 6.667 6.667 0 00.186 1.588 6.387 6.387 0 00-4-.045.742.742 0 00-.457.489 6.306 6.306 0 001.437 6.19 5.277 5.277 0 00-.979.229.742.742 0 00-.458.492zm13.336 5.3a4.791 4.791 0 01-4.489 1.327 4.814 4.814 0 011.326-4.491 4.9 4.9 0 011.674-1.115q.159-.051.319-.113a4.934 4.934 0 012.494-.1 4.786 4.786 0 01-1.324 4.493zm1.323-11.4a4.786 4.786 0 01-1.323 4.492 4.9 4.9 0 01-1.669 1.113q-.162.052-.325.115a4.93 4.93 0 01-2.5.1 4.984 4.984 0 01-.115-1.045v-.019a4.7 4.7 0 011.147-3.107c.109-.109.219-.226.328-.351a4.79 4.79 0 014.457-1.296zM19 6.412a4.786 4.786 0 012.241 4.112 4.734 4.734 0 01-1.114 3.125c-.12.12-.233.242-.339.364a4.984 4.984 0 01-.786.625 5.046 5.046 0 01-.908-.751l-.106-.114a4.735 4.735 0 01-1.224-3.248A4.791 4.791 0 0119 6.414zm-6.673 6.957a4.814 4.814 0 014.487 1.327l.031.033q.1.11.2.21a4.7 4.7 0 011.209 2.929v.5a4.945 4.945 0 01-.109.824 4.932 4.932 0 01-2.5-.1q-.161-.062-.32-.113a4.9 4.9 0 01-1.672-1.114 4.786 4.786 0 01-1.326-4.496zm0 6.908a4.976 4.976 0 012.55.12q.106.04.212.075a4.866 4.866 0 011.725 1.131 4.732 4.732 0 011.436 3.172v.5a4.947 4.947 0 01-.109.824 4.9 4.9 0 01-5.814-5.817z"
      />
      <path
        color={color}
        fill={fill}
        d="M6.387 29.387a2.807 2.807 0 00-1.265.3 17.524 17.524 0 0117.53-27.82.743.743 0 00.308-1.454A19.128 19.128 0 0019 0 19 19 0 004.015 30.681a2.82 2.82 0 102.372-1.294zm.945 3.765a1.336 1.336 0 11.391-.945 1.337 1.337 0 01-.392.945zM33.985 7.322a2.817 2.817 0 10-1.107 1 17.524 17.524 0 01-16.972 27.929.742.742 0 00-.26 1.461 19.177 19.177 0 003.354.3 19 19 0 0014.985-30.69zm-3.316-.582a1.336 1.336 0 111.889 0 1.336 1.336 0 01-1.889 0zM12.479 35.261l-.28.687.278-.688a.742.742 0 00-.556 1.376l.023.009a.742.742 0 00.535-1.384zM25.552 2.749h.008a.742.742 0 00.556-1.376l-.013-.005a.742.742 0 10-.551 1.378z"
      />
    </g>
  </svg>
);

Sector.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Sector;
