import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ text, children, name, className }) => (
  <label className={className} htmlFor={name}>
    {text}
    {children}
  </label>
);

Label.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Label;
