import { Button } from '@mui/material';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';
import { toast } from 'react-toastify';
import { exportCsvDataMetaculture } from 'services/API/Cooperative';
import { ThemeProvider } from '@mui/material/styles';

const Export = () => {
  const downloadFile = async () => {
    try {
      const response = await exportCsvDataMetaculture();
      const file = new Blob([response.data], { type: 'text/csv' });
      const fileURL = URL.createObjectURL(file);
      const link: HTMLAnchorElement | null = document.createElement('a');
      link.href = fileURL;
      link.download = 'assolia_cultures_export.csv';
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      toast.success('Le fichier a été téléchargé avec succès');
    } catch (error) {
      toast.error('Erreur durant le téléchargement du fichier');
    }
  };

  return (
    <div className="wrapper-export">
      <h1 className="title_section">Télécharger</h1>
      <div>
        <ThemeProvider theme={customButtonTheme}>
          <Button onClick={() => downloadFile()}>Télécharger le fichier des cultures</Button>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default Export;
