export const validateFormData = (formData, errors, optionalIndex) => {
  // Array to get the keys who need to be chekc over by some validator
  const keyToValidate = Object.keys(formData)
    .filter((key) => errors.hasOwnProperty(key))
    .reduce((obj, key) => {
      obj[key] = formData[key];
      return obj;
    }, {});

  for (const [key, value] of Object.entries(keyToValidate)) {
    // Get the name from the input verified from his label's text
    let inputName;
    if (errors.exception) {
      inputName = [...document.getElementsByTagName('label')].filter(
        (x) => x.getAttribute('for') === key + '-' + optionalIndex
      )?.[0]?.innerText;
    } else {
      inputName = [...document.getElementsByTagName('label')].filter(
        (x) => x.getAttribute('for') === key
      )?.[0]?.innerText;
    }

    inputName = inputName.replace(/\*/g, '').replace(/ *\([^)]*\) */g, '');

    errors[key].message = '';
    if (errors[key].validator) {
      for (const inputValidator of errors[key].validator) {
        // Interate through all inputValidator gave from the errors json for the current key
        let messageError = inputValidator(value, inputName);
        if (messageError) {
          errors[key].message += messageError + '\r\n';
          errors.formError = true;
        }
      }
    }
  }
  return errors;
};

export const validateFormDataMetaCulture = (formData, errors, metaCode, metaCultureName) => {
  const keyToValidate = Object.keys(formData)
    .filter((key) => errors.hasOwnProperty(key))
    .reduce((obj, key) => {
      obj[key] = formData[key];
      return obj;
    }, {});
  for (const [key, value] of Object.entries(keyToValidate)) {
    let isAllow = true;

    if ((!metaCode && metaCultureName) || (metaCode && !metaCultureName)) {
      isAllow = false;
    }
    let inputName;
    if (errors.exception) {
      inputName = [...document.getElementsByTagName('label')].filter(
        (x) => x.getAttribute('for') === key + '-'
      )?.[0]?.innerText;
    } else {
      inputName = [...document.getElementsByTagName('label')].filter(
        (x) => x.getAttribute('for') === key
      )?.[0]?.innerText;
    }
    errors[key].message = '';
    if (errors[key].validator) {
      for (const inputValidator of errors[key].validator) {
        // Interate through all inputValidator gave from the errors json for the current key
        let messageError = inputValidator(isAllow, inputName, metaCode);
        if (messageError) {
          errors[key].message += messageError + '\r\n';
          errors.formError = true;
        }
      }
    }
  }
  return errors;
};
