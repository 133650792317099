import API from 'services/API';

// Structures

export const getAllStructureAdmin = () => API.get(`/cooperatives?version=superAdmin_structures`);

export const addStructure = (structure) =>
  API.post(`/cooperatives/add`, structure, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export const updateStructure = (editStructure, id) =>
  API.post(`/cooperatives/edit/${id}`, editStructure, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export const removeStructure = (id) => API.delete(`/cooperatives/remove/${id}`);

export const addNewAdmin = (admin) => API.post('/users/add', admin);

export const removeAdmin = (id) => API.delete(`/users/remove/${id}`);
