// REACT
import { useState } from 'react';
// YARN
import { GridValidRowModel } from '@mui/x-data-grid-pro';
import { Button, ThemeProvider } from '@mui/material';
// SERVICES
import { SectorDataGrid } from './SectorDataGrid';
import More from 'components/icons/More';
import AddEditSectorModal from './form/AddEditSector';
// INTERFACES
import ISector from 'components/generics/Interface/Api/Response/Structures/ISector';
// THEMES
import { customInvertedButtonTheme } from 'assets/styles/themes/generic_button_mui';

type StructureSectorsParams = {
  sectors: ISector[];
  refreshData: () => void;
};
const StructureSectors = ({ sectors, refreshData }: StructureSectorsParams): React.JSX.Element => {
  const [modal, setModal] = useState<boolean>(false);
  const [editData, setEditData] = useState<GridValidRowModel | null | undefined>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const formSuccess = () => {
    setModal(false);
    setEditData(null);
    setIsEdit(false);
    refreshData();
  };

  return (
    <div className="section">
      <div className="main_container">
        <div className="contain-header">
          <h1 className="title_section">Liste des secteurs</h1>
          <div className="add_button_container">
            <ThemeProvider theme={customInvertedButtonTheme}>
              <Button
                onClick={() => {
                  setModal(!modal), setIsEdit(false);
                }}
              >
                Ajouter
                <More height={13} width={13} />
              </Button>
            </ThemeProvider>
          </div>
        </div>
        <SectorDataGrid
          sectors={sectors}
          refreshData={refreshData}
          setModal={setModal}
          setIsEdit={setIsEdit}
          setEditData={setEditData}
        />
      </div>
      {modal && (
        <AddEditSectorModal
          setEditData={setEditData}
          modal={modal}
          setModal={setModal}
          onSubmitSuccess={formSuccess}
          edit={editData}
          isEdit={isEdit}
        />
      )}
    </div>
  );
};

export default StructureSectors;
