import React, { useState } from "react";
import { ModalContext } from "utils/context";

import Trash from 'components/icons/Trash';
import { inputRequired, inputVerifyMail } from 'components/generics/Validators/validator';
import { validateFormData } from "../Validators/form.validate";
import './reportModal.style.scss';


export const ReportModal = ({ techniciansAddressMailList }) => {
    const MAIL_FORM = { mail: '' };
    const [listMailUser, setListMailUser] = useState(techniciansAddressMailList)
    const[checkEmptyListUserMail, setCheckEmptyListUserMail] = useState(false)

    const [errorsFormDefault, setErrorsFormDefault] = useState({
        formError: false,
        mail: {
            message: '',
            validator: [inputRequired, inputVerifyMail]
        },
    })

    const sendSimulation = (context) =>{
        if(listMailUser.length === 0){
         setCheckEmptyListUserMail(true);
        } else{
            context.setConfirmSendReport(true)
            context.setListMailUserFromModal(listMailUser)
            context.toggleModal()
        }
    }

    const addMail = () => {
        errorsFormDefault.formError = false
        const inputValue = document.getElementById('mail').value
        MAIL_FORM.mail = inputValue
        setErrorsFormDefault({ ...validateFormData(MAIL_FORM, errorsFormDefault) })
        if (!errorsFormDefault.formError) {
            setListMailUser(current => [...current, inputValue])
            // Reset input value
            document.getElementById('mail').value = null
            setCheckEmptyListUserMail(false);
        }
    }

    const removeMail = (i) => {
        setListMailUser(current => current.filter((value, index) => index !== i))
    }

    const handleKeyUp = (event) => {
        if (event.key === 'Enter') {
            addMail();
        }
    }

    return (<ModalContext.Consumer>
        {context => {
            if (context.showModal) {
                return (<div className="modal_delete">
                    <div className="content">
                        <div>
                            <p>Le rapport de la simulation sera envoyé aux adresses suivantes :</p>
                        </div>
                        <div style={{ width: '70%' }}>
                            {listMailUser.map((technicianMail, i) =>
                            (
                                <React.Fragment key={'mail' + i}>
                                    <div className="flex justify-space-between">
                                        <div>
                                            <p>{technicianMail}</p>
                                        </div>
                                        <div className="align-self-center" style={{ 'marginLeft': '10px', 'cursor': 'pointer' }}
                                            onClick={() => removeMail(i)}>
                                            <Trash fill={'#e69636'} color={'#e69636'} width={20} height={20} />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                            )}
                            <div>
                                <div>
                                    {errorsFormDefault?.mail.message && <p className="errorForm">{errorsFormDefault?.mail?.message}</p>}
                                </div>
                                <div className="flex justify-space-between report-modal-form">
                                    <div>
                                        <label for="mail">Adresse Mail</label>
                                        <input id="mail" placeholder="Entrez une adresse mail" onKeyUp={handleKeyUp}/>
                                    </div>
                                    <button onClick={() => addMail()}> Ajouter
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>{checkEmptyListUserMail&& <p  className="errorForm"> Aucun mail n'a été renseigné</p>}</div>
                        <div style={{ marginTop: '15px' }}>
                            <button onClick={context.toggleModal}>Annuler</button>
                            
                            <button onClick={() => sendSimulation(context)}>
                                Confirmer
                            </button>
                        </div>
                    </div>
                </div>);
            }
            return null
        }}
    </ModalContext.Consumer>)
}