import actionsProps from 'components/propTypes/actions';
import exploitationProp from 'components/propTypes/exploitation';
import MoreIcon from 'components/icons/MoreIcon';

const ExploitationTableRow = ({ data = {}, actions = [] }) => (
  <tr>
    <td>{data.name}</td>
    <td>{data.code}</td>
    {/* <td><ActionList data={data} actions={actions} /></td> */}
    <td>
      <div role="button" onClick={() => actions[0].func(data)}>
        <MoreIcon />
      </div>
    </td>
  </tr>
);

ExploitationTableRow.propTypes = {
  data: exploitationProp,
  actions: actionsProps,
};

export default ExploitationTableRow;
