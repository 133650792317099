const { createTheme } = require('@mui/material');

export const customDialogColorTheme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            border: '3px solid #e69636 !important',
            borderRadius: '20px',
            textAlign: 'center',
            fontSize: '18px',
            color: '#e69636 !important',
            width: '600px',
            height: '450px',
            overflow: 'hidden !important',
          },
          '& .MuiDialog-paper .MuiBox-root': {
            justifyContent: 'center !important',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          },
          '& .MuiDialog-paper .MuiBox-root .MuiSvgIcon-root': {
            fontSize: '36px',
            color: '#277134 !important',
            position: 'absolute',
            right: '20px',
            cursor: 'pointer',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '20px',
          color: '#e69636',
          fontFamily: 'Rubik-Bold !important',
          color: '#277134 !important',
          userSelect: 'none',
        },
      },
    },
  },
});
