import React, { useContext, useEffect, useState } from 'react';

import Table from 'components/generics/Table/Table';
import Button from 'components/generics/Button';
import ResultTableRow from './table/ResultTableRow';

import { ImportContext } from 'providers/ImportStatus/importStatus.providers';
import { useNavigate } from 'react-router-dom';

function Result() {
  const [data, setData] = useState([]);
  const { result, setResult } = useContext(ImportContext);
  const navigate = useNavigate();
  const [locationRoute, setLocationRoute] = useState('cooperative');

  useEffect(() => {
    setLocationRoute(window.location.pathname.split('/')[1]);
  }, []);

  useEffect(() => {
    if (typeof result?.message === 'string') {
      const data = [{ exploitationName: 'N/A', endStatus: 'ERREUR', info: [result.message] }];
      setData(data);
    } else {
      const data = result?.message?.exploitations.map((m) => {
        return {
          exploitationName: m?.name,
          endStatus: m?.status,
          info: m?.message,
        };
      });
      setData(data);
    }
  }, [result]);

  return (
    <div className="section">
      <div className="main_container">
        <h1 className="title_section">Résultats de l'importation</h1>
        <Table
          header={["Nom de l'exploitation", 'Statut de fin', 'Message']}
          data={data}
          RowElement={ResultTableRow}
        />
      </div>
      <div className="add_button_container">
        <Button
          text="Nouvel import"
          onClick={() => {
            setResult(null);
            navigate(`/${locationRoute}/import`);
          }}
        />
      </div>
    </div>
  );
}

export default Result;
