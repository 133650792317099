// YARN
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
// INTERFACES
import { IStackedParcelsRequestsRef } from 'components/generics/Interface/Exploitation/Parcels/IStackedParcelsRequestsRef';

export const CustomParcelCell = (
  params: GridRenderCellParams,
  stackedParcelsRequestsRef: React.MutableRefObject<IStackedParcelsRequestsRef[] | []>
) => {
  const { id, value, field } = params;
  let hasBeenUpdated = false;

  if (stackedParcelsRequestsRef?.current.length) {
    stackedParcelsRequestsRef?.current.forEach((request) => {
      if (request.parcelId === id && request[field as keyof IStackedParcelsRequestsRef]) {
        hasBeenUpdated = true;
      }
    });
  }

  return (
    <Box className={`cellBox ${hasBeenUpdated ? 'cellHasBeenUpdated' : ''}`}>
      <div>{value}</div>
    </Box>
  );
};

export const CustomParcelSurfaceCell = (
  params: GridRenderCellParams,
  stackedParcelsRequestsRef: React.MutableRefObject<IStackedParcelsRequestsRef[] | []>
) => {
  const { row, value } = params;
  const { surface, totalSurface } = value;

  let formattedSurface = surface;

  if (totalSurface !== surface) {
    formattedSurface = `${surface} (Total: ${totalSurface.toFixed(2).replace(/[.,]00$/, '')})`;
  }
  let hasBeenUpdated = false;

  if (stackedParcelsRequestsRef?.current.length) {
    stackedParcelsRequestsRef?.current.forEach((request) => {
      if (request?.parcelId === row.id && request?.surface) {
        hasBeenUpdated = true;
      }
    });
  }
  return surface !== totalSurface ? (
    <Box className={`cellBox ${hasBeenUpdated ? 'cellHasBeenUpdated' : ''}`}>
      {params.value.surface} (Total: {params.value.totalSurface.toFixed(2).replace(/[.,]00$/, '')})
    </Box>
  ) : (
    <Box className={`cellBox ${hasBeenUpdated ? 'cellHasBeenUpdated' : ''}`}>
      {formattedSurface}
    </Box>
  );
};

export const customIsIrrigableCell = (
  params: GridRenderCellParams,
  stackedParcelsRequestsRef: React.MutableRefObject<IStackedParcelsRequestsRef[] | []>
) => {
  const { id, value } = params;
  let hasBeenUpdated = false;

  if (stackedParcelsRequestsRef?.current.length) {
    stackedParcelsRequestsRef?.current.forEach((request) => {
      if (request?.parcelId === id && 'isIrrigable' in request) {
        hasBeenUpdated = true;
      }
    });
  }
  return value ? (
    <div className={`forecast_checkbox ${hasBeenUpdated ? 'cellHasBeenUpdated' : ''}`}>
      <CheckIcon />
    </div>
  ) : (
    <div className={`forecast_checkbox ${hasBeenUpdated ? 'cellHasBeenUpdated' : ''}`}>
      <CloseIcon />
    </div>
  );
};
