import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import userProp from 'components/propTypes/user';

import {
  getAllConstraintCategories,
  getCoProducts,
  associateCoProducts,
  newCategoryOrCoProduct,
} from 'services/API/Cooperative';

import Label from 'components/generics/Label';
import Select from 'components/generics/Select';
import Button from 'components/generics/Button';
import Close from 'components/icons/Close';
import InputLabel from 'components/generics/InputLabel';

const DEFAULT_FORM = { constraintCategory: '', coProducts: [] };

const CoProductForm = ({ onSubmitSuccess, edit }) => {
  const [defaultEdit, setDefaultEdit] = useState([]);
  const [formData, setFormData] = useState({ ...edit } || DEFAULT_FORM);
  const [categories, setCategories] = useState([]);
  const [coProducts, setCoProducts] = useState([]);
  const [coProductsIn, setCoProductsIn] = useState(null);
  const [filteredCoProducts, setFilteredCoProducts] = useState(null);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const cooperativeId = Number(localStorage.getItem('cooperative'));

  const removeCoProductIn = (key) => {
    let tempCoProduct = [...coProductsIn];
    tempCoProduct.splice(key, 1);
    formData.coProducts.splice(key, 1);
    setCoProductsIn(tempCoProduct);
  };

  const addCoProductIn = (key) => {
    let tempCoProduct = [...(coProductsIn || [])];
    tempCoProduct.push(key);
    setCoProductsIn(tempCoProduct);
    formData.coProducts.push(key);
  };

  useEffect(() => {
    getAllConstraintCategories().then((e) => {
      setCategories([{ id: null, displayName: 'Aucun' }].concat(e.data.data));
    });
    getCoProducts().then((e) => {
      setCoProducts([{ id: null, displayName: 'Aucun' }].concat(e.data.data));
    });

    if (edit?.coProducts) {
      let refCoProducts = [];
      for (var i = 0; i < edit.coProducts.length; i++) {
        formData.coProducts[i] = edit.coProducts[i];
        refCoProducts.push(edit.coProducts[i]);
      }
      setCoProductsIn(formData.coProducts);
      setDefaultEdit(refCoProducts);
    } else formData.coProducts = [];
  }, []);

  useEffect(() => {
    let tempFilteredCoProduct = coProductsIn
      ? coProducts.filter((coProduct) => !coProductsIn.some((item) => coProduct.id === item.id))
      : coProducts;
    setFilteredCoProducts(tempFilteredCoProduct);
  }, [coProducts, coProductsIn]);

  const onSubmit = (e) => {
    setLoading(true);
    setError(null);
    if (e) e.preventDefault();
    if (formData.constraintCategory && formData.coProducts) {
      associateCoProducts({
        cooperative: cooperativeId,
        constraintCategory: formData.constraintCategory.id,
        coProducts: formData.coProducts.map((coProduct) => {
          return coProduct.id;
        }),
      })
        .then((response) => {
          if (response.data.message === 'Association succeeded') {
            toast.success(`Co-Produit(s) associé(s) à ${formData.constraintCategory.displayName}`);
          } else if (response.data.message === 'Removed successfully') {
            toast.success(`Co-Produit(s) retiré(s) de ${formData.constraintCategory.displayName}`);
          } else {
            console.error('Unkonwn message');
          }
          setFormData(DEFAULT_FORM);
          onSubmitSuccess();
        })
        .catch((err) => {
          toast.error(err.response.data['message']);
          setCoProductsIn(defaultEdit);
        });
    } else if (formData.newCategory || formData.newCoProduct) {
      let data = { cooperative: cooperativeId };
      let message = '';
      let plural = '';
      if (formData.newCategory) {
        data.newCategory = formData.newCategory;
        message += formData.newCategory;
        plural = 'e';
      }
      if (formData.newCoProduct) {
        data.newCoProduct = formData.newCoProduct;
        plural = message !== '' ? 's' : '';
        message += message !== '' ? ' et ' + formData.newCoProduct : formData.newCoProduct;
      }
      newCategoryOrCoProduct(data)
        .then((response) => {
          if (response.data.message === 'Creation succeeded') {
            toast.success(`${message} créé${plural}`);
          } else {
            console.error('Unkonwn message');
          }
          setFormData(DEFAULT_FORM);
          onSubmitSuccess();
        })
        .catch((err) => toast.error(err.response.data['message']));
    } else {
      setError('Certains champs sont vides');
    }
    setLoading(false);
  };

  const updateForm = (key, value) => {
    if (key === 'coProducts') {
      addCoProductIn(value);
    } else {
      setFormData({ ...formData, [key]: value });
    }
  };

  return (
    <>
      <h1>{edit ? 'Associer' : 'Ajouter '} un co-produit</h1>
      {error?.length ? <p className="errorForm">{error}</p> : null}
      <form onSubmit={onSubmit}>
        {!edit && categories && (
          <section className="">
            <Label
              name="categoryLabel"
              htmlFor="constraintCategory"
              text="Catégorie"
              className="secondary"
            >
              <Select
                className="form"
                name="constraintCategory"
                onChange={(e) =>
                  updateForm(
                    'constraintCategory',
                    categories.filter((constraintCategory) => constraintCategory?.id === +e)[0]
                  )
                }
                options={categories}
                value={formData?.constraintCategory ? formData?.constraintCategory.id : ''}
                itemLabel="displayName"
                itemValue="id"
              />
            </Label>
          </section>
        )}
        {edit && categories && (
          <section className="">
            <Label
              name="categoryLabel"
              htmlFor="constraintCategory"
              text="Catégorie"
              className="secondary"
            >
              <Select
                className="form"
                name="constraintCategory"
                onChange={(e) =>
                  updateForm(
                    'constraintCategory',
                    categories.filter((constraintCategory) => constraintCategory?.id === +e)[0]
                  )
                }
                options={categories}
                value={formData?.constraintCategory ? formData?.constraintCategory.id : ''}
                itemLabel="displayName"
                itemValue="id"
              />
            </Label>
          </section>
        )}
        {!edit && (
          <section className="flex-row-form">
            <InputLabel
              name="newCoProduct"
              value={formData.newCoProduct ? formData.newCoProduct : ''}
              onChange={(e) => {
                updateForm('newCoProduct', e);
              }}
              defaultStyle="secondary width-middle"
              placeholder="Ajouter un co-produit"
              label="Nouveau co-produit :"
              disabled={loading}
            />
            <InputLabel
              name="newCoProductUnit"
              value={formData.newCoProductUnit ? formData.newCoProductUnit : ''}
              onChange={(e) => {
                updateForm('newCoProductUnit', e);
              }}
              defaultStyle="secondary width-middle"
              placeholder="Ajouter l'unité"
              label="Affichage dans simulation :"
              disabled={loading}
            />
          </section>
        )}
        {edit && filteredCoProducts && (
          <section className="">
            <Label
              name="coProductLabel"
              htmlFor="coProduct"
              text="Co-Produits"
              className="secondary"
            >
              <Select
                className="form"
                name="coProduct"
                onChange={(e) =>
                  updateForm(
                    'coProducts',
                    coProducts.filter((coProduct) => coProduct?.id === +e)[0]
                  )
                }
                options={filteredCoProducts}
                value={formData?.coProducts ? formData?.coProducts.id : ''}
                itemLabel="displayName"
                itemValue="id"
              />
            </Label>
            <div className="admin-box">
              {coProductsIn?.map((coProductIn, index) => (
                <div key={index}>
                  <Button
                    onClick={() => removeCoProductIn(index)}
                    text={coProductIn.displayName}
                    defaultStyle="admin-button"
                    backgroundColor="#277134"
                    textColor="#ebebeb"
                    disabled={loading}
                    icon={<Close width={13} height={13} />}
                  />
                </div>
              ))}
            </div>
          </section>
        )}
        <Button
          defaultStyle="m-auto d-block form"
          type="submit"
          text={edit ? 'Enregistrer' : 'Ajouter'}
          disabled={loading}
        />
      </form>
    </>
  );
};

CoProductForm.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
  edit: function (props, propName, componentName) {
    const propValue = props[propName];
    if (propValue === null) return;
    if (PropTypes.shape(userProp)) return;
    return new Error(`${componentName} only accepts null or coProductProp`);
  },
  secteur: PropTypes.number.isRequired,
};

export default CoProductForm;
