import { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  comparatorCalculate,
  comparatorSimulate,
  getComparatorResult,
  getSimulationStatus,
} from 'services/API/Simulation';
import { sendEvent } from 'utils/Event';
import { displayErrorMessage } from 'utils/tools_functions';
import LineGraph from './LineGraph';
import DataGridKeyFigures from './DataGridKeyFigures';
import DataGridParcelsSynthesis from './DataGridParcelsSynthesis';
import DataGridCAPPoints from './DataGridCAPPoints';
import CustomPieProduction from './CustomPieProduction';
import Loader from 'components/generics/Loader';
import CustomPieProductionGrossMargin from './CustomPieProductionGrossMargin';
import { colors } from 'utils/const';
import useCultureColors from './useCultureColors';
import { NavContext } from 'utils/context';

const Result = ({ exploitationSelected, setExploitation }) => {
  const [loading, setLoading] = useState(true);
  const { showBoundary } = useErrorBoundary();
  const location = useLocation();
  const navigate = useNavigate();
  const [exploitationContext, setExploitationContext] = useState(exploitationSelected);
  const selectedParcelsIds = location.state.selectedParcels;
  const [jobId, setJobId] = useState('');
  const [comparatorCalculatorResult, setComparatorCalculatorResult] = useState(null);
  const [comparatorSimulationResult, setComparatorSimulationResult] = useState(null);
  const [updatedSimulationSuggest, setUpdatedSimulationSuggest] = useState(null);
  const [updatedSimulationRepartitionIrrigation, setUpdatedSimulationRepartitionIrrigation] =
    useState(null);
  const [isSimulationEdited, setIsSimulationEdited] = useState(false);
  const { isNavBarOpen } = useContext(NavContext);
  const [colorList, setColorList] = useState(colors);
  const currentYear = +localStorage.getItem('currentYear');
  const shuffleColorList = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };
  const cropsHectaresDistribution =
    comparatorCalculatorResult?.result?.years[0]?.hectaresDistribution;

  const shuffledColorList = useMemo(() => colorList && shuffleColorList(colorList), [colorList]);

  const [getCultureColor] = useCultureColors(
    exploitationContext,
    cropsHectaresDistribution,
    comparatorCalculatorResult,
    shuffledColorList
  );

  const shuffledColorsArray =
    getCultureColor &&
    comparatorCalculatorResult?.result.years[0].hectaresDistribution.map((result, index) => ({
      [result.culture.name]: getCultureColor(result, index),
    }));

  useEffect(() => {
    sendEvent('loaderFullScreen', { loaderNeeded: false });
    getComparatorCalculatorResult();
    getComparatorSimulationResult();
  }, []);

  const body = {
    ...(selectedParcelsIds.length && { parcels: selectedParcelsIds }),
    year: exploitationContext?.currentYear + 1,
  };

  const getComparatorCalculatorResult = async () => {
    try {
      const result = await comparatorCalculate(exploitationContext?.id, body);
      const { data } = result.data;
      setComparatorCalculatorResult(data);
      // setLoading(false);
    } catch (error) {
      if (error?.response) {
        toast.error(error.response.data.message, { autoClose: false });
        showBoundary({ message: error.response.data.message, jobId });
      } else {
        toast.error(displayErrorMessage('ERR_UNEXPECTED'), { autoClose: false });
        showBoundary({ message: displayErrorMessage('ERR_UNEXPECTED'), jobId });
      }
    }
  };

  const getComparatorSimulationResult = async () => {
    if (!exploitationContext?.id) {
      try {
        const response = await setExploitation(+localStorage.getItem('exploitation'));
        const { data } = response.data;
        setExploitationContext(data);
      } catch (error) {
        toast.error(err.data);
      }
    }
    try {
      const result = await comparatorSimulate(exploitationContext?.id, body);
      const { data } = result.data;
      const jobId = data.job_id;
      setJobId(jobId);
      retrieveJob(jobId);
    } catch (error) {
      if (error?.response) {
        toast.error(error.response.data.message, { autoClose: false });
        showBoundary({ message: error.response.data.message, jobId });
      } else {
        toast.error(displayErrorMessage('ERR_UNEXPECTED'), { autoClose: false });
        showBoundary({ message: displayErrorMessage('ERR_UNEXPECTED'), jobId });
      }
    }
  };

  const retrieveJob = (jobId) => {
    setTimeout(async () => {
      const status = await getSimulationStatus(jobId);
      if (status.data.data.status !== 'COMPLETE' && status.data.data.status !== 'ERROR') {
        retrieveJob(jobId);
      } else {
        await getJobResult(jobId);
      }
    }, 2000);
  };

  const getJobResult = async (jobId) => {
    try {
      const response = await getComparatorResult(exploitationContext?.id, jobId);
      const { data } = response.data;
      setComparatorSimulationResult(data);
      setUpdatedSimulationSuggest(data.suggestions.suggestion1.parcels);
      setLoading(false);
      sendEvent('loaderFullScreen', { loaderNeeded: true });
    } catch (error) {
      const errorToDisplay = error?.response?.data || null;
      if (errorToDisplay?.data) {
        for (const error of errorToDisplay.data) {
          toast.error(displayErrorMessage(error.err_code, error.message), {
            autoClose: false,
          });
        }
        sendEvent('loaderFullScreen', { loaderNeeded: true });
        navigate('/exploitation/comparator');
      } else {
        toast.error(displayErrorMessage('ERR_UNEXPECTED'), { autoClose: false });
        showBoundary({ message: displayErrorMessage('ERR_UNEXPECTED'), jobId });
      }
    }
  };

  const calculatorCultures = useCallback(() => {
    return updatedSimulationSuggest.map((simulationParcel) => [simulationParcel.cultures[0].id]);
  }, [updatedSimulationSuggest]);

  const handleCalculator = async () => {
    const calculatorBody = {
      ...body,
      proposition: {
        cultureNn: calculatorCultures(),
      },
    };
    const recalculate = await comparatorCalculate(exploitationContext.id, calculatorBody);
    const { data } = recalculate.data;

    setComparatorSimulationResult((prevSimulation) => ({
      ...prevSimulation,
      years: data.result.years,
    }));
    setIsSimulationEdited(false);
  };

  return loading ? (
    <div>
      <Loader />
      {jobId && <p className="job_id_display text-center">Job Id : {jobId}</p>}
    </div>
  ) : (
    <div className="section">
      <div className="result-main">
        <div className="contain-title" style={{ top: isNavBarOpen ? '125px' : '76px' }}>
          <h1 className="title_section title_section_forecast">Résultat du Comparateur</h1>
          <button onClick={() => navigate('/exploitation/comparator')}>Recommencer</button>
        </div>
        <div className="container-result">
          <div className="result-tableDirection">
            <div className="table-columnLeft">
              <div className="block-graph">
                <h2 className="title_section_result">Marge Brute (prévisionnel)</h2>
                <LineGraph
                  currentYear={currentYear}
                  comparatorSimulationSuggest={comparatorSimulationResult}
                  comparatorCalculatorResult={comparatorCalculatorResult}
                />
              </div>
              <div className="block-graph block-CAPPoints">
                <h2 className="title_section_result">Point(s) catégorie(s) PAC</h2>
                <DataGridCAPPoints
                  comparatorSimulationResult={comparatorSimulationResult}
                  comparatorCalculatorResult={comparatorCalculatorResult}
                  currentYear={currentYear}
                />
              </div>
              <div className="block-graph">
                <h2 className="title_section_result">Chiffres clés</h2>
                <DataGridKeyFigures
                  comparatorSimulationResult={comparatorSimulationResult}
                  comparatorCalculatorResult={comparatorCalculatorResult}
                  currentYear={currentYear}
                />
              </div>
            </div>
            <div className="graph-columnRight">
              <div className="block-graph">
                <h2 className="title_section_result">Marge Brute</h2>
                <CustomPieProductionGrossMargin
                  exploitationContext={exploitationContext}
                  comparatorSimulationResult={comparatorSimulationResult}
                  comparatorCalculatorResult={comparatorCalculatorResult}
                  currentYear={currentYear}
                  shuffledColorsArray={shuffledColorsArray}
                />
              </div>
              <div className="block-graph">
                <h2 className="title_section_result">Production</h2>
                <CustomPieProduction
                  exploitationContext={exploitationContext}
                  comparatorSimulationResult={comparatorSimulationResult}
                  comparatorCalculatorResult={comparatorCalculatorResult}
                  currentYear={currentYear}
                  shuffledColorsArray={shuffledColorsArray}
                />
              </div>
            </div>
          </div>
          <div className="block-parcelSynthesis">
            <h2 className="title_section_result">Synthèse par parcelle</h2>
            {/* //? uncomment to reenable calculator button */}
            {/* <button
              className="button-calculate"
              onClick={handleCalculator}
              disabled={!isSimulationEdited}
            >
              Valider
            </button> */}
            <DataGridParcelsSynthesis
              exploitationContext={exploitationContext}
              comparatorSimulationResult={comparatorSimulationResult}
              comparatorCalculatorResult={comparatorCalculatorResult}
              updatedSimulationSuggest={updatedSimulationSuggest}
              setUpdatedSimulationSuggest={setUpdatedSimulationSuggest}
              setUpdatedSimulationRepartitionIrrigation={setUpdatedSimulationRepartitionIrrigation}
              setIsSimulationEdited={setIsSimulationEdited}
              currentYear={currentYear}
            />
            <p className="legend_comparator_result_synthesis">
              <span>*</span> Parcelle parente d'un regroupement.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
