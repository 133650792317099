import { useEffect, useState } from 'react';

const useDataGridLoading = (comparatorSimulationSuggest, comparatorCalculatorResult) => {
  const [dataGridLoading, setDataGridLoading] = useState(true);

  useEffect(() => {
    if (comparatorSimulationSuggest && comparatorCalculatorResult) {
      setDataGridLoading(false);
    }
  }, [comparatorSimulationSuggest]);

  return dataGridLoading;
};

export default useDataGridLoading;
