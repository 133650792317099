const localeTextConstants = {
  noRowsLabel: 'Aucune donnée',
  noResultsOverlayLabel: 'Aucun résultat trouvé.',

  // Density selector toolbar button text
  toolbarDensity: 'Densité',
  toolbarDensityLabel: 'Densité',
  toolbarDensityCompact: 'Compact',
  toolbarDensityStandard: 'Standard',
  toolbarDensityComfortable: 'Confortable',

  // Columns selector toolbar button text
  toolbarColumns: 'Colonnes',
  toolbarColumnsLabel: 'Sélectionner les colonnes',

  // Filters toolbar button text
  toolbarFilters: 'Filtres',
  toolbarFiltersLabel: 'Afficher les filtres',
  toolbarFiltersTooltipHide: 'Masquer les filtres',
  toolbarFiltersTooltipShow: 'Afficher les filtres',

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: 'Rechercher…',
  toolbarQuickFilterLabel: 'Rechercher',
  toolbarQuickFilterDeleteIconLabel: 'Effacer',

  // Export selector toolbar button text
  toolbarExport: 'Exporter',
  toolbarExportLabel: 'Exporter',
  toolbarExportCSV: 'Télécharger en CSV',
  toolbarExportPrint: 'Imprimer',
  toolbarExportExcel: 'Télécharger en Excel',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Trouver une colonne',
  columnsPanelTextFieldPlaceholder: 'Titre de la colonne',
  columnsPanelDragIconLabel: 'Réorganiser la colonne',

  // Columns management text
  columnsManagementSearchTitle: 'Rechercher',
  columnsManagementNoColumns: 'Aucune colonnes',
  columnsManagementShowHideAllText: 'Montrer/cacher tout',
  columnsManagementReset: 'Réinitialiser',

  // Filtre panel text
  filterPanelAddFilter: 'Ajouter un filtre',
  filterPanelRemoveAll: 'Supprimer tout',
  filterPanelDeleteIconLabel: 'Supprimer',
  filterPanelLogicOperator: 'Opérateur logique',
  filterPanelOperator: 'Opérateur',
  filterPanelOperatorAnd: 'Et',
  filterPanelOperatorOr: 'Ou',
  filterPanelColumns: 'Colonnes',
  filterPanelInputLabel: 'Valeur',
  filterPanelInputPlaceholder: 'Valeur du filtre',

  // Header filter operators text
  filterOperatorContains: 'contient',
  filterOperatorEquals: 'égal à',
  filterOperatorStartsWith: 'commence par',
  filterOperatorEndsWith: 'se termine par',
  filterOperatorIs: 'est',
  filterOperatorNot: "n'est pas",
  filterOperatorAfter: 'est après',
  filterOperatorOnOrAfter: 'est le ou après',
  filterOperatorBefore: 'est avant',
  filterOperatorOnOrBefore: 'est le ou avant',
  filterOperatorIsEmpty: 'est vide',
  filterOperatorIsNotEmpty: "n'est pas vide",
  filterOperatorIsAnyOf: 'fait partie de',
  'filterOperator=': '=',
  'filterOperator!=': '!=',
  'filterOperator>': '>',
  'filterOperator>=': '>=',
  'filterOperator<': '<',
  'filterOperator<=': '<=',

  // Filter values text
  headerFilterOperatorContains: 'Contient',
  headerFilterOperatorEquals: 'Égal à',
  headerFilterOperatorStartsWith: 'Commence par',
  headerFilterOperatorEndsWith: 'Se termine par',
  headerFilterOperatorIs: 'Est',
  headerFilterOperatorNot: "N'est pas",
  headerFilterOperatorAfter: 'Est après',
  headerFilterOperatorOnOrAfter: 'Est le ou après',
  headerFilterOperatorBefore: 'Est avant',
  headerFilterOperatorOnOrBefore: 'Est le ou avant',
  headerFilterOperatorIsEmpty: 'Est vide',
  headerFilterOperatorIsNotEmpty: "N'est pas vide",
  headerFilterOperatorIsAnyOf: 'Fait partie de',
  'headerFilterOperator=': 'Égal à',
  'headerFilterOperator!=': "N'est pas égal à",
  'headerFilterOperator>': 'Plus grand que',
  'headerFilterOperator>=': 'Plus grand ou égal à',
  'headerFilterOperator<': 'Moins que',
  'headerFilterOperator<=': 'Moins que ou égal à',

  // Total row amount footer text
  footerTotalRows: 'Nombre total de lignes:',

  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} lignes sélectionnées`
      : `${count.toLocaleString()} ligne sélectionnée`,
};

export default localeTextConstants;
