import useAUTH from 'providers/Auth/useAuth';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getExploitationTypes,
  getGeofoliaPreflight,
  getSecteurLight,
  importParcelsGeofolia,
  importParcelsResult,
  importParcelsStatus,
} from 'services/API/Cooperative';
import Table from 'components/generics/Table/Table';
import Button from 'components/generics/Button';
import Loader from 'components/generics/Loader';
import { toast } from 'react-toastify';
import { displayErrorMessage, displayMessage } from 'utils/tools_functions';
import { ImportContext } from 'providers/ImportStatus/importStatus.providers';
import { LoadingContext } from 'utils/context';
import ExploitationTableRowGeofolia from './table/ExploitationTableRowGeofolia';

function GeofoliaProcess() {
  const [preflightJobId, setPreflightJobId] = useState(null);
  const [importJobId, setImportJobId] = useState(null);
  const [cooperativeId, setCooperativeId] = useState(null);
  const [exploitationTypes, setExploitationTypes] = useState(null);
  const [cooperativeSectors, setCooperativeSectors] = useState(null);
  const [exploitationsToImport, setExploitationsToImport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  const [dataToHandle, setDataToHandle] = useState(null);
  const [checked, setChecked] = useState(null);
  const [selectedExploitationsTypes, setSelectedExploitationsTypes] = useState(null);
  const [selectedSectors, setSelectedSectors] = useState('');
  const [locationRoute, setLocationRoute] = useState('cooperative');

  const location = useLocation();
  const { user } = useAUTH();
  const { result, setResult } = useContext(ImportContext);
  const { resetDataLoader, setCustomText, setForceLoader } = useContext(LoadingContext);
  const navigate = useNavigate();

  useEffect(() => {
    setLocationRoute(window.location.pathname.split('/')[1]);
  }, []);

  useEffect(() => {
    if (exploitationsToImport && exploitationTypes && !dataToHandle) {
      loadAllDataToHandle();
    }
    setChecked(
      exploitationsToImport?.map((exploitationToImport) => ({
        code: exploitationToImport.exploitation.code,
        checked: true,
      }))
    );
    setSelectedExploitationsTypes(
      exploitationsToImport?.map((exploitationToImport) => {
        if (exploitationToImport.exploitation.exploitationTypeName) {
          const foundExploitationType = exploitationToImport.type.filter(
            (d) => d.exploitationTypeName === exploitationToImport.exploitation.exploitationTypeName
          );
          if (foundExploitationType.length > 0) {
            return {
              code: exploitationToImport.exploitation?.code,
              exploitationType: foundExploitationType[0].id,
            };
          } else {
            return {
              code: exploitationToImport.exploitation?.code,
              exploitationType: exploitationToImport.type.find((d) => d.isDefaultType === true)?.id,
            };
          }
        } else {
          return {
            code: exploitationToImport.exploitation?.code,
            exploitationType: exploitationToImport.type.find((d) => d.isDefaultType === true)?.id,
          };
        }
      })
    );
    setSelectedSectors(
      exploitationsToImport?.map((exploitationToImport) => ({
        code: exploitationToImport.exploitation?.code,
        sectorId: exploitationToImport.exploitation.sector?.id,
      }))
    );
  }, [exploitationsToImport, exploitationTypes]);

  useEffect(() => {
    if (result) {
      navigate(`/${locationRoute}/import/result`);
    }
  }, [result, locationRoute]);

  useEffect(() => {
    setLoading(true);
    setPreflightJobId(location.state.jobId);
  }, [location]);

  useEffect(() => {
    const cooperativeId = localStorage.getItem('cooperative') ?? user.cooperative.id;
    if (!cooperativeId) {
      toast.info('Selectionnez une cooperative');
      navigate('/cooperative');
    }
    setCooperativeId(cooperativeId);
    loadExploitationTypes();
    loadCooperativeSectors(cooperativeId);
  }, [user]);

  const loadAllDataToHandle = () => {
    const processedExploitations = exploitationsToImport.map((exploitationToImport) => {
      return {
        exploitationId: exploitationToImport.exploitation.id,
        sectorId: exploitationToImport.exploitation.sector?.id,
        exploitationTypeId: processExploitationType(exploitationToImport),
        code: exploitationToImport.exploitation.code,
        name: exploitationToImport.exploitation.name,
      };
    });
    setDataToHandle(processedExploitations);
  };

  const processExploitationType = (exploitationToImport) => {
    if (exploitationToImport.exploitation.exploitationTypeName) {
      const foundExploitationType = exploitationTypes.find(
        (d) => d.exploitationTypeName === exploitationToImport.exploitation.exploitationTypeName
      );
      if (foundExploitationType) {
        return foundExploitationType.id;
      } else {
        return exploitationTypes.find((exploitationType) => exploitationType.isDefaultType === true)
          ?.id;
      }
    } else {
      return exploitationTypes.find((exploitationType) => exploitationType.isDefaultType === true)
        ?.id;
    }
  };

  const loadExploitationTypes = async () => {
    try {
      const result = await getExploitationTypes();
      setExploitationTypes(result.data.data);
    } catch (error) {
      toast.error(displayErrorMessage('ERR_GET_EXPLOITATION_TYPES'));
    }
  };

  const loadCooperativeSectors = async (cooperativeId) => {
    try {
      const result = await getSecteurLight(cooperativeId);
      setCooperativeSectors(result.data.data);
    } catch (error) {
      toast.error(displayErrorMessage('ERR_GET_COOPERATIVE_SECTORS'));
    }
  };

  useEffect(() => {
    if (cooperativeId && cooperativeSectors?.length && exploitationTypes?.length) {
      loadGeofoliaPreflight(preflightJobId);
    }
  }, [preflightJobId, cooperativeSectors, exploitationTypes]);

  const loadGeofoliaPreflight = async (jobId) => {
    try {
      const result = await getGeofoliaPreflight(jobId);
      const { data } = result.data;
      const processedData = data.map((exploitation) => ({
        exploitation,
        secteur: cooperativeSectors,
        type: exploitationTypes,
      }));
      setExploitationsToImport(processedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(displayErrorMessage('ERR_IMPORT'));
      navigate('/cooperative/import');
    }
  };

  const handleData = (data, indexExploitation) => {
    const processedData = {
      ...data,
      code: exploitationsToImport[indexExploitation].exploitation.code,
      name: exploitationsToImport[indexExploitation].exploitation.name,
    };
    const existingData = dataToHandle.filter(
      (datumToHandle) => datumToHandle.code === processedData.code
    );
    if (existingData.length) {
      setDataToHandle((prevDataToHandle) => {
        return prevDataToHandle.map((datumToHandle) =>
          datumToHandle.code === processedData.code
            ? {
                ...processedData,
              }
            : { ...datumToHandle }
        );
      });
    } else {
      setDataToHandle((prevDataToHandle) => [...prevDataToHandle, processedData]);
    }
  };

  const deleteData = (exploitationCode) => {
    setDataToHandle((prevDataToHandle) =>
      prevDataToHandle?.filter((prevDatumToHandle) => prevDatumToHandle.code !== exploitationCode)
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectAll(false);
      setDataToHandle([]);
      setChecked((prevChecked) =>
        prevChecked.map((prevSingleChecked) => ({
          ...prevSingleChecked,
          checked: false,
        }))
      );
    } else {
      setSelectAll(true);
      setDataToHandle(
        exploitationsToImport.map((exploitationToImport) => {
          const getExploitationType = selectedExploitationsTypes?.find(
            (selectedExploitationType) =>
              selectedExploitationType.code === exploitationToImport.exploitation.code
          ).exploitationType;

          const getExploitationSector = selectedSectors?.find(
            (selectedSector) => selectedSector.code === exploitationToImport.exploitation.code
          ).sectorId;

          return {
            exploitationId: exploitationToImport.exploitation.id,
            sectorId: Number(getExploitationSector),
            exploitationTypeId: Number(getExploitationType),
            code: exploitationToImport.exploitation.code,
            name: exploitationToImport.exploitation.name,
          };
        })
      );
      setChecked((prevChecked) =>
        prevChecked.map((prevSingleChecked) => ({
          ...prevSingleChecked,
          checked: true,
        }))
      );
    }
  };

  const sendData = async () => {
    if (!dataToHandle.length) {
      return toast.error(displayErrorMessage('ERR_NO_DATA_SELECTED'));
    } else {
      const missingData = dataToHandle.some(
        (datum) => !datum?.sectorId || !datum?.exploitationTypeId
      );
      if (missingData) {
        return toast.error(displayErrorMessage('ERR_MISSING_DATA'));
      }
    }
    let formatedData = dataToHandle.map((exploitation) => ({
      sector: {
        id: exploitation.sectorId,
      },
      exploitationType: exploitation.exploitationTypeId,
      code: exploitation.code,
      name: exploitation.name,
    }));
    try {
      const response = await importParcelsGeofolia(preflightJobId, formatedData);
      const { jobId } = response.data;
      checkStatus(jobId);
      setImportJobId(jobId);
      toast.success("L'importation a commencé");
    } catch (error) {
      toast.error(displayErrorMessage('ERR_IMPORT'));
    }
  };

  const checkStatus = (jobId) => {
    const interval = setInterval(async () => {
      try {
        setForceLoader(true);
        setCustomText(
          displayMessage(
            dataToHandle.length > 1
              ? 'IMPORT_GEOFOLIA_EXPLOITATIONS'
              : 'IMPORT_GEOFOLIA_EXPLOITATION'
          )
        );
        const response = await importParcelsStatus(jobId);
        const { message } = response.data;
        if (message !== 'En cours') {
          getJobResult(jobId);
          clearInterval(interval);
          if (message === 'Fini sans erreur') {
            toast.success(`Importation terminée sans erreur`);
            resetDataLoader();
          } else {
            toast.error(displayErrorMessage('ERR_COMPLETE_IMPORT'));
            resetDataLoader();
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 3000);
  };

  const getJobResult = async (jobId) => {
    try {
      const response = await importParcelsResult(jobId);
      setResult(response.data);
    } catch (error) {
      toast.error(displayErrorMessage('ERR_COMPLETE_IMPORT'));
    }
  };

  return (
    <div className="section">
      <div className="main_container">
        <h1 className="title_section">Liste des exploitations Geofolia à importer</h1>
        <div className="select_all_button_container">
          <Button
            text={selectAll ? 'Tout désélectionner' : 'Tout sélectionner'}
            onClick={() => handleSelectAll()}
          />
        </div>
        {loading ? (
          <Loader />
        ) : (
          <Table
            header={['', 'Exploitations', 'Secteurs Assolia', "Types d'exploitation"]}
            data={exploitationsToImport}
            RowElement={ExploitationTableRowGeofolia}
            handleData={handleData}
            deleteData={deleteData}
            checked={checked}
            setChecked={setChecked}
            selectedExploitationsTypes={selectedExploitationsTypes}
            setSelectedExploitationsTypes={setSelectedExploitationsTypes}
            selectedSectors={selectedSectors}
            setSelectedSectors={setSelectedSectors}
          />
        )}
      </div>
      <div className="add_button_container">
        <Button text="Valider" onClick={() => sendData()} />
      </div>
    </div>
  );
}

export default GeofoliaProcess;
