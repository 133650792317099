import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCallbackDetails,
  GridCellEditStopParams,
  GridCellParams,
  GridColDef,
  GridRowClassNameParams,
  GridRowModesModel,
  GridRowsProp,
  GridSortModel,
  GridTreeNode,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useSingleClickEditDataGrid from '../../components/pages/Exploitation/Comparator/useSingleClickEditDataGrid';
import { customDataGridResultTheme } from './CustomForecastDataGridUtils';
import { mediaQuery } from './CustomGenericDataGridUtils';
import { CustomNoRowsOverlay } from './CustomRotateMatricesDataGridUtils';
import { ClickAwayListener, Theme } from '@mui/material';

type CustomForecastDataGridResultParams = {
  rows: GridRowsProp;
  columns: GridColDef[];
  isLoading?: boolean;
  fullSize: boolean;
  theme?: Theme;
  groupingColDef?: object;
  getTreeDataPath?: ((row: any) => string[]) | undefined;
  getRowClassName?: ((params: GridRowClassNameParams<any>) => string) | undefined;
  processRowUpdate?: ((newRow: any, oldRow: any) => any) | undefined;
  sortModel?: GridSortModel | undefined;
  treeData?: boolean | undefined;
  simulationContext?: string;
  fromSimulation?: boolean;
  fromRotateEffects?: boolean;
  onCellEditStop?: ((params: GridCellEditStopParams, event: MuiEvent) => void) | undefined;
  handleRowModesModelChange?:
    | ((rowModesModel: GridRowModesModel, details: GridCallbackDetails) => void)
    | undefined;
  rowModesModel?: undefined | any;
  setRowModesModel?: undefined | React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  fromCapPoints?: boolean;
  isCellEditable?: ((params: GridCellParams<any, any, any, GridTreeNode>) => boolean) | undefined;
  fromKeyNumberNitrogen?: boolean;
  fromCapPointsSimulation?: boolean;
};

const CustomForecastDataGridResult = ({
  rows,
  columns,
  fullSize,
  theme = undefined,
  groupingColDef = undefined,
  getTreeDataPath = undefined,
  getRowClassName = undefined,
  isLoading = false,
  processRowUpdate = undefined,
  sortModel = undefined,
  treeData = false,
  simulationContext = '',
  fromSimulation = false,
  fromRotateEffects = false,
  onCellEditStop = undefined,
  handleRowModesModelChange = undefined,
  rowModesModel = undefined,
  setRowModesModel = undefined,
  fromCapPoints = false,
  isCellEditable = undefined,
  fromKeyNumberNitrogen = false,
  fromCapPointsSimulation = false,
}: CustomForecastDataGridResultParams) => {
  const apiRef = useGridApiRef();

  const { cellModesModel, handleCellClick, handleCellModesModelChange } =
    useSingleClickEditDataGrid();

  const closeRowAfterScrollbarClick = () => {
    for (const key in rowModesModel) {
      if (
        rowModesModel[key]?.mode === 'edit' &&
        rowModesModel[key].hasOwnProperty('fromScrollBar')
      ) {
        apiRef.current.stopCellEditMode({ id: key, field: rowModesModel[key].fieldToFocus });
        if (setRowModesModel !== undefined) {
          setRowModesModel({});
        }
      }
    }
  };

  return (
    <ClickAwayListener onClickAway={closeRowAfterScrollbarClick}>
      <Box
        sx={{
          height: 'auto',
        }}
        className={simulationContext ? simulationContext : 'box'}
      >
        <ThemeProvider theme={theme ?? customDataGridResultTheme}>
          <DataGridPro
            sx={{
              '& .MuiDataGrid-virtualScroller': {
                overflowX: fromSimulation || fromRotateEffects ? 'auto !important' : '',
                maxHeight: !fullSize
                  ? '400px'
                  : fromRotateEffects
                  ? '70vh'
                  : fromSimulation
                  ? 'none'
                  : '100vh',
                [`@media (max-width: ${mediaQuery.breakpoints.values.lg}px)`]: {
                  maxHeight: !fullSize ? '400px' : fromSimulation ? 'none' : '51vh',
                },
              },
              '& .MuiDataGrid-cell': {
                display: fromCapPoints ? 'block' : '',
                lineHeight: fromCapPoints ? '2.5 !important' : '',
              },
            }}
            rows={rows}
            onCellEditStop={(params, event) => {
              if (fromRotateEffects) {
                if (onCellEditStop !== undefined) {
                  onCellEditStop(params, event);
                }
              }
            }}
            apiRef={apiRef}
            onRowClick={closeRowAfterScrollbarClick}
            onRowModesModelChange={handleRowModesModelChange}
            columns={columns}
            columnHeaderHeight={fromRotateEffects ? 80 : 56}
            getRowClassName={getRowClassName}
            cellModesModel={cellModesModel}
            onCellModesModelChange={handleCellModesModelChange}
            onCellClick={handleCellClick}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={(error) => console.log(error)}
            loading={isLoading}
            disableColumnMenu
            hideFooter
            autoHeight={fromKeyNumberNitrogen || fromCapPointsSimulation ? true : false}
            sortModel={sortModel}
            treeData={treeData}
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
            disableColumnResize={true}
            disableColumnReorder={true}
            defaultGroupingExpansionDepth={fromKeyNumberNitrogen ? 0 : -1}
            initialState={{
              pinnedColumns: {
                left: fromSimulation
                  ? [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'Parcelle']
                  : fromRotateEffects
                  ? [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'cropToPlant']
                  : [],
              },
            }}
            getEstimatedRowHeight={() => 54}
            getRowHeight={({ model }) => {
              if (fromRotateEffects && model && model.cropToPlant) {
                return 62;
              }
              if (fromSimulation) {
                if (
                  Object.values(model)
                    .filter((value) => value === Object(value))
                    .some((value) => value.isParcelDivision === true)
                ) {
                  return 'auto';
                }
                return 80;
              }
              if (fromCapPoints) {
                return 42;
              }
              if (fromKeyNumberNitrogen) {
                if (model.hierarchy.length > 1) {
                  return 52;
                }
                return 70;
              }
              return 52;
            }}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            isCellEditable={isCellEditable}
          />
        </ThemeProvider>
      </Box>
    </ClickAwayListener>
  );
};

export default CustomForecastDataGridResult;
