import { useState } from 'react';
import CustomDataGridResult from '../../../../utils/Datagrid/CustomForecastDataGridResult';
import { customDataGridResultSimulation } from '../../../../utils/Datagrid/CustomForecastDataGridUtils';
import { customInvertedButtonTheme } from 'assets/styles/themes/generic_button_mui';
import { Button, ThemeProvider } from '@mui/material';

const DataGridCAPHelp = ({ rows }) => {
  const [isOpen, setIsOpen] = useState(false);

  const columns = [
    {
      field: 'fourPoints',
      headerName: '4 points \n (sur une base de 46 €/ha)',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'fivePoints',
      headerName: '5 points \n (sur une base de 62 €/ha)',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
  ];

  const processedRows = [
    {
      id: 'capHelp-row',
      fourPoints: rows[0],
      fivePoints: rows[1],
    },
  ];

  const getRowClassName = () => 'Mui-even';

  return (
    <div className="cap-points-help-container">
      <div className="cap-points-help-container__title">
        <h2>Montant prévisionnel des aides PAC liées aux éco-régimes {isOpen ? '*' : ''}</h2>
        <ThemeProvider theme={customInvertedButtonTheme}>
          <Button
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? 'Fermer' : 'Afficher'}
          </Button>
        </ThemeProvider>
      </div>

      <div
        className={
          isOpen ? 'cap-points-help-container__opened' : 'cap-points-help-container__closed'
        }
      >
        <CustomDataGridResult
          rows={processedRows}
          columns={columns}
          isLoading={false}
          theme={customDataGridResultSimulation}
          getRowClassName={getRowClassName}
          simulationContext={'simulation-box'}
          fullSize={true}
        />

        <section className="notes">
          <em>
            *: Le montant des aides PAC est calculé en fonction de la surface des parcelles
            renseignées dans Assolia. Si toutes les parcelles d'une exploitation ne sont pas
            renseignées, ce calcul peut être faux.
          </em>
        </section>
      </div>
    </div>
  );
};

export default DataGridCAPHelp;
