import React from 'react';
import DataGridRotateEffects from './DataGridRotateEffects';

const RotateEffectsDataGridCooperative = ({ cultures, ownerId, refreshData }) => {
  return (
    <DataGridRotateEffects
      from={'cooperatives'}
      cultures={cultures}
      ownerId={ownerId}
      refreshData={refreshData}
    />
  );
};

export default RotateEffectsDataGridCooperative;
