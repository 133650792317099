import { useEffect, useState } from 'react';

const useCultureColors = (
  exploitationContext,
  cropsLength,
  comparatorCalculatorResult,
  shuffledColorList
) => {
  const [colorList, setColorList] = useState(shuffledColorList);
  const cultures = exploitationContext?.cultures.map((culture) => culture);

  const colorsSize = cropsLength && cropsLength.length;
  if (colorsSize && colorsSize > 95) {
    const arr = colorList;
    for (let i = 95; i < colorsSize; i++) {
      let newColor;
      do {
        newColor = setColor();
      } while (arr.includes(newColor));
      arr.push(newColor);
    }
    setColorList(arr);
  }

  const setColor = () => {
    const color = `#${`${(Math.floor(Math.random() * 56) + 200).toString(16)}${(
      Math.floor(Math.random() * 56) + 200
    ).toString(16)}${(Math.floor(Math.random() * 56) + 200).toString(16)}`}`;
    return color;
  };

  const filterColors = () => {
    return comparatorCalculatorResult?.result?.years?.map((year) =>
      year.hectaresDistribution.map((hectare) => {
        const existingColor = comparatorCalculatorResult?.cultures.find(
          (culture) => culture.id === hectare.culture.id
        )?.color;
        if (existingColor) {
          return setColorList((prevColorList) =>
            prevColorList.filter((color) => color !== existingColor)
          );
        }
        return null;
      })
    );
  };

  const getCultureColor = (data, index) => {
    const existingCultureColor = cultures.find((culture) => culture.id === data.culture.id)?.color;
    if (existingCultureColor) {
      return existingCultureColor;
    } else {
      return shuffledColorList[index];
    }
  };

  useEffect(() => {
    filterColors();
  }, [comparatorCalculatorResult]);

  return [getCultureColor];
};

export default useCultureColors;
