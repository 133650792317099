import React from 'react';
import { v1 } from 'uuid';

function ResultTableRow({ data }) {
  return (
    <tr>
      <td>{data.exploitationName}</td>
      <td>{data.endStatus}</td>
      <td>
        {data?.info &&
          data.info.map((i) => (
            <p key={v1()}>{typeof i === 'string' ? i : Object.values(i).join(' / ')}</p>
          ))}
      </td>
    </tr>
  );
}

export default ResultTableRow;
