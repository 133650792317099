import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { v1 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import secteurFullProp from 'components/propTypes/secteurFull';
import { sendEvent } from 'utils/Event';

import 'assets/styles/itemsGrid.scss';
import { useContext } from 'react';
import { LoadingContext } from 'utils/context';

const SecteurHome = ({ secteurs = [], setSecteur, secteur = {}, cooperative = {} }) => {
  const {setFullScreen} = useContext(LoadingContext)

  useEffect(() => {
    async function setSector() {
      let sectorId = localStorage.getItem('sector');
      if (sectorId && sectorId !== '') {
       await setSecteur(+sectorId);
      }
    }
    setSector()
    // Dismount
    return () => {
      sendEvent('cancelHttpRequest')
    };
  }, []);

  const navigate = useNavigate();
  return (
    <div className="Content">
      <div className="Sector grid">
        <h1 className="title_grid">Secteurs</h1>
        <div className="containerGrid">
          {secteurs.map((sect) => (
            <div
              key={v1()}
              onClick={() => {
                setFullScreen(true)
                localStorage.setItem('sector', sect.id);
                setSecteur(sect.id)
                  .then(() => {
                    navigate('/sector/exploitations');
                  })
                  .catch((err) => toast.error(err.data))
                  .finally(() => setFullScreen(false));
              }}
              className={`itemGrid${sect.id === secteur?.id ? ' active' : ''}`}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <p>{sect.name}</p>
              {sect.id !== secteur?.id && cooperative.logo ? (
                <img
                  src={cooperative.logo}
                  alt={`Logo ${cooperative.name}`}
                  width="50%"
                  style={{ marginTop: 20 }}
                />
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

SecteurHome.propTypes = {
  secteur: secteurFullProp,
  secteurs: PropTypes.arrayOf(secteurFullProp),
  setSecteur: PropTypes.func.isRequired,
  cooperative: PropTypes.object,
};

export default SecteurHome;
