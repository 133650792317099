import { useEffect, useState } from 'react';
import { allColumns, essentialColumns } from './CustomCultureDataGridUtils';
import useFilteredCulturesLocalStorage from './useFilteredCulturesLocalStorage';

const useDataGridCultureVisibility = (rows, setRows, processedRows, from, user) => {
  const [isLargeGrid, setIsLargeGrid] = useState(false);
  const [columnVisible, setColumnVisible] = useState(allColumns);
  const [toggleVisibilityFilter, setToggleVisibilityFilter] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const filteredCultures = JSON.parse(localStorage.getItem('filteredCultures'));
  let userMaskedCulturesIndex = -1;
  const structureId = Number(localStorage.getItem('cooperative') ?? user?.cooperative.id);
  const sectorId = Number(localStorage.getItem('sector'));
  const exploitationId = Number(localStorage.getItem('exploitation'));

  if (filteredCultures) {
    switch (from) {
      case 'fromCooperative':
        userMaskedCulturesIndex = filteredCultures.maskedCulturesByLevel.findIndex(
          (obj) => obj?.structure === structureId
        );
        break;
      case 'fromSector':
        userMaskedCulturesIndex = filteredCultures.maskedCulturesByLevel.findIndex(
          (obj) => obj?.sector === sectorId
        );
        break;
      case 'fromExploitation':
        userMaskedCulturesIndex = filteredCultures.maskedCulturesByLevel.findIndex(
          (obj) => obj?.exploitation === exploitationId
        );
        break;
    }
  }

  const [lastSelectedRows, setLastSelectedRows] = useState(
    filteredCultures && userMaskedCulturesIndex !== -1
      ? filteredCultures?.maskedCulturesByLevel[userMaskedCulturesIndex].maskedRows
      : []
  );

  useFilteredCulturesLocalStorage(
    user,
    from,
    filteredCultures,
    userMaskedCulturesIndex,
    lastSelectedRows,
    setLastSelectedRows,
    toggleVisibilityFilter,
    setToggleVisibilityFilter,
    selectedRows,
    structureId,
    sectorId,
    exploitationId
  );

  useEffect(() => {
    const newColumns = isLargeGrid ? allColumns : essentialColumns;
    setColumnVisible(newColumns);
  }, [isLargeGrid]);

  const handleVisibility = () => {
    setIsLargeGrid(!isLargeGrid);
  };

  const filterCheckedRows = () => {
    setLastSelectedRows(selectedRows);
    setRows(rows?.filter((culture) => !selectedRows.includes(culture.id)));
  };

  const handleCulturesHide = () => {
    if (selectedRows.length > 0) {
      setToggleVisibilityFilter(!toggleVisibilityFilter);
      filterCheckedRows();
    }
  };

  const handleCulturesShow = () => {
    setToggleVisibilityFilter(!toggleVisibilityFilter);
    setRows(processedRows);
    if (lastSelectedRows) {
      setSelectedRows(lastSelectedRows);
    }
  };

  const badgeContent = Math.abs(lastSelectedRows.length.toString());

  return {
    toggleVisibilityFilter,
    columnVisible,
    selectedRows,
    setSelectedRows,
    setColumnVisible,
    handleVisibility,
    handleCulturesHide,
    handleCulturesShow,
    badgeContent,
    isLargeGrid,
    lastSelectedRows,
  };
};

export default useDataGridCultureVisibility;
