import React from 'react';
import './CulturesMapTab.style.scss';

const TotalMapTab = ({ totalSurface, fromProduction = false }) => {
  return (
    <div
      className={
        fromProduction ? 'cultures-map-tab-surface sticky-table' : 'cultures-map-tab-surface'
      }
    >
      <p>
        <span>Surface SAU</span>
        <span>{Math.round(totalSurface * 100) / 100} ha</span>
      </p>
    </div>
  );
};

export default TotalMapTab;
