import { useContext } from 'react';
import './capPoints.style.scss';
import '../Tables/table.style.scss';
import { PdfContext } from 'utils/context';
import { v1 } from 'uuid';

const CapPoints = () => {
  const { currentYear, suggest, pac, pacHelps } = useContext(PdfContext);
  const fourPointCategories = [
    "Céréales d'hiver",
    'Céréales de printemps',
    'Plantes sarclées',
    "Oléagineux d'hiver",
    'Oléagineux de printemps',
  ];
  const years = suggest?.years;
  let headers = [
    ...['Campagne', `${currentYear} - ${currentYear + 1}`],
    ...Array(years.length - 1)
      .fill()
      .map((_, i) => `${i + (currentYear + 1)}-${i + (currentYear + 2)}`),
  ];
  const pacWithoutN1 = pac?.map((categoryPac) => ({
    ...categoryPac,
    data: categoryPac?.data?.slice(1),
  }));
  return (
    <article className="pdf-page">
      <div className="tableau_center">
        <h6 className="title_h6">AIDES POTENTIELLE SUR LES ECO RÉGIMES PAC</h6>
        <p className="title_description">*sur une base de 46 €/ha et 62 €/ha</p>
        <table className="table">
          <thead className="table_head">
            <tr className="table_tr">
              <th className="column_name" key="4points" scope="col">
                4 points
              </th>
              <th className="column_name" key="5points" scope="col">
                5 points
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="table_tr">
              {pacHelps.map((value) => {
                return (
                  <td className="value_table" key={v1()}>
                    {value}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="tableau_center">
        <h6 className="title_h6">Point(s) Catégories PAC</h6>
        <div className="ceiling_points">
          <div className="color four_points_color"></div>
          <span>plafond à 4 points</span>
        </div>
        <table className="table PAChelp cap-table" style={{ marginTop: '10px !important' }}>
          <thead className="table_head">
            <tr className="table_tr">
              {headers.map((header, i) => (
                <th key={i} scope="col" className="column_name">
                  <span>{header}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {pacWithoutN1?.map((p) => {
              let isFourPointCategorie = fourPointCategories.includes(p?.categorie);
              return (
                <tr className="table_tr" key={v1()}>
                  <td className="value_table cap-cell">
                    <div className="cap_category">
                      <span
                        className="text-left"
                        style={{ marginLeft: isFourPointCategorie ? '12px' : 0 }}
                      >
                        {p?.categorie}
                      </span>
                      {isFourPointCategorie && (
                        <div className="icons">
                          <div className="color four_points_color"></div>
                        </div>
                      )}
                    </div>
                  </td>
                  {p?.data.map((c) => (
                    <td className="value_table" key={v1()}>
                      {c.value}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </article>
  );
};

export default CapPoints;
