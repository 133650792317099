import React, { createContext, useState } from 'react';

const ImportContext = createContext();

function ImportProvider({ children }) {
  const [status, setStatus] = useState();
  const [jobId, setJobId] = useState(null);
  const [result, setResult] = useState(null);

  return (
    <ImportContext.Provider
      value={{
        status,
        setStatus: (value) => setStatus(value),
        jobId,
        setJobId: (value) => setJobId(value),
        result,
        setResult: (value) => setResult(value),
      }}
    >
      {children}
    </ImportContext.Provider>
  );
}
export { ImportContext, ImportProvider };
