import Box from '@mui/material/Box';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CustomDataGridResult from '../../../../utils/Datagrid/CustomForecastDataGridResult';
import useDataGridLoading from './useDataGridLoading';
import {
  CustomForecastHeader,
} from '../../../../utils/Datagrid/CustomForecastDataGridUtils';

export default function DataGridKeyFigures({
  comparatorSimulationResult,
  comparatorCalculatorResult,
  currentYear,
}) {
  const dataGridLoading = useDataGridLoading(
    comparatorSimulationResult,
    comparatorCalculatorResult
  );

  const columns = [
    {
      field: 'campaign',
      headerName: 'Campagne',
      headerClassName: 'result-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
    },
    {
      field: 'yearN1',
      headerName: `${currentYear - 2}-${currentYear - 1}`,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'yearN',
      headerName: `${currentYear - 1}-${currentYear}`,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'forecast',
      headerName: `${currentYear}-${currentYear + 1} (prévisionnel)`,
      renderHeader: () => CustomForecastHeader(currentYear, 'prévisionnel'),
      sortable: false,
      headerClassName: 'forecast-header header-material',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'simulationSuggest',
      headerName: `${currentYear}-${currentYear + 1} (Assolia)`,
      renderHeader: () => CustomForecastHeader(currentYear, 'Assolia'),
      sortable: false,
      headerClassName: 'forecast-header header-assolia header-material',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: CustomForecastCell,
    },
  ];

  const rows = [
    {
      id: 1,
      campaign: 'Marge brute (€)',
      forecast: (comparatorCalculatorResult?.maxGrossMargin.toString() ?? '0') + '€',
      simulationSuggest:
        (comparatorSimulationResult?.suggestions.suggestion1?.years[0].grossMargin.toString() ??
          '0') + '€',
      yearN: (comparatorSimulationResult?.previousGrossMargin[1] ?? '0') + '€',
      yearN1: (comparatorSimulationResult?.previousGrossMargin[0] ?? '0') + '€',
    },
    {
      id: 2,
      campaign: "Culture d'été (%)",
      forecast:
        (comparatorCalculatorResult?.result.years[0].propCultureEte.toString() ?? '0') + '%',
      simulationSuggest:
        (comparatorSimulationResult?.suggestions.suggestion1?.years[0].propCultureEte.toString() ??
          '0') + '%',
      yearN: '—',
      yearN1: '—',
    },
    // {
    //   id: 3,
    //   campaign: 'Quantité Ensillage (t)',
    //   forecast: (comparatorCalculatorResult?.result.years[0].Ensilage?.toString() ?? '0') + 't',
    //   simulationSuggest: (comparatorSimulationSuggest?.years[0].Ensilage?.toString() ?? '0') + 't',
    // },
    // {
    //   id: 4,
    //   campaign: 'Quantité Paille (t)',
    //   forecast: (comparatorCalculatorResult?.result.years[0].Paille?.toString() ?? '0') + 't',
    //   simulationSuggest: (comparatorSimulationSuggest?.years[0].Paille?.toString() ?? '0') + 't',
    // },
  ];

  return <CustomDataGridResult rows={rows} columns={columns} isLoading={dataGridLoading} />;
}

const CustomForecastCell = (params) => {
  if (
    params.formattedValue.slice(0, -1) !== '0' &&
    Number(params.formattedValue.slice(0, -1)) > Number(params.row.forecast.slice(0, -1))
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ position: 'relative' }}>
          <ArrowDropUpIcon
            sx={{
              color: '#277134',
              fontSize: '30px',
              position: 'absolute',
              top: '-2px',
              right: '100%',
            }}
          />
          <div>{params.value}</div>
        </div>
      </Box>
    );
  }
  if (
    params.formattedValue.slice(0, -1) !== '0' &&
    Number(params.formattedValue.slice(0, -1)) < Number(params.row.forecast.slice(0, -1))
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ position: 'relative' }}>
          <ArrowDropDownIcon
            sx={{
              color: 'red',
              fontSize: '30px',
              position: 'absolute',
              top: '-2px',
              right: '100%',
            }}
          />
          <div>{params.value}</div>
        </div>
      </Box>
    );
  }
};
