import { useState } from 'react';

const useDataGridMatricesBrushMode = () => {
  const [modeBrush, setModeBrush] = useState<boolean>(false);
  const [allowSubmitEffectBrush, setAllowSubmitEffectBrush] = useState<boolean>(false);
  const [selectedBrushValue, setSelectedBrushValue] = useState<string | null>(null);

  const handleBrushEffect = () => {
    setModeBrush(false);
    setSelectedBrushValue(null);
    setAllowSubmitEffectBrush(false);
  };

  const handleClickValue = (e: React.MouseEvent<HTMLSpanElement>) => {
    setModeBrush(true);
    setAllowSubmitEffectBrush(true);
    const target = e.target as HTMLSpanElement;
    setSelectedBrushValue(target.innerText);
  };

  return {
    modeBrush,
    setModeBrush,
    allowSubmitEffectBrush,
    setAllowSubmitEffectBrush,
    selectedBrushValue,
    setSelectedBrushValue,
    handleBrushEffect,
    handleClickValue,
  };
};

export default useDataGridMatricesBrushMode;
