import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

const Item = ({ id, onChangePath, active = false, path, text }) => (
  <Link onClick={() => onChangePath(id)} to={`/${path}`}>
    <div className={`item${active ? ' active' : ''}`}>
      <span>{text}</span>
    </div>
  </Link>
);

Item.propTypes = {
  id: PropTypes.number.isRequired,
  onChangePath: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default Item;
