export const generic = {
  // LOADER
  CREATE_EXPLOTATION_LOADER: {
    fr: `Veuillez patienter pendant la création de l'exploitation`,
  },
  UPDATE_EXPLOTATION_LOADER: {
    fr: `Veuillez patienter pendant la mise à jour de l'exploitation`,
  },
  CREATE_STRUCTURE_LOADER: {
    fr: `Veuillez patienter pendant la création de la structure`,
  },
  UPDATE_STRUCTURE_LOADER: {
    fr: `Veuillez patienter pendant la mise à jour de la structure`,
  },
  CREATE_SECTOR_LOADER: {
    fr: `Veuillez patienter pendant la création du secteur`,
  },
  UPDATE_SECTOR_LOADER: {
    fr: `Veuillez patienter pendant la mise à jour du secteur`,
  },
  ADD_TECHNICIAN_TO_SECTOR_LOADER: {
    fr: `Veuillez patienter pendant l'ajout du technicien au secteur`,
  },
  ADD_TECHNICIAN_TO_COOP_LOADER: {
    fr: `Veuillez patienter pendant l'ajout du technicien à la coopérative`,
  },
  UPDATE_TECHNICIAN_LOADER: {
    fr: `Veuillez patienter pendant la mise à jour des informations du technicien`,
  },
  CREATE_CULTURE_LOADER: {
    fr: `Veuillez patienter pendant la création de la culture`,
  },
  CREATE_PARCEL_LOADER: {
    fr: `Veuillez patienter pendant la création de la parcelle`,
  },
  UPDATE_CULTURE_LOADER: {
    fr: `Veuillez patienter pendant la mise à jour de la culture`,
  },
  CREATE_GROUNDTYPE_LOADER: {
    fr: `Veuillez patienter pendant la création du type de sol`,
  },
  UPDATE_GROUNDTYPE_LOADER: {
    fr: `Veuillez patienter pendant la mise à jour du type de sol`,
  },
  UPDATE_ROTATION_EFFECT_LOADER: {
    fr: `Veuillez patienter pendant la mise à jour des effets de rotation`,
  },
  UPDATE_GROUNDTYPE_EFFECT_LOADER: {
    fr: `Veuillez patienter pendant la mise à jour de l'effet de type de sol`,
  },
  UPDATE_PARCEL_LOADER: {
    fr: `Veuillez patienter pendant la mise à jour de la parcelle`,
  },
  WAIT_BUILD_PDF: {
    fr: 'Veuillez patienter pendant la création du pdf',
  },
  WAIT_SEND_MAIL: {
    fr: `Veuillez patienter pendant l'envoi du mail`,
  },
  // MAIL
  SEND_REPORT_MAIL_OK: {
    fr: 'Le mail a été envoyé avec succès',
  },
  //IMPORT
  IMPORT_GEOFOLIA_EXPLOITATIONS: {
    fr: "Veuillez patienter pendant l'import des exploitations sélectionnées",
  },
  IMPORT_GEOFOLIA_EXPLOITATION: {
    fr: "Veuillez patienter pendant l'import de l'exploitation sélectionnée",
  },
};
