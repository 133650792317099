import { ResponsivePie } from '@nivo/pie';
import './result.styles.scss';

const CustomPieProduction = ({
  comparatorSimulationResult,
  comparatorCalculatorResult,
  currentYear,
  shuffledColorsArray,
}) => {
  const addSameCulturesHa = (data) => {
    return data.reduce((acc, curr, i) => {
      const item = i > 0 && acc.find((cult) => cult.culture.id === curr.culture.id);
      if (item) {
        item.hectares += curr.hectares;
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
  };

  const renderPie = (data) => {
    return addSameCulturesHa(data)
      .filter((culture) => culture.hectares > 0)
      .map((result) => ({
        ...result,
        value: result.hectares,
        id: result.culture.name,
        label: result.culture.name,
        color: shuffledColorsArray.find((color) => color.hasOwnProperty(result.culture.name))[
          result.culture.name
        ],
      }))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  };

  const calculatorDataPie = renderPie(
    comparatorCalculatorResult?.result.years[0].hectaresDistribution
  );

  const SimulationDataPie = renderPie(
    comparatorSimulationResult?.suggestions.suggestion1.years[0].hectaresDistribution
  );

  return (
    <div className="header-container">
      <div className="header">
        <span>{`${currentYear}-${currentYear + 1}`}</span> (Comparatif)
      </div>
      <div className="pie-container">
        <div className="custom-pie-production">
          <div className="graph">
            <div className="graph-title">Prévisionnel</div>
            <CustomResponsivePie data={calculatorDataPie} />
          </div>
          <div className="graph">
            <div className="graph-title">Proposition Assolia</div>

            <CustomResponsivePie data={SimulationDataPie} />
          </div>
        </div>
        <CustomLegend data={calculatorDataPie} />
      </div>
    </div>
  );
};

export default CustomPieProduction;

const CustomResponsivePie = ({ data }) => {
  return (
    <>
      <ResponsivePie
        data={data}
        margin={{ top: 15, right: 15, bottom: 15, left: 35 }}
        colors={{ datum: 'data.color' }}
        innerRadius={0.8}
        radialLabel={(d) => {
          return `${d.label}: ${d.value} ha`;
        }}
        valueFormat={(value) => value + 'ha'}
        borderWidth={1}
        padAngle={2}
        cornerRadius={3}
        enableSliceLabels={false}
        sortByValue={true}
        arcLabelsTextColor="#e69636"
        enableArcLabels={false}
        activeOuterRadiusOffset={7}
        enableArcLinkLabels={true}
        arcLinkLabelsDiagonalLength={3}
        arcLinkLabelsStraightLength={3}
        arcLinkLabelsTextOffset={0}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabel={(datum) =>
          datum?.id.length > 13 ? `${datum?.id.slice(0, 13)}...` : datum?.id
        }
      />
    </>
  );
};
const CustomLegend = ({ data }) => {
  return (
    <div className="legend-container">
      {data.map((datum) => (
        <div className="legend-item" key={datum.id}>
          <div className="legend-color" style={{ backgroundColor: datum.color }}></div>
          <div>{datum.label}</div>
        </div>
      ))}
    </div>
  );
};
