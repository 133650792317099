// REACT
import { useEffect, useState } from 'react';
// YARN
import {
  GridColDef,
  GridRowId,
  GridRowParams,
  GridRowsProp,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { toast } from 'react-toastify';
import { customDialogTheme } from 'assets/styles/themes/Dialog/generic_dialog_mui';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
// SERVICES
import { deleteSector } from 'services/API/Cooperative';
import { CustomSortableHeader } from 'utils/Datagrid/CustomGenericDataGridUtils';
import useDataGridCommonListActions from 'utils/Datagrid/useDataGridCommonListActions';
import { CustomHeaderWithoutSort } from 'utils/Datagrid/CustomCultureDataGridUtils';
import CustomCommonListDataGrid from './../../../utils/Datagrid/CustomCommonListDataGrid';
// INTERFACES
import ISector from 'components/generics/Interface/Api/Response/Structures/ISector';
// THEMES
import './../../generics/commonListDataGrid.scss';
import { CustomCommonListDataGridTheme } from './../../../utils/Datagrid/CustomCommonListDataGridUtils';

type SectorDataGridParams = {
  sectors: ISector[];
  refreshData: () => void;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setEditData: React.Dispatch<React.SetStateAction<GridValidRowModel | null | undefined>>;
};

export const SectorDataGrid = ({
  sectors,
  refreshData,
  setModal,
  setIsEdit,
  setEditData,
}: SectorDataGridParams): React.JSX.Element => {
  const [rows, setRows] = useState<GridRowsProp | []>([]);
  const [dataGridLoading, setDataGridLoading] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [actionId, setActionId] = useState<GridRowId | null>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);
  const thisSector: GridValidRowModel | undefined = rows?.find((row) => row.id === actionId);

  type ProcessedRowType = {
    id: number;
    name: string;
    exploitationNumber: number;
  };

  const processedRows: ProcessedRowType[] = sectors?.map((sector) => {
    const exploitationNumber = sector?.exploitations.length;
    return {
      id: sector.id,
      name: sector.name,
      exploitationNumber: exploitationNumber,
    };
  });

  useEffect(() => {
    setDataGridLoading(true);
    processedRows?.length && setRows(processedRows);
    setDataGridLoading(false);
  }, [sectors]);

  const { getActions } = useDataGridCommonListActions(
    processedRows,
    setModal,
    setIsEdit,
    setEditData,
    setOpenDeleteDialog,
    setActionId
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nom',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: () => (
        <CustomSortableHeader setSortModel={setSortModel} fieldName={'name'} name={'Structure'} />
      ),
    },
    {
      field: 'exploitationNumber',
      headerName: "Nombre d'exploitations",
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'actions',
      getActions: (params: GridRowParams) => getActions(params.id),
      renderHeader: () => <CustomHeaderWithoutSort name={'Actions'} />,
    },
  ];

  const handleDeleteClick = async (): Promise<void> => {
    setOpenDeleteDialog(false);
    try {
      await deleteSector(thisSector?.id);
      toast.success(`Sector ${thisSector?.name} supprimé`);
      refreshData();
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const renderDeleteDialog = (): React.JSX.Element => {
    return (
      <ThemeProvider theme={customDialogTheme}>
        <Dialog maxWidth="md" open={openDeleteDialog}>
          <DialogTitle>Attention</DialogTitle>
          <DialogContent dividers>
            <h3>Supprimer un secteur</h3>
            <p>Vous êtes sur le point de supprimer le secteur</p>
            <p>
              <strong>{thisSector?.name}</strong>. Souhaitez-vous continuer?
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClick}>
              Supprimer <DoneIcon />
            </Button>
            <Button onClick={() => setOpenDeleteDialog(false)}>
              Non <ClearIcon />
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  };

  return (
    <>
      <div className="sector-data-grid common-list-data-grid">
        <CustomCommonListDataGrid
          rows={rows}
          columns={columns}
          isLoading={dataGridLoading}
          sortModel={sortModel}
          theme={CustomCommonListDataGridTheme}
        />
      </div>
      {renderDeleteDialog()}
    </>
  );
};
