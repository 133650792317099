import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import userProp from 'components/propTypes/user';

import { getCoProductsFromConstraintCategory, renameCoProducts } from 'services/API/Cooperative';

import Button from 'components/generics/Button';
import Label from 'components/generics/Label';
import InputLabel from 'components/generics/InputLabel';

const DEFAULT_FORM = { constraintCategory: '', coProducts: [] };

const ConstraintCategoryForm = ({ onSubmitSuccess, edit = null }) => {
  const [formData, setFormData] = useState({ ...edit } || DEFAULT_FORM);
  const [coProductsList, setCoProductsList] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const cooperativeId = Number(localStorage.getItem('cooperative'));

  useEffect(() => {
    getCoProductsFromConstraintCategory({
      cooperative: cooperativeId,
      constraintCategory: edit.constraintCategory.id,
    }).then((cc) => {
      setCoProductsList(cc.data.data);
      setFormData({ ...edit, coProducts: cc.data.data });
    });
  }, [cooperativeId, edit]);

  const updateForm = (key, value) => {
    let tab = formData.coProducts;
    tab[key].newName = value;
    formData.coProducts = tab;
    setFormData({ ...formData });
  };

  const updateFormUnit = (key, value) => {
    let tab = formData.coProducts;
    tab[key].newUnit = value;
    formData.coProducts = tab;
    setFormData({ ...formData });
  };

  const onSubmit = (e) => {
    setLoading(true);
    setError(null);
    if (e) e.preventDefault();
    let checkSubmit = formData?.coProducts
      ?.map((coProduct) => {
        return coProduct.newName || coProduct.newUnit ? coProduct : false;
      })
      .filter((b) => b !== false);
    if (checkSubmit.length > 0) {
      formData.cooperative = cooperativeId;
      renameCoProducts(formData)
        .then((response) => {
          if (response.data.message === 'Association succeeded') {
            toast.success(`Co-Produit(s) associé(s) à ${formData.constraintCategory.displayName}`);
          } else if (response.data.message === 'Removed successfully') {
            toast.success(`Co-Produit(s) retiré(s) de ${formData.constraintCategory.displayName}`);
          } else {
            console.error('Unkonwn message');
          }
          setFormData(DEFAULT_FORM);
          onSubmitSuccess();
        })
        .catch((err) => toast.error(err.response.data['message']));
    } else {
      setError('Certains champs sont vides');
    }
    setLoading(false);
  };

  return (
    <>
      <h1>Modifier un co-produit</h1>
      {error?.length ? <p className="error">{error}</p> : null}
      <form onSubmit={onSubmit}>
        {edit && (
          <Label
            name="categoryLabel"
            htmlFor="constraintCategory"
            text={`Dans la catégorie ${edit.constraintCategory.displayName}, renommer :`}
            className="secondary"
          >
            <>
              {coProductsList?.length > 0 &&
                coProductsList.map((coProduct, idx) => {
                  return (
                    <section key={idx} className="flex-row-form">
                      <InputLabel
                        key={`coProduct-${idx}`}
                        name={`coProduct_${idx}`}
                        value={
                          formData?.coProducts
                            ? formData?.coProducts[idx] &&
                              formData?.coProducts[idx]?.newName !== undefined
                              ? formData?.coProducts[idx]?.newName
                              : ''
                            : ''
                        }
                        onChange={(e) => {
                          updateForm(idx, e);
                        }}
                        defaultStyle="secondary width-middle"
                        placeholder="Renommer le co-produit"
                        label={`Renommer co-produit : ${coProduct.displayName}`}
                        disabled={loading}
                      />
                      <InputLabel
                        key={`coProductUnit-${idx}`}
                        name={`coProductUnit_${idx}`}
                        value={
                          formData?.coProducts
                            ? formData?.coProducts[idx] &&
                              formData?.coProducts[idx]?.newUnit !== undefined
                              ? formData?.coProducts[idx]?.newUnit
                              : ''
                            : ''
                        }
                        onChange={(e) => {
                          updateFormUnit(idx, e);
                        }}
                        defaultStyle="secondary width-middle"
                        placeholder="Modifier l'affichage"
                        label={`Affichage dans simulation : ${coProduct.displayNameSimulation}`}
                        disabled={loading}
                      />
                    </section>
                  );
                })}
              <>{coProductsList?.length === 0 && 'Aucun co-produit associé.'}</>
            </>
          </Label>
        )}
        <Button
          defaultStyle="m-auto d-block form"
          type="submit"
          text={edit ? 'Enregistrer' : 'Ajouter'}
          disabled={loading}
        />
      </form>
    </>
  );
};

ConstraintCategoryForm.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
  edit: function (props, propName, componentName) {
    const propValue = props[propName];
    if (propValue === null) return;
    if (PropTypes.shape(userProp)) return;
    return new Error(`${componentName} only accepts null or coProductProp`);
  },
  secteur: PropTypes.number.isRequired,
};

export default ConstraintCategoryForm;
