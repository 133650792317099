import Input from 'components/generics/Input';
import React, { useEffect, useState } from 'react';

function MinMaxCultures({ id, limit, setLimit }) {
  const [vaMinHa, setVaMinHa] = useState(0);
  const [vaMaxHa, setVaMaxHa] = useState(0);
  const [vaMinPct, setVaMinPct] = useState(0);
  const [vaMaxPct, setVaMaxPct] = useState(0);
  const [inputToggle, setInputToggle] = useState(true);

  const handleChangeMin = (e, field) => {
    let minValue = e.replace(/^-?[0-9]+$/, '');
    if (field === 'min') {
      setVaMinHa(minValue);
    } else if (field === 'minPct') {
      setVaMinPct(minValue);
    }
    if (Number(e) >= 0 && e) {
      const temp = limit.map((li) =>
        li.id === id
          ? {
              ...li,
              [field]: parseInt(e),
            }
          : li
      );
      setLimit(temp);
    } else {
      const temp = limit.map((li) =>
        li.id === id
          ? {
              ...li,
              [field]: 0,
            }
          : li
      );
      setLimit(temp);
    }
  };

  const handleChangeMax = (e, field) => {
    let maxValue = e.replace(/^-?[0-9]+$/, '');
    if (field === 'max') {
      setVaMaxHa(maxValue);
    } else if (field === 'maxPct') {
      setVaMaxPct(maxValue);
    }
    if (Number(e) >= 0 && e) {
      setLimit((prevLimit) =>
        prevLimit.map((li) =>
          li.id === id
            ? {
                ...li,
                [field]: parseInt(e),
              }
            : li
        )
      );
    } else {
      setLimit((prevLimit) =>
        prevLimit.map((li) =>
          li.id === id
            ? {
                ...li,
                [field]: 0,
              }
            : li
        )
      );
    }
  };

  useEffect(() => {
    if (id) {
      const result = limit.filter((li) => li.id === id);
      setVaMinHa(result[0]?.min);
      setVaMaxHa(result[0]?.max);
      setVaMinPct(result[0]?.minPct);
      setVaMaxPct(result[0]?.maxPct);
      if (
        result[0]?.min === 0 &&
        result[0]?.max === 0 &&
        (result[0]?.minPct > 0 || result[0]?.maxPct > 0)
      ) {
        setInputToggle(false);
      }
    }
  }, [limit, id]);

  const disableInput = (field) => {
    if (field === 'ha') {
      setInputToggle(true);
      handleChangeMin('0', 'minPct');
      handleChangeMax('0', 'maxPct');
    }
    if (field === 'pct') {
      setInputToggle(false);
      handleChangeMin('0', 'min');
      handleChangeMax('0', 'max');
    }
  };

  return (
    <span className="minMax">
      <span
        onClick={() => {
          disableInput('ha');
        }}
      >
        <p className={inputToggle ? undefined : 'text_disabled'}> Min:</p>
        <Input
          value={vaMinHa}
          onChange={(e) => handleChangeMin(e, 'min')}
          type="text"
          readOnly={inputToggle ? false : true}
        />
      </span>
      <span onClick={() => disableInput('ha')}>
        <p className={inputToggle ? undefined : 'text_disabled'}> Max:</p>
        <Input
          value={vaMaxHa}
          onChange={(e) => handleChangeMax(e, 'max')}
          type="text"
          readOnly={inputToggle ? false : true}
        />
      </span>
      <span
        className={inputToggle ? undefined : 'text_disabled'}
        style={{ fontWeight: 'bold', fontSize: 16 }}
      >
        (ha)
      </span>

      <span
        style={{ marginLeft: '20px' }}
        onClick={() => {
          disableInput('pct');
        }}
      >
        <p className={inputToggle ? 'text_disabled' : undefined}> Min:</p>
        <Input
          value={vaMinPct}
          onChange={(e) => handleChangeMin(e, 'minPct')}
          type="text"
          readOnly={inputToggle}
        />
      </span>
      <span onClick={() => disableInput('pct')}>
        <p className={inputToggle ? 'text_disabled' : undefined}> Max:</p>
        <Input
          value={vaMaxPct}
          onChange={(e) => handleChangeMax(e, 'maxPct')}
          type="text"
          readOnly={inputToggle}
        />
      </span>
      <span
        className={inputToggle ? 'text_disabled' : undefined}
        style={{ fontWeight: 'bold', fontSize: 16 }}
      >
        (%)
      </span>
    </span>
  );
}

export default MinMaxCultures;
