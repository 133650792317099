import { useContext } from 'react';
import './grossMarginGraph.style.scss';
import '../Tables/table.style.scss';
import { PdfContext } from 'utils/context';

const KeyNumberAndGraph = () => {
  const { graphMarge } = useContext(PdfContext);

  return (
    <article className="pdf-page">
      <div className="marge_brute_graph tableau_center">
        <h6 className="title_h6">Marge brute</h6>
        <img src={graphMarge} alt="Graphique Marge Brute" />
      </div>
    </article>
  );
};

export default KeyNumberAndGraph;
