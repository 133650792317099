import patternGreen from 'assets/images/pattern-green.png';
import patternRed from 'assets/images/pattern-red.png';
import patternRedAndGreen from 'assets/images/pattern-redandgreen.png';
import { Map, View } from 'ol';
import * as olColor from 'ol/color';
import { Control, defaults as defaultControls } from 'ol/control.js';
import { getWidth } from 'ol/extent.js';
import { Tile as TileLayer } from 'ol/layer.js';
import { fromLonLat, get as getProjection, transform } from 'ol/proj.js';
import WMTS from 'ol/source/WMTS.js';
import { Fill, Stroke, Style } from 'ol/style';
import WMTSTileGrid from 'ol/tilegrid/WMTS.js';
import { getColorByName } from 'utils/culturesColors';
import ol_style_FillPattern from 'ol-ext/style/FillPattern';

export function gpsToPixel(coords) {
  if (coords) {
    let converted = [];
    converted.push([]);
    if (coords.length) {
      coords.map((coord) => {
        const transformCoord = new transform(
          [Number(coord[1]), Number(coord[0])],
          'EPSG:4326',
          'EPSG:3857'
        );
        return converted[0].push(transformCoord);
      });
    }
    return converted;
  }
}

const mapIconSvg =
  '<svg><path d="m20.5 3-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM15 19l-6-2.11V5l6 2.11V19z"></path></svg>';

const centerIconSvg =
  '<svg><path d="M5 15H3v4c0 1.1.9 2 2 2h4v-2H5v-4zM5 5h4V3H5c-1.1 0-2 .9-2 2v4h2V5zm14-2h-4v2h4v4h2V5c0-1.1-.9-2-2-2zm0 16h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4zM12 9c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path></svg>';

const satIconSvg =
  '<svg><path d="m15.44.59-3.18 3.18c-.78.78-.78 2.05 0 2.83l1.24 1.24-.71.71-1.24-1.25c-.78-.78-2.05-.78-2.83 0L7.3 8.72c-.78.78-.78 2.05 0 2.83l1.24 1.24-.71.71-1.23-1.25c-.78-.78-2.05-.78-2.83 0L.59 15.43c-.78.78-.78 2.05 0 2.83l3.54 3.54c.78.78 2.05.78 2.83 0l3.18-3.18c.78-.78.78-2.05 0-2.83L8.9 14.55l.71-.71 1.24 1.24c.78.78 2.05.78 2.83 0l1.41-1.41c.78-.78.78-2.05 0-2.83L13.84 9.6l.71-.71 1.24 1.24c.78.78 2.05.78 2.83 0l3.18-3.18c.78-.78.78-2.05 0-2.83L18.26.58c-.78-.78-2.04-.78-2.82.01zM6.6 19.32l-1.06 1.06L2 16.85l1.06-1.06 3.54 3.53zm2.12-2.12-1.06 1.06-3.54-3.54 1.06-1.06 3.54 3.54zm9.54-9.54L17.2 8.72l-3.54-3.54 1.06-1.06 3.54 3.54zm2.12-2.12L19.32 6.6l-3.54-3.54L16.85 2l3.53 3.54zM14 21v2c4.97 0 9-4.03 9-9h-2c0 3.87-3.13 7-7 7zm0-4v2c2.76 0 5-2.24 5-5h-2c0 1.66-1.34 3-3 3z"></path></svg>';

const resolutions = [];
const matrixIds = [];
const proj3857 = getProjection('EPSG:3857');
const maxResolution = getWidth(proj3857.getExtent()) / 256;

for (let i = 0; i < 20; i++) {
  matrixIds[i] = i.toString();
  resolutions[i] = maxResolution / Math.pow(2, i);
}

const tileGrid = new WMTSTileGrid({
  origin: [-20037508, 20037508],
  resolutions: resolutions,
  matrixIds: matrixIds,
});

const ign_sat_source = new WMTS({
  url: 'https://data.geopf.fr/wmts',
  layer: 'ORTHOIMAGERY.ORTHOPHOTOS',
  matrixSet: 'PM',
  projection: 'EPSG:3857',
  tileGrid: tileGrid,
  style: 'normal',
  attributions:
    '<a href="https://www.ign.fr/" target="_blank">' +
    '<img src="https://data.geopf.fr/wmts/static/logos/IGN/IGN.gif" title="Institut national de l\'' +
    'information géographique et forestière" alt="IGN"></a>',
});

export const ign_sat = new TileLayer({
  source: ign_sat_source,
  name: 'satView',
});

const ign_map_source = new WMTS({
  url: 'https://data.geopf.fr/wmts',
  layer: 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2',
  matrixSet: 'PM',
  format: 'image/png',
  projection: 'EPSG:3857',
  tileGrid: tileGrid,
  style: 'normal',
  attributions:
    '<a href="https://www.ign.fr/" target="_blank">' +
    '<img src="https://data.geopf.fr/wmts/static/logos/IGN/IGN.gif" title="Institut national de l\'' +
    'information géographique et forestière" alt="IGN"></a>',
});

export const ign_map = new TileLayer({
  source: ign_map_source,
  name: 'mapView',
});
export class HandleViewMapControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {};
    const button = document.createElement('button');
    button.innerHTML = satIconSvg;
    button.className = 'toogleOlView';
    const element = document.createElement('div');
    element.className = 'view-map ol-unselectable ol-control';
    button.id = 'toggle-ol-view';
    element.appendChild(button);
    super({
      element: element,
      target: options.target,
    });
    button.addEventListener('click', this.switchView.bind(this), false);
  }

  switchView = function () {
    let button = document.getElementById('toggle-ol-view');
    const ign_map_layer = this.getMap()
      .getLayers()
      .getArray()
      .find((layer) => layer.get('name') === 'mapView');
    const ign_sat_layer = this.getMap()
      .getLayers()
      .getArray()
      .find((layer) => layer.get('name') === 'satView');
    const polygon_layer = this.getMap()
      .getLayers()
      .getArray()
      .find((layer) => layer.get('id') === 'polygonVector');
    if (ign_sat_layer === undefined) {
      button.innerHTML = mapIconSvg;
      this.getMap().addLayer(ign_sat);
      this.getMap()
        .getLayers()
        .getArray()
        .find((layer) => layer.get('name') === 'satView')
        .setZIndex(0);
      ign_map_layer.setZIndex(1);
      polygon_layer.setZIndex(1);
      ign_map_layer.setOpacity(0.3);
    } else {
      button.innerHTML = satIconSvg;
      this.getMap().removeLayer(ign_sat_layer);
      ign_map_layer.setZIndex(0);
      polygon_layer.setZIndex(1);
      ign_map_layer.setOpacity(1);
    }
  };
}

export class HandleResizeMapControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {};
    const button = document.createElement('button');
    button.innerHTML = centerIconSvg;
    button.className = 'resizeOlView';
    const element = document.createElement('div');
    element.className = 'resize-map ol-unselectable ol-control';
    element.appendChild(button);
    super({
      element: element,
      target: options.target,
    });
    button.addEventListener('click', this.resize.bind(this), false);
  }

  resize = function () {
    let polygonSource;
    this.getMap()
      .getLayers()
      .getArray()
      .filter((layer) => layer.get('id') === 'polygonVector')
      .forEach((layer) => (polygonSource = layer.getSource()));
    this.getMap().getView().fit(polygonSource.getExtent(), this.getMap().getSize());
  };
}

export function createNewMap() {
  return new Map({
    controls: defaultControls().extend([new HandleResizeMapControl(), new HandleViewMapControl()]),
    target: 'map',
    layers: [ign_map],
    view: new View({
      zoom: 10,
      center: fromLonLat([1, 43]),
    }),
  });
}

export function handleMouseMoveEvent(setCoords) {
  const handleWindowMouseMove = (event) => {
    setCoords({
      x: event.clientX + window.scrollX,
      y: event.clientY + window.scrollY,
    });
  };
  window.addEventListener('mousemove', handleWindowMouseMove);
  return () => {
    window.removeEventListener('mousemove', handleWindowMouseMove);
  };
}

export function zoomToFit(map) {
  let polygonSource;
  map
    .getLayers()
    .getArray()
    .filter((layer) => layer.get('id') === 'polygonVector')
    .forEach((layer) => (polygonSource = layer.getSource()));
  map.getView().fit(polygonSource.getExtent(), map.getSize());
}

function getStyleData(color, opacity, culturesColors) {
  let colorArray = new olColor.asArray('#' + getColorByName(color, culturesColors)).slice();
  colorArray[3] = opacity;
  return [colorArray, 5];
}

const getColorArray = (color, opacity, culturesColors) => {
  let colorArray = new olColor.asArray('#' + getColorByName(color, culturesColors)).slice();
  colorArray[3] = opacity;
  return colorArray;
};

export function setPolygonStyle(parcelFeature, color, opacity = null, culturesColors = null) {
  let getStrokeColor = 'grey';
  let defaultcolorArray = new olColor.asArray('#e3e3e3').slice();
  defaultcolorArray[3] = 0.7;

  if (Array.isArray(color) && color.length === 2) {
    return parcelFeature.setStyle(
      new Style({
        fill: new ol_style_FillPattern({
          pattern: 'hatch',
          ratio: 1,
          color: getColorArray(color[0].name, opacity, culturesColors),
          offset: 0,
          scale: 2,
          fill: new Fill({ color: getColorArray(color[1].name, opacity, culturesColors) }),
          size: 6,
          spacing: 12,
          angle: 0,
        }),
      })
    );
  }

  if (Array.isArray(color) && color.length === 1) {
    defaultcolorArray = color[0].name;
  }

  if (color?.constructor === Object || typeof color === 'string') {
    defaultcolorArray = color;
  }

  const [getFillColor, width] = !opacity
    ? [defaultcolorArray, 3]
    : getStyleData(defaultcolorArray, opacity, culturesColors);

  if (culturesColors) {
    getStrokeColor = getFillColor;
  }

  return parcelFeature.setStyle(
    new Style({
      stroke: new Stroke({
        color: getStrokeColor,
        width,
      }),
      fill: new Fill({
        color: getFillColor,
      }),
    })
  );
}

const patternRedImage = new Image();
patternRedImage.src = patternRed;

export function redPattern() {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  return context.createPattern(patternRedImage, 'repeat');
}

const patternGreenImage = new Image();
patternGreenImage.src = patternGreen;

export function greenPattern() {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  return context.createPattern(patternGreenImage, 'repeat');
}

const patternRedAndGreenImage = new Image();
patternRedAndGreenImage.src = patternRedAndGreen;

export function redAndGreenPattern() {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  return context.createPattern(patternRedAndGreenImage, 'repeat');
}

export const getMainCultureByYear = (targetYear, parcelData) => {
  if (targetYear === parcelData?.cultureN?.year) {
    return parcelData?.cultureN?.name;
  }
  if (targetYear === parcelData?.cultureN1?.year) {
    return parcelData?.cultureN1?.name;
  }

  const culturesByYear = parcelData?.cultures?.find(
    (cultureYearData) => cultureYearData.year === targetYear
  );

  const culturesName = culturesByYear?.reduce((accumulator, currentCulture, index) => {
    if (index === 0) {
      return currentCulture?.name;
    } else {
      return accumulator + ' & ' + currentCulture?.name;
    }
  }, '');

  return culturesName ?? 'inconnu';
};

export const getPopupHeight = (ref) => {
  if (ref?.current?.offsetHeight) {
    return ref.current.offsetHeight;
  } else {
    return 350;
  }
}
