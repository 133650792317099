import Close from "components/icons/Close";
import React from "react";
import { LoadingContext } from "utils/context";
import { useContext } from "react";
import { useEffect } from "react";


export const FormModal = ({onClick, customTemplate }) => {
    const {setFullScreen} = useContext(LoadingContext)

    useEffect(() => {
        setFullScreen(true)
        return () => setFullScreen(false)
    })
    return (
        <div className="modal">
            <div
                tabIndex={0}
                role="button"
                aria-label="close"
                className="close"
                onClick={onClick}
            >
                <Close />
            </div>
            <div className="content">
                    {/* Custom template */}
                    {customTemplate}
            </div>
        </div>
    )
}