// REACT
import React, { useState } from 'react';

// YARN
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';

// SERVICES
import dropletLogo from 'assets/images/laissez-tomber.png';
import './CulturesMapTab.style.scss';
import { getMainCultureByYear } from './MapCommon';

// INTERFACES
import { IParcelCulture } from '../Interface/Api/Response/Exploitation/Parcels/IParcelCulture';
import { ICulture } from '../Interface/ICulture';
import { IResultInfo } from '../Interface/Simulation/IResultInfo';
import ClearIcon from '@mui/icons-material/Clear';

// THEMES
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';
import { customDialogAddEditCommonTheme } from 'assets/styles/themes/Dialog/generic_dialog_add_edit_parcel';
import ContentCutIcon from '@mui/icons-material/ContentCut';

type ResultsInfoParams = {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  resultsInfo: IResultInfo;
  cultures: ICulture[];
  year: number;
  updateFromMap: (parcelId: number, updatedCultures: IParcelCulture[], year: number) => void;
};

const ResultsInfo = ({
  modal,
  setModal,
  resultsInfo,
  cultures,
  year,
  updateFromMap,
}: ResultsInfoParams): React.JSX.Element => {
  const checkCultureByYear = () => {
    const culture = resultsInfo.cultures.find((culture) => culture.year === year);
    if (culture) {
      return culture;
    }
  };

  const [currentCulture, setCurrentCulture] = useState<any>(checkCultureByYear());

  const findCultureName = (cultureId: number | string) => {
    return cultures.find((culture) => cultureId === culture.id)?.name;
  };

  const handleCultureChange = (event: SelectChangeEvent<number>, index: number) => {
    const { value } = event.target;
    const currentCultureClone = structuredClone(currentCulture);
    currentCultureClone[index].id = value;
    currentCultureClone[index].name = findCultureName(value);
    setCurrentCulture(currentCultureClone);
  };

  const handleUpdate = (parcelId: number, updatedCultures: IParcelCulture[], year: number) => {
    updateFromMap(parcelId, updatedCultures, year);
  };

  const filteredCultures = resultsInfo.isIrrigableOnly
    ? cultures
    : cultures.filter((culture) => culture.isIrrigableOnly === false);

  const isSplittedParcel = currentCulture?.length > 1;

  return (
    <ThemeProvider theme={customDialogAddEditCommonTheme}>
      <Dialog
        maxWidth="md"
        fullWidth
        open={modal}
        onClose={() => setModal(false)}
        id="results-modal"
      >
        <Box className="modal_header">
          <DialogTitle>
            {resultsInfo?.name}{' '}
            {isSplittedParcel && (
              <>
                <ContentCutIcon />
              </>
            )}
          </DialogTitle>
          <ClearIcon className="close-icon" onClick={() => setModal(false)} />
        </Box>
        <DialogContent>
          <Box>
            <InputLabel id="surface">Surface :</InputLabel>
            <TextField id="surface" value={resultsInfo?.surface} disabled={true} />
          </Box>
          {resultsInfo?.groupSurface && (
            <Box>
              <InputLabel id="groupSurface">Surface du regroupement :</InputLabel>
              <TextField
                id="groupSurface"
                value={Number(resultsInfo?.groupSurface.toFixed(2))}
                disabled={true}
              />
            </Box>
          )}
          <Box>
            <InputLabel id="cultureN">Précédent :</InputLabel>
            <TextField
              id="cultureN"
              value={getMainCultureByYear(year - 1, resultsInfo)}
              disabled={true}
            />
          </Box>
          <Box>
            <InputLabel id="cultureN">Anté-précédent :</InputLabel>
            <TextField
              id="cultureN1"
              value={getMainCultureByYear(year - 2, resultsInfo)}
              disabled={true}
            />
          </Box>
          <Box>
            <InputLabel id="groundType">Type de sol :</InputLabel>
            <Select id="groundType" value={resultsInfo?.groundType?.name} disabled={true}>
              <MenuItem key={resultsInfo?.groundType?.id} value={resultsInfo?.groundType?.name}>
                <ListItemText primary={resultsInfo?.groundType?.name} />
              </MenuItem>
            </Select>
          </Box>
          <Box
            id="irrigable-center"
            className={`${
              isSplittedParcel || resultsInfo?.groupSurface ? 'irrigable-center-splitted' : ''
            }`}
          >
            <Box>
              <InputLabel id="irrigable">Irrigable :</InputLabel>
              <Checkbox
                disabled={true}
                className="checkbox-irrigable"
                id="irrigable"
                checked={resultsInfo?.isIrrigableOnly}
              />
            </Box>
          </Box>
          {resultsInfo?.groupParcelNames && (
            <Stack
              spacing={1}
              direction="row"
              useFlexGap
              flexWrap="wrap"
              className="result-info-stack"
            >
              <span>Regroupée avec :</span>
              {resultsInfo?.groupParcelNames.map((parcelName, index) => (
                <Chip
                  key={index}
                  label={parcelName}
                  variant="outlined"
                  className="result-info-chip"
                />
              ))}
            </Stack>
          )}
          <DialogContent dividers>
            <Box className="wrapper-cultures-select">
              <InputLabel className="wrapper-cultures-select__year">
                {year - 1} - {year}
              </InputLabel>
              <>
                {currentCulture?.map((culture: any, idx: number) => {
                  return isSplittedParcel ? (
                    <Box className="wrapper-cultures-select__splitted" key={idx}>
                      <Box key={idx}>
                        <div className="wrapper-cultures-select__infos">
                          <h4 className="wrapper-cultures-select__title">Parcelle {idx + 1}</h4>
                          <div>
                            <span>Surface : </span>
                            <span className="wrapper-cultures-select__surface">
                              {Number(culture?.surface.toFixed(2))}
                            </span>
                          </div>
                          {culture.irrigation > 0 && (
                            <div className="culture-infos-icon">
                              <img
                                src={dropletLogo}
                                alt="Logo irrigation"
                                className="dropletLogo"
                              />
                              <span>Irrigation Obligatoire</span>
                            </div>
                          )}
                        </div>
                        <Select
                          id="cultureList"
                          value={culture.id}
                          onChange={(e) => handleCultureChange(e, idx)}
                        >
                          {filteredCultures.map((culture) => (
                            <MenuItem key={culture.id} value={culture.id}>
                              {culture.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                  ) : (
                    <Box key={idx}>
                      <Box key={idx}>
                        {culture.irrigation > 0 && (
                          <div className="culture-infos-icon">
                            <img src={dropletLogo} alt="Logo irrigation" className="dropletLogo" />
                            <span>Irrigation Obligatoire</span>
                          </div>
                        )}
                        <Select
                          id="cultureList"
                          value={culture.id}
                          onChange={(e) => handleCultureChange(e, idx)}
                        >
                          {filteredCultures.map((culture) => (
                            <MenuItem key={culture.id} value={culture.id}>
                              {culture.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                  );
                })}
              </>
            </Box>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <ThemeProvider theme={customButtonTheme}>
            <Button
              type="submit"
              onClick={() => handleUpdate(resultsInfo?.id, currentCulture, year)}
            >
              Valider
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ResultsInfo;
