import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { getSecteurCultures, getSecteurInformations } from 'services/API/Secteur';
import { sendEvent } from 'utils/Event';

import Sector from 'components/icons/Sector';

import { useNavigate } from 'react-router-dom';
import DataGridGroundTypeEffects from '../Cooperative/DataGridGroundTypeEffects';

const GroundTypeEffectsDataGridSector = ({ secteur, groundTypes }) => {
  const [cultures, setCultures] = useState(null);
  const navigate = useNavigate();
  const [sector, setSector] = useState(secteur);

  useEffect(() => {
    if (!sector) {
      if (!sector && localStorage.getItem('sector')) {
        getSecteurInformations(+localStorage.getItem('sector'))
          .then((resp) => {
            setSector(resp.data.data);
          })
          .catch((err) => toast.error(err.data));
      } else {
        toast.info('Selectionnez un secteur');
        navigate('/sector');
      }
    }
    // Dismount
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  useEffect(() => {
    if (sector) {
      refreshData();
    }
  }, [sector]);

  const refreshData = () =>
    getSecteurCultures(sector.id)
      .then((resp) => {
        setCultures(resp?.data?.data?.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch(() => toast.error);

  return (
    <>
      <p className="title_spacing">
        <span className="subtitle_spacing">
          <Sector width={30.0} height={30} fill="$secondary" />
        </span>
        <span className="green_text subtitle_spacing">{sector?.name} </span>
      </p>
      <DataGridGroundTypeEffects
        from={'sectors'}
        cultures={cultures}
        ownerId={sector?.id}
        refreshData={refreshData}
        groundTypes={groundTypes}
      />
    </>
  );
};

export default GroundTypeEffectsDataGridSector;
