import React from 'react';
import PropTypes from 'prop-types';

const MenuLeft = ({ color= '#707070', fill='none', width=19.031, height=19.031 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 22.5">
    <g id="menu" transform="translate(1 1)">
      <line
        id="Ligne_45"
        data-name="Ligne 45"
        x1="26"
        fill={fill}
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Ligne_47"
        data-name="Ligne 47"
        x1="26"
        transform="translate(0 20.5)"
        fill={fill}
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Ligne_46"
        data-name="Ligne 46"
        x1="17"
        transform="translate(0 10.25)"
        fill={fill}
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

MenuLeft.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default MenuLeft;
