import { createTheme } from '@mui/material/styles';

const green = '#277134';
const yellow = '#e69636';

export const MultiSelectTheme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          border : '1px solid' + yellow,
          borderRadius : '0px !important',
          '&:hover': {
            border: '1px solid' + green,
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 'unset !important',
        },
      },
    }, 
    MuiFormLabel: {
      styleOverrides: {
        root: {},
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.6) !important',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
          icon:{
            color: green + '!important',
          },
          root: {
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
          color: yellow + '!important',
          '&.Mui-checked': {
            color: green + '!important',
          },
          },
      },
    },
  },
});
