import React, { useState, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { v1 } from 'uuid';

import actionsProps from 'components/propTypes/actions';
import userProps from 'components/propTypes/user';

import { minimalRoleRequired } from 'utils';

import useAuth from 'providers/Auth/useAuth';

import MoreIcon from 'components/icons/MoreIcon';
import './actionList.style.scss';
import { ModalContext } from 'utils/context';
import { DeleteModal } from '../Modals/deleteModal';
import { displayMessage } from 'utils/tools_functions';
import { useEffect } from 'react';

const ActionList = ({ data, actions }) => {
  const { user } = useAuth();
  const ref = useRef();
  const [isOpen, setIsOpen] = useState('none');
  const [showModal, updateShowModal] = useState(false);
  const [confirmDeletion, setConfirmDeletion] = useState(false);

  useEffect(() =>{
    if(confirmDeletion) {
      returnConfirmationDeletion()
    }
    
  }, [confirmDeletion, data, actions])

  const toggleModal = () => {
    updateShowModal(!showModal)
  }

  useOnClickOutside(ref, () => {
    isOpen && setIsOpen('none');
  });

  const toggleList = () => {
    return isOpen === 'block' ? 'none' : 'block';
  };

  const returnConfirmationDeletion = async () => {
    return actions.filter(x => x.label === "Supprimer")[0].func(data)
  }


  return (
    <ModalContext.Provider value={{ showModal, toggleModal, setConfirmDeletion }}>

    <div ref={ref} className="actionList">
      <div role="button" tabIndex={0} onClick={() => setIsOpen(toggleList)}>
        <MoreIcon />
      </div>
      <div className="actionContainer" style={{ display: isOpen }}>
        {actions
          .filter((a) => minimalRoleRequired(user?.role, a.minimalRole))
          .map((action, i) => (
            <div
              className="action"
              role="button"
              tabIndex={i + 1}
              key={v1()}
              onClick={() => action.label === "Supprimer" ? toggleModal() : action.func(data)}
            >
              {action.label}
            </div>
          ))}
      </div>
    </div>
    <DeleteModal headerModal={displayMessage('DELETE_CONFIRM_MODAL', data.name)}></DeleteModal>
    </ModalContext.Provider>
  );
};

ActionList.propTypes = {
  data: userProps.isRequired,
  actions: actionsProps.isRequired,
};

export default ActionList;
