import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { getCulturesByExploitationId, resetGroundTypeEffects } from 'services/API/Exploitation';
import { displayErrorMessage } from 'utils/tools_functions';
import { sendEvent } from 'utils/Event';

import DataGridGroundTypeEffects from '../Cooperative/DataGridGroundTypeEffects';
import { ThemeProvider } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { customDialogTheme } from 'assets/styles/themes/Dialog/generic_dialog_mui';
import './groundTypeEffect.style.scss';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';

const GroundTypeEffectsDataGridExploitation = ({ exploitation, setExploitation }) => {
  const [groundTypes, setGroundTypes] = useState([]);
  const [cultures, setCultures] = useState(null);
  const navigate = useNavigate();
  const [exploit, setExploit] = useState(exploitation);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    if (!exploit) {
      if (!exploit && localStorage.getItem('exploitation')) {
        setExploitation(+localStorage.getItem('exploitation'))
          .then((resp) => setExploit(resp.data.data))
          .catch((err) => toast.error(err.data));
      } else {
        toast.info('Selectionnez une exploitation');
        navigate('/exploitation');
      }
    }
    // Dismount
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  useEffect(() => {
    if (exploit) {
      refreshData();
    }
  }, [exploit]);

  const refreshData = () => {
    getCulturesByExploitationId(exploit.id)
      .then((response) => {
        setCultures(response.data.data.sort((a, b) => a.name.localeCompare(b.name)));
        setGroundTypes(
          response.data.data[0]?.groundTypeEffects?.map((groundTypeEffect) => {
            return {
              id: groundTypeEffect?.groundType?.id,
              name: groundTypeEffect?.groundType?.name,
            };
          })
        );
      })
      .catch((err) => {
        if (err.message !== 'canceled') {
          toast.error(displayErrorMessage('ERR_GET_EFFECT'));
        }
      });
  };

  const resetAllGroundTypeEffects = () => {
    setOpenDeleteDialog(false);
    resetGroundTypeEffects(exploit)
      .then(() => {
        toast.success(`Effets ${exploit.name} réinitialisés`);
        refreshData();
      })
      .catch(() => toast.error(displayErrorMessage('ERR_REINIT_EFFECT')));
  };

  const renderDeleteDialog = () => {
    return (
      <ThemeProvider theme={customDialogTheme}>
        <Dialog maxWidth="md" open={openDeleteDialog}>
          <DialogTitle>Attention</DialogTitle>
          <DialogContent dividers>
            <h3>Remettre les valeurs par défaut</h3>
            <p>Réinitialiser les effets de type de sol de l'exploitation</p>
            <p>Souhaitez-vous continuer?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetAllGroundTypeEffects}>
              Confirmer <DoneIcon />
            </Button>
            <Button onClick={() => setOpenDeleteDialog(false)}>
              Non <ClearIcon />
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  };

  const handleResetGroundTypeEffects = () => {
    setOpenDeleteDialog(true);
  };
  return (
    <>
      <div className="reset_button_container">
        <ThemeProvider theme={customButtonTheme}>
          <Button onClick={handleResetGroundTypeEffects}>Remettre les valeurs par défaut</Button>
        </ThemeProvider>
      </div>
      <DataGridGroundTypeEffects
        from={'exploitations'}
        cultures={cultures}
        ownerId={exploit?.id}
        refreshData={refreshData}
        groundTypes={groundTypes}
      />
      {renderDeleteDialog()}
    </>
  );
};

export default GroundTypeEffectsDataGridExploitation;
