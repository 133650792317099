// REACT
import { useEffect, useState, useRef, useMemo } from 'react';
// YARN
import { ThemeProvider } from '@mui/material/styles';
import { Box, InputLabel, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';
import { GridColDef, GridRowModel } from '@mui/x-data-grid-pro';
// SERVICES
import {
  copyCurrentValueToFuture,
  StyledTooltipErrorInputCostModal,
} from 'utils/Datagrid/CustomCultureDataGridUtils';
import {
  checkDecimalRegexTwoDigitsDatagridValue,
  checkNoDecimalRegexTwoDigitsDatagridValue,
  getOnlyAttributeUpdated,
} from 'utils/tools_functions';
import { updateCulture } from 'services/API/Cooperative';
import { updateCulture as updateCultureSector } from 'services/API/Secteur';
import { updateCulture as updateCultureExploitation } from 'services/API/Exploitation';
import { validateDatagridData } from 'components/generics/Validators/datagrid.validate';
// INTERFACES
import { IInputCostValue } from 'components/generics/Interface/Commons/Culture/IInputCostValue';
import { IFormErrorObjects, IFormErrors } from 'components/generics/Interface/Commons/IErrorForm';
import { IErrorResponse } from 'components/generics/Interface/Commons/IErrorResponse';
// THEMES
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';
import { customDialogInputCostTheme } from 'assets/styles/themes/Dialog/generic_dialog_input_mui';

type InputCostModalParams = {
  openInputCostDialog: boolean;
  setOpenInputCostDialog: React.Dispatch<React.SetStateAction<boolean>>;
  inputCostValue: IInputCostValue;
  refreshData: Function;
  paramsInputCost: { inputCost: boolean; inputCostN5: boolean };
  setParamsInputCost: React.Dispatch<
    React.SetStateAction<{ inputCost: boolean; inputCostN5: boolean }>
  >;
  errorsFormDefault: IFormErrors;
  setErrorsFormDefault: React.Dispatch<React.SetStateAction<IFormErrors | undefined>>;
  columns: GridColDef[];
  owner: { parent: string; id: number } | null;
  updatedRow: GridRowModel;
};

const InputCostModal = ({
  openInputCostDialog,
  setOpenInputCostDialog,
  inputCostValue,
  refreshData,
  paramsInputCost,
  setParamsInputCost,
  errorsFormDefault,
  setErrorsFormDefault,
  columns,
  owner,
  updatedRow,
}: InputCostModalParams) => {
  const [inputCostClone, setInputCostClone] = useState<IInputCostValue>(inputCostValue);
  const ref = useRef<HTMLDivElement>(null);
  const { inputCost, inputCostN5 } = paramsInputCost;
  useEffect(() => {
    if (inputCost) {
      setTimeout(() => {
        if (ref?.current) {
          const inputN: HTMLInputElement | null = ref.current?.querySelector(
            '#inputCostWithoutNitrogen'
          );
          inputN?.focus();
        }
      }, 100);
    }
    if (inputCostN5) {
      setTimeout(() => {
        if (ref?.current) {
          const inputN5: HTMLInputElement | null = ref.current?.querySelector(
            '#inputCostWithoutNitrogenN5'
          );
          inputN5?.focus();
        }
      }, 100);
    }
  }, [inputCost, inputCostN5, ref]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    const { value } = e.target;

    setInputCostClone((prev) => ({
      ...prev,
      [field]:
        field !== 'necessaryNitrogenQuantity'
          ? checkDecimalRegexTwoDigitsDatagridValue(value)
          : checkNoDecimalRegexTwoDigitsDatagridValue(value),
    }));
  };

  const handleInputCostClick = async () => {
    setParamsInputCost((prev) => ({
      ...prev,
      inputCost: false,
      inputCostN5: false,
    }));
    const toUpdate = getOnlyAttributeUpdated(inputCostValue, inputCostClone);

    errorsFormDefault.formError = false;
    setErrorsFormDefault({
      ...validateDatagridData(toUpdate, errorsFormDefault, columns, updatedRow.id),
    });

    if (!errorsFormDefault.formError) {
      if (Object.entries(toUpdate).length > 0) {
        if (
          toUpdate.hasOwnProperty('inputCostWithoutNitrogen') ||
          toUpdate.hasOwnProperty('inputCostWithoutNitrogenN5')
        ) {
          toUpdate.economicAttributeCollection = {
            inputCostWithoutNitrogen: {
              currentEconomicValue:
                toUpdate.inputCostWithoutNitrogen ?? updatedRow.inputCostWithoutNitrogen,
              futureEconomicValue: toUpdate.hasOwnProperty('inputCostWithoutNitrogenN5')
                ? copyCurrentValueToFuture(
                    toUpdate.inputCostWithoutNitrogenN5,
                    inputCostClone.inputCostWithoutNitrogenN5
                  )
                : updatedRow.inputCostWithoutNitrogenN5,
            },
          };
          toUpdate.hasOwnProperty('inputCostWithoutNitrogen') &&
            delete toUpdate.inputCostWithoutNitrogen;
          toUpdate.hasOwnProperty('inputCostWithoutNitrogenN5') &&
            delete toUpdate.inputCostWithoutNitrogenN5;
        }

        if (toUpdate.hasOwnProperty('necessaryNitrogenQuantity')) {
          toUpdate.necessaryNitrogenQuantity =
            toUpdate.necessaryNitrogenQuantity ?? updatedRow.necessaryNitrogenQuantity;
        }

        if (toUpdate.economicAttributeCollection) {
          updatedRow = {
            ...updatedRow,
            economicAttributeCollection: toUpdate.economicAttributeCollection,
          };
          delete updatedRow.price;
          delete updatedRow.priceN5;
          delete updatedRow.inputCostWithoutNitrogen;
          delete updatedRow.inputCostWithoutNitrogenN5;
        }

        try {
          setOpenInputCostDialog(false);
          if (!owner) {
            await updateCulture(toUpdate, inputCostValue.cultureId);
          } else if (owner.parent === 'fromSector') {
            await updateCultureSector(owner.id, toUpdate, inputCostValue.cultureId);
          } else {
            await updateCultureExploitation(owner.id, toUpdate, inputCostClone.cultureId);
          }
          toast.success(`Culture mise à jour`);
          refreshData();
        } catch (error) {
          const apiError = error as IErrorResponse;
          toast.error(apiError?.response?.data?.message);
        }
      }
      setOpenInputCostDialog(false);
    }
  };
  const handleCloseInputCostDialog = () => {
    setOpenInputCostDialog(false);
    setParamsInputCost((prev) => ({
      ...prev,
      inputCost: false,
      inputCostN5: false,
    }));

    setErrorsFormDefault((prev) => {
      if (
        prev?.inputCostWithoutNitrogen &&
        typeof prev.inputCostWithoutNitrogen === 'object' &&
        prev?.inputCostWithoutNitrogenN5 &&
        typeof prev.inputCostWithoutNitrogenN5 === 'object' &&
        prev?.necessaryNitrogenQuantity &&
        typeof prev.necessaryNitrogenQuantity === 'object'
      ) {
        return {
          ...prev,
          formError: false,
          inputCostWithoutNitrogen: {
            ...prev.inputCostWithoutNitrogen,
            message: '',
          },
          inputCostWithoutNitrogenN5: {
            ...prev.inputCostWithoutNitrogenN5,
            message: '',
          },
          necessaryNitrogenQuantity: {
            ...prev.necessaryNitrogenQuantity,
            message: '',
          },
        };
      }
    });
  };

  const isOpen = useMemo(() => errorsFormDefault.formError, [errorsFormDefault]);

  return (
    <ThemeProvider theme={customDialogInputCostTheme}>
      <Dialog maxWidth="md" open={openInputCostDialog} ref={ref} PaperProps={{ ref: ref }}>
        <Box>
          <DialogTitle>Calcul de Charges</DialogTitle>
          <ClearIcon onClick={handleCloseInputCostDialog} />
        </Box>

        <DialogContent dividers>
          <Box>
            <InputLabel id="inputCostWithoutNitrogenLabel">Charges (€/ha)</InputLabel>
            {(
              errorsFormDefault?.[
                'inputCostWithoutNitrogen' as keyof IFormErrors
              ] as IFormErrorObjects
            ).message && (
              <>
                <StyledTooltipErrorInputCostModal
                  open={isOpen}
                  title={
                    (
                      errorsFormDefault?.[
                        'inputCostWithoutNitrogen' as keyof IFormErrors
                      ] as IFormErrorObjects
                    ).message
                  }
                  placement="top"
                >
                  <span></span>
                </StyledTooltipErrorInputCostModal>
              </>
            )}
            <TextField
              id="inputCostWithoutNitrogen"
              value={inputCostClone?.inputCostWithoutNitrogen}
              onChange={(e) => handleChange(e, 'inputCostWithoutNitrogen')}
              className={
                (
                  errorsFormDefault?.[
                    'inputCostWithoutNitrogen' as keyof IFormErrors
                  ] as IFormErrorObjects
                ).message
                  ? 'MuiErrorForm'
                  : ''
              }
              placeholder="Entrez une charge"
            />
          </Box>
          <Box>
            <InputLabel id="inputCostWithoutNitrogenN5Label">Charges N+5 (€/ha)</InputLabel>
            {(
              errorsFormDefault?.[
                'inputCostWithoutNitrogenN5' as keyof IFormErrors
              ] as IFormErrorObjects
            ).message && (
              <StyledTooltipErrorInputCostModal
                open={isOpen}
                title={
                  (
                    errorsFormDefault?.[
                      'inputCostWithoutNitrogenN5' as keyof IFormErrors
                    ] as IFormErrorObjects
                  ).message
                }
                placement="top"
              >
                <span></span>
              </StyledTooltipErrorInputCostModal>
            )}
            <TextField
              id="inputCostWithoutNitrogenN5"
              value={inputCostClone?.inputCostWithoutNitrogenN5}
              onChange={(e) => handleChange(e, 'inputCostWithoutNitrogenN5')}
              className={
                (
                  errorsFormDefault?.[
                    'inputCostWithoutNitrogenN5' as keyof IFormErrors
                  ] as IFormErrorObjects
                ).message
                  ? 'MuiErrorForm'
                  : ''
              }
              placeholder="Entrez une charge N+5"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <ThemeProvider theme={customButtonTheme}>
            <Button id="inputCostButton" onClick={handleInputCostClick}>
              Enregistrer
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
      ;
    </ThemeProvider>
  );
};

export default InputCostModal;
