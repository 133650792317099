import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendEvent } from 'utils/Event';
import { toast } from 'react-toastify';
import {
  getCulturesByExploitationId,
  getExploitationInformations,
} from 'services/API/Exploitation';
import { displayErrorMessage } from 'utils/tools_functions';
import DataGridCulture from '../Cooperative/DataGridCulture';

function CulturesDataGridExploitation({
  exploitation,
  setExploitation,
  structureId,
  setColorContext,
  user,
  nitrogenCover,
  setNitrogenCover,
  biomassSupplyByCover,
  setBiomassSupplyByCover,
}) {
  const [exploit, setExploit] = useState(exploitation);
  const [cultures, setCultures] = useState([]);
  const [coProductsList, setCoProductsList] = useState(null);
  const [economicAttribute, setEconomicAttribute] = useState(exploit?.economicAttributeCollection);

  const navigate = useNavigate();

  useEffect(() => {
    if (!exploit) {
      loadData();
    }
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  useEffect(() => {
    if (exploit) {
      refreshData();
    }
  }, [exploit]);

  const loadData = () => {
    if (localStorage.getItem('exploitation')) {
      setExploitation(+localStorage.getItem('exploitation'))
        .then(
          (resp) => (
            setExploit(resp.data.data),
            setEconomicAttribute(resp.data.data?.economicAttributeCollection),
            setNitrogenCover({ nitrogenSupplyByCover: resp.data.data?.nitrogenSupplyByCover }),
            setBiomassSupplyByCover({ biomassSupplyByCover: resp.data.data?.biomassSupplyByCover })
          )
        )
        .catch((err) => toast.error(err.data));
    } else {
      toast.info('Selectionnez une exploitation');
      navigate('/exploitation');
    }
  };

  const refreshData = () => {
    getCulturesByExploitationId(exploit.id)
      .then((res) => {
        const cultures = res.data;
        setCultures(cultures);
        setColorContext(cultures.data);
      })
      .catch((err) => {
        if (err.message !== 'canceled') {
          toast.error(displayErrorMessage('ERR_GET_CONSTRAINT_CAT'));
        }
      });
  };

  const getOffCropSettings = async () => {
    try {
      const result = await getExploitationInformations(exploit.id);
      const data = result.data;
      setEconomicAttribute(data.data?.economicAttributeCollection);
    } catch (error) {
      toast.error(error.data);
    }
  };

  return (
    <DataGridCulture
      columnsDefinition={cultures.metadata}
      from={'fromExploitation'}
      cultures={cultures.data}
      refreshData={refreshData}
      exploitation={exploit}
      structureId={structureId}
      coProductsList={coProductsList}
      setCoProductsList={setCoProductsList}
      economicAttribute={economicAttribute}
      getOffCropSettings={getOffCropSettings}
      nitrogenCover={nitrogenCover}
      biomassSupplyByCover={biomassSupplyByCover}
      user={user}
    />
  );
}

export default CulturesDataGridExploitation;
