import React from 'react';
import './CulturesMapTab.style.scss';
import DoubleLeftArrow from 'components/icons/DoubleLeftArrow';
import DoubleRightArrow from 'components/icons/DoubleRightArrow';

const YearsMapTab = ({ year, minYear, maxYear, setYear }) => {
  const next = () => {
    if (year < new Date().getFullYear() + 7) {
      setYear(year + 1);
    }
  };

  const previous = () => {
    setYear(year - 1);
  };

  return (
    <div className="years-map-tab-container">
      {year > minYear ? (
        <button onClick={previous}>
          <DoubleLeftArrow />
        </button>
      ) : (
        <button className="button_light" disabled="disabled">
          <DoubleLeftArrow />
        </button>
      )}
      <div className="years-map-tab">
        {year - 1} - {year}
      </div>
      {year < maxYear ? (
        <button onClick={next}>
          <DoubleRightArrow />
        </button>
      ) : (
        <button className="button_light" disabled="disabled">
          <DoubleRightArrow />
        </button>
      )}
    </div>
  );
};

export default YearsMapTab;
