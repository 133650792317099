import React from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from 'providers/Auth/useAuth';

import logo from 'assets/images/logo.png';
import './Login.style.scss';

import ForgotForm from 'components/pages/ForgotPassword/form';

const ForgotPassword = () => {
  const { user } = useAuth();

  return user ? (
    <Navigate to="/" />
  ) : (
    <div className="Login">
      <div className="form">
        <div className="logoContainer">
          <div className="recenterLogo logo m-auto d-block">
            <img className="resizeLogo" src={logo} alt="logo assolia" />
          </div>
        </div>
        <div className="container">
          <p className="text-center title">Récuperation mot de passe</p>
          <ForgotForm />
        </div>
      </div>
      <div className="img" />
    </div>
  );
};

export default ForgotPassword;
