import { useCallback, useState } from 'react';
import CustomDataGridResult from '../../../utils/Datagrid/CustomForecastDataGridResult';
import {
  CustomEditFixedCell,
  CustomForecastCell,
  CustomForecastHeader,
  RenderEditForecastCell,
  culturesSelectValues,
  customFixedCell,
} from '../../../utils/Datagrid/CustomForecastDataGridUtils';
import {
  CustomSortableHeader,
  childrenTotalSurface,
  getFamily,
  totalSurfaceComparator,
} from 'utils/Datagrid/CustomGenericDataGridUtils';
import './Comparator/forecast.styles.scss';

const ParcelsForecast = ({
  exploitationContext,
  dataGridLoading,
  setIsAllowedSubmit,
  stackedRequestsRef,
}) => {
  const [sortModel, setSortModel] = useState([
    {
      field: 'parcel',
      sort: 'asc',
    },
  ]);
  const currentYear = exploitationContext?.currentYear;
  const renderEditForecastCell = (params) => {
    return <RenderEditForecastCell exploitationContext={exploitationContext} {...params} />;
  };
  const renderEditFixedCell = (params) => {
    return <CustomEditFixedCell {...params} />;
  };

  const columns = [
    {
      field: 'parcel',
      headerName: 'Parcelle',
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1.2,
      sortable: false,
      cellClassName: (params) =>
        params.cellMode === 'view' && params.row.parentId.length > 1 && 'first-children',
      renderHeader: () => (
        <CustomSortableHeader setSortModel={setSortModel} fieldName={'parcel'} name={'Parcelle'} />
      ),
    },
    {
      field: 'surface',
      headerName: 'Surface (ha)',
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 0.6,
      sortable: false,
      valueGetter: (value, row) => ({
        totalSurface: row.totalSurface,
        surface: row.surface,
      }),
      valueFormatter: (value) => {
        if (value.totalSurface !== value.surface) {
          return `${value.surface} (Total: ${value.totalSurface
            .toFixed(2)
            .replace(/[.,]00$/, '')})`;
        }
        return value.surface;
      },
      sortComparator: totalSurfaceComparator,
      cellClassName: (params) => params.row.parentId.length > 1 && 'parcel_children',
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'surface'}
          name={'Surface (ha)'}
        />
      ),
    },
    {
      field: 'isIrrigable',
      headerName: 'Irrigabilité',
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 0.4,
      sortable: false,
      cellClassName: (params) => params.row.parentId.length > 1 && 'parcel_children',
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'isIrrigable'}
          name={'Irrigabilité'}
        />
      ),
    },
    {
      field: 'groundType',
      headerName: 'Type de sols',
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      cellClassName: (params) => params.row.parentId.length > 1 && 'parcel_children',
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'groundType'}
          name={'Type de sols'}
        />
      ),
    },
    {
      field: 'cultureN1',
      headerName: `${currentYear - 2}-${currentYear - 1}`,
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      cellClassName: (params) => params.row.parentId.length > 1 && 'parcel_children',
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'cultureN1'}
          name={`${currentYear - 2}-${currentYear - 1}`}
        />
      ),
    },
    {
      field: 'cultureN',
      headerName: `${currentYear - 1}-${currentYear}`,
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      cellClassName: (params) => params.row.parentId.length > 1 && 'parcel_children',
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'cultureN'}
          name={`${currentYear - 1}-${currentYear}`}
        />
      ),
    },
    {
      field: 'forecast',
      headerName: `${currentYear}-${currentYear + 1} (prévisionnel)`,
      type: 'singleSelect',
      renderHeader: CustomForecastHeader,
      sortable: false,
      headerClassName: 'forecast-header header-material',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      editable: true,
      valueOptions: (params) => {
        return culturesSelectValues(params.row.forecast, exploitationContext);
      },
      renderEditCell: renderEditForecastCell,
      renderCell: (params) => CustomForecastCell(params, stackedRequestsRef),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'forecast'}
          name={CustomForecastHeader(currentYear, 'prévisionnel')}
        />
      ),
    },
    {
      field: 'fixed',
      headerName: 'Culture fixée',
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      editable: true,
      renderEditCell: renderEditFixedCell,
      renderCell: (params) => customFixedCell(params, stackedRequestsRef),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'fixed'}
          name={'Culture fixée'}
        />
      ),
    },
  ];

  const processedRows = exploitationContext?.parcels.map((parcel) => {
    const parcelForecast = exploitationContext?.forecasts?.find(
      (forecast) =>
        forecast?.parcel.id === parcel.id &&
        forecast?.year.year === exploitationContext?.currentYear + 1
    );
    return {
      id: parcel?.id,
      parcel: parcel?.name,
      surface: parcel?.surface,
      totalSurface: parcel?.isParent
        ? childrenTotalSurface(parcel?.id, exploitationContext?.parcels) + parcel?.surface
        : parcel?.surface,
      parcelId: parcel?.id,
      isIrrigable: parcel?.isIrrigable.toString() === 'true' ? 'oui' : 'non',
      groundType: parcel?.groundType?.name,
      cultureN1: parcel?.cultureN1?.name,
      cultureN: parcel?.cultureN?.name,
      forecast: parcelForecast?.culture.name ?? 'Aucun forecast',
      fixed: parcelForecast?.fixed ?? false,
      parentId: parcel?.parent ? getFamily(parcel, exploitationContext?.parcels) : [parcel.id],
      isParent: parcel?.isParent ?? false,
    };
  });

  const processRowUpdate = useCallback(
    async (updatedRow, previousRow) => {
      const updatedForecastCultureId = exploitationContext?.cultures?.find(
        (culture) => culture.name === updatedRow.forecast
      )?.id;

      const isFixedSameAsOriginal = exploitationContext?.forecasts.some((forecast) => {
        return forecast.parcel.id === updatedRow.parcelId && forecast.fixed === updatedRow.fixed;
      });

      const isForecastSameAsOriginal = exploitationContext?.forecasts.some((forecast) => {
        return (
          forecast.parcel.id === updatedRow.parcelId &&
          forecast.culture.id === updatedForecastCultureId
        );
      });

      let existInStackedRequest = false;

      stackedRequestsRef.current.forEach((request) => {
        if (request?.rowId === updatedRow.id) {
          existInStackedRequest = true;
        }
      });

      if (previousRow.fixed !== updatedRow.fixed) {
        const data = {
          parcel: updatedRow.parcelId,
          culture: updatedForecastCultureId,
          fixed: updatedRow.fixed,
          updatedFixed: true,
        };

        if (!existInStackedRequest) {
          stackedRequestsRef.current.push({
            ...data,
            rowId: updatedRow.id,
          });
        } else if (!isFixedSameAsOriginal) {
          stackedRequestsRef.current = stackedRequestsRef.current.map((request) => {
            if (request?.rowId === updatedRow.id) {
              return {
                ...request,
                ...data,
              };
            } else {
              return request;
            }
          });
        } else if (isFixedSameAsOriginal && !isForecastSameAsOriginal) {
          stackedRequestsRef.current = stackedRequestsRef.current.map((request) => {
            if (request?.rowId === updatedRow.id) {
              delete request?.updatedFixed;
              delete data.updatedFixed;
              return {
                ...request,
                ...data,
              };
            } else {
              return request;
            }
          });
        } else {
          stackedRequestsRef.current = stackedRequestsRef.current.filter(
            (request) => request?.rowId !== updatedRow.id
          );
        }
        setIsAllowedSubmit(stackedRequestsRef.current.length ? true : false);

        return updatedRow;
      }

      if (
        previousRow.forecast !== updatedRow.forecast &&
        updatedRow.forecast !== 'Aucun forecast'
      ) {
        const data = {
          parcel: updatedRow.parcelId,
          culture: updatedForecastCultureId,
          fixed: updatedRow.fixed,
          forecast: true,
        };

        if (!existInStackedRequest) {
          stackedRequestsRef.current.push({
            ...data,
            rowId: updatedRow.id,
          });
        } else if (!isForecastSameAsOriginal) {
          stackedRequestsRef.current = stackedRequestsRef.current.map((request) => {
            if (request?.rowId === updatedRow.id) {
              return {
                ...request,
                ...data,
              };
            } else {
              return request;
            }
          });
        } else if (isForecastSameAsOriginal && !isFixedSameAsOriginal) {
          stackedRequestsRef.current = stackedRequestsRef.current.map((request) => {
            if (request?.rowId === updatedRow.id) {
              return {
                ...request,
                ...data,
                forecast: false,
              };
            } else {
              return request;
            }
          });
        } else {
          stackedRequestsRef.current = stackedRequestsRef.current.filter(
            (request) => request?.rowId !== updatedRow.id
          );
        }
        setIsAllowedSubmit(stackedRequestsRef.current.length ? true : false);

        return updatedRow;
      }

      return previousRow;
    },
    [exploitationContext]
  );

  const groupingColDef = {
    width: 5,
    valueFormatter: () => '',
    headerName: '',
    field: 'group',
    align: 'right',
    hideDescendantCount: true,
  };

  const getTreeDataPath = (row) => row.parentId;

  const getRowClassName = (params) => {
    if (params.row.parentId.length > 1) {
      return 'MuiDataGrid-ParcelChild--row';
    }
  };

  const isCellEditable = (params) => {
    if (params.row.parentId.length > 1) {
      return false;
    }
    return params.field === 'fixed' && params.row?.forecast === 'Aucun forecast' ? false : true;
  };

  return (
    processedRows && (
      <div className="parcel-forecast-datagrid">
        <CustomDataGridResult
          rows={processedRows}
          columns={columns}
          isLoading={dataGridLoading}
          processRowUpdate={processRowUpdate}
          fullSize={true}
          sortModel={sortModel}
          setSortModel={setSortModel}
          groupingColDef={groupingColDef}
          treeData={true}
          getTreeDataPath={getTreeDataPath}
          getRowClassName={getRowClassName}
          isCellEditable={isCellEditable}
        />
      </div>
    )
  );
};

export default ParcelsForecast;
