import React from 'react';
import PropTypes from 'prop-types';

const More = ({ color = '#fff', fill = '#fff', width = 19.031, height = 19.031 }) => (
  <svg width={width} height={height} viewBox="0 0 19.031 19.031">
    <path
      d="M18.287 8.772h-8.029V.743a.743.743 0 00-1.486 0v8.029H.743a.743.743 0 100 1.486h8.025v8.029a.743.743 0 101.486 0v-8.029h8.029a.743.743 0 000-1.486zm0 0"
      fill={fill}
      color={color}
    />
  </svg>
);

More.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default More;
