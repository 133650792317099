import { useEffect, useState } from 'react';
import { getCultures, getSecteurInformations } from 'services/API/Secteur';
import { sendEvent } from 'utils/Event';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataGridCulture from '../Cooperative/DataGridCulture';
import { displayErrorMessage } from 'utils/tools_functions';

const CulturesDataGridSector = ({
  secteur,
  user,
  nitrogenCover,
  setNitrogenCover,
  biomassSupplyByCover,
  setBiomassSupplyByCover,
}) => {
  const [sector, setSector] = useState(secteur);
  const [sectorCultures, setSectorCultures] = useState([]);
  const [economicAttribute, setEconomicAttribute] = useState(secteur?.economicAttributeCollection);
  const navigate = useNavigate();

  useEffect(() => {
    if (!sector) {
      if (!sector && localStorage.getItem('sector')) {
        getSecteurInformations(+localStorage.getItem('sector'))
          .then((resp) => {
            setSector(resp.data.data);
            setEconomicAttribute(resp.data.data?.economicAttributeCollection);
            setNitrogenCover({ nitrogenSupplyByCover: resp.data.data?.nitrogenSupplyByCover });
            setBiomassSupplyByCover({ biomassSupplyByCover: resp.data.data?.biomassSupplyByCover });
          })
          .catch((err) => {
            toast.error(err.data);
          });
      } else {
        toast.info('Selectionnez un secteur');
        navigate('/sector');
      }
    }
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  useEffect(() => {
    if (sector) {
      refreshData();
    }
  }, [sector]);

  const refreshData = () => {
    getCultures(sector.id)
      .then((resp) => setSectorCultures(resp.data))
      .catch((err) => {
        if (err.message !== 'canceled') {
          toast.error(displayErrorMessage('ERR_GET_CULTURE'));
        }
      });
  };

  const getOffCropSettings = async () => {
    try {
      const result = await getSecteurInformations(sector.id);
      const data = result.data;
      setEconomicAttribute(data.data?.economicAttributeCollection);
    } catch (error) {
      toast.error(error.data);
    }
  };

  return (
    <DataGridCulture
      columnsDefinition={sectorCultures?.metadata}
      from={'fromSector'}
      cultures={sectorCultures?.data}
      user={user}
      refreshData={refreshData}
      sector={sector}
      economicAttribute={economicAttribute}
      getOffCropSettings={getOffCropSettings}
      nitrogenCover={nitrogenCover}
      biomassSupplyByCover={biomassSupplyByCover}
    />
  );
};

export default CulturesDataGridSector;
