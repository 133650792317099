import PropTypes from 'prop-types';

export default PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    minimalRole: PropTypes.number,
    // minimalRole: PropTypes.minimalRole,
    func: PropTypes.func,
  })
);
