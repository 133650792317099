export function lengthInInterval(tab, repetition, nb) {
  return tab.length > nb * repetition;
}

export function truncateTab(tab, size, repetition) {
  return tab.length > size * repetition
    ? tab.slice(size * (repetition - 1), size * repetition)
    : tab.length > size * (repetition - 1)
    ? tab.slice(size * (repetition - 1), tab.length)
    : [];
}

export function toLowerCaseWithFirstLetterUpper(stringParam) {
  if (!stringParam) {
    return '';
  }

  return (
    stringParam.toUpperCase().substring(0, 1) +
    stringParam.toLowerCase().substring(1, stringParam.length)
  );
}
