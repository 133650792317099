import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { resetPassword } from 'services/API/User';

import InputLabel from 'components/generics/InputLabel';
import Button from 'components/generics/Button';
import { displayErrorMessage } from 'utils/tools_functions';

const DEFAULT_FORM = { password: '', passwordVerify: '', token: '' };

const LoginForm = () => {
  const [formData, setFormData] = useState(DEFAULT_FORM);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const [action, setAction] = useState('');

  useEffect(() => {
    setToken(query.get('token'));
    setAction(query.get('action'));
  }, []);

  const onSubmit = (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    if (formData.password === formData.passwordVerify) {
      resetPassword(token, formData) 
        .then(() => {
          toast.success('Votre mot de passe a été mis a jour');
          setLoading(false);
          navigate('/login');
        })
        .catch(() => {
          setLoading(false);
          toast.error(displayErrorMessage('ERR_REINIT_PASSWORD'));
        });
    } else {
      toast.error(displayErrorMessage('ERR_DIFFERENT_PASSWORD'));
      setLoading(false);
    }
  };

  const updateForm = (key, value) => setFormData({ ...formData, [key]: value });

  return (
    <>
      <form onSubmit={onSubmit}>
        <InputLabel
          name="password"
          value={formData.password}
          onChange={(e) => {
            updateForm('password', e);
          }}
          placeholder="Mot de passe"
          label="Mot de passe"
          defaultStyle="login"
          type="password"
          disabled={loading}
        />
        <InputLabel
          name="passwordVerify"
          value={formData.passwordVerify}
          onChange={(e) => {
            updateForm('passwordVerify', e);
          }}
          placeholder="Vérification mot de passe"
          label="Vérification mot de passe"
          defaultStyle="login"
          type="password"
          disabled={loading}
        />
        <Button
          defaultStyle="d-block"
          type="submit"
          text={action === 'reset' ? `Changer mot de passe` : `Valider le mot de passe`}
          disabled={loading}
        />
      </form>
    </>
  );
};

export default LoginForm;
