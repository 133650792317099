import { createTheme } from '@mui/material';

export const customDialogTheme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            border: '3px solid #e69636 !important',
            borderRadius: '20px',
            textAlign: 'center',
            fontSize: '18px',
            color: '#e69636 !important',
          },
          '& .MuiDialog-paper .MuiDialogTitle-root': {
            fontSize: '20px',
            color: '#e69636',
            fontFamily: 'Rubik-Medium !important',
            textTransform: 'uppercase',
            userSelect: 'none',
          },
          '& .MuiDialog-paper .MuiDialogContentText-root': {
            display: 'flex',
            textAlign: 'start',
            fontSize: '14px',
            paddingTop: '10px',
            whiteSpace: 'pre-wrap',
          },
          '& .MuiDialog-paper .MuiDialogContentText-root .MuiSvgIcon-root': {
            paddingRight: '5px',
            color: '#e69636 !important',
          },
          '& .MuiDialog-paper .MuiDialogActions-root': {
            justifyContent: 'space-evenly !important',
          },
          '& .MuiDialog-paper .MuiDialogActions-root .MuiButton-root': {
            backgroundColor: '#277134 !important',
            color: '#FFF',
            fontFamily: 'Rubik-Medium !important',
            padding: '5px 10px 5px 20px',
            fontSize: '16px',
            '&:last-child': {
              backgroundColor: '#FF0000 !important',
            },
            '&:last-child .MuiSvgIcon-root': {
              backgroundColor: 'transparent !important',
            },
            '&:hover': {
              backgroundColor: '#e69636 !important',
            },
          },
          '& .MuiDialog-paper .MuiDialogActions-root .MuiButton-root .MuiSvgIcon-root': {
            backgroundColor: '#e69636 !important',
            borderRadius: '100%',
            marginLeft: '6px',
            fontSize: '18px',
            padding: '1px !important',
            color: '#277134 !important',
            fontFamily: 'Rubik-Medium !important',
          },
          '& .MuiDialog-paper .MuiDialogContent-root': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          },
        },
      },
    },
  },
});

export const customWrappedDialogTheme = createTheme(customDialogTheme, {
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper .MuiDialogContent-root': {
            overflow: 'unset !important',
            whiteSpace: 'unset !important',
          },
        },
      },
    },
  },
});
