import { IParcelCulture } from 'components/generics/Interface/Api/Response/Exploitation/Parcels/IParcelCulture';
import { IParcelSimu } from 'components/generics/Interface/Simulation/IParcelSimu';

let currentYear: number;
const getCurrentYearFromLocalStorage: string | null = localStorage.getItem('currentYear');
if (null !== getCurrentYearFromLocalStorage) {
  currentYear = Number(getCurrentYearFromLocalStorage);
}

type RenderConstraintNotRespectedParams = {
  year: any;
  index: number;
  constraintName: string;
  constraintDetailName: string;
  suggest: any;
  result: any;
};

export const RenderConstraintNotRespected = ({
  year,
  index,
  constraintName,
  constraintDetailName,
  suggest,
  result,
}: RenderConstraintNotRespectedParams): JSX.Element => {
  let messageErrorDisplay: string;
  if (constraintName === 'restrictionDurationConstraint') {
    const parcelNameArray: string[] = [];
    for (const parcelIndex of year.errorsConstraints[constraintName].parcels) {
      parcelNameArray.push(suggest.parcels[parcelIndex].name);
    }
    messageErrorDisplay = `La contrainte Obligation/Interdiction de culture n'est pas respectée en année ${
      currentYear + index
    }-${currentYear + 1 + index}. Les parcelles concernées sont ${parcelNameArray.join(', ')}`;
  } else if (constraintName === 'coversConstraint') {
    const parcelCoversArray: string[] = [];
    for (const parcelId of year.errorsConstraints[constraintName].parcels) {
      const parcel: string = suggest.parcels?.find(
        (parcel: IParcelSimu) => parcel?.id === parcelId
      )?.name;

      const cultures: number[] =
        year.errorsConstraints[constraintName].cultures.find((cultureIds: number[]) =>
          cultureIds.some(() =>
            suggest.parcels?.some((parcel: IParcelSimu) => parcel.id === parcelId)
          )
        ) || [];

      const cultureNames: string[] = cultures.map((cultureId: number) => {
        return result.cultures?.find((culture: IParcelCulture) => culture?.id === cultureId)?.name;
      });

      parcelCoversArray.push(`${parcel} avec la culture ${cultureNames.join(', ')}`);
    }

    messageErrorDisplay = `La contrainte Proposition de couvert n'est pas respectée en année ${
      currentYear + index
    }-${currentYear + 1 + index}. Les parcelles concernées sont : ${parcelCoversArray.join(', ')}`;
  } else if (constraintName === 'TFIConstraint') {
    if (constraintDetailName === 'herbicideCompliant') {
      messageErrorDisplay = `La contrainte IFT herbicide n'est pas respectée en année ${
        currentYear + index
      }-${currentYear + 1 + index}. En ${currentYear + index}-${currentYear + 1 + index} : ${
        year.averageTFIHerbicide
      } | Attendu : ${result.constraints.TFI.maxHerbicide}`;
    } else {
      messageErrorDisplay = `La contrainte IFT non herbicide n'est pas respectée en année ${
        currentYear + index
      }-${currentYear + 1 + index}. En ${currentYear + index}-${currentYear + 1 + index} : ${
        year.averageTFIWithoutHerbicide
      } | Attendu : ${result.constraints.TFI.maxWithoutHerbicide}`;
    }
  } else {
    const min: number =
      result.constraints[constraintName].filter(
        (constraintGlobalData: { displayName: string }) =>
          constraintGlobalData.displayName === constraintDetailName
      )?.[0]?.['min'] || 0;
    const max: number | null =
      result.constraints[constraintName].filter(
        (constraintGlobalData: { displayName: string }) =>
          constraintGlobalData.displayName === constraintDetailName
      )?.[0]?.['max'] || null;
    messageErrorDisplay = `La contrainte ${constraintDetailName} n'est pas respectée en année ${
      currentYear + index
    }-${currentYear + 1 + index}. En ${currentYear + index}-${currentYear + 1 + index} : ${
      year.errorsConstraints[constraintName][constraintDetailName]
    } | Attendu : ${min} ${max ? '- ' + max : ''}`;
  }

  return (
    <div
      style={{
        backgroundColor: '#e74c3c',
        width: '100%',
        height: '50px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 10,
        borderRadius: 15,
        padding: '0 7px',
      }}
      key={index}
    >
      <span style={{ textAlign: 'center', color: '#fff', fontSize: 15 }}>
        {messageErrorDisplay}
      </span>
    </div>
  );
};
