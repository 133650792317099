import { useContext } from 'react';
import './graphPerYear.style.scss';
import '../pdfComponent.style.scss';
import { PdfContext } from 'utils/context';
import { v1 } from 'uuid';

const GraphPreviousYear = () => {
  type graphPerYearItem = {
    proposal: string;
    charts: string[];
  };
  const { graphPerYear, proposal, currentYear } = useContext(PdfContext);

  const graphPreviousYear: string = graphPerYear.find(
    (prop: graphPerYearItem) => prop.proposal === proposal
  ).charts[0];

  return (
    <article className="pdf-page ">
      <div className="tableau_center">
        <h6 className="title_h6">Production année courante (ha)</h6>
        <div className="multiple-graph-bloc">
          <div key={v1()} className="graph">
            <h6 className="title_campagne">
              Campagne {currentYear - 1} - {currentYear}
            </h6>
            <img
              className="graph-production"
              src={graphPreviousYear}
              alt="Graphique de production année précédente"
            />
          </div>
        </div>
      </div>
    </article>
  );
};

export default GraphPreviousYear;
