import PropTypes from 'prop-types';

export default PropTypes.shape({
  createdAt: PropTypes.string,
  email: PropTypes.string,
  firstname: PropTypes.string,
  id: PropTypes.number,
  lastname: PropTypes.string,
  role: PropTypes.string,
});
