import { Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { importCsvDataMetaculture } from 'services/API/Cooperative';
import { displayErrorMessage, formatFileSize } from 'utils/tools_functions';

const Import = () => {
  const [file, setFile] = useState<File | null | undefined>(null);

  const importDataFromFile = () => {
    let formData = new FormData();
    formData.append('file', file as File);
    importCsvDataMetaculture(formData)
      .then(() => {
        toast.success(`Les données du fichier ${file?.name} ont été importées.`);
      })
      .catch((error) => {
        console.log(error);
        toast.error(displayErrorMessage('ERR_IMPORT_CSV'));
      });
  };

  return (
    <div>
      <h1 className="title_section">Importer les metacultures</h1>
      {file ? (
        <div className="wrapper-import">
          <div className="wrapper-import__infos">
            <div>
              Nom du fichier : <span className="wrapper-import__infos__detail">{file.name} </span>
            </div>
            <div>
              <span>
                Taille du fichier :{' '}
                <span className="wrapper-import__infos__detail">{formatFileSize(file.size)}</span>
              </span>
            </div>
          </div>
          <ThemeProvider theme={customButtonTheme}>
            <div>
              <div className="wrapper-import__button">
                <Button onClick={() => importDataFromFile()}>Importer les données</Button>
              </div>
              <div className="wrapper-import__button">
                <Button onClick={() => setFile(null)}>Importer un nouveau fichier</Button>
              </div>
            </div>
          </ThemeProvider>
        </div>
      ) : (
        <div>
          <input type="file" accept="text/csv" onChange={(e) => setFile(e?.target?.files?.[0])} />
        </div>
      )}
    </div>
  );
};

export default Import;
