// REACT
import React, { SyntheticEvent, useEffect, useState } from 'react';

// YARN
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { ThemeProvider } from '@mui/material/styles';

// INTERFACE
import { IMetaculture } from 'components/generics/Interface/IMetaculture';
import { IFormErrorObjects, IFormErrors } from 'components/generics/Interface/Commons/IErrorForm';

// THEMES
import { customAutocompleteTheme } from 'assets/styles/themes/Dialog/generic_autocomplete';

const useAutoComplete = (
  data: IMetaculture[],
  errorsFormDefault: IFormErrors,
  field: string,
  initialValue: string | null,
  resetMetaCultureValue: () => void
) => {
  const [inputValue, setInputValue] = useState(initialValue || '');
  const [options, setOptions] = useState<string[]>([]);
  const [metaCode, metaCultureName] = inputValue.split('-').map((part) => part.trim());

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.metaCode + '-' + option.metaCultureName,
  });

  const filterData = (value: string, data: IMetaculture[]) => {
    const filtered =
      data &&
      filterOptions(data, {
        inputValue,
        getOptionLabel: (option) => option.metaCode + '-' + option.metaCultureName,
      });

    if (!value || filtered?.length === 0) {
      return data?.map((item) => item?.metaCode + '-' + item?.metaCultureName);
    }

    return filtered?.map((item) => item?.metaCode + '-' + item?.metaCultureName);
  };

  useEffect(() => {
    setOptions(filterData(inputValue, data));
  }, [inputValue, data]);

  const handleAutoCompleteChange = (e: SyntheticEvent, newValue: string | null) => {
    if (newValue) {
      setInputValue(newValue);
    } else {
      setInputValue('');
    }
  };

  const renderAutoComplete = () => {
    return (
      data &&
      data.length > 0 && (
        <ThemeProvider theme={customAutocompleteTheme}>
          <Autocomplete
            value={inputValue}
            onChange={handleAutoCompleteChange}
            options={options ?? []}
            onInputChange={(e, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Rechercher"
                error={
                  (errorsFormDefault?.[`${field}` as keyof IFormErrors] as IFormErrorObjects)
                    .message
                    ? true
                    : false
                }
                helperText={
                  (errorsFormDefault?.[`${field}` as keyof IFormErrors] as IFormErrorObjects)
                    .message
                }
              />
            )}
            freeSolo
            slotProps={{
              clearIndicator: {
                onClick: () => {
                  resetMetaCultureValue();
                },
              },
            }}
            clearIcon={<ClearIcon fontSize="small" />}
          />
        </ThemeProvider>
      )
    );
  };

  return {
    renderAutoComplete,
    inputValue,
    metaCode,
    metaCultureName,
    setInputValue,
  };
};

export default useAutoComplete;
