import React, { ChangeEvent, ElementType, SetStateAction, useContext, useState } from "react"
import { displayErrorMessage, formatFileSize } from "utils/tools_functions";
import Loader from "components/generics/Loader";
import { toast } from "react-toastify";
import { getGroundTypeByExploitationId, ImportTelepac } from "services/API/Exploitation";
import Button from "components/generics/Button";
import { InputLabel, MenuItem, Select, SelectChangeEvent, styled } from "@mui/material";
import { ExploitationContext } from "utils/context";
import BasicObject from "components/generics/Interface/Api/Common/BasicObject";
import { importParcelsResult, importParcelsStatus } from "services/API/Cooperative";
import { useNavigate } from "react-router-dom";
import { IExploitationContext } from "components/generics/Interface/Context/IExploitationContext";
import { LoadingContext } from "utils/context";
import { ILoadingContext } from "components/generics/Interface/Context/ILoadingContext";
import './telepacImport.scss';

const TelepacImport = (): React.JSX.Element => {
    const [file, setFile] = useState<File | null>(null);
    const [result, setResult] = useState<Object>(false);
    const { setCustomText, setLoading, loading } = useContext(LoadingContext) as ILoadingContext;
    const [currentGroundType, setCurrentGroundType] = React.useState<string>('defaultItem');
    const navigate = useNavigate();
    const { groundTypesList, getGroundTypesList } = useContext(ExploitationContext) as IExploitationContext

    const handleSubmit = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return
        if (e?.target?.files[0]) {
            setFile(e?.target?.files[0])
            await getGroundTypesList()
        }
    }

    const importDataFromFile = async () => {
        setCustomText('Veuillez patienter pendant l\'import des données Télépac')
        const formData = new FormData();
        formData.append('file', file as File);
        formData.append('groundTypeId', currentGroundType);
        try {
            const result = await ImportTelepac(formData, localStorage.getItem('exploitation'));
            checkStatus(result.data.data.jobId);
        } catch (error) {
            toast.error(displayErrorMessage('ERR_IMPORT_TELEPAC'));
        }
    };

    const handleChangeGroundType = (event: SelectChangeEvent<string>) => {
        setCurrentGroundType(event.target.value)
    }

    const checkStatus = (jobId: number) => {
        const interval = setInterval(async () => {
            try {
                setCustomText('Veuillez patienter pendant l\'import des données Télépac')
                const response = await importParcelsStatus(jobId);
                const { message } = response.data;
                if (message !== 'En cours') {
                    getJobResult(jobId);
                    clearInterval(interval);
                    if (message === 'Fini sans erreur') {
                        toast.success(`Les données du fichier ${file?.name} ont été importées.`);
                        navigate(`/exploitation/parcels`);
                    } else {
                        toast.error(displayErrorMessage('ERR_IMPORT_TELEPAC'));
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }, 3000);
    };

    const getJobResult = async (jobId: number) => {
        try {
            const response = await importParcelsResult(jobId);
            setResult(response.data);
        } catch (error) {
            toast.error(displayErrorMessage('ERR_IMPORT_TELEPAC'));
        }
    };

    return (
        <div className="section">
            <div className="main_container full-height">
                <h1 className="title_section">Import du fichier .xml Telepac</h1>
                <div className="telepacImportContainer">
                    {file ? (
                        <div className="information-file">
                            <span className="importDetailsTitle">
                                Nom du fichier : <span className="importDetailsDescription">{file?.name}</span>
                            </span>
                            <span className="importDetailsTitle" style={{ marginTop: 20 }}>
                                Taille du fichier :{' '}
                                <span className="importDetailsDescription">{formatFileSize(file?.size)}</span>
                            </span>
                            {groundTypesList &&

                                <div className="importDetailsSelectGroundType">
                                    <InputLabel id="groundTypeList">Veuillez sélectionner un type de sol par défaut avant d'importer les données Télépac</InputLabel>
                                    <Select
                                        labelId="groundTypeList"
                                        id="groundTypeList"
                                        value={currentGroundType}
                                        label="Type de sol par défaut"
                                        onChange={handleChangeGroundType}
                                    >
                                        <MenuItem key={"defaultItem"} value={"defaultItem"} disabled>Type de sol par défaut</MenuItem>
                                        {groundTypesList.map((groundType: BasicObject) =>
                                            <MenuItem key={groundType.id} value={groundType.id}>{groundType.name}</MenuItem>
                                        )}
                                    </Select>
                                </div>
                            }

                            <div className="buttonUpload">
                                <div className="buttonContainer">
                                    <Button
                                        text="Importer les données"
                                        onClick={() => importDataFromFile()}
                                        disabled={loading || !!result || currentGroundType === 'defaultItem'}
                                    />
                                </div>
                                <div className="buttonContainer">
                                    <Button
                                        text="Importer un nouveau fichier"
                                        onClick={() => {
                                            setFile(null);
                                            setResult(false)
                                            setCurrentGroundType('defaultItem')
                                        }}
                                        backgroundColor="#e74c3c"
                                        disabled={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <input
                                type="file"
                                onChange={(e) => handleSubmit(e)}
                                accept="text/xml"
                                id="file"
                                className="input-file"
                            />
                            <label htmlFor="file" style={{ margin: 'auto' }}>
                                Parcourir
                            </label>
                        </div>
                    )}
                </div>
            </div>
        </div>

    )
}


export default TelepacImport;