import React from 'react';
import { v1 } from 'uuid';
import PropTypes from 'prop-types';

const SimulationSteps = ({ steps, currentStep, changeStep, cleanObligationRestriction }) => {
  return (
    <div className="simulation_step_container">
      {steps.map((e, i) => (
        <div
          key={v1()}
          className={`simulation_step${i === currentStep ? ' active' : ''}${
            i < currentStep ? ' passed' : ''
          }`}
          onClick={() => {
            if (i === 5) {
              cleanObligationRestriction();
            }
            changeStep(i);
          }}
        >
          <p>{e.title}</p>
        </div>
      ))}
    </div>
  );
};

SimulationSteps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })).isRequired,
  currentStep: PropTypes.number.isRequired,
  changeStep: PropTypes.func.isRequired,
};

export default SimulationSteps;
