// REACT
import { useEffect, useState, useRef } from 'react';
// YARN
import { ThemeProvider } from '@mui/material/styles';
import { Box, Checkbox, InputLabel, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';
import { GridColDef, GridRowModel } from '@mui/x-data-grid-pro';
// SERVICES
import { updateCulture } from 'services/API/Cooperative';
import { getOnlyAttributeUpdated } from 'utils/tools_functions';
import useAutoComplete from 'utils/hooks/useAutoComplete';
// INTERFACES
import { IMetaCultureValue } from 'components/generics/Interface/Commons/Culture/IMetaCultureValue';
import { IFormErrors } from 'components/generics/Interface/Commons/IErrorForm';
import { IErrorResponse } from 'components/generics/Interface/Commons/IErrorResponse';
import { IMetaculture } from 'components/generics/Interface/IMetaculture';
// THEMES
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';
import { customMetaCultureDialogTheme } from 'assets/styles/themes/Dialog/generic_autocomplete';

type MetaCultureModalParams = {
  openMetacultureDialog: boolean;
  setOpenMetacultureDialog: React.Dispatch<React.SetStateAction<boolean>>;
  metacultureValue: IMetaCultureValue;
  refreshData: Function;
  errorsFormDefault: IFormErrors;
  columns: GridColDef[];
  owner: { parent: string; id: number } | null;
  updatedRow: GridRowModel;
  metaculture: IMetaculture[];
};

const MetaCultureModal = ({
  openMetacultureDialog,
  setOpenMetacultureDialog,
  metacultureValue,
  refreshData,
  errorsFormDefault,
  owner,
  updatedRow,
  metaculture,
}: MetaCultureModalParams) => {
  const [metacultureClone, setMetacultureClone] = useState<IMetaCultureValue>(metacultureValue);
  const ref = useRef<HTMLDivElement>(null);

  const resetMetacultureClone = () => {
    setMetacultureClone({
      ...metacultureClone,
      metaCultureName: updatedRow?.name || '',
      metaCode: '',
      rootBiomass: 0,
      aerialBiomass: 0,
      isArable: false,
      capCategory: '',
    });

    setInputValue('');
  };

  const { renderAutoComplete, inputValue, setInputValue, metaCode, metaCultureName } =
    useAutoComplete(
      metaculture,
      errorsFormDefault,
      'metaCode',
      metacultureValue.metaCode === 'Aucun' ? updatedRow?.name : metacultureValue.metaCode,
      resetMetacultureClone
    );

  useEffect(() => {
    if (inputValue) {
      const selectedMetaCulture = metaculture?.find((item) => item?.metaCode === metaCode);

      if (selectedMetaCulture) {
        setMetacultureClone({
          ...metacultureClone,
          metaCode: selectedMetaCulture?.metaCode,
          metaCultureName: selectedMetaCulture?.metaCultureName,
          aerialBiomass: selectedMetaCulture?.aerialBiomass ?? 0,
          rootBiomass: selectedMetaCulture?.rootBiomass ?? 0,
          capCategory: selectedMetaCulture?.capCategoryName,
          isArable: selectedMetaCulture?.arable,
        });
      }
    }
  }, [metaCode]);

  const handleMetacultureClick = async () => {
    const toUpdate = getOnlyAttributeUpdated(metacultureValue, metacultureClone);

    errorsFormDefault.formError = false;

    if (!errorsFormDefault.formError) {
      if (Object.entries(toUpdate).length > 0) {
        if (toUpdate.hasOwnProperty('metaCode')) {
          toUpdate.metaCode = toUpdate.metaCode ?? updatedRow.metaCode;

          if (toUpdate.metaCode === '') {
            toUpdate.metaCode = null;
          }

          delete toUpdate.metaCultureName;
          delete toUpdate.rootBiomass;
          delete toUpdate.aerialBiomass;
          delete toUpdate.isArable;
          delete toUpdate.capCategory;
        }

        try {
          setOpenMetacultureDialog(false);
          if (!owner) {
            await updateCulture(toUpdate, metacultureValue.cultureId);
          }
          toast.success(`Culture mise à jour`);
          refreshData();
        } catch (error) {
          const apiError = error as IErrorResponse;
          if (apiError?.response?.data?.message === 'MetaCode should be a string of 3 characters') {
            toast.error('Le Code Culture est incorrect');
          } else {
            toast.error(apiError?.response?.data?.message);
          }
        }
      }
      setOpenMetacultureDialog(false);
    }
  };
  const handleCloseMetacultureDialog = () => {
    setOpenMetacultureDialog(false);
  };

  return (
    <ThemeProvider theme={customMetaCultureDialogTheme}>
      <Dialog maxWidth="md" open={openMetacultureDialog} ref={ref} PaperProps={{ ref: ref }}>
        <Box>
          <DialogTitle>Code culture</DialogTitle>
          <ClearIcon onClick={handleCloseMetacultureDialog} />
        </Box>
        <DialogContent className="" dividers>
          <Box id="custom-box-content">
            <Box>
              <InputLabel id="metaCultureLabel" htmlFor="metaCulture">
                Code culture :
              </InputLabel>
              {renderAutoComplete()}
            </Box>
            <Box>
              <InputLabel id="CoefBALabel" htmlFor="coefBA">
                Coef biomasse aérienne :
              </InputLabel>
              <TextField
                id="coefBA"
                placeholder="0"
                value={metacultureClone?.aerialBiomass}
                disabled={true}
              />
            </Box>
            <Box>
              <InputLabel id="CoefBRLabel" htmlFor="coefBR">
                Coef biomasse racinaire :
              </InputLabel>
              <TextField
                id="coefBR"
                placeholder="0"
                disabled={true}
                value={metacultureClone?.rootBiomass}
              />
            </Box>
            <Box>
              <InputLabel htmlFor="capCategory">Catégorie PAC :</InputLabel>
              <TextField
                id="capCategory"
                disabled={true}
                placeholder="Aucun"
                value={metacultureClone?.capCategory}
              />
            </Box>
            <Box>
              <InputLabel id="isArableLabel" htmlFor="isArable">
                Terre arable :
              </InputLabel>
              <Checkbox
                className="meta_culture_checkbox"
                id="isArable"
                disabled={true}
                checked={metacultureClone?.isArable}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <ThemeProvider theme={customButtonTheme}>
            <Button onClick={handleMetacultureClick}>Enregistrer</Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
      ;
    </ThemeProvider>
  );
};

export default MetaCultureModal;
