import React from "react";
import { ModalContext } from "utils/context";
import './deleteModal.style.scss';

export const DeleteModal = ( {headerModal}) => {

    return (<ModalContext.Consumer>
        {context => {
            if (context.showModal) {
                return (<div className="modal_delete">
                    <div className="content">
                        <div>
                            <p>{headerModal}</p>
                        </div>
                        <div>
                            <button onClick={context.toggleModal}>Annuler</button>
                            <button onClick={() => {
                                context.setConfirmDeletion(true)
                                context.toggleModal()
                            }}>
                                Confirmer
                            </button>
                        </div>
                    </div>
                </div>);
            }
            return null
        }}
    </ModalContext.Consumer>
    );
}