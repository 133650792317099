import { useContext } from 'react';
import './grossMarginGraph.style.scss';
import '../Tables/table.style.scss';
import { PdfContext } from 'utils/context';
import { v1 } from 'uuid';
import { lengthInInterval, truncateTab } from '../Generics/utilsFunctions';

const KeyNumberPage = () => {
  const { currentYear, keyNumber, suggest } = useContext(PdfContext);

  const years = suggest?.years;
  let headers = [
    ...['Campagne', `${currentYear} - ${currentYear + 1}`],
    ...Array(years.length - 1)
      .fill()
      .map((_, i) => `${i + (currentYear + 1)}-${i + (currentYear + 2)}`),
  ];
  const keyNumberWithoutN1 = keyNumber?.map((keyNumberCategory) => ({
    ...keyNumberCategory,
    data: keyNumberCategory?.data?.slice(1),
  }));

  const numberOfRowPerPage = 12;
  let toDuplicate = true;
  let repetition = 1;
  let keyNumberPages = [];

  while (toDuplicate) {
    // On découpe la list des graphs en 2 graph par page pour que ce soit lisible
    let truncatedResult = truncateTab(keyNumberWithoutN1, numberOfRowPerPage, repetition);
    keyNumberPages.push(
      buildKeyNumberTable(truncatedResult, repetition > 1 ? '(Suite)' : '', headers)
    );
    lengthInInterval(keyNumberWithoutN1, repetition, numberOfRowPerPage)
      ? repetition++
      : (toDuplicate = false);
  }

  return keyNumberPages;
};

const buildKeyNumberTable = (truncatedKeyNumber, message, headers) => {
  return (
    <article className="pdf-page">
      <div className="marge_brute_graph">
        <h6 className="title_h6 title_key">Chiffres clés {message}</h6>
        <table className="table">
          <thead className="table_head">
            <tr className="key_table_tr">
              {headers.map((header, i) => (
                <th className="column_name" key={i} scope="col">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {truncatedKeyNumber?.map((other) => (
              <tr className="key_table_tr" key={v1()}>
                <td className="value_table">{other?.id}</td>
                {other?.data.map((year) => (
                  <td className="value_table" key={v1()}>
                    {year.value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </article>
  );
};

export default KeyNumberPage;
