const { createTheme } = require('@mui/material');

export const customDialogRegroupParcelsTheme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            border: '3px solid #e69636 !important',
            borderRadius: '20px',
            textAlign: 'center',
            fontSize: '18px',
            color: '#e69636 !important',
          },
          '& .MuiDialog-paper .MuiBox-root': {
            justifyContent: 'center !important',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          },
          '& .MuiDialog-paper .MuiBox-root .MuiSvgIcon-root': {
            fontSize: '36px',
            color: '#277134 !important',
            position: 'absolute',
            right: '20px',
            cursor: 'pointer',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '20px',
          color: '#e69636',
          fontFamily: 'Rubik-Bold !important',
          color: '#277134 !important',
          userSelect: 'none',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          display: 'flex',
          alignItems: 'center',
          padding: '10px 24px',
          '& > label': {
            padding: '10px',
            overflow: 'unset !important',
            maxWidth: '160px',
            whiteSpace: 'pre-wrap',
            fontFamily: 'Rubik-Medium !important',
            color: '#277134 !important',
            userSelect: 'none',
            width: '30%',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center !important',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          outline: '1px solid #e69636 !important',
          ':hover': {
            outline: '2px solid #e69636 !important',
          },
        },
      },
    },
  },
});

export const SelectParcelTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '.MuiSelect-select': {
            textAlign: 'left',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 'unset !important',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&[data-shrink="true"]': {
            color: '#e69636 !important',
            top: '-6px',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#277134 !important',
        },
      },
    },
  },
});
