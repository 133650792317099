import Import from './Import/Import';
import Export from './Export/Export';
import './tools.scss';

function Tools() {
  return (
    <div className="wrapper-inputs">
      <Export />
      <Import />
    </div>
  );
}

export default Tools;
