import React from 'react';
import PropTypes from 'prop-types';

function MoreIcon({ color = '#000', fill = '#707070', width = 19, height = 19 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 408 408">
      <path
        color={color}
        fill={fill}
        d="M204 102c28.05 0 51-22.95 51-51S232.05 0 204 0s-51 22.95-51 51 22.95 51 51 51zm0 51c-28.05 0-51 22.95-51 51s22.95 51 51 51 51-22.95 51-51-22.95-51-51-51zm0 153c-28.05 0-51 22.95-51 51s22.95 51 51 51 51-22.95 51-51-22.95-51-51-51z"
      />
    </svg>
  );
}

MoreIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default MoreIcon;
