export const algoErrorMessages = (selectedError, additionalInfo) => {
  // 0 to 1999 for algorithm errors
  const errorMessages = {
    100: {
      fr: "Clé manquante dans l'entrée JSON.",
      en: 'Missing key in JSON input.',
    },
    101: {
      fr: "Sous-clé manquante dans la clé d'entrée JSON ([KEY] : subkey).",
      en: 'Missing subkey in JSON input key ([KEY]: subkey).', // ([KEY]: subkey)
    },
    200: {
      fr: 'Les valeurs du tableau doivent être des nombres.',
      en: 'Array values must be numbers.',
    },
    201: {
      fr: 'La valeur doit être un nombre.',
      en: 'Value must be a number.',
    },
    210: {
      fr: 'Les valeurs du tableau doivent être des nombres entiers.',
      en: 'Array values must be integers.',
    },
    211: {
      fr: 'La valeur doit être un nombre entier.',
      en: 'Value must be an integer.',
    },
    220: {
      fr: 'Les valeurs du tableau doivent être de type booléen.',
      en: 'Array values must be of type boolean.',
    },
    230: {
      fr: 'Les valeurs du tableau doivent être de type chaîne de caractères.',
      en: 'Array values must be of type string.',
    },
    241: {
      fr: "L'élément doit être de type liste.",
      en: 'Element must be of type list.',
    },
    300: {
      fr: 'Les valeurs du tableau doivent être positives.',
      en: 'Array values must be positive.',
    },
    310: {
      fr: 'Les valeurs du tableau doivent être 1, 0 ou -1.',
      en: 'Array values must be either 1, 0 or -1.',
    },
    311: {
      fr: 'Valeurs du tableau hors limites.',
      en: 'Array values out of bounds.',
    },
    320: {
      fr: 'La somme du tableau doit être égale à 100.',
      en: 'Array sum must be equal to 100.',
    },
    400: {
      fr: 'La longueur du tableau ne correspond pas au nombre de cultures.',
      en: 'Length of array is not matching the number of cultures.',
    },
    410: {
      fr: 'La longueur du tableau ne correspond pas au nombre de parcelles.',
      en: 'Length of array is not matching the number of parcels.',
    },
    420: {
      fr: 'Les éléments du tableau doivent être de taille 2.',
      en: 'Array elements must be of size 2.',
    },
    430: {
      fr: 'La longueur du tableau ne correspond pas au nombre de types de sol.',
      en: 'Length of array is not matching the number of ground types.',
    },
    440: {
      fr: 'Les longueurs du tableau ne correspondent pas.',
      en: 'Array lengths are not matching.',
    },
    500: {
      fr: 'La contrainte ne peut pas correspondre (max < min).',
      en: `Constraint can't be matched (max < min).`,
    },
    501: {
      fr: 'La contrainte ne peut pas correspondre (0 != max < min).',
      en: `Constraint can't be matched (0 != max < min).`,
    },
    600: {
      fr: 'Nom inconnu (mauvaise référence).',
      en: 'Unknown name (bad reference).',
    },
    700: {
      fr: '/!\\ Avertissement Irrigation incomplète.',
      en: '/!\\ Warning Irrigation incomplete.',
    },

    // 2000 to 4999 for impossible requests.
    '2000_SEVERAL': {
      fr: `Les contraintes suivantes sont antinomiques : ${
        typeof additionalInfo === 'array' ? additionalInfo?.join(',') : additionalInfo
      }`,
    },
    '2000_ONE': {
      fr: `La contrainte ${additionalInfo?.message} n’est pas réalisable.`,
    },
    '2000_0': {
      fr: `Besoin ${additionalInfo?.['co-product']}`,
    },
    '2000_1': {
      fr: `Culture céréale à paille (%)`,
    },
    '2000_2': {
      fr: `Durée de présence/d’interdiction de la culture ${additionalInfo?.crop}`,
    },
    '2000_3': {
      fr: `Culture hiver (%)`,
    },
    '2000_4': {
      fr: `Volume irrigation`,
    },
    '2000_5': {
      fr: `Nombre de parcelles irrigables`,
    },
    '2000_6': {
      fr: `Restriction parcelle ${additionalInfo?.parcel} culture ${additionalInfo?.crop} campagne ${additionalInfo?.year}`,
    },
    '2000_7': {
      fr: `Contrainte PAC`,
    },
    '2000_8': {
      fr: `Surface culture ${additionalInfo?.crop}`,
    },
    '2000_9': {
      fr: `Obligation parcelle ${additionalInfo?.parcel} culture ${additionalInfo?.crop} campagne ${additionalInfo?.year}`,
    },
    '2000_12': {
      fr: `Culture ${additionalInfo?.crop} uniquement irrigable`,
    },
    2000: {
      fr: `Une culture est interdite, alors que d'autres contraintes la rendent obligatoire.`, // Parcelle concerné : NOM_DE_PARCELLE, culture concerné : NOM_DE_CULTURE
      en: `A crop is prohibited, while other constraints make it mandatory.`,
    },
    2002: {
      fr: `Impossible de respecter la période de répétition de la culture.`, // Parcelle concerné : NOM_DE_PARCELLE, culture concerné : NOM_DE_CULTURE
      en: `Unable to adhere to the crop's repetition period.`,
    },
    2003: {
      fr: `Impossible de respecter la durée d'interdiction de la culture.'`, // Parcelle concerné : NOM_DE_PARCELLE, culture concerné : NOM_DE_CULTURE
      en: `Unable to adhere to the crop's prohibition period.`,
    },
    2004: {
      fr: `Une culture uniquement irrigable est obligatoire sur une parcelle non irrigable.`, // Parcelle concerné : NOM_DE_PARCELLE, culture concerné : NOM_DE_CULTURE
      en: `An irrigated-only crop is mandated on a non-irrigable plot.`,
    },
    2005: {
      fr: `D'autres contraintes rendent impossible l'obligation ou l'interdiction de la culture sur la parcelle.`, // Parcelle concerné : NOM_DE_PARCELLE, culture concerné : NOM_DE_CULTURE
      en: `Other constraints make it impossible to enforce the obligation or prohibition of the crop on the plot.`,
    },
    2010: {
      fr: 'Plusieurs cultures sont obligées sur la même campagne culturale sur une même parcelle.', // Campagne concernée : CORRESPONDANCE YEAR, Parcelle concernée : NOM_DE_PARCELLE, Cultures concernées : NOM_DE_CULTURE1, NOM_DE_CULTURE2
      en: `Several crops are required in the same crop year on the same plot.`,
    },
    2020: {
      fr: 'Les surfaces disponibles pour cette culture ne sont pas suffisantes pour atteindre le minimum demandé.', // Surface totale : total_surface
      en: `The available surfaces for this crop are not sufficient to meet the required minimum.`,
    },
    2021: {
      fr: 'Les contraintes obligent la culture sur une plus grande surface que le maximum requis.', // Campagne concernée : CORRESPONDANCE YEAR
      en: `The constraints compel the crop over a larger area than the maximum required.`,
    },
    2022: {
      fr: `La somme des surfaces maximales est plus petite que la surface totale ET aucune culture n'a pas de contrainte max.`, // Surface totale : total_surface
      en: `The sum of the maximum areas is smaller than the total area AND no crop has a max constraint.`,
    },
    2023: {
      fr: `Les parcelles disponibles pour la culture ne permettent pas d'atteindre une surface dans l'intervalle demandé.`,
      en: `Unable to fulfill the requested interval with the available plots for this crop.`,
    },
    2024: {
      fr: `Impossible de maintenir la surface minimum demandée sur la durée à cause des restrictions de durée sur la culture.`,
      en: `Unable to fulfill the requested minimum surface over time because of the duration restrictions on the crop.`,
    },
    2030: {
      fr: `Impossible de réaliser le nombre de points PAC requis avec les surfaces disponibles.`, // Points requis : goal, Points possibles: points
      en: `Unable to achieve the required number of CAP points with the available surfaces.`,
    },
    2040: {
      fr: `Il n'y a pas de culture d'été pour satisfaire le minimum demandé.`,
      en: `There is no summer crop to meet the minimum requirement.`,
    },
    2041: {
      fr: `Il n'y a pas de culture d'hiver pour satisfaire le minimum demandé.`,
      en: `There is no winter crop to meet the minimum requirement.`,
    },
    2042: {
      fr: `La surface totale des parcelles est nulle.`,
      en: `The total area of the plots is zero.`,
    },
    2043: {
      fr: `La surface minimale des cultures d'été est supérieure au minimum de culture d'hiver requis.`,
      en: `The minimum area for summer crops exceeds the minimum required for winter crops.`,
    },
    2044: {
      fr: `La surface minimale des cultures d'été est supérieure au maximum de culture d'été requis.`,
      en: `The minimum area for summer crops exceeds the maximum required for summer crops.`,
    },
    2045: {
      fr: `La surface minimale des cultures d'hiver est supérieure au maximum de culture d'hiver requis.`,
      en: `The minimum area for winter crops exceeds the maximum required for winter crops.`,
    },
    2046: {
      fr: `La surface minimale des cultures d'été est inférieure au minimum de culture d'été requis.`,
      en: `The minimum area for summer crops is less than the minimum required for summer crops.`,
    },
    2047: {
      fr: `La surface minimale des cultures d'hiver est inférieure au minimum de culture d'hiver requis.`,
      en: `The minimum area for winter crops is less than the minimum required for winter crops.`,
    },
    2050: {
      fr: `La contrainte spéciale n'est pas réalisable parce que la surface des parcelles où les cultures sont autorisées n'est pas suffisante.`,
      en: `The specific constraint is not feasible because the area of the plots where crops are allowed is not sufficient.`,
    },
    2060: {
      fr: `Impossible d'atteindre le minimum d'irrigation demandé avec les cultures et les parcelles disponibles.`,
      en: `Unable to reach the required minimum irrigation with the available crops and plots.`,
    },
    2061: {
      fr: `Les contraintes nécessitent une irrigation plus importante que le maximum demandé.`,
      en: `The constraints necessitate irrigating beyond the maximum requested.`,
    },
    2062: {
      fr: `Il n'y a pas assez de parcelles irrigable pour atteindre le minimum de parcelles irrigés.`,
      en: `There aren't enough irrigable plots to meet the minimum required irrigated plots.`,
    },
    2063: {
      fr: `Des cultures uniquement irrigable sont obligées mais il n'y a pas de contrainte d'irrigation.`,
      en: `Some only irrigable crops are mandatory but there is no irrigatioin constraint.`,
    },
    2064: {
      fr: `Des cultures uniquement irrigable ont une consommation en eau nulle.`,
      en: `Only irrigable crops have zero water consumption.`,
    },
    2070: {
      fr: `Il n'y a pas de parcelle où les cultures produisant le co-produit ${additionalInfo?.co_product_type} demandé sont autorisées.`,
      en: `There is no plot where the crops producing the requested co-product are allowed.`,
    },
    2080: {
      fr: `La surface maximale des cultures produisant le co-produit n'est pas suffisante pour produire assez le co-produit.`,
      en: `The maximum surface area of the crops producing the co-product is not sufficient to produce enough of the co-product.`,
    },
    2081: {
      fr: `La surface minimale des cultures produisant le co-produit va produire plus de co-produit que le maximum demandé.`,
      en: `The minimum surface area of the crops producing the co-product will yield more co-product than the maximum required.`,
    },
    2090: {
      fr: `L'intervalle de la contrainte est trop restreint pour le remplir avec les surfaces disponibles.`,
      en: `The constraint interval is too small to fill it with the available surfaces.`,
    },
    2091: {
      fr: `Il n'existe pas de surface ou de combinaison de surface de parcelle dans l'intervalle donné par la contrainte de surface de la culture.`,
      en: `No available surface or combination can be found in the given interval set by the crop surface constraint.`,
    },
    4000: {
      fr: `Limite de temps dépassée pour le calcul de la simulation.`,
      en: `Time limit exceeded for simulation calculation.`,
    },
    4001: {
      fr: `Les contraintes sont trop restrictives, aucune solution trouvée.`,
      en: `Constraints are too restrictive. No solution found.`,
    },
    9999: {
      fr: 'Erreur inconnue',
      en: 'Unknown error',
    },
  };

  return errorMessages[selectedError];
};

export default algoErrorMessages;
