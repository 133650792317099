import ParcelInfo from 'components/generics/Map/ParcelInfo';
import { Feature } from 'ol';
import { getCenter } from 'ol/extent';
import { Polygon } from 'ol/geom';
import { Point } from 'ol/geom.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import { Vector as VectorSource } from 'ol/source.js';
import { useContext, useEffect, useRef, useState } from 'react';
import { ExploitationContext } from 'utils/context';
import {
  createNewMap,
  getPopupHeight,
  gpsToPixel,
  handleMouseMoveEvent,
  setPolygonStyle,
  zoomToFit,
} from './MapCommon';
import { emptyCulture } from 'utils/culturesColors';
import './Map.style.scss';
import MapTab from './MapTab';

function MapResults({
  data,
  parcelUpdated,
  setModalData,
  nbYear,
  setMapYear,
  center,
  suggestions,
  previousCropMargin,
  currentYear,
}) {
  const { culturesColors } = useContext(ExploitationContext);
  const [year, setYear] = useState(currentYear + 1);
  const [maxYear] = useState(currentYear + nbYear);
  const [minYear] = useState(currentYear);
  const yearIndex = year - maxYear + (nbYear - 1);
  const [parcelInfo, setParcelInfo] = useState(null);
  const [modalCoords, setModalCoords] = useState(null);
  const [storeParcelColor, setStoreParcelColor] = useState([]);
  const [cultureHoverName, setCultureHoverName] = useState(null);
  const [parcelHoverCultureName, setParcelHoverCultureName] = useState(null);
  const [map, setMap] = useState();
  const [polygonSource, setPolygonSource] = useState();
  const [coords, setCoords] = useState({ x: 0, y: 0 });

  const mapElement = useRef();
  const popup = useRef(null);
  const popupContent = useRef(null);
  let activePolygon;

  useEffect(() => {
    return handleMouseMoveEvent(setCoords);
  }, []);

  useEffect(() => {
    setMap(createNewMap());
  }, []);

  useEffect(() => {
    if (map) {
      setMapDetails(map);

      map.once('rendercomplete', function (event) {
        zoomToFit(map);
      });
    }
    setMapYear(year);
  }, [map, data, year, parcelUpdated, center]);

  useEffect(() => {
    if (map && cultureHoverName) {
      const extent = map.getView().calculateExtent(map.getSize());
      if (polygonSource) {
        polygonSource.forEachFeatureInExtent(extent, function (feature) {
          const selectedParcel = data?.find(
            (parcel) => parcel.id + '' + year + '' + parcel.name === feature.getId()
          );
          let parcelCultureAtYear = selectedParcel.cultureN;
          if (selectedParcel?.cultureN?.year === year) {
            parcelCultureAtYear = selectedParcel.cultureN;
          }
          if (selectedParcel?.cultureN1?.year === year) {
            parcelCultureAtYear = selectedParcel.cultureN1;
          }
          if (!parcelCultureAtYear.name) {
            parcelCultureAtYear = { ...emptyCulture, year: year };
          }
          for (let i = 0; i < selectedParcel?.cultures.length; i++) {
            const nextYear = minYear + (i + 1);
            if (nextYear === year) {
              parcelCultureAtYear = selectedParcel?.cultures[i];
            }
          }

          if (selectedParcel && Array.isArray(parcelCultureAtYear)) {
            if (parcelCultureAtYear.length === 1) {
              parcelCultureAtYear.forEach((parcelCultureItem) => {
                if (parcelCultureItem.name === cultureHoverName || cultureHoverName === 'null') {
                  setPolygonStyle(feature, parcelCultureItem.name, 1, culturesColors);
                } else {
                  setPolygonStyle(feature, parcelCultureItem.name, 0.3, culturesColors);
                }
              });
            }
            if (parcelCultureAtYear.length === 2) {
              if (
                parcelCultureAtYear.some(
                  (parcelCultureItem) => parcelCultureItem.name === cultureHoverName
                ) ||
                cultureHoverName === 'null'
              ) {
                setPolygonStyle(feature, parcelCultureAtYear, 1, culturesColors);
              } else {
                setPolygonStyle(feature, parcelCultureAtYear, 0.3, culturesColors);
              }
            }
          } else if (selectedParcel && parcelCultureAtYear) {
            if (parcelCultureAtYear.name === cultureHoverName || cultureHoverName === 'null') {
              setPolygonStyle(feature, parcelCultureAtYear.name, 1, culturesColors);
            } else {
              setPolygonStyle(feature, parcelCultureAtYear.name, 0.3, culturesColors);
            }
          }
        });
      }
    }
  }, [cultureHoverName]);

  function setMapDetails(map) {
    if (map && year) {
      map
        .getLayers()
        .getArray()
        .filter((layer) => layer.get('id') === 'polygonVector')
        .forEach((layer) => map.removeLayer(layer));
      const polygonSourceEffect = new VectorSource();
      data
        ?.filter((parcel) => parcel.coordinates)
        .map((parcel) => {
          let parcelCultureAtYear = parcel.cultureN;
          if (!parcelCultureAtYear.name) {
            parcelCultureAtYear = { ...emptyCulture, year: minYear };
          }
          for (let i = 0; i < parcel.cultures.length; i++) {
            const nextYear = minYear + (i + 1);
            if (nextYear === year) {
              parcelCultureAtYear = parcel.cultures[i];
            }
          }

          const parcelFeature = new Feature({
            geometry: new Polygon(gpsToPixel(parcel.coordinates)),
          });
          const parcelIdByYear = parcel.id + '' + year + '' + parcel.name;
          parcelFeature.setId(parcelIdByYear);
          parcelFeature.set('parcelId', parcel.id);
          parcelFeature.set('parcelYear', year);
          parcelFeature.set('isGroupedParcel', parcel?.isGroupedParcel);
          if (parcel?.isGroupedParcel) {
            parcelFeature.set('groupChildrenParcels', parcel?.groupChildrenParcels);
            parcelFeature.set('groupSurface', parcel?.groupSurface);
          }
          if (parcel?.parent) {
            parcelFeature.set('parent', parcel?.parent);
          }

          setPolygonStyle(
            parcelFeature,
            activePolygon?.name
              ? activePolygon.name
              : parcelCultureAtYear?.name ?? parcelCultureAtYear,
            1,
            culturesColors
          );

          let finalColorTable = storeParcelColor;
          finalColorTable.forEach((parcelColor, index) => {
            if (parcelColor.id === parcelIdByYear) {
              finalColorTable.splice(index, 1);
            }
          });

          finalColorTable.push({
            id: parcelIdByYear,
            culture: Array.isArray(parcelCultureAtYear)
              ? parcelCultureAtYear
              : parcelCultureAtYear?.name,
          });

          setStoreParcelColor(finalColorTable);

          polygonSourceEffect.addFeature(parcelFeature);
          setPolygonSource(polygonSourceEffect);

          return polygonSourceEffect;
        });

      const geometryFunction = function () {
        polygonSourceEffect.getFeatures().forEach((feature) => {
          return new Point(getCenter(feature.getGeometry().getExtent()));
        });
      };

      const polygonVector = new VectorLayer({
        id: 'polygonVector',
        source: polygonSourceEffect,
        geometry: geometryFunction(),
      });

      map.addLayer(polygonVector);
      map.set();

      let selected = null;
      map.on('pointermove', function (e) {
        const extent = map.getView().calculateExtent(map.getSize());

        if (selected !== null) {
          setParcelInfo(null);
          setModalCoords(null);
          polygonSourceEffect.forEachFeatureInExtent(extent, function (feature) {
            const selectedCultureName = storeParcelColor.find(
              (parcel) => parcel.id === feature.getId()
            );

            if (selectedCultureName && selectedCultureName.culture) {
              setPolygonStyle(feature, selectedCultureName.culture, 1, culturesColors);
            }
            setParcelHoverCultureName(null);
          });
          selected = null;
        }

        map.forEachFeatureAtPixel(e.pixel, function (f) {
          selected = f;
          const selectedParcel = data?.find(
            (parcel) => parcel.id + '' + year + '' + parcel.name === f.getId()
          );
          if (selectedParcel) {
            const cultureByYearFound = selectedParcel?.cultures?.find(
              (culture) => culture?.year === year
            );

            const updatedSelectedParcel = structuredClone(selectedParcel);
            updatedSelectedParcel.cultures = cultureByYearFound ?? [];
            setParcelInfo(updatedSelectedParcel);
          }

          setModalCoords(e.pixel);

          polygonSourceEffect.forEachFeatureInExtent(extent, function (feature) {
            const selectedCultureName = storeParcelColor.find(
              (parcel) => parcel.id === feature.getId()
            );
            const featureToHide = selected.get('isGroupedParcel')
              ? !selected.get('groupChildrenParcels')?.includes(feature.get('parcelId')) &&
                feature.getId() !== selected.getId() &&
                feature.get('parcelId') !== selected.get('parent')
              : feature.getId() !== selected.getId();
            if (selectedCultureName && selectedCultureName.culture) {
              if (featureToHide) {
                setPolygonStyle(feature, selectedCultureName.culture, 0.3, culturesColors);
              } else {
                setParcelHoverCultureName(selectedCultureName.culture);
              }
            }
          });
          return true;
        });
      });

      map.on('click', function (e) {
        map.forEachFeatureAtPixel(e.pixel, function (feature) {
          const parcelToUpdate = feature.get('parent')
            ? data?.find((parcel) => parcel?.id === feature.get('parent'))
            : data?.find((parcel) => parcel?.id === feature.get('parcelId'));
          setModalData(parcelToUpdate, feature.get('parcelYear'), minYear);
        });
      });
    }
  }

  const handleCultureHover = (cultureHoverName) => {
    setCultureHoverName(cultureHoverName);
  };

  return (
    <div className="tab_container">
      <div className="details_container">
        <MapTab
          data={data}
          header={['', 'Culture', 'Surface', 'Marge (€/ha)']}
          suggestions={suggestions}
          previousCropMargin={previousCropMargin}
          isDynamic={true}
          year={year}
          minYear={minYear}
          maxYear={maxYear}
          setYear={setYear}
          yearIndex={yearIndex}
          parcelHoverCultureName={parcelHoverCultureName}
          parentCallback={handleCultureHover}
          fromSynthesis
        />
      </div>
      <div ref={mapElement} className="map_container">
        <div
          id="map"
          className="map_element"
          onMouseOut={() => {
            setParcelInfo(null);
            setModalCoords(null);
          }}
        ></div>
        {parcelInfo && (
          <div className="parcel-info-modal" ref={popup}>
            {modalCoords && (
              <div
                ref={popupContent}
                id="popup"
                className="ol-popup"
                style={{
                  marginLeft: coords.x - 50 + 'px',
                  marginTop: coords.y - getPopupHeight(popupContent) - 30  + 'px',
                }}
              >
                <ParcelInfo
                  cultureData={parcelHoverCultureName}
                  parcelInfo={parcelInfo}
                  parcels={data}
                  year={year}
                  minYear={minYear}
                  from="mapResult"
                ></ParcelInfo>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default MapResults;
