import { Checkbox, ListItemText, MenuItem } from '@mui/material';
import { filtersType, selectAllType } from './useFilteredRotationsLocalStorage';

type useDatagridMatricesFilterParams = {
  selectedFilters: filtersType;
  setSelectedFilters: React.Dispatch<React.SetStateAction<filtersType>>;
  selectAllFilters: selectAllType;
  setSelectAllFilters: React.Dispatch<React.SetStateAction<selectAllType>>;
  setToggleFilter: React.Dispatch<React.SetStateAction<boolean>>;
};

type itemsListType = {
  id: number;
  name: string;
  [key: string]: any;
};

const useDatagridMatricesFilter = ({
  selectedFilters,
  setSelectedFilters,
  selectAllFilters,
  setSelectAllFilters,
  setToggleFilter,
}: useDatagridMatricesFilterParams) => {
  const handleToggleFilter = () => {
    setToggleFilter((prev) => !prev);
  };

  const handleOnChangeFilter = (
    itemIds: Array<string | number>,
    type: string,
    itemsList: itemsListType[]
  ) => {
    const updatedFilters = { ...selectedFilters[type as keyof filtersType] };
    const isSelectAll = itemIds.includes('all');
    const allFiltersUnchecked = Object.values(
      selectedFilters[type as keyof filtersType] as filtersType
    ).every((value) => !value);

    if (itemsList) {
      itemsList?.map((item) => {
        if (isSelectAll) {
          const isChecked = !selectAllFilters[type as keyof filtersType];
          setSelectAllFilters((prev) => ({
            ...prev,
            [type]: true,
          }));
          updatedFilters[item.id] = isChecked;
          if (allFiltersUnchecked) {
            updatedFilters[item.id] = true;
          }
        }
        if (itemIds.includes(item.id)) {
          updatedFilters[item.id] = !selectedFilters[type as keyof filtersType]?.[item.id];
          setSelectAllFilters((prev) => ({
            ...prev,
            [type]: false,
          }));
        } else if (selectedCount(type) > 1) {
          setSelectAllFilters((prev) => ({
            ...prev,
            [type]: true,
          }));
        }
      });

      const allFiltersUncheckedUpdated = Object.values(updatedFilters).every((value) => !value);
      if (allFiltersUncheckedUpdated) {
        setSelectAllFilters((prev) => ({
          ...prev,
          [type]: false,
        }));
      }
      setSelectedFilters((prev) => ({
        ...prev,
        [type]: updatedFilters,
      }));
    }
  };

  const renderFilterMenuItems = (item: itemsListType, selectedFilters: Record<number, boolean>) => {
    return (
      <MenuItem key={item.id} value={item.id}>
        <Checkbox checked={selectedFilters?.[item.id]} />
        <ListItemText primary={item.name} />
      </MenuItem>
    );
  };

  const selectedCount = (type: string) => {
    return Object.values(selectedFilters[type as keyof filtersType] as filtersType).filter(
      (value) => value
    ).length;
  };

  const isIndeterminate = (type: string) => {
    const totalCount = Object.keys(
      selectedFilters?.[type as keyof filtersType] as filtersType
    ).length;
    const countSelected = selectedCount(type);
    return countSelected > 0 && countSelected !== totalCount;
  };

  return {
    handleOnChangeFilter,
    renderFilterMenuItems,
    handleToggleFilter,
    isIndeterminate,
  };
};

export default useDatagridMatricesFilter;
