export const staticErrorMessage = (selectedError, additionalInfo = null) => {
  // GET
  const errorMessages = {
    ERR_GET_EFFECT: {
      fr: 'Impossible de récuperer les effets',
    },
    ERR_GET_COOP: {
      fr: 'Impossible de récuperer les coopératives',
    },
    ERR_GET_COPRODUCT: {
      fr: 'Impossible de récuperer les co-produits',
    },
    ERR_GET_CONSTRAINT_CAT: {
      fr: 'Impossible de récupérer les catégories de contraintes.',
    },
    ERR_GET_ROTATE_EFFECT: {
      fr: 'Impossible de récuperer les rotations de cette culture',
    },
    ERR_GET_CULTURE: {
      fr: 'Impossible de récuperer les cultures',
    },
    ERR_GET_METACULTURE: {
      fr: 'Impossible de récuperer les métacultures',
    },
    ERR_GET_PARCEL: {
      fr: 'Impossible de récuperer les parcelles',
    },
    ERR_GET_FORECAST: {
      fr: 'Impossible de récuperer le prévisionnel',
    },
    ERR_GET_GROUND_EFFECT: {
      fr: 'Impossible de récuperer les types de sols',
    },
    ERR_GET_EXPLOITATION_TYPES: {
      fr: "Impossible de récuperer les types d'exploitation",
    },
    ERR_GET_COOPERATIVE_SECTORS: {
      fr: 'Impossible de récuperer les secteurs de la structure',
    },
    ERR_GET_YEAR_INFO: {
      fr: 'Impossible de charger les informations des années',
    },
    ERR_GET_TECHNICIAN_FULL: {
      fr: 'Impossible de récupérer la liste des techniciens',
    },
    ERR_GET_ARABLE_SURFACE: {
      fr: 'Impossible de récupérer le surface de terre arable',
    },
    // REINITIALISATION
    ERR_REINIT_EFFECT: {
      fr: 'Impossible de réinitialiser les effets',
    },
    ERR_REINIT_CULTURE: {
      fr: 'Impossible de réinitialiser les cultures',
    },
    ERR_REINIT_PASSWORD: {
      fr: 'Impossible de re-initialiser votre mot de passe',
    },
    //UPDATE
    ERR_UPDATE_EFFECT: {
      fr: 'Erreur mise à jour effet',
    },
    // DELETE
    ERR_DELETE_LINK_COPRODUCT_CONSTRAINT: {
      fr: 'Erreur sur la suppression relation co-produit/contrainte',
    },
    ERR_DELETE_LINK_USER_SECTOR: {
      fr: 'Erreur de suppression relation technicien-secteur',
    },
    ERR_DELETE_CULTURE: {
      fr: 'Erreur de suppression de la culture',
    },
    ERR_DELETE_PARCEL: {
      fr: 'Erreur de suppression de la parcelle',
    },
    ERR_DELETE_PARCEL_GROUP: {
      fr: 'Erreur de suppression du regroupement',
    },
    // IMPORT
    ERR_COMPLETE_IMPORT: {
      fr: 'Importation terminée avec erreur',
    },
    ERR_IMPORT_CSV: {
      fr: `Une erreur est survenue lors de l'import du fichier CSV`,
    },
    ERR_IMPORT_GEOFOLIA: {
      fr: `Une erreur est survenue lors de l'import du fichier .zip Geofolia`,
    },
    ERR_IMPORT_TELEPAC: {
      fr: `Une erreur est survenue lors de l'import du fichier .xml Télépac`,
    },
    ERR_IMPORT: {
      fr: `Une erreur est survenue lors de l'import`,
    },
    // ADD
    ERR_ADD_CULTURE: {
      fr: `Une erreur est survenue lors de l'ajout d'une culture`,
    },
    ERR_ADD_LINK_COPRODUCT_CONSTRAINT: {
      fr: `Erreur d'ajout relation co-produit/contrainte`,
    },
    ERR_ADD_LINK_USER_SECTOR: {
      fr: `Erreur d'ajout relation technicien-secteur`,
    },
    // MAIL
    ERR_SEND_REPORT_SIMULATION: {
      fr: `Une erreur est survenue lors de l'envoi du rapport`,
    },
    ERR_ADD_EXISTING_EMAIL: {
      fr: `L'e-mail "${additionalInfo?.email}" est déjà utilisé. L'utilisateur n'a pas été créé.`,
    },
    ERR_UPDATE_EXISTING_EMAIL: {
      fr: `L'e-mail "${additionalInfo?.email}" est déjà utilisé. L'utilisateur n'a pas été modifié.`,
    },
    // GENERAL
    ERR_NO_DATA_SELECTED: {
      fr: 'Aucune donnée sélectionnée',
    },
    ERR_MISSING_DATA: {
      fr: 'Informations manquantes',
    },
    ERR_UNEXPECTED: {
      fr: 'Une erreur imprévue est survenue',
    },
    ERR_INACTIVE_COOP: {
      fr: 'Coopérative inactive',
    },
    ERR_INCORRECT_CREDENTIAL: {
      fr: 'Identifiants incorrects',
    },
    ERR_DIFFERENT_PASSWORD: {
      fr: 'Les mots de passe ne sont pas identiques',
    },
    ERR_CONSTRAINT_PAC: {
      fr: 'Indiquer le nombre de points PAC',
    },
    ERR_CONSTRAINT_TFI: {
      fr: 'Le max IFT ne doit pas être supérieur à 1000',
    },
  };
  return errorMessages[selectedError];
};

export default staticErrorMessage;
