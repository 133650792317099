import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ProtectedRoute from 'providers/Auth/ProtectedRoute';
import LoginPage from 'components/pages/Login';
import ForgoPasswordPage from 'components/pages/ForgotPassword';
import ResetPasswordPage from 'components/pages/ResetPassword';
import HomePage from 'components/pages/Home';
import CooperativePage from 'components/pages/Cooperative';
import SecteurPage from 'components/pages/Sector';
import ExploitationPage from 'components/pages/Exploitation';
import Administration from 'components/pages/Administration';
import { ExploitationProvider } from 'utils/context';

const RoutesList = () => (
  <Routes>
    <Route path="/login" element={
      <LoginPage />
    } />

    <Route path="/forgotPassword" element={
      <ForgoPasswordPage />
    } />
    <Route path="/newpassword" element={
      <ResetPasswordPage />
    } />
    <Route path="administration/*" element={
      <ProtectedRoute>
        <Administration />
      </ProtectedRoute>
    } />
    <Route path="cooperative/*" element={
      <ProtectedRoute>
        <CooperativePage />
      </ProtectedRoute>
    } />
    <Route path="sector/*" element={
      <ProtectedRoute>
        <SecteurPage />
      </ProtectedRoute>
    } />
    <Route path="exploitation/*" element={
      <ExploitationProvider>
          <ProtectedRoute>
            <ExploitationPage />
          </ProtectedRoute>
      </ExploitationProvider>
    } />
    <Route path="/" element={
      <HomePage />
    } />
  </Routes>
);

export default RoutesList;
