import Button from 'components/generics/Button';
import useAuth from 'providers/Auth/useAuth';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getMappingFiles } from 'services/API/Cooperative';
import { ImportContext } from 'providers/ImportStatus/importStatus.providers';

const CooperativeImport = () => {
  const { user } = useAuth();
  const [cooperativeId, setCooperativeId] = useState('');
  const [exploitationId, setExploitationId] = useState(null);
  const navigate = useNavigate();
  const [canImportWiuz, setCanImportWiuz] = useState(false);
  const [canImportGeofolia, setCanImportGeofolia] = useState(false);
  const [canImportTelepac, setCanImportTelepac] = useState(false);
  const { result } = useContext(ImportContext);
  const [locationRoute, setLocationRoute] = useState('cooperative');

  useEffect(() => {
    let coopId = '';
    if (localStorage.getItem('cooperative')) {
      coopId = localStorage.getItem('cooperative');
    } else if (user.cooperative?.id) {
      coopId = user.cooperative.id;
    }

    if (!coopId) {
      toast.info('Selectionnez une cooperative');
      navigate('/cooperative');
    } else {
      setCooperativeId(coopId);
    }
    
    if (localStorage.getItem('exploitation')) {
      setExploitationId(localStorage.getItem('exploitation'))
    }
  }, [navigate, user]);

  useEffect(() => {
    if (cooperativeId !== '') {
      getMappingFiles(cooperativeId)
        .then((resp) => {
          if (resp.data.message.filter((m) => m === 'Wiuz').length > 0) {
            setCanImportWiuz(true);
          }
          if (resp.data.message.filter((m) => m === 'Geofolia').length > 0) {
            setCanImportGeofolia(true);
          }
          if (resp.data.message.filter((m) => m === 'Telepac').length > 0 && localStorage.getItem('exploitation')
          && locationRoute === "exploitation") {
            setCanImportTelepac(true)
          }
        })
        .catch((err) => {
          setCanImportWiuz(false);
          setCanImportGeofolia(false);
          setCanImportTelepac(false);
          console.log(err);
        });
    }

  }, [cooperativeId]);

  useEffect(() => {
    setLocationRoute(window.location.pathname.split('/')[1]);
    if (result) {
      navigate(`/${window.location.pathname.split('/')[1]}/import/result`);
    }
  }, [result, navigate]);

  return (
    <div className="section">
      <div className="main_container full-height">
        <h1 className="title_section">Import de données</h1>
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItem: 'center', height: '50%' }}
        >
          <div
            className="button_upload"
            style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 50 }}
          >
            <div style={{ padding: '40px', margin: 'auto' }}>
              <Button
                text="Importer les données parcellaires CSV"
                onClick={() => navigate(`/${locationRoute}/import/csvImport`)}
              />
            </div>
            {canImportWiuz && (
              <div style={{ padding: '40px', margin: 'auto' }}>
                <Button
                  text="Importer les données Wiuz"
                  onClick={() => navigate(`/${locationRoute}/import/loginWiuz`)}
                />
              </div>
            )}
            {canImportGeofolia && (
              <div style={{ padding: '40px', margin: 'auto' }}>
                <Button
                  text="Importer les données Geofolia"
                  onClick={() => navigate(`/${locationRoute}/import/geofolia`)}
                />
              </div>
            )}
            {canImportTelepac && (
              <div style={{ padding: '40px', margin: 'auto' }}>
                <Button
                  text="Importer les données Télépac"
                  onClick={() => navigate(`/${locationRoute}/import/telepac`)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CooperativeImport;
