import { ResponsiveLine } from '@nivo/line';
import './result.styles.scss';
import { useEffect, useState } from 'react';

function LineGraph({ currentYear, comparatorSimulationSuggest, comparatorCalculatorResult }) {
  const [yScale, setYScale] = useState({
    min: Number.MAX_SAFE_INTEGER,
    max: Number.MIN_SAFE_INTEGER,
  });
  const DEFAULT_LINE_CHART = [
    {
      id: 'Prévisionnel',
      data: [],
    },
    {
      id: 'Assolia',
      data: [],
    },
  ];
  const [lineGraphData, setLineGraphData] = useState(DEFAULT_LINE_CHART);

  useEffect(() => {
    if (comparatorSimulationSuggest || comparatorCalculatorResult) {
      const updatedLineGraphData = [...DEFAULT_LINE_CHART];
      comparatorSimulationSuggest?.suggestions?.suggestion1?.years.map((e) => {
        updatedLineGraphData[1].data.push({
          y: comparatorSimulationSuggest?.previousGrossMargin[0],
          x: `${currentYear - 1}`,
        });
        updatedLineGraphData[1].data.push({
          y: comparatorSimulationSuggest?.previousGrossMargin[1],
          x: `${currentYear}`,
        });
        updatedLineGraphData[1].data.push({
          y: e.grossMargin,
          x: `${currentYear + 1}`,
        });
        return true;
      });

      comparatorCalculatorResult?.result?.years.map((e) => {
        updatedLineGraphData[0].data.push({
          y: comparatorSimulationSuggest?.previousGrossMargin[0],
          x: `${currentYear - 1}`,
        });
        updatedLineGraphData[0].data.push({
          y: comparatorSimulationSuggest?.previousGrossMargin[1],
          x: `${currentYear}`,
        });
        updatedLineGraphData[0].data.push({
          y: e.grossMargin,
          x: `${currentYear + 1}`,
        });
        return true;
      });

      let minY = yScale.min;
      let maxY = yScale.max;

      updatedLineGraphData.forEach((item) => {
        item.data.forEach((data) => {
          minY = Math.min(minY, data.y);
          maxY = Math.max(maxY, data.y);
        });
      });

      setYScale({
        min: minY,
        max: maxY,
      });

      setLineGraphData([...updatedLineGraphData]);
    }
  }, [comparatorSimulationSuggest, comparatorCalculatorResult]);

  return (
    <div className="lineChart" id="marge-graph">
      <ResponsiveLine
        data={lineGraphData}
        margin={{
          top: 50,
          right: 115,
          bottom: 110,
          left: 60,
        }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: yScale.min - (yScale.min * 10) / 100,
          max: yScale.max + (yScale.max * 10) / 100,
          stacked: false,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        enableGridX={false}
        pointSize={8}
        pointColor={{ from: 'color' }}
        colors={['#e69636', '#277134']}
        pointBorderWidth={3}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: 115,
            translateY: 0,
            itemsSpacing: 0,
            width: 200,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            symbolSize: 12,
            symbolShape: 'square',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
}

export default LineGraph;
