import { useContext } from 'react';
import { PdfContext } from 'utils/context';
import CultureListTable from '../Tables/CultureListTable';
import { lengthInInterval, truncateTab } from '../Generics/utilsFunctions';

const CultureList = () => {
  const { cultures } = useContext(PdfContext);

  const numberOfRowPerTable = 10;
  let toDuplicate = true;
  let repetition = 1;
  let slicedCultureTable = [];

  while (toDuplicate) {
    // On découpe les tableaux en X éléments pour qu'ils soit lisible
    let truncatedCultures = truncateTab(cultures, numberOfRowPerTable, repetition);
    slicedCultureTable.push(
      <CultureListTable
        key={repetition}
        truncatedCultures={truncatedCultures}
        messageDuplicate={repetition > 1 ? '(Suite)' : ''}
      />
    );
    lengthInInterval(cultures, repetition, numberOfRowPerTable)
      ? repetition++
      : (toDuplicate = false);
  }

  return slicedCultureTable;
};

export default CultureList;
