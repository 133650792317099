import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { displayErrorMessage } from 'utils/tools_functions';
import Table from 'components/generics/Table/Table';
import ExploitationTableRow from 'components/pages/Cooperative/table/ExploitationTableRow';
import useAUTH from 'providers/Auth/useAuth';
import {
  importWiuzExploitation,
  getExploitationTypes,
  getSecteurLight,
  importParcelsWiuz,
  importParcelsStatus,
  importParcelsResult,
} from 'services/API/Cooperative';
import Button from 'components/generics/Button';
import { toast } from 'react-toastify';
import { ImportContext } from 'providers/ImportStatus/importStatus.providers';

function CooperativeExploitation() {
  const { user } = useAUTH();
  const cooperativeId = localStorage.getItem('cooperative');

  const [data, setData] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [types, setTypes] = useState([]);
  const dataToHandle = [];
  const { setStatus, setJobId, result, setResult } = useContext(ImportContext);
  const [locationRoute, setLocationRoute] = useState('cooperative');
  const navigate = useNavigate();
  const [idGet, setIdGet] = useState('');

  useEffect(() => {
    setIdGet(cooperativeId ? cooperativeId : user.cooperative.id);
    getExploitationTypes().then((resp) => setTypes(resp.data.data));
  }, [user.cooperative.id, cooperativeId]);

  useEffect(() => {
    if (idGet) {
      getSecteurLight(idGet).then((resp) => {
        setSectors(resp.data.data);
      });
    }
  }, [idGet]);

  useEffect(() => {
    if (idGet && sectors.length > 0) {
      importWiuzExploitation(idGet).then((resp) => {
        const data = resp.data.data.map((exploit) => {
          return {
            exploitation: exploit,
            secteur: sectors,
            type: types,
          };
        });

        setData(data);
      });
    }
  }, [sectors, idGet, types]);

  useEffect(() => {
    setLocationRoute(window.location.pathname.split('/')[1]);
  }, []);

  useEffect(() => {
    if (result) {
      navigate(`/${locationRoute}/import/result`);
    }
  }, [result, navigate, locationRoute]);

  const handleData = (data) => {
    if (dataToHandle.filter((d) => d?.exploitationId === data?.exploitationId).length > 0) {
      let index = dataToHandle.findIndex((d) => d?.exploitationId === data?.exploitationId);
      dataToHandle[index] = data;
    } else {
      dataToHandle.push(data);
    }
  };

  const deleteData = (exploitationId) => {
    if (dataToHandle.filter((d) => d?.exploitationId === exploitationId).length > 0) {
      let index = dataToHandle.findIndex((d) => d?.exploitationId === exploitationId);
      dataToHandle.splice(index, 1);
    }
  };

  const sendData = () => {
    let canSendData = true;
    if (dataToHandle.length === 0) {
      toast.error(displayErrorMessage('ERR_NO_DATA_SELECTED'));
      canSendData = false;
    } else {
      dataToHandle.forEach((d) => {
        if (
          d?.sectorId === '' ||
          d?.sectorId === 0 ||
          d?.exploitationTypeId === '' ||
          d?.exploitationTypeId === 0
        ) {
          toast.error(displayErrorMessage('ERR_MISSING_DATA'));
          canSendData = false;
        }
      });
    }
    if (canSendData) {
      let formatedData = { exploitations: dataToHandle };
      importParcelsWiuz(idGet, formatedData)
        .then((resp) => {
          checkStatus(resp.data.jobId);
          setJobId(resp.data.jobId);
          toast.success("L'importation a commencé");
        })
        .catch((err) => toast.error(displayErrorMessage('ERR_IMPORT')));
    }
  };

  const checkStatus = (jobId) => {
    const interval = setInterval(() => {
      importParcelsStatus(jobId)
        .then((resp) => {
          setStatus(resp.data.message);
          if (resp.data.message !== 'En cours') {
            getJobResult(jobId);
            clearInterval(interval);
            if (resp.data.message === 'Fini sans erreur') {
              toast.success('Importation terminée sans erreur');
            } else {
              toast.error(displayErrorMessage('ERR_COMPLETE_IMPORT'));
            }
          }
        })
        .catch((err) => toast.error(displayErrorMessage('ERR_IMPORT')));
    }, 5000);
  };

  const getJobResult = (jobId) => {
    importParcelsResult(jobId)
      .then((resp) => {
        setResult(resp.data);
      })
      .catch((err) => toast.error(displayErrorMessage('ERR_COMPLETE_IMPORT')));
  };

  return (
    <div className="section">
      <div className="main_container">
        <h1 className="title_section">Liste des exploitations Wiuz</h1>
        <Table
          header={['', 'Exploitations', 'Secteurs Assolia', "Types d'exploitation"]}
          data={data}
          RowElement={ExploitationTableRow}
          handleData={handleData}
          deleteData={deleteData}
        />
      </div>
      <div className="add_button_container">
        <Button text="Valider" onClick={sendData} />
      </div>
    </div>
  );
}

export default CooperativeExploitation;
