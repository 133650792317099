import DataGridRotateEffects from '../Cooperative/DataGridRotateEffects';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { sendEvent } from 'utils/Event';
import { toast } from 'react-toastify';
import { getCulturesByExploitationId } from 'services/API/Exploitation';
import { displayErrorMessage } from 'utils/tools_functions';

const RotateEffectsDataGridExploitation = ({ exploitation, setExploitation }) => {
  const [exploit, setExploit] = useState(exploitation);
  const [cultures, setCultures] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!exploit) {
      loadData();
    }
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  useEffect(() => {
    if (exploit) {
      refreshData();
    }
  }, [exploit]);

  const loadData = () => {
    if (localStorage.getItem('exploitation')) {
      setExploitation(+localStorage.getItem('exploitation'))
        .then((resp) => setExploit(resp.data.data))
        .catch((err) => toast.error(err.data));
    } else {
      toast.info('Selectionnez une exploitation');
      navigate('/exploitation');
    }
  };

  const refreshData = () => {
    getCulturesByExploitationId(exploit.id)
      .then((res) => {
        setCultures(res?.data?.data?.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch((err) => {
        if (err.message !== 'canceled') {
          toast.error(displayErrorMessage('ERR_GET_CONSTRAINT_CAT'));
        }
      });
  };

  return (
    <DataGridRotateEffects
      from={'exploitations'}
      cultures={cultures}
      refreshData={refreshData}
      ownerId={exploit?.id}
    />
  );
};

export default RotateEffectsDataGridExploitation;
