export const APIErrorMessages = (selectedError, additionalInfo) => {
  const errorMessages = {
    ERR_GROUNDTYPE_LINK_PARCEL: {
      fr: `Ce type de sol est utilisé par ${additionalInfo} ${
        additionalInfo > 1 ? 'parcelles' : 'parcelle'
      }`,
      en: `This groundType is used by ${additionalInfo} ${
        additionalInfo > 1 ? 'parcels' : 'parcel'
      }`,
    },
  };

  return errorMessages[selectedError];
};

export default APIErrorMessages;
