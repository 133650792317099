// REACT
import { useContext } from 'react';
// SERVICES
import { PdfContext } from 'utils/context';
import { v1 } from 'uuid';
import { lengthInInterval, truncateTab } from '../Generics/utilsFunctions';
// THEMES
import '../Tables/table.style.scss';
import './keyNumberNitrogenPage.style.scss';
// FILES
import fleche from 'assets/images/fleche.png';

type KeyNumberCategory = {
  id: string;
  data: Array<DataItem>;
  isChild?: boolean;
};

type DataItem = {
  value: string;
  x: string;
};

const KeyNumberNitrogenPage = () => {
  const { currentYear, keyNumberNitrogen, suggest } = useContext(PdfContext);

  const years = suggest?.years;
  let headers: string[] = [
    ...['Campagne', `${currentYear} - ${currentYear + 1}`],
    ...Array(years.length - 1)
      .fill('')
      .map((_, i) => `${i + (currentYear + 1)}-${i + (currentYear + 2)}`),
  ];

  //cleaning N1 data
  const keyNumberNitrogenWithoutN1 = keyNumberNitrogen?.map(
    (keyNumberCategory: KeyNumberCategory) => ({
      ...keyNumberCategory,
      data: keyNumberCategory?.data?.slice(1),
    })
  );

  const culturesNitrogenGain = keyNumberNitrogenWithoutN1.filter(
    (item: KeyNumberCategory) => item.isChild && !item.data.every((datum) => datum.value === '-')
  );
  const orderedKeyNumber = keyNumberNitrogenWithoutN1.filter(
    (item: KeyNumberCategory) => !item.isChild
  );

  const parentIndex = orderedKeyNumber.findIndex(
    (item: KeyNumberCategory) => item.id === "Gain d'azote lié aux légumineuses"
  );

  orderedKeyNumber.splice(parentIndex + 1, 0, ...culturesNitrogenGain);

  const numberOfRowPerPage = 12;
  let toDuplicate = true;
  let repetition = 1;
  let keyNumberPages = [];

  while (toDuplicate) {
    let truncatedResult: KeyNumberCategory[] = truncateTab(
      orderedKeyNumber,
      numberOfRowPerPage,
      repetition
    );
    keyNumberPages.push(
      buildKeyNumberTable(truncatedResult, repetition > 1 ? '(Suite)' : '', headers)
    );
    lengthInInterval(orderedKeyNumber, repetition, numberOfRowPerPage)
      ? repetition++
      : (toDuplicate = false);
  }

  return keyNumberPages;
};

const buildKeyNumberTable = (
  truncatedKeyNumber: KeyNumberCategory[],
  message: string,
  headers: string[]
) => {
  return (
    <article className="pdf-page">
      <div className="tableau_center">
        <h6 className="title_h6 title_key">Chiffres clés azote {message}</h6>
        <table className="table">
          <thead className="table_head">
            <tr className="key_table_tr">
              {headers.map((header, i) => (
                <th className="column_name" key={i} scope="col">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {truncatedKeyNumber?.map((other) => {
              if (other.isChild) {
                return (
                  <tr className="key_table_tr grey_row nitrogen_tr" key={v1()}>
                    <td className="value_table nitrogen_td">
                      <div className="inline_icon_cell nitrogen_culture">
                        <img className="cell-icon" src={fleche} alt="Logo fleche" />
                        <span>{other.id}</span>
                      </div>
                    </td>
                    {other?.data.map((year) => (
                      <td className="value_table" key={v1()}>
                        {year.value}
                      </td>
                    ))}
                  </tr>
                );
              } else {
                return (
                  <tr className="key_table_tr nitrogen_tr" key={v1()}>
                    <td className="value_table row_label">{other?.id}</td>
                    {other?.data.map((year) => (
                      <td className="value_table" key={v1()}>
                        {year.value}
                      </td>
                    ))}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </article>
  );
};

export default KeyNumberNitrogenPage;
