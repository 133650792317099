import React, { useContext, useState } from 'react';

import './loader.style.scss';
import PropTypes from 'prop-types';
import Close from 'components/icons/Close';

const LoaderCustom = ({ text = 'Veuillez patienter pendant la récupération des données', fullscreen = false }) => {
  const [forceHideLoader, setForceHideLoader] = useState(false);
  return (
    <div
      className={`fullscreen-loader ${fullscreen ? 'full' : ''}`}
      style={{
        visibility: forceHideLoader ? 'hidden' : '',
        transition: 'width 0.5s',
      }}
    >
      <div
        tabIndex={0}
        role="button"
        aria-label="close"
        className="close"
        onClick={() => {
          setForceHideLoader(true);
        }}
      >
        <Close />
      </div>
      <div className="center-flex">
        <div className="spinner"></div>
        <div className="text">{text}</div>
      </div>
    </div>
  );
};

LoaderCustom.propTypes = {
  text: PropTypes.string,
};

export default LoaderCustom;
