// YARN
import {
  DataGridPro,
  useGridApiRef,
  GridRowsProp,
  GridColDef,
  GridSortModel,
  GridRowParams,
  GridApiPro,
} from '@mui/x-data-grid-pro';
import { ThemeProvider, Box, Theme } from '@mui/material';
// SERVICES
import { CustomNoRowsOverlay } from './CustomRotateMatricesDataGridUtils';

type CustomLevelListDataGridParams = {
  rows: GridRowsProp;
  columns: GridColDef[];
  isLoading: boolean;
  sortModel: GridSortModel;
  onRowClick?: ((params: GridRowParams) => void) | undefined;
  theme: Theme;
};

const CustomCommonListDataGrid = ({
  rows,
  columns,
  isLoading,
  sortModel,
  theme,
  onRowClick = undefined,
}: CustomLevelListDataGridParams): React.JSX.Element => {
  const apiRef: React.MutableRefObject<GridApiPro> = useGridApiRef();

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <DataGridPro
          rows={rows}
          apiRef={apiRef}
          columns={columns}
          loading={isLoading}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
          }
          disableColumnMenu
          hideFooter
          sortModel={sortModel}
          disableColumnResize={true}
          disableColumnReorder={true}
          getRowHeight={() => {
            return 100;
          }}
          onRowClick={onRowClick ?? undefined}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </ThemeProvider>
    </Box>
  );
};

export default CustomCommonListDataGrid;
