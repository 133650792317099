import { useEffect, useState } from 'react';
import { getCoProductsPerCooperative, getCooperative, getCultures } from 'services/API/Cooperative';
import { toast } from 'react-toastify';
import { displayErrorMessage } from 'utils/tools_functions';
import DataGridCulture from './DataGridCulture';

const CulturesDataGridCooperative = ({
  user,
  cooperativeId,
  cooperativeName,
  economicAttribute,
  setEconomicAttribute,
  nitrogenCover,
  setNitrogenCover,
  biomassSupplyByCover,
  setBiomassSupplyByCover,
  setCooperativeCulturesAtIndexLevel,
}) => {
  const [coProductsList, setCoProductsList] = useState(null);
  const [cooperativeCultures, setCooperativeCultures] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    getCoProducts();
    getCooperativeCultures();
  };

  const getCooperativeCultures = async () => {
    try {
      const result = await getCultures(cooperativeId);
      const data = result.data;
      setCooperativeCultures(data);
      setCooperativeCulturesAtIndexLevel(data.data.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (error) {
      toast.error(displayErrorMessage('ERR_GET_CULTURE'));
    }
  };

  const getOffCropSettings = async () => {
    try {
      const result = await getCooperative(cooperativeId);
      const data = result.data;
      setEconomicAttribute(data.data?.economicAttributeCollection);
      setNitrogenCover({ nitrogenSupplyByCover: data.data?.nitrogenSupplyByCover });
      setBiomassSupplyByCover({ biomassSupplyByCover: data.data?.biomassSupplyByCover });
    } catch (error) {
      toast.error(error.data);
    }
  };

  const getCoProducts = async () => {
    let cooperativeId = null;
    if (user?.role === 'SUPER_ADMIN') {
      cooperativeId = Number(localStorage.getItem('cooperative'));
    }
    try {
      const result = await getCoProductsPerCooperative(cooperativeId);
      const { data } = result.data;
      setCoProductsList([{ id: null, displayName: 'Aucun' }].concat(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <DataGridCulture
      columnsDefinition={cooperativeCultures?.metadata}
      from={'fromCooperative'}
      cultures={cooperativeCultures?.data}
      refreshData={loadData}
      user={user}
      coProductsList={coProductsList}
      cooperativeId={cooperativeId}
      economicAttribute={economicAttribute}
      cooperativeName={cooperativeName}
      nitrogenCover={nitrogenCover}
      getOffCropSettings={getOffCropSettings}
      biomassSupplyByCover={biomassSupplyByCover}
      setBiomassSupplyByCover={setBiomassSupplyByCover}
    />
  );
};

export default CulturesDataGridCooperative;
