import { useContext } from 'react';
import './graphPerYear.style.scss';
import '../pdfComponent.style.scss';
import { PdfContext } from 'utils/context';
import { lengthInInterval, truncateTab } from '../Generics/utilsFunctions';
import { v1 } from 'uuid';

const GraphPerYearPage = () => {
  const { graphPerYear, proposal, currentYear } = useContext(PdfContext);

  const numberOfGraphPerPage = 2;
  let toDuplicate = true;
  let repetition = 1;
  let graphPerYearPages = [];
  const graphPerYearWithoutN1 = graphPerYear
    .find((prop) => prop.proposal === proposal)
    .charts.slice(1);

  while (toDuplicate) {
    // On découpe la list des graphs en 2 graph par page pour que ce soit lisible
    let truncatedResult = truncateTab(graphPerYearWithoutN1, numberOfGraphPerPage, repetition);
    graphPerYearPages.push(
      buildGraphPerYear(
        truncatedResult,
        repetition > 1 ? '(Suite)' : '',
        repetition,
        currentYear,
        numberOfGraphPerPage
      )
    );
    lengthInInterval(graphPerYearWithoutN1, repetition, numberOfGraphPerPage)
      ? repetition++
      : (toDuplicate = false);
  }

  return graphPerYearPages;
};

const buildGraphPerYear = (
  graphsToRender,
  message,
  repetition,
  currentYear,
  numberOfGraphPerPage
) => {
  return (
    <article className="pdf-page ">
      <div className="tableau_center">
        <h6 className="title_h6">Production (ha) {message}</h6>
        <div className="multiple-graph-bloc">
          {graphsToRender?.map((img, idx) => (
            <div key={v1()} className="graph">
              <h6 className="title_campagne">
                Campagne {idx + currentYear + (repetition - 1) * numberOfGraphPerPage} -{' '}
                {idx + (currentYear + 1) + (repetition - 1) * numberOfGraphPerPage}
              </h6>
              <img className="graph-production" src={img} alt="Graphique de production annuelle" />
            </div>
          ))}
        </div>
      </div>
    </article>
  );
};

export default GraphPerYearPage;
