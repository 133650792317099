// REACT
import { useEffect, useMemo, useState } from 'react';
// YARN
import {
  GridColDef,
  GridRowId,
  GridRowParams,
  GridRowsProp,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';
// SERVICES
import { CustomSortableHeader } from 'utils/Datagrid/CustomGenericDataGridUtils';
import useDataGridCommonListActions from 'utils/Datagrid/useDataGridCommonListActions';
import { CustomHeaderWithoutSort } from 'utils/Datagrid/CustomCultureDataGridUtils';
import CustomCommonListDataGrid from 'utils/Datagrid/CustomCommonListDataGrid';
import { formatDate } from 'utils';
import { deleteTechnician, linkUserToSector } from 'services/API/Cooperative';
import useAuth from 'providers/Auth/useAuth';
// INTERFACES
import { ITechnician } from 'components/generics/Interface/Api/Response/User/ITechnician';
// THEMES
import { CustomCommonListDataGridTheme } from 'utils/Datagrid/CustomCommonListDataGridUtils';
import { customDialogTheme } from 'assets/styles/themes/Dialog/generic_dialog_mui';

type StructureTechniciansDataGridParams = {
  technicians: ITechnician[];
  refreshData: () => void;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setEditData: React.Dispatch<React.SetStateAction<GridValidRowModel | null | undefined>>;
  sector: number;
};
export const StructureTechniciansDataGrid = ({
  technicians,
  refreshData,
  setModal,
  setIsEdit,
  setEditData,
  sector,
}: StructureTechniciansDataGridParams): React.JSX.Element => {
  const [rows, setRows] = useState<GridRowsProp | []>([]);
  const [dataGridLoading, setDataGridLoading] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [actionId, setActionId] = useState<GridRowId | null>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'email',
      sort: 'asc',
    },
  ]);
  const { user } = useAuth();
  const thisTechnician: GridValidRowModel | undefined = rows?.find((row) => row.id === actionId);

  type ProcessedRowType = {
    id: number;
    email: string;
    lastname: string;
    firstname: string;
    createdAt: string;
  };
  const processedRows: ProcessedRowType[] = useMemo(() => {
    return technicians?.map((technician) => {
      return {
        id: technician.id,
        email: technician.email,
        lastname: technician.lastname,
        firstname: technician.firstname,
        createdAt: technician.createdAt,
      };
    });
  }, [technicians]);

  useEffect(() => {
    setDataGridLoading(true);
    setRows(processedRows ?? []);
    if (processedRows) {
      setDataGridLoading(false);
    }
  }, [technicians]);

  const handleRemoveTechnicianFromSector = async (id: GridRowId): Promise<void> => {
    let cooperativeId: number | null = null;
    if (user?.role === 'SUPER_ADMIN') {
      cooperativeId = Number(localStorage.getItem('cooperative'));
    }

    const userToUnlink = {
      id: id,
      sectorsToRemove: [sector],
      cooperative: cooperativeId,
    };

    try {
      await linkUserToSector(userToUnlink);
      toast.success(`Technicien ${rows?.find((row) => row.id === id)?.email} supprimé du secteur`);
      refreshData();
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const { getActions } = useDataGridCommonListActions(
    processedRows,
    setModal,
    setIsEdit,
    setEditData,
    setOpenDeleteDialog,
    setActionId,
    sector,
    handleRemoveTechnicianFromSector
  );

  const columns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'E-mail',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: () => (
        <CustomSortableHeader setSortModel={setSortModel} fieldName={'email'} name={'E-mail'} />
      ),
    },
    {
      field: 'lastname',
      headerName: 'Nom',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: () => (
        <CustomSortableHeader setSortModel={setSortModel} fieldName={'lastname'} name={'Nom'} />
      ),
    },
    {
      field: 'firstname',
      headerName: 'Prénom',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: () => (
        <CustomSortableHeader setSortModel={setSortModel} fieldName={'firstname'} name={'Prénom'} />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Date de création',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (value) => formatDate(value),
      flex: 1,
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'createdAt'}
          name={'Date de création'}
        />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'actions',
      getActions: (params: GridRowParams) => getActions(params.id),
      renderHeader: () => <CustomHeaderWithoutSort name={'Actions'} />,
    },
  ];

  const handleDeleteClick = async (): Promise<void> => {
    setOpenDeleteDialog(false);
    try {
      await deleteTechnician(thisTechnician);
      toast.success(`Technicien ${thisTechnician?.email} supprimé`);
      refreshData();
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const renderDeleteDialog = (): React.JSX.Element => {
    return (
      <ThemeProvider theme={customDialogTheme}>
        <Dialog maxWidth="md" open={openDeleteDialog}>
          <DialogTitle>Attention</DialogTitle>
          <DialogContent dividers>
            <h3>Supprimer un technicien</h3>
            <p>Vous êtes sur le point de supprimer le technicien</p>
            <p>
              <strong>{thisTechnician?.email}</strong>. Souhaitez-vous continuer?
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClick}>
              Supprimer <DoneIcon />
            </Button>
            <Button onClick={() => setOpenDeleteDialog(false)}>
              Non <ClearIcon />
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  };

  return (
    <>
      <div className="sector-data-grid common-list-data-grid">
        <CustomCommonListDataGrid
          rows={rows}
          columns={columns}
          isLoading={dataGridLoading}
          sortModel={sortModel}
          theme={CustomCommonListDataGridTheme}
        />
      </div>
      {renderDeleteDialog()}
    </>
  );
};
