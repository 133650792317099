import React from 'react';
import PropTypes from 'prop-types';

const Sector = ({ color = '#000', fill = '#d12d38', width = 38, height = 38 }) => (
  <svg width={width} height={height} viewBox="0 0 38 38">
    <defs>
      <clipPath id="prefix__a">
        <path fill={fill} color={color} d="M0 0h37v37H0z" />
      </clipPath>
    </defs>
    <path
      fill={fill}
      color={color}
      d="M36.277 28.975a23.092 23.092 0 00-16.252 6.792.723.723 0 001.025 1.019 21.637 21.637 0 0115.227-6.366.723.723 0 000-1.445zm0 0M21.207 28.306a.722.722 0 00-1-.194 28.523 28.523 0 00-7.757 7.762.723.723 0 101.2.806 27.078 27.078 0 017.366-7.371.723.723 0 00.194-1zm0 0"
    />
    <g clipPath="url(#prefix__a)">
      <path
        fill={fill}
        color={color}
        d="M36.277 23.158a28.422 28.422 0 00-9.5 1.64.723.723 0 10.482 1.362 26.981 26.981 0 019.022-1.557.723.723 0 000-1.445zm0 0"
      />
      <path
        fill={fill}
        color={color}
        d="M10.354 32.041a32.938 32.938 0 0119.231-12.118 32.21 32.21 0 016.689-.7.723.723 0 100-1.445 34.006 34.006 0 00-6.114.556v-1.56A10.344 10.344 0 0029.89.163a.723.723 0 00-.906 0 10.344 10.344 0 00-.27 16.611v1.849a34.212 34.212 0 00-4.051 1.19v-4.228a.72.72 0 00-.21-.509l-4.047-4.071a.724.724 0 00-.513-.213h-8.262a.723.723 0 00-.512.213l-4.047 4.071a.72.72 0 00-.21.51v2.76q-.755-.138-1.518-.243v-2.58a7.991 7.991 0 00-.27-12.777.723.723 0 00-.906 0A7.99 7.99 0 003.9 15.523v2.41q-1.581-.148-3.18-.149a.723.723 0 000 1.445 32.594 32.594 0 0116.413 4.429 34.121 34.121 0 00-3.413 2.651 28.751 28.751 0 00-13-3.142.723.723 0 000 1.445 27.281 27.281 0 0111.864 2.746 34.149 34.149 0 00-3.073 3.4A22.606 22.606 0 00.72 28.982a.723.723 0 000 1.445 21.15 21.15 0 017.891 1.525q-.749 1.041-1.42 2.138l-1.1 1.8a.723.723 0 101.234.753l1.1-1.8a32.1 32.1 0 011.856-2.715.683.683 0 00.071-.093zM26.118 8.576a9.222 9.222 0 013.323-6.908 9.222 9.222 0 013.323 6.908 9 9 0 01-2.6 6.207V9.25a.723.723 0 00-1.445 0v5.534a9 9 0 01-2.6-6.207zM2.289 9.25a6.762 6.762 0 012.336-4.985A6.762 6.762 0 016.961 9.25a6.533 6.533 0 01-1.614 4.231V8.672a.723.723 0 00-1.445 0v4.809A6.533 6.533 0 012.288 9.25zm19.918 5.612h-6.224l-2.61-2.626H19.6zm-10.572-2.323l2.31 2.323H9.326zm-3.324 3.769h6.648v4.6q-.5-.231-1.012-.446V18.03a.723.723 0 00-.723-.723h-3.18a.723.723 0 00-.723.723v.859q-.5-.131-1.012-.248zm4.191 3.579q-.86-.317-1.734-.587v-.547H12.5zm3.9 1.724v-5.3h6.817v4.069a33.916 33.916 0 00-3.62 1.757q-.562.315-1.111.653-1.025-.626-2.086-1.176zm0 0"
      />
    </g>
    <path
      fill={fill}
      color={color}
      d="M29.441 7.226a.724.724 0 10-.511-.212.728.728 0 00.511.212zm0 0M23.168 26.396a.722.722 0 10.511-.212.729.729 0 00-.511.212zm0 0"
    />
  </svg>
);

Sector.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Sector;
