import React from 'react';
import { v1 } from 'uuid';
import PropTypes from 'prop-types';

import actionsProps from 'components/propTypes/actions';
import constraintCoProductProps from 'components/propTypes/constraintCoProduct';

import TableHead from './TableHead';

import './tableCultures.style.scss';

const TableCoProducts = ({ header, data, RowElement, actions }) => (
  <div className='table-scroll'>
    <div className="table-cultures">
      <table className="table">
        <TableHead items={header} />
        <tbody>
          {data?.map((row) => (
            <RowElement key={v1()} data={row} actions={actions} />
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

TableCoProducts.propTypes = {
  header: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(constraintCoProductProps),
  RowElement: PropTypes.func.isRequired,
  actions: actionsProps.isRequired,
};


export default TableCoProducts;
