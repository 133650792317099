import { ResponsivePie } from '@nivo/pie';
import './result.styles.scss';
import { patternLinesDef } from '@nivo/core';

const CustomPieProductionGrossMargin = ({
  comparatorSimulationResult,
  comparatorCalculatorResult,
  currentYear,
  shuffledColorsArray,
}) => {
  const simulationCropsGrossMargin =
    comparatorSimulationResult?.suggestions.suggestion1.years[0].cropsGrossMargin;

  const calculatorCropsGrossMargin = comparatorCalculatorResult?.result.years[0].cropsGrossMargin;

  const addSameCulturesMargin = (data) => {
    return data.reduce((acc, curr, i) => {
      let item = i > 0 && acc.find((cult) => cult.culture.id === curr.culture.id);
      if (item) {
        item.grossMargin += curr.grossMargin;
      } else {
        acc.push({
          culture: { id: curr.culture.id, name: curr.culture.name },
          grossMargin: curr.grossMargin,
        });
      }
      return acc;
    }, []);
  };

  const renderPie = (data) => {
    return addSameCulturesMargin(data)
      .filter((culture) => culture.grossMargin !== 0)
      .map((result, index) => ({
        ...result,
        value: Math.abs(Math.round(result.grossMargin)),
        id: result.culture.name,
        label: result.culture.name,
        color: shuffledColorsArray.find((color) => color.hasOwnProperty(result.culture.name))[
          result.culture.name
        ],
        negative: Math.sign(result.grossMargin) === -1 ? true : false,
      }))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  };

  const simulationDataPie = renderPie(simulationCropsGrossMargin);

  const calculatorDataPie = renderPie(calculatorCropsGrossMargin);

  const mergeData = [...calculatorDataPie, ...simulationDataPie];
  const removeDuplicates = mergeData
    .filter((value, index, self) => index === self.findIndex((datum) => datum.id === value.id))
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

  const isValueNegative = (data, value) => {
    return data.filter((simulationData) => simulationData.value === value)[0].negative;
  };

  return (
    <div className="header-container">
      <div className="header">
        <span>{`${currentYear}-${currentYear + 1}`}</span> (Comparatif)
      </div>
      <div className="pie-container">
        <div className="custom-pie-production">
          <div className="graph">
            <div className="graph-title">Prévisionnel</div>
            <CustomResponsivePie data={calculatorDataPie} isValueNegative={isValueNegative} />
          </div>
          <div className="graph">
            <div className="graph-title">Proposition Assolia</div>

            <CustomResponsivePie data={simulationDataPie} isValueNegative={isValueNegative} />
          </div>
        </div>
        <CustomLegend data={removeDuplicates} />
      </div>
      <p className="legend-asterisk">* Culture ayant une marge brute négative</p>
    </div>
  );
};

export default CustomPieProductionGrossMargin;

const CustomResponsivePie = ({ data, isValueNegative }) => {
  return (
    <>
      <ResponsivePie
        data={data}
        margin={{ top: 15, right: 15, bottom: 15, left: 35 }}
        colors={{ datum: 'data.color' }}
        innerRadius={0.8}
        radialLabel={(d) => {
          return `${d.label}: ${d.value} €`;
        }}
        valueFormat={(value) => (isValueNegative(data, value) ? `-${value}€` : value + '€')}
        borderWidth={1}
        borderColor={(data) => (data.data.negative ? ' #f00000' : 'grey')}
        padAngle={2}
        cornerRadius={3}
        enableSliceLabels={false}
        arcLabelsTextColor="#e69636"
        enableArcLabels={false}
        activeOuterRadiusOffset={7}
        sortByValue={true}
        enableArcLinkLabels={true}
        arcLinkLabelsDiagonalLength={3}
        arcLinkLabelsStraightLength={3}
        arcLinkLabelsTextOffset={0}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabel={(datum) =>
          datum?.id.length > 13 ? `${datum?.id.slice(0, 13)}...` : datum?.id
        }
        defs={[
          patternLinesDef('lines', {
            spacing: 10,
            rotation: -46,
            lineWidth: 5,
            background: 'inherit',
            color: 'white',
          }),
        ]}
        fill={[{ match: (d) => d.data.negative === true, id: 'lines' }]}
        tooltip={(input) => {
          return (
            <div className="legend-item pie-tooltip">
              <div className="legend-color" style={{ backgroundColor: input.datum.color }}></div>
              <div>
                {input.datum.label}:{' '}
                <span style={{ color: input.datum.data.negative ? 'red' : '' }}>
                  {input.datum.formattedValue}
                </span>
              </div>
            </div>
          );
        }}
      />
    </>
  );
};
const CustomLegend = ({ data }) => {
  return (
    <div className="legend-container">
      {data.map((datum) => (
        <div className="legend-item" key={datum.id}>
          <div className="legend-color" style={{ backgroundColor: datum.color }}></div>
          <div style={{ color: datum.negative ? 'red' : '' }}>
            {datum.label}
            {datum.negative && <span>*</span>}
          </div>
          {/* {datum.negative && (
            <Tooltip title="Texte explicatif du pourquoi du comment">
              <InfoIcon sx={{ color: 'red', width: '20px', marginLeft: '5px' }} />
            </Tooltip>
          )} */}
        </div>
      ))}
    </div>
  );
};
