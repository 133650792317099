// REACT
import React, { useState, useRef, SyntheticEvent } from 'react';

// YARN
import { toast } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { Close } from '@mui/icons-material';

// SERVICES
import {
  inputRequired,
  inputVerifyMail,
  inputStringMinMaxLengthValidator,
  inputStringMinLengthValidator,
} from 'components/generics/Validators/validator';
import {
  addExploitation,
  addFarmer,
  removeFarmer,
  removeExploitation,
  updateExploitation,
} from 'services/API/Secteur';
import {
  checkTypeStringRegexDatagrid,
  displayErrorMessage,
  checkDecimalNegativeDatagrid,
} from 'utils/tools_functions';
import { validateFormData } from 'components/generics/Validators/form.validate';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';
import { customDialogTheme } from 'assets/styles/themes/Dialog/generic_dialog_mui';
import LoadingScreen from 'components/generics/loadingScreen';
import { customDialogAddEditCommonTheme } from 'assets/styles/themes/Dialog/generic_dialog_add_edit_parcel';

// INTERFACES
import ISector from 'components/generics/Interface/Api/Response/Structures/ISector';
import { IErrorResponse } from 'components/generics/Interface/Commons/IErrorResponse';

type FormDataType = {
  id?: number;
  name: string;
  code: string;
  irrigability: number;
  farmers?: FormDataFarmerType[];
};

type FormDataFarmerType = {
  id?: number;
  firstname: string;
  lastname: string;
  email: string;
  role: Array<string>;
  cooperative: number;
  sectors: Array<number>;
  exploitations: Array<number>;
};

const DEFAULT_FORM: FormDataType = {
  name: '',
  code: '',
  irrigability: 0,
};

const DEFAULT_FARMER: FormDataFarmerType = {
  firstname: '',
  lastname: '',
  email: '',
  role: ['ROLE_FARMER'],
  cooperative: 0,
  sectors: [],
  exploitations: [],
};

type AddExploitationModalParams = {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmitSuccess: () => void;
  secteur: ISector | null;
  loadDataSector: (sectorId: number) => void;
  choosenStructureId: number | null;
  edit?: FormDataType | null;
  isEdit: boolean;
};

const AddExploitationModal = ({
  modal,
  setModal,
  onSubmitSuccess,
  secteur,
  loadDataSector,
  choosenStructureId,
  edit,
  isEdit,
}: AddExploitationModalParams): React.JSX.Element => {
  const [formData, setFormData] = useState<FormDataType>(isEdit ? edit! : DEFAULT_FORM);
  const [currentFarmers, setCurrentFarmers] = useState(isEdit ? edit?.farmers : []);
  const [newFarmers, setNewFarmers] = useState<FormDataFarmerType[]>([]);
  const [addFarmers, setAddFarmers] = useState<FormDataFarmerType>(DEFAULT_FARMER);
  const [removeId, setRemoveId] = useState<number[]>([]);
  const [errorsFormDefault, setErrorsFormDefault] = useState({
    formError: false,
    name: {
      message: '',
      validator: [inputRequired, inputStringMinLengthValidator(2)],
    },
    code: {
      message: '',
      validator: [inputRequired, inputStringMinMaxLengthValidator(1, 30)],
    },
  });
  const [errorsFormFarmer, setErrorsFormFarmer] = useState({
    formError: false,
    firstname: {
      message: '',
      validator: [inputRequired],
    },
    lastname: {
      message: '',
      validator: [inputRequired],
    },
    email: {
      message: '',
      validator: [inputVerifyMail],
    },
  });
  const [loading, setLoading] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const choosenStructureIdChoose = Number(localStorage.getItem('cooperative'));
  const [isSuspendOpen, setIsSuspendOpen] = useState(false);

  const addFarmerHandle = () => {
    if (addFarmers !== DEFAULT_FARMER) {
      errorsFormFarmer.formError = false;
      setErrorsFormFarmer({ ...validateFormData(addFarmers, errorsFormFarmer) });
      if (!errorsFormFarmer.formError) {
        const newFarmersArr = newFarmers;
        newFarmersArr.push(addFarmers);
        setNewFarmers(newFarmersArr);
        setAddFarmers(DEFAULT_FARMER);
      }
    }
  };

  const deleteCurrentFarmer = (farmer: FormDataFarmerType) => {
    const newDeleteArray = removeId;
    newDeleteArray.push(farmer.id ?? 0);
    setRemoveId(newDeleteArray);
    const newCurrentFarmers = currentFarmers?.filter(
      (currentFarmer) => currentFarmer.id !== farmer?.id
    );
    setCurrentFarmers(newCurrentFarmers);
  };

  const deleteNewFarmer = (index: number) => {
    const arr: FormDataFarmerType[] = [];
    for (let i = 0; i < newFarmers.length; i++) {
      if (i !== index) {
        arr.push(newFarmers[i]);
      }
    }
    setNewFarmers(arr);
  };

  const checkDisabledFarmerButton = () => {
    return !(addFarmers.firstname && addFarmers.lastname && addFarmers.email);
  };

  const handleOnSubmit = (e: SyntheticEvent) => {
    if (e) e.preventDefault();

    errorsFormDefault.formError = false;
    errorsFormFarmer.formError = false;

    // Validate formData
    setErrorsFormDefault({ ...validateFormData(formData, errorsFormDefault) });

    // Validate each new farmer
    let allFarmersValid = true;
    newFarmers.forEach((farmer) => {
      const farmerErrors = validateFormData(farmer, errorsFormFarmer);
      if (farmerErrors.formError) {
        allFarmersValid = false;
        setErrorsFormFarmer({ ...farmerErrors });
      }
    });

    if (!errorsFormDefault.formError && allFarmersValid) {
      setLoading(true);
      const exploitationData = {
        ...formData,
        sector: secteur?.id,
      };

      const addOrUpdateFarmers = (exploitationId: number) => {
        const updatedFarmers = newFarmers.map((farmer) => ({
          ...farmer,
          sectors: [...farmer.sectors, secteur?.id],
          exploitations: [...farmer.exploitations, exploitationId],
          cooperative: choosenStructureIdChoose ? choosenStructureIdChoose : choosenStructureId,
        }));

        return Promise.all(
          updatedFarmers.map((farmer) =>
            addFarmer(farmer)
              .then(() => {
                toast.success(`Agriculteur ${farmer.firstname} ${farmer.lastname} créé`);
              })
              .catch((err) => {
                const apiError = err as IErrorResponse;
                if (apiError?.response?.status === 409) {
                  toast.error(displayErrorMessage('ERR_ADD_EXISTING_EMAIL', farmer as any));
                } else {
                  toast.error(err.response.data.message);
                }
              })
          )
        );
      };

      if (isEdit) {
        removeId.forEach((id) => {
          removeFarmer(id)
            .then(() => {
              toast.success(`Agriculteur supprimé`);
              loadDataSector(secteur?.id ?? 0);
            })
            .catch((err) => {
              toast.error(err.message);
            });
        });

        updateExploitation(exploitationData, formData.id)
          .then(() => {
            toast.success(`Exploitation ${formData.name} mise à jour`);
            return addOrUpdateFarmers(formData.id ?? 0);
          })
          .then(() => {
            loadDataSector(secteur?.id ?? 0);
            onSubmitSuccess();
          })
          .catch((err) => {
            toast.error(err.message);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        addExploitation(exploitationData)
          .then((response) => {
            const exploitationId = response.data.data.id;
            toast.success(`Exploitation ${formData.name} créée.`);
            return addOrUpdateFarmers(exploitationId);
          })
          .then(() => {
            loadDataSector(secteur?.id ?? 0);
            onSubmitSuccess();
          })
          .catch((err) => {
            if (formData.code) {
              toast.error(`Le code ne doit contenir que des chiffres.`);
            } else {
              toast.error(err.response.data.message);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const updateNewFarmer = (key: string, value: string) =>
    setAddFarmers({ ...addFarmers, [key]: value });

  const updateForm = (key: string, value: string) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSuspend = () => {
    removeExploitation(formData.id)
      .then(() => {
        toast.success(`Exploitation ${formData.name} supprimée`);
        loadDataSector(secteur?.id ?? 0);
      })
      .catch((err) => {
        toast.error(err.message);
      });
    onSubmitSuccess();
  };

  return (
    <>
      <ThemeProvider theme={customDialogAddEditCommonTheme}>
        <Dialog
          maxWidth="md"
          fullWidth
          open={modal}
          ref={ref}
          onClose={() => setModal(false)}
          id="add_exploitation_dialog"
        >
          <div className="add_exploitation_container">
            <LoadingScreen open={loading}></LoadingScreen>
            <Box className="modal_header">
              <DialogTitle>
                {isEdit ? 'Editer une exploitation' : 'Ajouter une exploitation'}
              </DialogTitle>
              <ClearIcon className="close-icon" onClick={() => setModal(false)} />
            </Box>
            <DialogContent>
              <fieldset className="add_exploitation_fieldset">
                <legend>Exploitation</legend>
                <Box id="add_name" className="add_exploitation_container__box">
                  <InputLabel id="nameLabel" htmlFor="name">
                    Nom<span className="label_asterisk">*</span>
                  </InputLabel>
                  <TextField
                    id="name"
                    value={formData.name}
                    onChange={(e) => {
                      updateForm('name', checkTypeStringRegexDatagrid(e.target.value));
                    }}
                    placeholder="Entrez le nom de l'exploitation"
                    disabled={loading}
                    error={errorsFormDefault?.name.message ? true : false}
                    helperText={errorsFormDefault?.name.message}
                  />
                </Box>
                <Box className="add_exploitation_container__box">
                  <InputLabel id="codeLabel" htmlFor="code">
                    Code<span className="label_asterisk">*</span>
                  </InputLabel>
                  <TextField
                    id="code"
                    onChange={(e) => {
                      updateForm('code', checkTypeStringRegexDatagrid(e.target.value));
                    }}
                    value={formData.code}
                    placeholder="1234"
                    disabled={loading}
                    error={Boolean(errorsFormDefault?.code.message)}
                    helperText={errorsFormDefault?.code.message}
                  />
                </Box>
                <Box className="add_exploitation_container__box">
                  <InputLabel id="irrigabilityLabel" htmlFor="irrigability">
                    Capacité d’irrigation (en m3){' '}
                  </InputLabel>
                  <TextField
                    id="irrigability"
                    onChange={(e) => {
                      updateForm('irrigability', checkDecimalNegativeDatagrid(e.target.value));
                    }}
                    value={formData.irrigability}
                    placeholder="0"
                    disabled={loading}
                  />
                </Box>
                <Box>
                  <InputLabel id="sectorLabel" htmlFor="secteur">
                    Secteur
                  </InputLabel>
                  <TextField
                    id="secteur"
                    value={secteur?.name}
                    placeholder={secteur?.name}
                    disabled={true}
                  />
                </Box>
              </fieldset>
              <fieldset className="add_farmer_fieldset">
                <legend>
                  Agriculteur{' '}
                  <ThemeProvider theme={customButtonTheme}>
                    <Button
                      id="add_farmer_button"
                      onClick={addFarmerHandle}
                      disabled={checkDisabledFarmerButton()}
                    >
                      Ajouter
                    </Button>
                  </ThemeProvider>
                </legend>
                <Box className="add-farmer-list">
                  <Box>
                    {currentFarmers &&
                      currentFarmers.length > 0 &&
                      currentFarmers.map((farmer) => (
                        <div key={farmer.id}>
                          <Button
                            variant="contained"
                            onClick={() => deleteCurrentFarmer(farmer)}
                            disabled={loading}
                            endIcon={<Close width={13} height={13} />}
                          >
                            {`${farmer.firstname} ${farmer.lastname}`}
                          </Button>
                        </div>
                      ))}
                    {newFarmers.map((farmer, index) => (
                      <div key={index}>
                        <Button
                          variant="contained"
                          onClick={() => deleteNewFarmer(index)}
                          disabled={loading}
                          endIcon={<Close width={13} height={13} />}
                        >
                          {`${farmer.firstname} ${farmer.lastname}`}
                        </Button>
                      </div>
                    ))}
                  </Box>
                </Box>
                <Box>
                  <InputLabel id="firtnameLabel" htmlFor="firstname">
                    Prenom
                  </InputLabel>
                  <TextField
                    id="firstname"
                    value={addFarmers.firstname}
                    onChange={(e) => {
                      updateNewFarmer('firstname', checkTypeStringRegexDatagrid(e.target.value));
                    }}
                    placeholder="Entrez le prenom de l'Agriculteur"
                    disabled={loading}
                  />
                </Box>
                <Box>
                  <InputLabel id="lastnameLabel" htmlFor="lastname">
                    Nom
                  </InputLabel>
                  <TextField
                    id="lastname"
                    value={addFarmers.lastname}
                    onChange={(e) => {
                      updateNewFarmer('lastname', checkTypeStringRegexDatagrid(e.target.value));
                    }}
                    placeholder="Entrez le nom de l'Agriculteur"
                    disabled={loading}
                  />
                </Box>
                <Box>
                  <InputLabel id="emailLabel" htmlFor="email">
                    Email
                  </InputLabel>
                  <TextField
                    id="email"
                    value={addFarmers.email}
                    onChange={(e) => {
                      updateNewFarmer('email', e.target.value);
                    }}
                    placeholder="Entrez l'email de l'agriculteur"
                    disabled={loading}
                    error={Boolean(errorsFormFarmer?.email.message)}
                    helperText={errorsFormFarmer?.email.message}
                  />
                </Box>
              </fieldset>
            </DialogContent>
            <div className="text_helper_exploitation">
              <div>
                <span className="label_asterisk">*</span> Le champ est obligatoire.
              </div>
            </div>
            <DialogActions>
              <ThemeProvider theme={customButtonTheme}>
                {isEdit && (
                  <Button
                    id="custom-delete-button"
                    type="submit"
                    disabled={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsSuspendOpen(true);
                    }}
                  >
                    Supprimer l'Exploitation
                  </Button>
                )}
                <Box>
                  <Button id="add_exploitation_button" onClick={handleOnSubmit}>
                    {isEdit ? 'Mettre à jour' : 'Ajouter'}
                  </Button>
                </Box>
              </ThemeProvider>
            </DialogActions>
            {isSuspendOpen && (
              <ThemeProvider theme={customDialogTheme}>
                <Dialog open={isSuspendOpen}>
                  <DialogTitle>Attention</DialogTitle>
                  <DialogContent dividers>
                    <h3>Supprimer une exploitation</h3>
                    <p>Vous êtes sur le point de supprimer l'exploitation</p>
                    <p>
                      <strong>{isEdit ? `${formData.name}` : ''}</strong>. <br /> Souhaitez-vous
                      continuer ?
                    </p>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleSuspend}>
                      Supprimer <DoneIcon />
                    </Button>
                    <Button onClick={() => setIsSuspendOpen(false)}>
                      Non <ClearIcon />
                    </Button>
                  </DialogActions>
                </Dialog>
              </ThemeProvider>
            )}
          </div>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default AddExploitationModal;
