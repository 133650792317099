import { gridQuickFilterValuesSelector } from '@mui/x-data-grid';
import React, { createContext, useState } from 'react';
import { getGroundTypeByExploitationId } from 'services/API/Exploitation';

export const PdfContext = createContext();

export const NavContext = createContext();

export function NavProvider({ children }) {
  const [isNavBarOpen, setIsNavBarOpen] = useState(true);

  return (
    <NavContext.Provider
      value={{
        isNavBarOpen,
        setIsNavBarOpen,
      }}
    >
      {children}
    </NavContext.Provider>
  );
}

export const ModalContext = React.createContext();

export const LoadingContext = React.createContext({
  fullscreen: false,
  customText: null,
});

export function LoadingProvider({ children }) {
  const [fullscreen, setFullScreen] = useState(false);
  const [customText, setCustomText] = useState(null);
  const [forceLoader, setForceLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const resetDataLoader = () => {
    setFullScreen(false);
    setCustomText(null);
    setForceLoader(false);
  };

  return (
    <LoadingContext.Provider
      value={{
        fullscreen,
        setFullScreen,
        customText,
        setCustomText,
        forceLoader,
        setForceLoader,
        loading,
        setLoading,
        resetDataLoader
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
}

export const ArableContext = React.createContext({
  arableSurface: 0,
  setArableSurface: () => {},
});

export const ExploitationContext = React.createContext({});

export function ExploitationProvider({ children }) {
  const [exploitationContext, setExploitationContext] = useState(null);
  const [culturesColors, setCulturesColors] = useState([]);
  const [parcelsInformations, setParcelsInformations] = useState([]);
  const [groundTypesList, setGroundTypesList] = useState([])

  const resetContextExploitations = () => {
    setExploitationContext(null);
  };

  const getGroundTypesList = async () => {

    if(!groundTypesList || !(groundTypesList.length > 0)) {
      const result = await getGroundTypeByExploitationId(localStorage.getItem('exploitation'))
      setGroundTypesList(result.data.data)
    }     
  }

  return (
    <ExploitationContext.Provider
      value={{
        exploitationContext,
        setExploitationContext,
        resetContextExploitations,
        culturesColors,
        setCulturesColors,
        parcelsInformations,
        setParcelsInformations,
        groundTypesList,
        getGroundTypesList
      }}
    >
      {children}
    </ExploitationContext.Provider>
  );
}
