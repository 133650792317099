import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import useAuth from 'providers/Auth/useAuth';
import Button from 'components/generics/Button';

import logo from 'assets/images/logo.png';
import homeImg from 'assets/images/homeImg.png';

import './Home.style.scss';

const Home = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const selectHomePageByUserRole = () => {
    let homePage;
    if (user) {
      switch (user.role) {
        case 'SUPER_ADMIN':
          homePage = 'administration';
          break;
        case 'ADMIN':
          homePage = 'cooperative/sectors';
          break;
        case 'TECHNICIAN':
          homePage = 'sector';
          break;
        case 'FARMER':
          homePage = 'exploitation';
          break;
        default:
          homePage = 'exploitation';
          break;
      }
    }
    navigate('/' + homePage)
  }

  return user ? (
    <div className="Home">
      <img className="home_main_logo" src={logo} alt="main logo assolia" />
      <div className="home_content">
        <img className="home_logo" src={homeImg} alt="logo assolia" />
        <h1 className="title">L'assolement personnalisé et instantané</h1>
        <Button
          type="button"
          onClick={() => selectHomePageByUserRole()}
          text="Accéder à mes profils agriculteurs"
        />
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default Home;
