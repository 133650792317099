import { useContext } from 'react';
import './coverPage.style.scss';
import { PdfContext } from 'utils/context';
import { formatDate } from 'utils';

const CoverPage = () => {
  const { exploitation, cooperative, date, user, sector, jobId } = useContext(PdfContext);

  let exploitationNameSize = 64;
  if (exploitation?.name.length > 34 && exploitation?.name.length < 60) {
    exploitationNameSize = 46;
  } else if (exploitation?.name.length >= 60) {
    exploitationNameSize = 34;
  }

  return (
    <article className="cover-page-pdf">
      <div className="low_separator"></div>
      <div className="cover">
        {cooperative.logo && (
          <img
            src={cooperative.logo + '?not-from-cache-please'}
            className="logo"
            alt={`Logo ${cooperative.name}`}
            crossOrigin="Anonymous"
          />
        )}
        <div className="title">
          <h3>Résultats de simulation</h3>
          <h1 style={{ fontSize: exploitationNameSize }}>{exploitation?.name}</h1>
        </div>
        <div className="info_cover">
          <div className="info">
            <span className="info_attribute">DATE</span>
            <span className="info_value">{formatDate(date)}</span>
          </div>
          <div className="info border-left">
            <span className="info_attribute">TECHNICIEN</span>
            <span className="info_value">{`${user.lastname} ${user.firstname}`}</span>
          </div>
          <div className="info border-left">
            <span className="info_attribute">SECTEUR</span>
            <span className="info_value">{sector}</span>
          </div>
        </div>
        <div className="job_id_display job_id_display_pdf"> Job Id: {jobId}</div>
      </div>
    </article>
  );
};

export default CoverPage;
