import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import InputLabel from 'components/generics/InputLabel';
import Button from 'components/generics/Button';
import { useNavigate } from 'react-router-dom';

const DEFAULT_FORM = { username: '', password: '' };

function LoginWiuz() {
  const [formData, setFormData] = useState(DEFAULT_FORM);
  const [loading] = useState(false);
  const [wiuzUser] = useState('');
  const navigate = useNavigate();
  const [locationRoute, setLocationRoute] = useState('cooperative');

  useEffect(() => {
    setLocationRoute(window.location.pathname.split('/')[1]);
  }, []);

  const onSubmit = (e) => {
    navigate(`/${locationRoute}/import/exploitation`);
    // if (e) e.preventDefault();
    // setLoading(true);
    // JWTService.signInWithCredentials(formData.username, formData.password, () => setLoading(false))
    //   .then((response) => {
    //     setLoading(false);
    //     toast.success('Connecté à Wiuz');
    //   })

    //   .catch((err) => {
    //     setLoading(false);
    //     if (err.code && err.code === 401) {
    //       if (err.message === 'Cooperative suspendue, merci de contacter un administrateur.') {
    //         toast.error('Coopérative inactive');
    //       } else {
    //         toast.error('Identifiants incorrects');
    //       }
    //       updateForm('password', '');
    //     } else {
    //       toast.error('Erreur inconnue');
    //       setFormData(DEFAULT_FORM);
    //     }
    //   });
  };

  const updateForm = (key, value) => setFormData({ ...formData, [key]: value });

  return wiuzUser ? (
    <Navigate to={`/${locationRoute}/import/exploitation`} />
  ) : (
    <div className="section">
      <div className="main_container">
        <h1 className="title_section">Connexion Wiuz</h1>

        <div style={{ width: '25vw', margin: 'auto', marginTop: 150 }}>
          <form onSubmit={onSubmit}>
            <InputLabel
              name="username"
              value={formData.username}
              onChange={(e) => {
                updateForm('username', e);
              }}
              placeholder="Identifiant"
              label="IDENTIFIANT"
              defaultStyle="login"
              disabled={loading}
            />
            <InputLabel
              name="password"
              value={formData.password}
              type="password"
              onChange={(e) => {
                updateForm('password', e);
              }}
              placeholder="Mot de passe"
              defaultStyle="login"
              label="MOT DE PASSE"
              disabled={loading}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button defaultStyle="d-block" type="submit" text="Connexion" disabled={loading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginWiuz;
