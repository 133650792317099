// REACT
import React from 'react';

// SERVICES
import './CulturesMapTab.style.scss';
import { getMainCultureByYear } from './MapCommon';

// Interface
import { IParcelSimu } from '../Interface/Simulation/IParcelSimu';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import { IResultInfo } from '../Interface/Simulation/IResultInfo';
import { IParcelCulture } from '../Interface/Api/Response/Exploitation/Parcels/IParcelCulture';

type ParcelInfoParams = {
  cultureData: IParcelCulture[] | string;
  parcelInfo: IParcelSimu;
  parcels: IResultInfo[];
  year?: number | null;
  minYear?: number | null;
  from?: string | null;
};

const ParcelInfo = ({
  cultureData,
  parcelInfo,
  parcels,
  year = null,
  minYear = null,
  from = null,
}: ParcelInfoParams): React.JSX.Element => {
  const parentParcelName = parcels?.find((parcel) => parcel?.id === parcelInfo?.parent)?.name;

  const isSplittedParcel = parcelInfo?.cultures?.length > 1;

  const parcel = parcels?.find((parcel: IResultInfo) => parcel?.id === parcelInfo?.id);

  const currentCultures =
    typeof cultureData === 'string'
      ? cultureData
      : cultureData?.map((culture) => culture?.name).join(' & ');

  let isMinYear: boolean = false;
  if (year !== null && minYear !== null) {
    isMinYear = year <= minYear;
  }

  return (
    <div id="popup-content">
      <div className="parcel_title">
        <h1>
          {parcelInfo?.name}{' '}
          {isSplittedParcel && (
            <>
              <ContentCutIcon />
            </>
          )}
        </h1>
      </div>
      <div className="popup-content__wrapper">
        <div className="popup-content__left">
          {from === 'mapResult' && !isSplittedParcel && (
            <p>
              <span className="popup-content__left__label">Culture :</span>
              <span className="popup-content__left__parcel">{currentCultures}</span>
            </p>
          )}
          <p>
            <span className="popup-content__left__label">
              {parcelInfo?.groupSurface ? 'Surface totale' : 'Surface'} :
            </span>
            <span className="popup-content__left__parcel">
              {parcelInfo?.groupSurface
                ? Number(parcelInfo?.groupSurface.toFixed(2))
                : Number(parcelInfo?.surface.toFixed(2))}
            </span>
          </p>
          <p>
            <span className="popup-content__left__label">
              {from === 'mapResult'
                ? 'Précédent'
                : `${parcelInfo?.cultureN?.year - 1} - ${parcelInfo?.cultureN?.year}`}{' '}
              :
            </span>
            <span className="popup-content__left__parcel">
              {year ? getMainCultureByYear(year - 1, parcel) : parcelInfo?.cultureN?.name}
            </span>
          </p>
          {!isMinYear && (
            <p>
              <span className="popup-content__left__label">
                {from === 'mapResult'
                  ? 'Anté-précédent'
                  : `${parcelInfo?.cultureN1?.year - 1} - ${parcelInfo?.cultureN1?.year}`}{' '}
                :
              </span>
              <span className="popup-content__left__parcel">
                {year ? getMainCultureByYear(year - 2, parcel) : parcelInfo?.cultureN1?.name}
              </span>
            </p>
          )}
          <p>
            <span className="popup-content__left__label">Type de sol : </span>
            <span className="popup-content__left__parcel">{parcelInfo?.groundType?.name}</span>
          </p>
          <p>
            <span className="popup-content__left__label">Irrigabilité :</span>
            <span className="popup-content__left__parcel">
              {parcelInfo?.isIrrigableOnly || parcelInfo?.isIrrigable ? 'Oui' : 'Non'}
            </span>
          </p>
        </div>
        {isSplittedParcel && (
          <div className="popup-content__right">
            {parcelInfo?.cultures.map((culture, index) => (
              <div key={index}>
                <h4 className="popup-content__right__title">Parcelle {index + 1}</h4>
                <p>
                  <span className="popup-content__right__label">Culture :</span>
                  <span className="popup-content__right__culture">{culture?.name}</span>
                </p>
                <p>
                  <span className="popup-content__right__label">Surface :</span>
                  <span className="popup-content__right__culture">
                    {Number(culture?.surface.toFixed(2))}
                  </span>
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
      {parcelInfo?.parent && (
        <p>
          {parentParcelName ? (
            <span>
              <i>Cette parcelle est regroupée avec {parentParcelName}</i>
            </span>
          ) : (
            <span>
              <i>Cette parcelle fait partie d'un regroupement</i>
            </span>
          )}
        </p>
      )}
      {parcelInfo?.isParent && (
        <p>
          <span>
            <i>Cette parcelle est parente d'un regroupement</i>
          </span>
        </p>
      )}
    </div>
  );
};

export default ParcelInfo;
