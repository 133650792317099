import Button from 'components/generics/Button';
import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getParcelsByExploitation,
  getCulturesByExploitationId,
  addForecast,
  getForecastsByExploitation,
} from 'services/API/Exploitation';
import { sendEvent } from 'utils/Event';
import { ExploitationContext } from 'utils/context';
import './forecast.styles.scss';

//materialUI datagrid
import { toast } from 'react-toastify';
import { displayErrorMessage } from 'utils/tools_functions';
import CustomDataGrid from '../../../../utils/Datagrid/CustomForecastDataGrid';
import { ThemeProvider, Button as MButton } from '@mui/material';

//material UI multiSelect
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import { MenuItem, Select } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Settings from '@mui/icons-material/Tune';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
  CustomForecastCell,
  CustomForecastHeader,
  RenderEditForecastCell,
  themeFilters,
  updateExploitationContext,
} from '../../../../utils/Datagrid/CustomForecastDataGridUtils';
import {
  CustomSortableHeader,
  childrenTotalSurface,
  getFamily,
  totalSurfaceComparator,
} from 'utils/Datagrid/CustomGenericDataGridUtils';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';

const Forecast = ({ exploitation, setExploitation }) => {
  const [exploit, setExploit] = useState(exploitation);
  const { exploitationContext, setExploitationContext } = useContext(ExploitationContext);
  const [rows, setRows] = useState([]);
  const [dataGridLoading, setDataGridLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortModel, setSortModel] = useState([
    {
      field: 'parcel',
      sort: 'asc',
    },
  ]);
  const [filters, setFilters] = useState(null);
  const [parentFilter, setParentFilter] = useState('');
  const [rowsFromUpdatedParent, setRowsFromUpdatedParent] = useState([]);
  const [rowsFromLastUpdatedFilter, setRowsFromLastUpdatedFilter] = useState(null);
  const [toggleFirstChild, setToggleFirstChild] = useState(false);
  const [firstChildChoice, setFirstChildChoice] = useState('');
  const [toggleFilter, setToggleFilter] = useState(false);
  const [toggleOtherChildren, setToggleOtherChildren] = useState({
    2: false,
    3: false,
  });
  const [otherChildsChoice, setotherChildsChoice] = useState({
    2: '',
    3: '',
  });
  const [isAllowedSubmit, setIsAllowedSubmit] = useState(false);

  const currentYear = +localStorage.getItem('currentYear');
  const navigate = useNavigate();
  const removeDuplicates = (array) => array.filter((item, index) => array.indexOf(item) === index);

  const processedRows = exploitationContext?.parcels.map((parcel) => {
    const parcelForecast = exploitationContext?.forecasts?.find(
      (forecast) => forecast.parcel.id === parcel.id && forecast.year.year === currentYear + 1
    );

    return {
      id: parcel?.id,
      parcel: parcel?.name,
      surface: parcel?.surface,
      totalSurface: parcel?.isParent
        ? childrenTotalSurface(parcel?.id, exploitationContext?.parcels) + parcel?.surface
        : parcel?.surface,
      parcelId: parcel?.id,
      isIrrigable: parcel?.isIrrigable.toString() === 'true' ? 'oui' : 'non',
      groundType: parcel?.groundType?.name,
      cultureN1: parcel?.cultureN1?.name,
      cultureN: parcel?.cultureN?.name,
      forecast: parcelForecast?.culture.name ?? 'Aucun forecast',
      fixed: parcelForecast?.fixed,
      parentId: parcel?.parent ? getFamily(parcel, exploitationContext?.parcels) : [parcel.id],
      isParent: parcel?.isParent ?? false,
    };
  });

  const originalRowsCount = exploitationContext?.parcels.map((parcel) => parcel.id).length;
  const countFilteredRows = originalRowsCount - rows.length;
  const rowsParcels = rows.map((row) => row.parcel);
  const stackedRequestsRef = useRef([]);
  const defaultFilters = [
    {
      dataKey: 'groundType',
      name: 'Type de sols',
      value: removeDuplicates(rows.map((row) => row.groundType)),
      visible: false,
      isParent: false,
      isChild: false,
    },
    {
      dataKey: 'isIrrigable',
      name: 'Irrigabilité',
      value: removeDuplicates(rows.map((row) => row.isIrrigable)),
      visible: false,
      isParent: false,
      isChild: false,
    },
    {
      dataKey: 'cultureN1',
      name: `${currentYear - 2}-${currentYear - 1}`,
      value: removeDuplicates(rows.map((row) => row.cultureN1)),
      visible: false,
      isParent: false,
      isChild: false,
    },
    {
      dataKey: 'cultureN',
      name: `${currentYear - 1}-${currentYear}`,
      value: removeDuplicates(rows.map((row) => row.cultureN)),
      visible: false,
      isParent: false,
      isChild: false,
    },
  ];

  const renderEditForecastCell = (params) => {
    return <RenderEditForecastCell exploitationContext={exploitationContext} {...params} />;
  };

  const columns = [
    {
      field: 'parcel',
      headerName: 'Parcelle',
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      cellClassName: (params) =>
        params.cellMode === 'view' && params.row.parentId.length > 1 && 'first-children',
      renderHeader: () => (
        <CustomSortableHeader setSortModel={setSortModel} fieldName={'parcel'} name={'Parcelle'} />
      ),
    },
    {
      field: 'surface',
      headerName: 'Surface (ha)',
      valueGetter: (value) => value && `${value}ha`,
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 0.8,
      sortable: false,
      valueGetter: (value, row) => ({
        totalSurface: row.totalSurface,
        surface: row.surface,
      }),
      valueFormatter: (value) => {
        if (value.totalSurface !== value.surface) {
          return `${value.surface} (Total: ${value.totalSurface
            .toFixed(2)
            .replace(/[.,]00$/, '')})`;
        }
        return value.surface;
      },
      sortComparator: totalSurfaceComparator,
      cellClassName: (params) => params.row.parentId.length > 1 && 'parcel_children',
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'surface'}
          name={'Surface( ha)'}
        />
      ),
    },
    {
      field: 'isIrrigable',
      headerName: 'Irrigabilité',
      headerClassName: 'forecast-header',
      // valueGetter: ({ value }) => (value === true ? 'oui' : 'non'),
      headerAlign: 'center',
      align: 'center',
      flex: 0.6,
      sortable: false,
      cellClassName: (params) => params.row.parentId.length > 1 && 'parcel_children',
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'isIrrigable'}
          name={'Irrigabilité'}
        />
      ),
    },
    {
      field: 'groundType',
      headerName: 'Type de sols',
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      cellClassName: (params) => params.row.parentId.length > 1 && 'parcel_children',
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'groundType'}
          name={'Type de sols'}
        />
      ),
    },
    {
      field: 'cultureN1',
      headerName: `${currentYear - 2}-${currentYear - 1}`,
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      cellClassName: (params) => params.row.parentId.length > 1 && 'parcel_children',
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'cultureN1'}
          name={`${currentYear - 2}-${currentYear - 1}`}
        />
      ),
    },
    {
      field: 'cultureN',
      headerName: `${currentYear - 1}-${currentYear}`,
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      cellClassName: (params) => params.row.parentId.length > 1 && 'parcel_children',
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'cultureN'}
          name={`${currentYear - 1}-${currentYear}`}
        />
      ),
    },
    {
      field: 'forecast',
      headerName: `${currentYear}-${currentYear + 1} (prévisionnel)`,
      editable: true,
      sortable: false,
      renderCell: (params) => CustomForecastCell(params, stackedRequestsRef),
      renderEditCell: renderEditForecastCell,
      renderHeader: () => CustomForecastHeader(currentYear, 'prévisionnel'),
      headerClassName: 'forecast-header header-material',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      cellClassName: (params) => params.row.parentId.length > 1 && 'parcel_children_forecast',
    },
    {
      field: 'fixed',
      headerName: 'Culture fixée',
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      type: 'boolean',
      cellClassName: (params) => params.row.parentId.length > 1 && 'parcel_children_fixed',
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'fixed'}
          name={'Culture fixée'}
        />
      ),
    },
  ];

  useEffect(() => {
    setDataGridLoading(true);
    if (!exploit) {
      if (!exploit && localStorage.getItem('exploitation')) {
        setExploitation(+localStorage.getItem('exploitation'))
          .then((resp) => setExploit(resp.data.data))
          .catch((err) => toast.error(err.data));
      } else {
        toast.info('Selectionnez une exploitation');
        navigate('/exploitation');
      }
    }
    // Dismount
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  useEffect(() => {
    if (exploit) {
      loadData();
    }
  }, [exploit]);

  const loadData = async () => {
    try {
      const results = await Promise.all([
        getParcelsByExploitation(exploit.id),
        getCulturesByExploitationId(exploit.id),
        getForecastsByExploitation(exploit.id),
      ]);
      setExploitationContext((prevExploitationContext) => ({
        ...prevExploitationContext,
        parcels: results[0].data.data,
        cultures: results[1].data.data,
        forecasts: results[2].data.data,
        currentYear: +localStorage.getItem('currentYear'),
      }));
      setDataGridLoading(false);
    } catch (error) {
      toast.error(displayErrorMessage('ERR_GET_FORECAST'));
    }
  };

  useEffect(() => {
    if (exploitationContext) {
      processRows();
    }
  }, [exploitationContext]);

  useEffect(() => {
    if (rows.length > 0) {
      setSelectedRows(rows.filter((row) => row.parentId.length === 1).map((row) => row.id));
      !filters && setFilters(defaultFilters);
    }
  }, [rows]);

  useEffect(() => {
    const filterChanged = filters?.find((filter) => filter.changed === true);
    if (!filterChanged) {
      processRows();
      return;
    }
    processRows(filterChanged);
  }, [filters]);

  const checkedFiltersRows = () => {
    return filters.map((filter) => {
      const rowsValuesByFilter = rows.map((row) =>
        filter.dataKey ? row[filter.dataKey]?.toString() : row.parcel
      );
      const containsAll = (arr1, arr2) => arr2.every((arr2Item) => arr1.includes(arr2Item));
      const sameMembers = (arr1, arr2) => containsAll(arr1, arr2) && containsAll(arr2, arr1);
      const checkData = sameMembers(rowsValuesByFilter, filter.value);
      return { [filter.name]: checkData };
    });
  };

  const processRows = useCallback(
    (filter) => {
      if (exploitationContext?.forecasts) {
        if (filter) {
          let filteredRows = rows;
          const checkedData = checkedFiltersRows();
          if (checkedData.some((data) => Object.values(data)[0] === false)) {
            const otherFiltersNotEmpty = filters.filter(
              (eachFilter) => eachFilter.name !== filter.name && eachFilter.value.length
            );
            if (!filter.isParent) {
              if (filter.childNumber > 1) {
                const parentFiltersValues = otherFiltersNotEmpty
                  .filter((otherFilter) => otherFilter.isChild || otherFilter.isParent)
                  .map((otherFilter) => ({
                    dataKey: otherFilter.dataKey,
                    value: otherFilter.value,
                  }));

                if (otherFiltersNotEmpty.length) {
                  const filteredRowsArrays = parentFiltersValues.map((parentFilterValues) => {
                    return processedRows.filter((processedRow) =>
                      parentFilterValues.value.includes(processedRow[parentFilterValues.dataKey])
                    );
                  });
                  const [firstFilter, secondFilter, thirdFilter] = filteredRowsArrays;

                  filteredRows = firstFilter.filter((firstFilterRow) =>
                    secondFilter.some(
                      (secondFilterRow) => secondFilterRow.parcel === firstFilterRow.parcel
                    )
                  );
                  if (thirdFilter) {
                    filteredRows = thirdFilter.filter((thirdFilterRow) =>
                      filteredRows.some(
                        (filteredRow) => filteredRow.parcel === thirdFilterRow.parcel
                      )
                    );
                  }
                }
              } else if (otherFiltersNotEmpty.length) {
                filteredRows = processedRows.filter((processedRow) => {
                  const parentFilterValues = otherFiltersNotEmpty.find(
                    (otherFilter) => otherFilter.isParent === true
                  )?.value;
                  if (!parentFilterValues) {
                    return;
                  }

                  const parentDataKey = filters.find((filter) => filter.isParent).dataKey;
                  return (
                    processedRow[parentDataKey].toString() ===
                    parentFilterValues.find(
                      (parentFilterValue) =>
                        parentFilterValue === processedRow[parentDataKey].toString()
                    )
                  );
                });
              }
            } else {
              filteredRows = processedRows;
            }
          }
          filteredRows = filteredRows.filter(
            (row) =>
              row[filter.dataKey].toString() ===
              filter.value.find(
                (filteredParcel) => filteredParcel === row[filter.dataKey].toString()
              )
          );
          filter.isParent && setRowsFromUpdatedParent(filteredRows);
          filter.isChild &&
            setRowsFromLastUpdatedFilter((prev) => ({
              ...prev,
              [filter.childNumber]: filteredRows,
            }));
          setRows(filteredRows);
          return;
        } else {
          setRows(processedRows);
        }
      }
    },
    [exploitationContext, rows, filters]
  );

  const processRowUpdate = useCallback(
    async (updatedRow, previousRow) => {
      if (
        previousRow.forecast !== updatedRow.forecast &&
        updatedRow.forecast !== 'Aucun forecast'
      ) {
        const updatedForecastCultureId = exploitationContext?.cultures?.find(
          (culture) => culture.name === updatedRow.forecast
        )?.id;

        const data = {
          parcel: updatedRow.parcelId,
          culture: updatedForecastCultureId,
        };

        const isForecastSameAsOriginal = exploitationContext?.forecasts.some((forecast) => {
          return (
            forecast.parcel.id === updatedRow.parcelId &&
            forecast.culture.id === updatedForecastCultureId
          );
        });

        let existInStackedRequest = false;

        stackedRequestsRef.current.forEach((request) => {
          if (request?.rowId === updatedRow.id) {
            existInStackedRequest = true;
          }
        });

        if (!existInStackedRequest) {
          stackedRequestsRef.current.push({
            ...data,
            rowId: updatedRow.id,
            forecast: true,
          });
        } else if (!isForecastSameAsOriginal) {
          stackedRequestsRef.current = stackedRequestsRef.current.map((request) => {
            if (request?.rowId === updatedRow.id) {
              return {
                ...request,
                ...data,
              };
            } else {
              return request;
            }
          });
        } else {
          stackedRequestsRef.current = stackedRequestsRef.current.filter(
            (request) => request?.rowId !== updatedRow.id
          );
        }
        setIsAllowedSubmit(stackedRequestsRef.current.length ? true : false);

        return updatedRow;
      }
      return previousRow;
    },
    [exploitationContext, exploit]
  );
  const handleComparatorSubmit = useCallback(() => {
    try {
      if (rows.every((row) => row.forecast === 'Aucun forecast')) {
        return toast.error(displayErrorMessage('ERR_MISSING_DATA'));
      }
      if (!selectedRows.length) {
        return toast.error(displayErrorMessage('ERR_NO_DATA_SELECTED'));
      }
      navigate('/exploitation/comparator/result', {
        state: {
          selectedParcels:
            selectedRows.length === processedRows.map((row) => row.id).length ? [] : selectedRows,
        },
      });
    } catch (error) {
      toast.error(displayErrorMessage('ERR_GET_FORECAST'));
    }
  }, [selectedRows]);

  const handleChange = (event, _, filter) => {
    const {
      target: { value },
    } = event;
    if (value[value.length - 1] === 'all') {
      setFilters((prevFilters) => {
        if (isIndeterminate(filter) || value[0] === 'all') {
          return prevFilters.map((prevFilter) =>
            filter.name === prevFilter.name
              ? {
                  ...prevFilter,
                  value: prevFilter.dataKey
                    ? removeDuplicates(
                        processedRows.map((row) => row[prevFilter.dataKey].toString())
                      )
                    : removeDuplicates(processedRows.map((row) => row.parcel)),
                  changed: true,
                }
              : {
                  ...prevFilter,
                  changed: false,
                }
          );
        } else {
          return prevFilters.map((prevFilter) =>
            filter.name === prevFilter.name
              ? {
                  ...prevFilter,
                  value: [],
                  changed: true,
                }
              : {
                  ...prevFilter,
                  changed: false,
                }
          );
        }
      });
      return;
    }
    setFilters((prevFilters) =>
      prevFilters.map((prevFilter) =>
        filter.name === prevFilter.name
          ? {
              ...prevFilter,
              value: typeof value === 'string' ? value.split(',') : value,
              changed: true,
            }
          : { ...prevFilter, changed: false }
      )
    );
  };

  const handleParentChange = (event) => {
    const {
      target: { value },
    } = event;
    setParentFilter(typeof value === 'string' ? value.split(',') : value);

    setFilters((prevFilters) => {
      return prevFilters.map((filter) => {
        if (!value.includes(filter.dataKey)) {
          return {
            ...filter,
            visible: false,
            isParent: false,
          };
        }
        return filter.dataKey === value
          ? {
              ...filter,
              visible: true,
              isParent: true,
            }
          : filter;
      });
    });
  };

  const removeParentFilter = () => {
    setParentFilter('');
    setFilters(defaultFilters);
  };

  const removeChildFilter = (filter, index) => {
    const resetFilterValues = removeDuplicates(
      processedRows.map((processedRow) => processedRow[filter.dataKey])
    );
    if (index !== 1) {
      setFilters((prevFilters) =>
        prevFilters.map((prevFilter) =>
          filter.name === prevFilter.name
            ? {
                ...prevFilter,
                value: resetFilterValues,
                changed: false,
                isChild: false,
                childNumber: null,
              }
            : prevFilter.childNumber === index - 1
            ? { ...prevFilter, changed: true }
            : { ...prevFilter, changed: false }
        )
      );
      setotherChildsChoice((prev) =>
        index === 2
          ? {
              2: '',
              3: '',
            }
          : {
              ...prev,
              3: '',
            }
      );

      setToggleOtherChildren((prev) =>
        index === 2
          ? {
              2: false,
              3: false,
            }
          : {
              ...prev,
              3: false,
            }
      );

      return;
    }

    setFilters((prevFilters) =>
      prevFilters.map((prevFilter) =>
        filter.name === prevFilter.name
          ? {
              ...prevFilter,
              value: resetFilterValues,
              changed: false,
              isChild: false,
              childNumber: null,
            }
          : prevFilter.isParent
          ? { ...prevFilter, changed: true }
          : { ...prevFilter, changed: false }
      )
    );
    setFirstChildChoice('');
    setToggleFirstChild(false);
  };

  const handleChildrenChange = (event) => {
    const {
      target: { value },
    } = event;
    setFirstChildChoice(typeof value === 'string' ? value.split(',') : value);

    setFilters((prevFilters) => {
      return prevFilters.map((filter) => {
        if (!value.includes(filter.dataKey)) {
          return {
            ...filter,
            visible: false,
            isChild: false,
            childNumber: null,
          };
        }
        return filter.dataKey === value
          ? {
              ...filter,
              visible: true,
              isChild: true,
              childNumber: 1,
            }
          : filter;
      });
    });
  };

  const handleOtherChildrenChange = (event, index) => {
    const {
      target: { value },
    } = event;

    setotherChildsChoice((prev) => ({
      ...prev,
      [index]: typeof value === 'string' ? value.split(',') : value,
    }));

    setFilters((prevFilters) => {
      return prevFilters.map((filter) => {
        if (!value.includes(filter.dataKey)) {
          return {
            ...filter,
            visible: false,
            childNumber: filter.childNumber ? filter.childNumber : null,
          };
        }
        return filter.dataKey === value
          ? {
              ...filter,
              visible: true,
              isChild: true,
              childNumber: index,
            }
          : filter;
      });
    });
  };

  const checkRows = (filter, parcel) => {
    return rows.map((row) => {
      if (row[filter.dataKey] === isParcelIrrigable(filter, parcel)) {
        return true;
      }
      if (row[filter.dataKey] === parcel?.[filter.dataKey]?.name) {
        return true;
      }
    });
  };

  const isParcelIrrigable = (filter, parcel) =>
    parcel?.[filter.dataKey].toString() === 'true' ? 'oui' : 'non';

  const isChecked = (filter, parcel) => {
    if (!filter.dataKey) {
      return filter.value.includes(parcel.name) && rowsParcels.includes(parcel.name);
    }
    if (filter.dataKey === 'isIrrigable') {
      return (
        filter.value.includes(isParcelIrrigable(filter, parcel)) &&
        checkRows(filter, parcel).includes(true)
      );
    }
    return (
      filter.value.includes(parcel?.[filter.dataKey]?.name) &&
      checkRows(filter, parcel).includes(true)
    );
  };

  const isAllSelected = (filter) => {
    if (!filter.dataKey) {
      return (
        filter.value.length > 0 &&
        filter.value.every((filterValue) => rowsParcels.includes(filterValue))
      );
    }
    const valuesFromParentRows = rowsFromUpdatedParent?.map((parentRow) =>
      parentRow[filter.dataKey]?.toString()
    );

    return (
      filter.value.length > 0 &&
      valuesFromParentRows.every((filterFromParentValue) =>
        filter.value.includes(filterFromParentValue.toString())
      )
    );
  };

  const isIndeterminate = (filter) => {
    if (!filter.dataKey) {
      return (
        filter.value.length > 0 &&
        !filteredParcels(filter)
          .map((filteredValue) => filteredValue.name)
          .every((value) => filter.value.includes(value))
      );
    }
    if (filter.dataKey === 'isIrrigable') {
      return (
        filter.value.length > 0 &&
        !filteredParcels(filter)
          .map((filteredValue) =>
            filteredValue[filter.dataKey]?.toString() === 'true' ? 'oui' : 'non'
          )
          .every((value) => filter.value.includes(value))
      );
    }

    return (
      filter.value.length > 0 &&
      !filteredParcels(filter)
        .map((filteredValue) => filteredValue[filter.dataKey]?.name)
        .every((value) => filter.value.includes(value))
    );
  };

  const isDisabled = (filter, parcel) => {
    if (!filter.isParent) {
      let valuesFromParentRows = processedRows.map((parentRow) => parentRow[filter.dataKey]);
      if (rowsFromUpdatedParent.length) {
        valuesFromParentRows = rowsFromUpdatedParent.map((parentRow) => parentRow[filter.dataKey]);
      }
      if (filter.childNumber > 1) {
        if (rowsFromLastUpdatedFilter) {
          if (rowsFromLastUpdatedFilter[filter.childNumber - 1]) {
            valuesFromParentRows = rowsFromLastUpdatedFilter?.[filter.childNumber - 1]?.map(
              (parentRow) => parentRow[filter.dataKey]
            );
          }
          if (
            !rowsFromLastUpdatedFilter[filter.childNumber - 1] &&
            rowsFromLastUpdatedFilter[filter.childNumber - 2]
          ) {
            valuesFromParentRows = rowsFromLastUpdatedFilter?.[filter.childNumber - 2]?.map(
              (parentRow) => parentRow[filter.dataKey]
            );
          }
        }
      }
      if (filter.dataKey === 'isIrrigable') {
        if (!valuesFromParentRows.includes(isParcelIrrigable(filter, parcel))) {
          return true;
        }
      } else {
        if (!valuesFromParentRows.includes(parcel[filter.dataKey]?.name)) {
          return true;
        }
      }
      return false;
    }
    return false;
  };

  const renderFilters = (filter) => {
    return filteredParcels(filter).map((parcel) => (
      <MenuItem
        key={parcel.id}
        value={
          filter.dataKey
            ? filter.dataKey === 'isIrrigable'
              ? parcel?.[filter.dataKey].toString() === 'true'
                ? 'oui'
                : 'non'
              : parcel?.[filter.dataKey]?.name
            : parcel.name
        }
        disabled={isDisabled(filter, parcel)}
      >
        <Checkbox checked={isChecked(filter, parcel)} />
        <ListItemText
          primary={
            filter.dataKey
              ? filter.dataKey === 'isIrrigable'
                ? parcel?.[filter.dataKey].toString() === 'true'
                  ? 'oui'
                  : 'non'
                : parcel?.[filter.dataKey]?.name
              : parcel.name
          }
        />
      </MenuItem>
    ));
  };

  const filteredParcels = (filter) =>
    exploitationContext?.parcels.filter(
      (value, index, self) =>
        index ===
        self.findIndex((other) =>
          !filter.dataKey
            ? other.name === value?.name
            : filter.dataKey === 'isIrrigable'
            ? other?.[filter.dataKey] === value?.[filter.dataKey]
            : other?.[filter.dataKey]?.name === value?.[filter.dataKey]?.name
        )
    );

  const disableFilter = (childNumber) => {
    if (childNumber) {
      return filters
        .filter((filter) => filter.childNumber)
        .map((filter) => filter.childNumber)
        .some((filterChildNumber) => filterChildNumber > childNumber);
    }
    return false;
  };

  const disableParentFilter = filters?.some((filter) => filter.isChild === true);

  const handleReset = () => {
    setFilters(null);
    setParentFilter('');
    setToggleFirstChild(false);
    setToggleOtherChildren({
      2: false,
      3: false,
    });
    setotherChildsChoice({
      2: '',
      3: '',
    });
    setFirstChildChoice('');
    setRowsFromUpdatedParent([]);
    setRowsFromLastUpdatedFilter(null);
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      left: -10,
      top: -20,
      position: 'absolute',
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      backgroundColor: '#e69636',
    },
  }));

  const badgeContent = filters
    ?.filter((filter) => filter.isChild || filter.isParent)
    .length.toString();

  const noRows = useMemo(() => rows.length === 0, [rows]);

  const handleFilterDisplayValues = (filter) => {
    if (filter.childNumber === 1 && rowsFromLastUpdatedFilter) {
      if (
        rowsFromLastUpdatedFilter[filter.childNumber]?.length &&
        rowsFromLastUpdatedFilter[filter.childNumber + 1]?.length
      ) {
        return removeDuplicates(
          rowsFromLastUpdatedFilter[filter.childNumber]?.map((rowValue) => rowValue[filter.dataKey])
        );
      }
    }
    if (filter.childNumber === 1 && filter.changed === false) {
      if (rowsFromLastUpdatedFilter && rowsFromLastUpdatedFilter[filter.childNumber]?.length) {
        return removeDuplicates(
          rowsFromLastUpdatedFilter[filter.childNumber]?.map((rowValue) => rowValue[filter.dataKey])
        );
      }
      if (rowsFromUpdatedParent.length) {
        return removeDuplicates(rowsFromUpdatedParent?.map((rowValue) => rowValue[filter.dataKey]));
      }
      return filter.value;
    }
    if (filter.childNumber === 2 && filter.changed === false) {
      if (rowsFromLastUpdatedFilter && rowsFromLastUpdatedFilter[filter.childNumber]) {
        return removeDuplicates(
          rowsFromLastUpdatedFilter[filter.childNumber]?.map((rowValue) => rowValue[filter.dataKey])
        );
      }
      if (rowsFromLastUpdatedFilter && rowsFromLastUpdatedFilter[filter.childNumber - 1]) {
        return removeDuplicates(
          rowsFromLastUpdatedFilter[filter.childNumber - 1]?.map(
            (rowValue) => rowValue[filter.dataKey]
          )
        );
      }
      if (rowsFromUpdatedParent.length) {
        return removeDuplicates(rowsFromUpdatedParent?.map((rowValue) => rowValue[filter.dataKey]));
      }
      return filter.value;
    }

    return removeDuplicates(rows.map((rowValue) => rowValue[filter.dataKey]));
  };

  const callAPI = async () => {
    if (!stackedRequestsRef.current.length) {
      return;
    }

    const forecastParcelsCulturesToUpdate = stackedRequestsRef.current.map((forecast) => {
      return {
        parcel: forecast.parcel,
        culture: forecast.culture,
      };
    });

    const body = {
      parcels: forecastParcelsCulturesToUpdate,
      year: currentYear + 1,
    };

    try {
      const result = await addForecast(exploit.id, body);
      const { data } = result.data;
      updateExploitationContext(setExploitationContext, data);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ??
          'Une erreur est survenue : impossible de mettre à jour le prévisionnel.'
      );
    }
    stackedRequestsRef.current = [];
    setIsAllowedSubmit(false);
  };

  return (
    <div className="section">
      <div className="forecast_container">
        <div className="contain-header-forecast">
          <h1 className="title_section title_section_forecast">Informations parcelles</h1>
          <div className="boutons_section_forecast">
            <ThemeProvider theme={customButtonTheme}>
              <MButton disabled={!isAllowedSubmit} onClick={callAPI}>
                Enregistrer le prévisionnel <SaveIcon sx={{ width: '18px' }} />
              </MButton>
            </ThemeProvider>
            <div className="button_container_forecast">
              <Button text="Lancer le comparateur" onClick={handleComparatorSubmit} />
            </div>
          </div>
        </div>
        <div className="filter_container">
          <div
            className="button_filters"
            onClick={() => {
              setToggleFilter((isOpen) => !isOpen);
            }}
          >
            <span>{toggleFilter ? 'Masquer' : 'Afficher'} les filtres</span>
            <Settings sx={{ color: '#e69636' }} />
            {!toggleFilter && (
              <StyledBadge badgeContent={badgeContent} color="primary"></StyledBadge>
            )}
          </div>
          {toggleFilter && (
            <>
              <ThemeProvider theme={themeFilters}>
                {!parentFilter.length && (
                  <FormControl variant="filled">
                    <InputLabel id="columnsLabel">Filtre Parent</InputLabel>
                    <Select
                      labelId="columnsLabel"
                      id="columnSelect"
                      value={parentFilter}
                      onChange={handleParentChange}
                      input={<OutlinedInput label="Tag" />}
                      disabled={filters && disableParentFilter}
                    >
                      {defaultFilters.map((filter) => (
                        <MenuItem
                          key={filter.name}
                          value={filter.dataKey ? filter.dataKey : 'parcel'}
                        >
                          {filter.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {filters &&
                  filters.map(
                    (filter, index) =>
                      filter.isParent && (
                        <Fragment key={filter.name}>
                          <FormControl variant="filled" disabled={filters && disableParentFilter}>
                            <InputLabel id="filterLabel" sx={{ top: '-7px' }}>
                              {filter.name}
                            </InputLabel>
                            <Select
                              labelId="filterLabel"
                              id="filter"
                              multiple
                              value={filter.value}
                              onChange={(e) => handleChange(e, index, filter)}
                              input={<OutlinedInput label="Tag" />}
                              renderValue={(selected) => selected.join(', ')}
                            >
                              <MenuItem value="all">
                                <ListItemIcon>
                                  <Checkbox
                                    checked={isAllSelected(filter)}
                                    indeterminate={isIndeterminate(filter)}
                                    sx={{
                                      color: '#277134 !important',
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText primary="Tout sélectionner" />
                              </MenuItem>
                              {renderFilters(filter)}
                            </Select>
                            {!toggleFirstChild && (
                              <CloseIcon
                                className="close-filter"
                                onClick={() => removeParentFilter()}
                              />
                            )}
                          </FormControl>
                          {!toggleFirstChild
                            ? !noRows && (
                                <Button
                                  text="Ajouter un filtre"
                                  onClick={() => {
                                    setToggleFirstChild(true);
                                  }}
                                />
                              )
                            : !firstChildChoice.length && (
                                <FormControl variant="filled">
                                  <InputLabel id="columnsLabel" sx={{ top: '-8px' }}>
                                    Filtre Enfant
                                  </InputLabel>
                                  <Select
                                    labelId="columnsLabel"
                                    id="columnSelect"
                                    value={firstChildChoice}
                                    onChange={handleChildrenChange}
                                    input={<OutlinedInput label="Tag" />}
                                    disabled={disableFilter(1)}
                                  >
                                    {filters
                                      .filter((filter) => !filter.isParent)
                                      .map((filter) => (
                                        <MenuItem
                                          key={filter.name}
                                          value={filter.dataKey ? filter.dataKey : 'parcel'}
                                        >
                                          {filter.name}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                              )}
                        </Fragment>
                      )
                  )}
                {filters &&
                  filters
                    .filter((filter) => filter.isChild)
                    .sort((a, b) => a.childNumber - b.childNumber)
                    .map((filter, index) => (
                      <Fragment key={filter.name}>
                        <FormControl disabled={disableFilter(index + 1)} variant="filled">
                          <InputLabel id="filterLabel" sx={{ top: '-8px' }}>
                            {filter.name}
                          </InputLabel>
                          <Select
                            labelId="filterLabel"
                            id="filter"
                            multiple
                            value={filter.value}
                            onChange={(e) => handleChange(e, index + 1, filter)}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={() => handleFilterDisplayValues(filter).join(', ')}
                          >
                            <MenuItem value="all">
                              <ListItemIcon>
                                <Checkbox
                                  checked={isAllSelected(filter)}
                                  indeterminate={isIndeterminate(filter)}
                                  sx={{
                                    color: '#277134 !important',
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="Tout sélectionner" />
                            </MenuItem>
                            {renderFilters(filter)}
                          </Select>
                          {!toggleOtherChildren[index + 2] && (
                            <CloseIcon
                              className="close-filter"
                              onClick={() => removeChildFilter(filter, index + 1)}
                            />
                          )}
                        </FormControl>
                        {toggleOtherChildren[index + 2]
                          ? !otherChildsChoice[index + 2].length && (
                              <FormControl variant="filled">
                                <InputLabel id="columnsLabel" sx={{ top: '-8px' }}>
                                  Filtre Enfant {index + 2}
                                </InputLabel>
                                <Select
                                  labelId="columnsLabel"
                                  id="columnSelect"
                                  value={otherChildsChoice[index + 2]}
                                  onChange={(e) => handleOtherChildrenChange(e, index + 2)}
                                  input={<OutlinedInput label="Tag" />}
                                  disabled={disableFilter(index + 2)}
                                >
                                  {filters
                                    .filter(
                                      (filter) =>
                                        !filter.isParent &&
                                        filter.childNumber !== index + 1 &&
                                        filter.childNumber !== 1
                                    )
                                    .map((filter) => (
                                      <MenuItem
                                        key={filter.name}
                                        value={filter.dataKey ? filter.dataKey : 'parcel'}
                                      >
                                        {filter.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            )
                          : filter.childNumber !== 3 &&
                            !noRows && (
                              <Button
                                text="Ajouter un filtre"
                                onClick={() =>
                                  setToggleOtherChildren((prev) => ({
                                    ...prev,
                                    [index + 2]: true,
                                  }))
                                }
                              />
                            )}
                      </Fragment>
                    ))}
                <button
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Réinitialiser
                </button>
              </ThemeProvider>
            </>
          )}
          <p className="parcel-hidden">
            <span>
              {countFilteredRows === 0 || countFilteredRows === 1
                ? `${countFilteredRows} parcelle masquée`
                : `${countFilteredRows} parcelles masquées`}
            </span>
          </p>
        </div>

        <div className="datagrid_container">
          <CustomDataGrid
            rows={rows}
            columns={columns}
            processRowUpdate={processRowUpdate}
            loading={dataGridLoading}
            onRowSelectionModelChange={setSelectedRows}
            rowSelectionModel={selectedRows}
            sortModel={sortModel}
            setSortModel={setSortModel}
          />
        </div>
      </div>
    </div>
  );
};

export default Forecast;
