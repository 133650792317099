// REACT
import React, { useState, useContext, SyntheticEvent } from 'react';

// YARN
import { toast } from 'react-toastify';
import useAuth from 'providers/Auth/useAuth';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
  ThemeProvider,
} from '@mui/material';
import { Clear } from '@mui/icons-material';

// SERVICES
import { checkTypeStringRegexDatagrid, displayMessage } from 'utils/tools_functions';
import { inputRequired } from 'components/generics/Validators/validator';
import { validateFormData } from 'components/generics/Validators/form.validate';
import { addSecteur, updateSecteur } from 'services/API/Cooperative';
import { LoadingContext } from 'utils/context';
import { customDialogCommonTheme } from 'assets/styles/themes/Dialog/generic_dialog_add_culture';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';

// Interface
import { GridValidRowModel } from '@mui/x-data-grid-pro';
import { IFormErrorObjects, IFormErrors } from 'components/generics/Interface/Commons/IErrorForm';

type FormDataType = {
  name: string;
};

const DEFAULT_FORM: FormDataType = { name: '' };

type AddEditSectorModalParams = {
  setEditData: React.Dispatch<React.SetStateAction<GridValidRowModel | null>>;
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmitSuccess: () => void;
  edit?: GridValidRowModel | null;
  isEdit: boolean;
};

const AddEditSectorModal = ({
  setEditData,
  modal,
  setModal,
  onSubmitSuccess,
  edit,
  isEdit,
}: AddEditSectorModalParams): React.JSX.Element => {
  const { user } = useAuth();
  const [formData, setFormData] = useState<GridValidRowModel>(edit || DEFAULT_FORM);
  const updateForm = (key: keyof FormDataType, value: string): void =>
    setFormData({ ...formData, [key]: value });
  const [loading, setLoading] = useState<boolean>(false);
  const setTextLoader = useContext(LoadingContext);
  const [errorsFormDefault, setErrorsFormDefault] = useState<IFormErrors>({
    formError: false,
    name: {
      message: '',
      validator: [inputRequired],
    },
  });

  const onSubmit = async (e: SyntheticEvent) => {
    errorsFormDefault.formError = false;
    setErrorsFormDefault({ ...validateFormData(formData, errorsFormDefault) });
    if (e) e.preventDefault();
    if (!errorsFormDefault.formError) {
      setModal(false);
      setLoading(true);
      if (edit) {
        setTextLoader.customText = displayMessage('UPDATE_SECTOR_LOADER');
        try {
          await updateSecteur(formData);
          toast.success(`Secteur ${formData.name} mis à jour`);
          setFormData(DEFAULT_FORM);
          onSubmitSuccess();
        } catch (error) {
          if (error instanceof Error) {
            toast.error(error.message);
          }
        }
      } else {
        setTextLoader.customText = displayMessage('CREATE_SECTOR_LOADER');
        let cooperativeId = -1;
        if (user?.role === 'SUPER_ADMIN')
          cooperativeId = Number(localStorage.getItem('cooperative'));
        try {
          await addSecteur(formData, cooperativeId);
          toast.success(`Secteur ${formData.name} créé`);
          setFormData(DEFAULT_FORM);
          onSubmitSuccess();
        } catch (error) {
          if (error instanceof Error) {
            toast.error(error.message);
          }
        }
      }
    }
    setLoading(false);
  };

  return (
    <ThemeProvider theme={customDialogCommonTheme}>
      <Dialog
        maxWidth="md"
        fullWidth
        open={modal}
        onClose={() => {
          setModal(false), setEditData(null);
        }}
      >
        <div className="add_culture_container">
          <Box>
            <DialogTitle>{!isEdit ? 'Ajouter un secteur' : 'Modifier un secteur'}</DialogTitle>
            <Clear
              className="close-icon"
              onClick={() => {
                setModal(false), setEditData(null);
              }}
            />
          </Box>
          <DialogContent>
            <Box id="custom-box-content">
              <Box id="add_name">
                <InputLabel id="nameLabel" htmlFor="name">
                  Nom<span className="label_asterisk">*</span>
                </InputLabel>
                <TextField
                  id="name"
                  value={formData.name}
                  onChange={(e) => {
                    updateForm('name', checkTypeStringRegexDatagrid(e.target.value));
                  }}
                  placeholder="Entrez un nom"
                  disabled={loading}
                  className={
                    (errorsFormDefault['name'] as IFormErrorObjects)?.message ? 'MuiErrorForm' : ''
                  }
                  error={(errorsFormDefault['name'] as IFormErrorObjects)?.message ? true : false}
                  helperText={(errorsFormDefault['name'] as IFormErrorObjects)?.message}
                />
              </Box>
              <ThemeProvider theme={customButtonTheme}>
                <Box>
                  <Button onClick={onSubmit} disabled={loading}>
                    Enregistrer
                  </Button>
                </Box>
              </ThemeProvider>
            </Box>
          </DialogContent>
        </div>
      </Dialog>
    </ThemeProvider>
  );
};

export default AddEditSectorModal;
