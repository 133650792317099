import React from 'react';

import ExploitationIcon from 'components/icons/Exploitation';
import { ArableContext, ExploitationContext } from 'utils/context';
import { useContext } from 'react';

const ExploitationHeader = () => {
  const { arableSurface } = useContext(ArableContext);
  const { exploitationContext } = useContext(ExploitationContext);

  return (
    <div>
      {exploitationContext && (
        <span className="title_spacing">
          <span className="subtitle_spacing">
            <ExploitationIcon width={30.0} height={30} fill="$secondary" />
          </span>
          <span className="green_text subtitle_spacing">{exploitationContext?.name}</span>
          <span className="subtitle_spacing">
            {exploitationContext?.code ? ' - ' + exploitationContext?.code : ''}
          </span>
          <span className="subtitle_spacing">
            {arableSurface ? (
              <div>
                {' '}
                - Surface arable: <b>{arableSurface.toFixed(2)} ha</b>
              </div>
            ) : (
              ''
            )}
          </span>
        </span>
      )}
    </div>
  );
};

export default ExploitationHeader;
