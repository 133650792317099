import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { displayErrorMessage } from 'utils/tools_functions';
import JWTService from 'services/JWT';
import useAuth from 'providers/Auth/useAuth';

import InputLabel from 'components/generics/InputLabel';
import Button from 'components/generics/Button';

const DEFAULT_FORM = { username: '', password: '' };

const LoginForm = () => {
  const [formData, setFormData] = useState(DEFAULT_FORM);
  const [loading, setLoading] = useState(false);
  const { setUser } = useAuth();

  const onSubmit = (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    JWTService.signInWithCredentials(formData.username, formData.password, () => setLoading(false))
      .then((response) => {
        setLoading(false);
        toast.success('Connecté');
        window.userGuiding.identify(response?.data?.data?.id)
        setUser(response.data.data);
      })
      .catch((err) => {
        setLoading(false);
        if (err.code && err.code === 401) {
          if (err.message === 'Cooperative suspendue, merci de contacter un administrateur.') {
            toast.error(displayErrorMessage('ERR_INACTIVE_COOP'));
          } else {
            toast.error(displayErrorMessage('ERR_INCORRECT_CREDENTIAL'));
          }
          updateForm('password', '');
        } else {
          toast.error(displayErrorMessage('ERR_UNEXPECTED'));
          setFormData(DEFAULT_FORM);
        }
      });
  };

  const updateForm = (key, value) => setFormData({ ...formData, [key]: value });

  return (
    <>
      <form onSubmit={onSubmit}>
        <InputLabel
          name="username"
          value={formData.username}
          onChange={(e) => {
            updateForm('username', e);
          }}
          placeholder="Identifiant"
          label="IDENTIFIANT"
          defaultStyle="login"
          disabled={loading}
        />
        <InputLabel
          name="password"
          value={formData.password}
          type="password"
          onChange={(e) => {
            updateForm('password', e);
          }}
          placeholder="Mot de passe"
          defaultStyle="login"
          label="MOT DE PASSE"
          disabled={loading}
        />
        <Link onClick={() => localStorage.setItem('action_password', 'reset')} to="/forgotPassword">
          Mot de passe oublié ?
        </Link>
        <Button defaultStyle="d-block" type="submit" text="Connexion" disabled={loading} />
      </form>
    </>
  );
};

export default LoginForm;
