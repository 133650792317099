//REACT
import { useCallback, useState } from 'react';

//SERVICES
import { getPercentage } from 'utils/tools_functions';
import { brushValueList } from './CustomRotateMatricesDataGridUtils';

//YARN
import {
  GridCallbackDetails,
  GridCellEditStopParams,
  GridRenderCellParams,
  GridRowModes,
  GridRowModesModel,
  MuiEvent,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';

//INTERFACES
import { IStackedRequestMatrice } from 'components/generics/Interface/RotationMatrices/IStackedRequestMatrice';
import { IFormErrorsMatrices } from 'components/generics/Interface/Commons/IErrorForm';

type useDataGridMatricesCellBehaviourParams = {
  stackedRequestsRef: React.MutableRefObject<IStackedRequestMatrice[]>;
  errorsEffectFormDefault: IFormErrorsMatrices;
  setErrorsEffectFormDefault: React.Dispatch<React.SetStateAction<IFormErrorsMatrices>>;
  currentRotationLevel?: string;
  validateDatagridMatrix: Function;
};

type rowModesModelType = {
  [key: string]: {
    fieldToFocus: string;
    fromScrollBar: boolean;
    fromEscapeKeyDown?: boolean;
    mode: string;
  };
};

const useDataGridMatricesCellBehaviour = ({
  stackedRequestsRef,
  errorsEffectFormDefault,
  setErrorsEffectFormDefault,
  currentRotationLevel,
  validateDatagridMatrix,
}: useDataGridMatricesCellBehaviourParams) => {
  const [columnHighlight, setColumnHighlight] = useState<boolean | number>(false);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const getFramingValueColors = (
    value: number,
    brushValueList: Array<{ number: number; color: string }>
  ) => {
    const brushNumberList = brushValueList.map(({ number }) => number);
    let superiorNumber = Math.max(...brushNumberList);
    let inferiorNumber = Math.min(...brushNumberList);

    if (value < inferiorNumber) {
      return null;
    }

    const findColor = (num: number) => brushValueList?.find(({ number }) => number === num)?.color;

    if (value > superiorNumber) {
      const colorValue = findColor(superiorNumber);
      return {
        superiorValue: { color: colorValue, percentage: '100' },
        inferiorValue: { color: colorValue },
      };
    }

    if (brushNumberList.includes(value)) {
      const colorValue = findColor(value);
      return {
        superiorValue: { color: colorValue, percentage: '100' },
        inferiorValue: { color: colorValue },
      };
    }

    brushNumberList.forEach((number) => {
      if (number >= value && number < superiorNumber) {
        superiorNumber = number;
      }
      if (number <= value && number > inferiorNumber) {
        inferiorNumber = number;
      }
    });

    let superiorValue = {
      color: brushValueList?.find((value) => value.number === superiorNumber)?.color,
      percentage: getPercentage(value, superiorNumber),
    };
    let inferiorValue = {
      color: brushValueList?.find((value) => value.number === inferiorNumber)?.color,
    };

    return { superiorValue, inferiorValue };
  };

  const renderCell = (params: GridRenderCellParams) => {
    const { id, field } = params;
    const apiRef = useGridApiContext();
    let columnIndex = apiRef.current.getColumnIndex(field, false);
    let hasBeenUpdated = false;
    const cssMixParams = getFramingValueColors(parseFloat(params.value), brushValueList);

    if (stackedRequestsRef?.current.length) {
      stackedRequestsRef?.current.forEach((request) => {
        if (
          request?.cellInformations?.rowId === id &&
          request?.cellInformations?.columnId === parseInt(field)
        ) {
          hasBeenUpdated = true;
        }
      });
    }

    return (
      <Box
        onMouseEnter={() => setColumnHighlight(columnIndex)}
        onMouseLeave={() => setColumnHighlight(false)}
        className={`cellBox ${hasBeenUpdated ? 'cellHasBeenUpdated' : ''} ${
          columnIndex === columnHighlight ? 'highlight' : ''
        }`}
      >
        <div>{params.value}</div>
        <span
          style={{
            padding: 4,
            backgroundColor: cssMixParams
              ? `color-mix(in srgb,${cssMixParams.superiorValue.color} ${
                  cssMixParams.superiorValue.percentage + '%'
                }, ${cssMixParams.inferiorValue.color})`
              : 'white',
          }}
        ></span>
      </Box>
    );
  };

  const handleRowModesModelChange = useCallback(
    (newModel: GridRowModesModel, details: GridCallbackDetails) => {
      setRowModesModel((prevRowModesModel) => {
        const updatedModel = { ...newModel };
        for (const key in newModel) {
          if (
            newModel[key].hasOwnProperty('fromEscapeKeyDown') &&
            (newModel as rowModesModelType)[key]?.fromEscapeKeyDown
          ) {
            updatedModel[key].mode === GridRowModes.View;

            return updatedModel;
          }
        }

        if (Object.keys(newModel).length > 1) {
          for (const key in newModel) {
            if (
              !newModel[key].hasOwnProperty('fieldToFocus') &&
              newModel[key].mode === GridRowModes.Edit
            ) {
              updatedModel[key].mode = GridRowModes.View;
            }
          }
        } else {
          for (const key in newModel) {
            if (newModel[key].mode === GridRowModes.View) {
              updatedModel[key].mode = GridRowModes.Edit;
            }
            if (newModel[key].hasOwnProperty('ignoreModifications')) {
              updatedModel[key].mode = GridRowModes.View;
            }
          }

          if (Object.keys(prevRowModesModel).length) {
            for (const key in prevRowModesModel) {
              if ((prevRowModesModel as rowModesModelType)[key]?.fromScrollBar) {
                updatedModel[key].mode = GridRowModes.Edit;
                (updatedModel as rowModesModelType)[key].fromScrollBar = false;
              }
              if ((prevRowModesModel as rowModesModelType)[key]?.fromScrollBar === false) {
                updatedModel[key].mode = GridRowModes.View;
              }
            }
          }
        }

        return updatedModel;
      });
    },
    []
  );

  const onCellEditStop = (params: GridCellEditStopParams, event: MuiEvent) => {
    const { id, field, value } = params;
    const target = (event as React.SyntheticEvent)?.target as HTMLElement;
    if (
      target?.classList &&
      Object.keys(target?.classList).length > 0 &&
      Array.from(target?.classList).includes('MuiDataGrid-virtualScroller')
    ) {
      event.defaultMuiPrevented = true;
      const newModel = {
        [params.id]: {
          mode: GridRowModes.Edit,
          fieldToFocus: params.field,
          fromScrollBar: true,
        },
      };
      handleRowModesModelChange(newModel, params);
    }
    if (params.reason === 'escapeKeyDown') {
      const formatedToUpdateObject = { effect: value };
      setErrorsEffectFormDefault({
        ...validateDatagridMatrix(
          formatedToUpdateObject,
          errorsEffectFormDefault,
          id,
          parseInt(field),
          currentRotationLevel
        ),
      });

      const newModel = {
        [params.id]: {
          mode: GridRowModes.View,
          fieldToFocus: params.field,
          fromScrollBar: false,
          fromEscapeKeyDown: true,
        },
      };
      handleRowModesModelChange(newModel, params);
    }
  };

  return { renderCell, onCellEditStop, rowModesModel, setRowModesModel, handleRowModesModelChange };
};

export default useDataGridMatricesCellBehaviour;
