const { createTheme } = require('@mui/material');

export const customDialogNitrogenTheme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            border: '3px solid #e69636 !important',
            borderRadius: '20px',
            textAlign: 'center',
            fontSize: '18px',
            color: '#e69636 !important',
          },
          '& .MuiDialog-paper .MuiBox-root': {
            justifyContent: 'center !important',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          },
          '& .MuiDialog-paper .MuiBox-root .MuiSvgIcon-root': {
            fontSize: '36px',
            color: '#277134 !important',
            position: 'absolute',
            right: '20px',
            cursor: 'pointer',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '20px',
          color: '#e69636',
          fontFamily: 'Rubik-Bold !important',
          color: '#277134 !important',
          userSelect: 'none',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          display: 'flex',
          alignItems: 'center',
          '& label': {
            padding: '10px',
            overflow: 'unset !important',
            maxWidth: '160px',
            whiteSpace: 'pre-wrap',
            fontFamily: 'Rubik-Medium !important',
            color: '#277134 !important',
            userSelect: 'none',
          },
          '& .MuiErrorForm .MuiInputBase-root:hover': {
            outline: '2px solid #d32f2f !important',
          },
          '& .MuiErrorForm .MuiOutlinedInput-root.Mui-focused': {
            outline: '2px solid #d32f2f !important',
          },
          '& .MuiOutlinedInput-root.Mui-focused': {
            outline: '2px solid #e69636',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center !important',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          height: '50px !important',
          border: '1px solid #e69636 !important',
          borderRadius: '4px !important',
          ':hover': {
            borderColor: '#e69636',
          },
          '& .MuiInputBase-root': {
            height: '50px !important',
            ':hover': {
              outline: '2px solid #e69636 !important',
            },
          },
          '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
            {
              borderColor: '#e69636 !important',
            },
          '&.MuiErrorForm': {
            border: '1px solid #d32f2f !important',
            borderColor: '#d32f2f !important',
          },
          '&.MuiErrorForm .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
              borderColor: '#d32f2f !important',
            },
          },
          '&.MuiErrorForm .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
            {
              borderColor: '#d32f2f !important',
            },
          '& .MuiOutlinedInput-root.Mui-focused': {
            borderColor: '#e69636 !important',
          },
          '& .MuiOutlinedInput-root.Mui-focused fieldset': {
            borderColor: '#e69636 !important',
          },
          '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
            {
              borderColor: '#e69636 !important',
            },
          '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline ': {
            borderWidth: '1px !important',
            border: 'unset !important',
          },
        },
      },
    },
  },
});
