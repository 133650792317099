import React from 'react';
import { Link, Navigate } from 'react-router-dom';

import useAuth from 'providers/Auth/useAuth';

import logo from 'assets/images/logo.png';
import './Login.style.scss';

import LoginForm from 'components/pages/Login/form';

const Login = () => {
  const { user } = useAuth();

  return user ? (
    <Navigate to="/" />
  ) : (
    <div data-testid="login-container" className="Login">
      <div className="form">
        <div className="logoContainer">
          <div className="recenterLogo logo m-auto d-block">
            <img className="resizeLogo" src={logo} alt="logo assolia" />
          </div>
        </div>
        <div className="container">
          <p data-testid="login-connection" className="text-center title">Connexion</p>
          <LoginForm />
        </div>
        <Link
          className="footer d-block text-center"
          onClick={() => localStorage.setItem('action_password', 'create')}
          to="/forgotPassword"
        >
          Première connexion ?
        </Link>
      </div>
      <div className="img" />
    </div>
  );
};

export default Login;
