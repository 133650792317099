import { GridActionsCellItem, GridRowModes } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { displayErrorMessage } from 'utils/tools_functions';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CancelIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { customDialogTheme } from 'assets/styles/themes/Dialog/generic_dialog_mui';
import { StyledTooltip } from './CustomGenericDataGridUtils';
import { removeGroupementParcel, removeParcel } from 'services/API/Exploitation';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckIcon from '@mui/icons-material/Check';

const useDataGridParcelsActions = (
  rows,
  refreshData,
  errorsFormDefault,
  setEditData,
  setModalGroup
) => {
  const [rowModesModel, setRowModesModel] = useState({});
  const [actionId, setActionId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteGroupingDialog, setOpenDeleteGroupingDialog] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(null);
  const [menuRow, setMenuRow] = useState(null);
  const open = Boolean(isMenuOpen);

  const handleSaveClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  const handleEditClick = (id) => () => {
    setRowModesModel((prevRowModesModel) => {
      if (Object.keys(prevRowModesModel).length === 0) {
        return { [id]: { mode: GridRowModes.Edit } };
      } else {
        let resetPrevRowModesModel = {};
        for (const key in prevRowModesModel) {
          if (prevRowModesModel.hasOwnProperty(key)) {
            resetPrevRowModesModel[key] = { mode: GridRowModes.View };
          }
        }
        return { ...resetPrevRowModesModel, [id]: { mode: GridRowModes.Edit, fromAction: true } };
      }
    });
  };

  const handleCancelClick = (id) => (field) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    errorsFormDefault.formError = false;

    for (const key in errorsFormDefault) {
      if (typeof errorsFormDefault[key] === 'object') {
        errorsFormDefault[key]['message'] = '';
      }
    }
  };

  const handleDeleteDialogOpen = (id) => {
    setActionId(id);
    setOpenDeleteDialog(true);
  };

  const handleGroupingMenu = (event, row) => {
    setMenuRow(row);
    setIsMenuOpen(event.currentTarget);
  };

  const handleCloseGroupingMenu = () => setIsMenuOpen(null);

  const handleDeletegroupingDialog = (id) => {
    setActionId(id);
    setIsMenuOpen(null);
    setOpenDeleteGroupingDialog(true);
  };

  const handleUpdategroupingDialog = (row) => {
    setIsMenuOpen(null);
    const [parentId] = row.parentId;
    const children = rows
      .filter((row) => row.parentId.length > 1 && row.parentId.includes(parentId))
      .flatMap((row) => row.id);
    const editData = {
      parent: parentId,
      children,
    };

    setEditData(editData);
    setModalGroup(true);
  };

  const getActions = ({ id, row }) => {
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
    const isParent = row.isParent;
    const isChild = row.parentId.length > 1;
    if (isInEditMode && !isChild) {
      return [
        <>
          <StyledTooltip placement="top" title="Valider">
            <GridActionsCellItem
              icon={<CheckIcon sx={{ paddingLeft: '2px !important', color: '#277134' }} />}
              label="Valider"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />
          </StyledTooltip>
          <StyledTooltip placement="top" title="Annuler">
            <GridActionsCellItem
              icon={<CancelIcon sx={{ paddingLeft: '2px !important' }} />}
              label="Annuler"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          </StyledTooltip>
        </>,
      ];
    }
    if (!isChild) {
      return [
        <>
          <StyledTooltip placement="top" title="Edition">
            <GridActionsCellItem
              icon={<EditIcon sx={{ paddingLeft: '2px !important' }} />}
              label="Edition"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />
          </StyledTooltip>

          <StyledTooltip placement="top" title="Supprimer">
            <GridActionsCellItem
              icon={<DeleteIcon sx={{ paddingLeft: '2px !important' }} />}
              label="Supprimer"
              onClick={() => handleDeleteDialogOpen(id)}
              color="inherit"
            />
          </StyledTooltip>
          {isParent && (
            <>
              <StyledTooltip placement="top" title="Gérer le regroupement">
                <GridActionsCellItem
                  icon={<MoreVertIcon sx={{ paddingLeft: '2px !important' }} />}
                  label="Gérer le regroupement"
                  onClick={(e) => handleGroupingMenu(e, row)}
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  color="inherit"
                />
              </StyledTooltip>
              <Menu
                id="basic-menu"
                anchorEl={isMenuOpen}
                open={open}
                onClose={handleCloseGroupingMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  className="menu-item menu-item__border"
                  onClick={() => {
                    handleUpdategroupingDialog(menuRow);
                  }}
                >
                  Modifier le regroupement
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  onClick={() => handleDeletegroupingDialog(menuRow?.id)}
                >
                  Supprimer le regroupement
                </MenuItem>
              </Menu>
            </>
          )}
        </>,
      ];
    } else {
      return [<></>];
    }
  };

  const handleDeleteClick = async () => {
    setOpenDeleteDialog(false);
    const parcelToDelete = rows.find((row) => row.id === actionId);
    try {
      await removeParcel(parcelToDelete.exploitationId, parcelToDelete.id);
      toast.success(`Parcelle ${parcelToDelete.parcel} supprimée`);
      refreshData();
    } catch (error) {
      toast.error(displayErrorMessage('ERR_DELETE_PARCEL'));
    }
  };

  const handleDeleteGroupingClick = async () => {
    setOpenDeleteGroupingDialog(false);
    const parentParcel = rows.find((row) => row.id === actionId);
    try {
      await removeGroupementParcel(parentParcel.exploitationId, parentParcel.id);
      toast.success(`Le regroupement a été supprimé`);
      refreshData();
    } catch (error) {
      toast.error(displayErrorMessage('ERR_DELETE_PARCEL_GROUP'));
    }
  };

  const renderDeleteDialog = () => {
    return (
      <ThemeProvider theme={customDialogTheme}>
        <Dialog maxWidth="xs" open={openDeleteDialog}>
          <DialogTitle>Attention</DialogTitle>
          <DialogContent
            dividers
          >{`Voulez-vous vraiment supprimer cette parcelle ?`}</DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClick}>
              Oui <DoneIcon />
            </Button>
            <Button onClick={() => setOpenDeleteDialog(false)}>
              Non <ClearIcon />
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  };

  const renderDeletegroupingDialog = () => {
    return (
      <ThemeProvider theme={customDialogTheme}>
        <Dialog maxWidth="sm" open={openDeleteGroupingDialog}>
          <DialogTitle>Attention</DialogTitle>
          <DialogContent
            dividers
          >{`Voulez-vous vraiment supprimer le regroupement ?`}</DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteGroupingClick}>
              Oui <DoneIcon />
            </Button>
            <Button onClick={() => setOpenDeleteGroupingDialog(false)}>
              Non <ClearIcon />
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  };

  return {
    rowModesModel,
    setRowModesModel,
    getActions,
    renderDeleteDialog,
    renderDeletegroupingDialog,
  };
};

export default useDataGridParcelsActions;
