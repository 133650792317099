import { customDialogCommonTheme } from './generic_dialog_add_culture';

const { createTheme } = require('@mui/material');

export const customAutocompleteTheme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: '#e69636 !important',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#e69636 !important',
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#d32f2f !important',
          marginLeft: '2px !important',
          letterSpacing: 'unset !important',
          fontStyle: 'italic',
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
          whiteSpace: 'pre-wrap !important',
          fontSize: '0.7rem',
          lineHeight: '1',
          position: 'absolute',
          top: '55px',
          bottom: '0px',
          right: '0px',
          left: '0px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            width: '18px !important',
            textAlign: 'center',
            color: '#e69636 !important',
            position: 'absolute',
            right: '5px',
            cursor: 'pointer',
            
          },
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100% !important',
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          '.MuiInputBase-root': {
            minWidth: '230px !important',
            maxWidth: '100% !imporatnt',
          },
        },
      },
    },
  },
});

export const customMetaCultureDialogTheme = createTheme(customDialogCommonTheme, {
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
         '& .MuiDialog-paper .MuiBox-root .MuiSvgIcon-root': {
            fontSize: '36px !important',
            color: '#277134 !important',
            position: 'absolute',
            right: '20px',
            cursor: 'pointer !important',
          }
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
        },
      },
    },
  },
});
