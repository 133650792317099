import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LicenseInfo } from '@mui/x-license';

import Auth from 'providers/Auth';
import Router from 'router/index';

const App = () => {
  LicenseInfo.setLicenseKey(
    process.env.REACT_APP_DATAGRID_LICENSE_PRO
  );

  return (
    <>
      <Auth>
        <Router />
      </Auth>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default App;
