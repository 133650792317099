import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import API from 'services/API';

import useAuth from 'providers/Auth/useAuth';
import { routing } from 'utils/const';
import { subscribe, unsubscribe } from 'utils/Event';

import MainNav from 'components/MainNav/MainNav';
import Nav from 'components/Nav/Nav';
import LoaderCustom from 'components/generics/LoaderCustom';
import { LoadingContext, NavContext } from 'utils/context';

const ProtectedRoute = ({ children }) => {
  const { user, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { isNavBarOpen } = useContext(NavContext);
  const [sideNavItem, setSideNavItem] = useState(null);
  const setLoaderInfo = useContext(LoadingContext);
  const { customText, forceLoader, fullscreen, loading, setLoading, resetDataLoader } =
    useContext(LoadingContext);
  let axiosInterceptorRequest = null;
  // Life Hook DidMount
  let axiosInterceptorResponse = null;
  useEffect(() => {
    let isLoaderNeeded = true;
    // Event trigger to set a full screen loader and not a partial one
    subscribe('loaderFullScreen', (event) => {
      if (event?.detail?.loaderNeeded === false) {
        isLoaderNeeded = false;
      } else {
        isLoaderNeeded = true;
      }
    });

    // Interceptors axios to display load screen for any http request
    axiosInterceptorRequest = API.interceptors.request.use(
      (config) => {
        // For some request a full screen loading is not required
        if (isLoaderNeeded) {
          setLoading(true);
        }
        return config;
      },
      (error) => {
        setLoading(false);
        return Promise.reject(error);
      }
    );

    const arrayOfApiCallWhereLoaderNeedToStayAlive = [
      'importParcelsStatus',
      'telepac',
      'importParcelsGeofolia',
      'geofolia/persist',
      'Wiuz',
    ];
    // Interceptors axios to hide load screen when the http request is completed
    axiosInterceptorResponse = API.interceptors.response.use(
      (config) => {
        const checkPresenceUrlArray = arrayOfApiCallWhereLoaderNeedToStayAlive.filter((url) =>
          config.config.url.includes(url)
        );
        if (checkPresenceUrlArray.length === 0) {
          setLoading(false);
          resetDataLoader();
        }
        return config;
      },
      (error) => {
        if (error.code !== 'ERR_CANCELED') {
          setLoading(false);
        }
        resetDataLoader();
        return Promise.reject(error);
      }
    );
  }, []);

  // Life Hook Dismount
  useEffect(() => {
    return () => {
      unsubscribe('loaderFullScreen');
    };
  }, []);

  useEffect(() => {
    if (!user) {
      try {
        jwtDecode(localStorage.getItem('jwt_token'));
      } catch (error) {
        // TODO : ADD TOAST
        logout();
        navigate('/login');
      }
    }
  }, [user]);

  useEffect(() => {
    setSideNavItem(routing[location.pathname.split('/')[1]]);
  }, [location]);

  useEffect(() => {
    // Remove overload interceptors
    if (axiosInterceptorRequest > 1) {
      API.interceptors.request.eject(axiosInterceptorRequest);
    }
    if (axiosInterceptorResponse > 1) {
      API.interceptors.response.eject(axiosInterceptorResponse);
    }
    if (!loading && setLoaderInfo.customText) {
      // Reset the customText to null when loading is not needed anymore
      setLoaderInfo.customText = null;
    }
  }, [setLoading]);

  return (
    user && (
      <div className="page">
        <div className="contain">
          {user && <MainNav user={user} />}
          {sideNavItem && <Nav user={user} items={sideNavItem} />}
          <div>
            {loading || forceLoader ? (
              <LoaderCustom isNavBarOpen={isNavBarOpen} fullscreen={fullscreen} text={customText} />
            ) : null}
            {children}
          </div>
        </div>
      </div>
    )
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ProtectedRoute;
