import { useContext } from 'react';
import './parcelSynthesisTable.style.scss';
import './table.style.scss';
import { PdfContext } from 'utils/context';
import gouteletteLogo from 'assets/images/laissez-tomber-min.png';
import logoCover from 'assets/images/logo-cover.png';
import logoCoverTransparent from 'assets/images/logo-cover-transparent.png';
import logoCoverDarkGreen from 'assets/images/logo-cover-dark-green.png';
import { v1 } from 'uuid';
import { toLowerCaseWithFirstLetterUpper } from '../Generics/utilsFunctions';

const ParcelSynthesisTable = ({
  repetition,
  parcels,
  years,
  messageDuplicate,
  isNitrogenASelectedConstraint,
}) => {
  const { currentYear, cultures, isCoverProposalASelectedConstraint } = useContext(PdfContext);
  let headers = [
    ...['Parcelle', 'Surface'],
    ...Array(years.length + 1)
      .fill()
      .map((_, i) => `${i + (currentYear - 1)}-${i + currentYear}`),
    ...['Qté MS moyenne'],
  ];
  const heightTableHead = '45px';
  const heightTableBody = '592.5px';

  return (
    <article className="pdf-page">
      {isCoverProposalASelectedConstraint && repetition === 1 && (
        <div className="legend_simulation_synthesis">
          {isNitrogenASelectedConstraint && (
            <div className="legend-cover">
              <img src={logoCover} alt="Logo couvert légumineuse" />
              Couvert à base légumineuse avant la culture
            </div>
          )}
          <div className="legend-cover goutelette_img">
            <img src={logoCoverDarkGreen} alt="Logo couvert non légumineuses" />
            Couvert à base non légumineuse avant la culture
          </div>
          <div className="legend-cover goutelette_img">
            <img src={logoCoverTransparent} alt="Logo couvert possible" />
            Autre couvert possible avant la culture
          </div>
        </div>
      )}
      <div className="tableau_center">
        <h6 className="title_h6">
          {parcels.length > 0 && messageDuplicate !== ''
            ? `Rotation des cultures par parcelle ${messageDuplicate}`
            : `Rotation des cultures par parcelle`}
        </h6>
        <table
          className="table table-full-page"
          style={{
            height: parcels.length <= 5 ? 'auto' : '',
          }}
        >
          <thead className="table_head" style={{ height: heightTableHead }}>
            <tr className="table_tr">
              {headers.map((header, i) => (
                <th key={i} scope="col" className="column_name">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody style={{ height: parcels.length <= 5 ? 'auto' : heightTableBody }}>
            {parcels?.map((parcel, idxParcel) => (
              <tr
                className="table_tr"
                key={v1()}
                style={{ height: parcels.length <= 5 ? 'auto' : `calc(100% / ${parcels.length})` }}
              >
                <td className="parcel_value">{parcelNameCell(parcel?.isParent, parcel?.name)}</td>
                <td className="parcel_value">{(parcel?.surface.toFixed(1) + ' ha').toString()}</td>
                {parcel?.cultures.map((culture, idx) => {
                  const hasPlotDivision = culture.length > 1;
                  return !Array.isArray(culture) ? (
                    <td className="parcel_value" key={v1()}>
                      <div className="flex-column-synthesis">
                        <div className="cell-content-name">
                          {toLowerCaseWithFirstLetterUpper(culture.name.toString())}
                        </div>
                        <div className="icons">
                          {cultures.find((exploitCulture) => exploitCulture.id === culture.id)
                            ?.color && (
                            <div
                              className="color"
                              style={{
                                backgroundColor: cultures.find(
                                  (exploitCulture) => exploitCulture.id === culture.id
                                )?.color,
                              }}
                            ></div>
                          )}
                          {years[idx - 1]?.irrigationDistribution[idxParcel] > 0 && (
                            <img
                              className="goutelette_img"
                              src={gouteletteLogo}
                              alt="Logo irrigation"
                            />
                          )}
                          {(culture.cover === 'POSSIBLE' ||
                            culture.cover === 'LEGUME' ||
                            culture.cover === 'OTHER') && (
                            <img
                              className="goutelette_img"
                              src={
                                culture?.cover === 'POSSIBLE'
                                  ? logoCoverTransparent
                                  : culture?.cover === 'LEGUME'
                                  ? logoCover
                                  : logoCoverDarkGreen
                              }
                              alt="Logo cover"
                            />
                          )}
                        </div>
                      </div>
                    </td>
                  ) : (
                    <>
                      <td
                        className={`parcel_value ${hasPlotDivision ? 'cell_culture_dashed' : ''}`}
                        key={v1()}
                      >
                        {culture?.map((cultureArray, index) => {
                          const cultureColor = cultures.find(
                            (exploitCulture) => exploitCulture.id === cultureArray.id
                          )?.color;

                          return (
                            <div
                              key={index}
                              className={`flex-column-synthesis ${
                                hasPlotDivision ? 'flex-column-synthesis-splitted' : ''
                              }`}
                            >
                              {hasPlotDivision && (
                                <div className="parcel_value_surface">
                                  <span>{Number(cultureArray.surface.toFixed(2))}</span>
                                </div>
                              )}
                              <div className="cell-content-name">
                                {toLowerCaseWithFirstLetterUpper(cultureArray.name.toString())}
                              </div>
                              <div className="icons">
                                {cultureColor && (
                                  <div
                                    className="color"
                                    style={{ backgroundColor: cultureColor }}
                                  ></div>
                                )}
                                {years[idx - 1]?.irrigationDistribution[idxParcel] > 0 && (
                                  <img
                                    className="goutelette_img"
                                    src={gouteletteLogo}
                                    alt="Logo irrigation"
                                  />
                                )}
                                {(cultureArray.cover === 'POSSIBLE' ||
                                  cultureArray.cover === 'LEGUME' ||
                                  cultureArray.cover === 'OTHER') && (
                                  <img
                                    className="goutelette_img"
                                    src={
                                      cultureArray?.cover === 'POSSIBLE'
                                        ? logoCoverTransparent
                                        : cultureArray?.cover === 'LEGUME'
                                        ? logoCover
                                        : logoCoverDarkGreen
                                    }
                                    alt="Logo cover"
                                  />
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </td>
                    </>
                  );
                })}
                <td className="parcel_value">{parcel?.averageBiomassInput + ' ' + '(t MS/ha)'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </article>
  );
};

const parcelNameCell = (isParent, parcelName) => {
  return (
    <div className="cell-content">
      {isParent ? 'Îlot ' : ''}
      {toLowerCaseWithFirstLetterUpper(parcelName)}
    </div>
  );
};

export default ParcelSynthesisTable;
