// REACT
import { SyntheticEvent, useEffect, useState } from 'react';
// YARN
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import { Select as SelectMui } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// SERVICES
import { toast } from 'react-toastify';
import { createGroupementParcel, editGroupementParcel } from 'services/API/Exploitation';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';
// INTERFACES
import { IParcelsList } from 'components/generics/Interface/Api/Response/Exploitation/Parcels/IParcelsList';
import { IExploitation } from 'components/generics/Interface/Api/Response/Structures/IExploitation';
// THEMES
import { ThemeProvider } from '@mui/material/styles';
import '../../../assets/styles/group_parcel.style.scss';
import {
  customDialogRegroupParcelsTheme,
  SelectParcelTheme,
} from 'assets/styles/themes/Dialog/generic_dialog_regroup_parcel';
import LoadingScreen from 'components/generics/loadingScreen';

const DEFAULT_FORM: formDataType = { parent: '', children: [] };

type formDataType = {
  parent: number | string;
  children: number[];
};

type GroupParcelModalParams = {
  onSubmitSuccess: () => void;
  loadingComplete: boolean;
  allParcels: IParcelsList[];
  exploitation: IExploitation;
  edit: formDataType | null;
  modalGroup: boolean;
  setModalGroup: React.Dispatch<React.SetStateAction<boolean>>;
};

type parentOptionsType = IParcelsList & {
  label?: string;
};

const GroupParcelModal = ({
  onSubmitSuccess,
  loadingComplete,
  allParcels,
  exploitation,
  edit,
  modalGroup,
  setModalGroup,
}: GroupParcelModalParams) => {
  const [formData, setFormData] = useState<formDataType>(edit || DEFAULT_FORM);
  const [childrenParcels, setChildrenParcels] = useState<IParcelsList[]>([]);
  const [parcelsWithEmptyCultures, setParcelsWithEmptyCultures] = useState<IParcelsList[]>([]);

  useEffect(() => {
    if (allParcels?.length) {
      const catchParcelsEmptyCultures = allParcels.map((parcel) => {
        if (!parcel.cultureN) {
          return {
            ...parcel,
            cultureN: {
              id: 0,
              name: 'Culture N vide',
              year: 0,
              surface: 0,
              irrigation: 0,
              cover: 'POSSIBLE',
              isMain: false
            },
          };
        }
        if (!parcel.cultureN1) {
          return {
            ...parcel,
            cultureN1: {
              id: 0,
              name: 'Culture N-1 vide',
              year: 0,
              surface: 0,
              irrigation: 0,
              cover: 'POSSIBLE',
              isMain: false
            },
          };
        }
        return { ...parcel };
      });
      setParcelsWithEmptyCultures(catchParcelsEmptyCultures);
    }
  }, [allParcels]);

  const getOptionParent = (): parentOptionsType[] => {
    let optionsParents: parentOptionsType[];
    if (!edit) {
      optionsParents = parcelsWithEmptyCultures.filter((parcel: IParcelsList) => {
        const isChildParcel = parcel?.parent ? true : false;
        const isParentParcel = parcel?.isParent ? true : false;
        const hasAvailableChildToAdds = hasAvailableChildToAdd(parcel?.id);

        if (isChildParcel) {
          return false;
        }
        if (isParentParcel && !hasAvailableChildToAdds) {
          return false;
        }

        return true;
      });
    } else {
      optionsParents = parcelsWithEmptyCultures.filter(
        (parcel) => parcel?.isParent && parcel?.id === edit?.parent
      );
    }
    optionsParents.forEach((parcel: IParcelsList, index) => {
      optionsParents[
        index
      ].label = `${parcel?.name} ( ${parcel?.surface} ha - ${parcel?.cultureN?.name} - ${parcel?.cultureN1?.name} - ${parcel?.groundType?.name})`;
    });

    return [...optionsParents];
  };

  useEffect(() => {
    if (!!formData.parent) {
      let allChildrenParcels = parcelsWithEmptyCultures.filter(
        (parcel) =>
          !parcel?.isParent &&
          (!parcel?.parent || parcel.parent === +formData?.parent) &&
          parcel?.id !== +formData?.parent &&
          hasSameCultureAndGroundType(parcel)
      );
      setChildrenParcels([...allChildrenParcels]);
    } else {
      setChildrenParcels([]);
    }
  }, [formData?.parent, parcelsWithEmptyCultures]);

  const hasAvailableChildToAdd = (parentParcelId: number) => {
    let allChildrenParcels = parcelsWithEmptyCultures.filter(
      (parcel) =>
        !parcel?.isParent &&
        !parcel?.parent &&
        parcel?.id !== parentParcelId &&
        hasSameCultureAndGroundType(parcel, parentParcelId)
    );

    if (allChildrenParcels.length === 0) {
      return false;
    }

    return true;
  };

  const hasSameCultureAndGroundType = (
    child: IParcelsList,
    parentParcelId: number | null = null
  ) => {
    let parent;
    if (parentParcelId) {
      parent = allParcels.find((parcel) => parcel.id === parentParcelId);
    } else {
      parent = allParcels.filter((p) => +formData.parent === p.id)[0];
    }

    return (
      child?.cultureN?.id === parent?.cultureN?.id &&
      child?.cultureN1?.id === parent?.cultureN1?.id &&
      child?.groundType?.id === parent?.groundType?.id
    );
  };

  const updateParent = (e: number) => {
    let clone = JSON.parse(JSON.stringify(formData));
    clone.parent = +e;
    let children = allParcels.reduce(
      (accumulator: number[], currentParcel) => {
        if (currentParcel?.parent && currentParcel?.parent === e) {
          accumulator.push(currentParcel.id);
        }
        return accumulator;
      },

      []
    );
    clone.children = children;
    setFormData(clone);
  };

  const deleteChild = (index: number) => {
    let newFormData = { ...formData };
    newFormData.children = newFormData.children.filter(
      (childrenId) => childrenId !== newFormData.children[index]
    );
    setFormData(newFormData);
  };

  const onSubmit = (e: SyntheticEvent) => {
    if (formData.children.length > 0) {
      setModalGroup(false);
      if (e) e.preventDefault();

      if (!edit) {
        createGroupementParcel(exploitation.id, formData)
          .then(() => {
            toast.success(`Regroupement créé`);
            setFormData(DEFAULT_FORM);
            updateParcelWithoutAPI();
            onSubmitSuccess();
          })
          .catch((err) => toast.error(err.response.data['message']));
      } else {
        editGroupementParcel(exploitation.id, formData, edit.parent)
          .then(() => {
            toast.success('Regroupement mis à jour');
            setFormData(DEFAULT_FORM);
            updateParcelWithoutAPI();
            onSubmitSuccess();
          })
          .catch((err) => toast.error(err.response.data['message']));
      }
    }
  };

  const updateParcelWithoutAPI = () => {
    allParcels[allParcels.indexOf(allParcels.filter((p) => p.id === formData.parent)[0])].isParent =
      true;
    formData.children.forEach((c) => {
      allParcels[allParcels.indexOf(allParcels.filter((p) => p.id === c)[0])].parent =
        +formData.parent;
    });
  };

  const renderValue = () => {
    const multiSelectNames = formData?.children.map(
      (parcelId) => allParcels?.find((parcel) => parcel.id === parcelId)?.name
    );

    return <p> {multiSelectNames.join(', ')} </p>;
  };

  const renderMainParcel = () => {
    const mainParcel = getOptionParent()?.find((parcel) => parcel.id === formData?.parent)?.label;

    return <p> {mainParcel} </p>;
  };

  const handleChange = (value: Array<number | string>) => {
    let newFormData = { ...formData };

    if (value[value.length - 1] === 'all-parcels') {
      if (newFormData.children.length === childrenParcels.length) {
        newFormData.children = [];
      } else {
        newFormData.children = childrenParcels.map((child) => child.id);
      }
    } else {
      if (value.every((childOptionValue) => typeof childOptionValue === 'number')) {
        newFormData.children = value;
      }
    }

    setFormData(newFormData);
  };

  return (
    <ThemeProvider theme={customDialogRegroupParcelsTheme}>
      <Dialog maxWidth="md" fullWidth open={modalGroup} onClose={() => setModalGroup(false)}>
        <LoadingScreen open={!loadingComplete}></LoadingScreen>

        <Box>
          <DialogTitle>Regrouper des parcelles</DialogTitle>
          <ClearIcon onClick={() => setModalGroup(false)} />
        </Box>

        <DialogContent className="dialogcontent-select-parcel">
          <InputLabel id="MainParcel">Parcelle principale</InputLabel>
          <FormControl sx={{ width: '100%' }} variant="filled">
            <ThemeProvider theme={SelectParcelTheme}>
              <InputLabel id="selectParcel">Choisir une parcelle principale</InputLabel>
              {getOptionParent().length ? (
                <SelectMui
                  sx={{ height: 50 }}
                  labelId="selectParcel"
                  className="form"
                  id="selectMainParcel"
                  value={formData?.parent}
                  onChange={(event) => updateParent(event.target.value as number)}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={renderMainParcel}
                  MenuProps={{ autoFocus: false }}
                >
                  {getOptionParent().map((parcel) => (
                    <MenuItem key={parcel?.id} value={parcel?.id}>
                      <ListItemText primary={parcel.label} />
                    </MenuItem>
                  ))}
                </SelectMui>
              ) : (
                <></>
              )}
            </ThemeProvider>
          </FormControl>
        </DialogContent>

        <DialogContent className="dialogcontent-select-parcel">
          <InputLabel id="childrenParcels">Parcelles enfants</InputLabel>
          <FormControl sx={{ width: '100%' }} variant="filled">
            <ThemeProvider theme={SelectParcelTheme}>
              <InputLabel id="selectChildrenParcel">
                {!formData.parent
                  ? "Choisissez d'abord une parcelle principale"
                  : childrenParcels?.length === 0
                  ? 'Aucune parcelle associable'
                  : childrenParcels?.length === formData?.children?.length
                  ? 'Plus de parcelles associable'
                  : 'Choisissez des parcelles enfants'}
              </InputLabel>
              <SelectMui
                sx={{ height: 50 }}
                labelId="selectChildrenParcel"
                id="multiSelectParcel"
                multiple
                value={formData?.children}
                onChange={(event) => handleChange(event.target.value as (number | string)[])}
                input={<OutlinedInput label="Tag" />}
                renderValue={renderValue}
                MenuProps={{ autoFocus: false }}
              >
                {childrenParcels.length && (
                  <MenuItem key={'select-all-parcels'} value={'all-parcels'}>
                    <Checkbox checked={childrenParcels.length === formData?.children.length} />
                    <ListItemText primary={'Tout sélectionner'} />
                  </MenuItem>
                )}
                {childrenParcels.length ? (
                  childrenParcels.map((child) => (
                    <MenuItem key={child.id} value={child.id}>
                      <Checkbox checked={formData?.children?.indexOf(child.id) > -1} />
                      <ListItemText primary={child.name} />
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key="noChildrenKey" disabled>
                    <ListItemText primary="Aucune parcelle associable" />
                  </MenuItem>
                )}
              </SelectMui>
            </ThemeProvider>
          </FormControl>
        </DialogContent>

        <DialogContent
          className="group-parcel-list"
          sx={{
            minHeight: formData?.children?.length > 0 ? '132px' : '0px',
            height:
              formData?.children?.length > 0
                ? 'calc(' + formData?.children?.length + '*46px)'
                : '0px',
          }}
        >
          {formData?.children?.length > 0 ? (
            <div className="parcel-list__wrapper">
              <List className="parcel-list__list">
                {formData?.children?.map((childId, index) => (
                  <ListItem
                    key={index}
                    className="parcel-list__item"
                    secondaryAction={
                      <div key={index} className="button_delete" onClick={() => deleteChild(index)}>
                        <ClearIcon fontSize="medium" htmlColor="white" />
                      </div>
                    }
                  >
                    <ListItemText
                      key={index}
                      className="parcel-list__item"
                      primary={allParcels.filter((a) => a.id === +childId)[0]?.name}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          ) : (
            <></>
          )}
        </DialogContent>

        <DialogActions>
          <ThemeProvider theme={customButtonTheme}>
            <Button id="inputCostButton" onClick={onSubmit} disabled={formData.children.length < 1}>
              {edit ? 'Enregistrer' : 'Valider le regroupement'}
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default GroupParcelModal;
