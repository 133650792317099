import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { v1 } from 'uuid';
import { minimalRoleRequired } from 'utils';

import Item from 'components/Nav/Item';
import './nav.style.scss';
import { NavContext } from 'utils/context';

const Nav = ({ user, items }) => {
  const location = useLocation();
  const { isNavBarOpen } = useContext(NavContext);

  return (
    <>
      <div
        className="nav"
        style={{
          opacity: isNavBarOpen ? '1' : '0',
          visibility: isNavBarOpen ? 'visible' : 'hidden',
          height: isNavBarOpen ? '50px' : '0px',
        }}
      >
        <div className="nav-contains">
          {items.map(
            (e) =>
              minimalRoleRequired(user?.role, e.minimalRole) && (
                <Item
                  key={v1()}
                  path={`/${e.path}`}
                  text={e.text}
                  active={`/${e.path}` === location.pathname}
                />
              )
          )}
        </div>
      </div>
      <div className="marginNav"></div>
    </>
  );
};

Nav.propTypes = {
  user: PropTypes.shape({
    cooperative: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    email: PropTypes.string,
    firstname: PropTypes.string,
    id: PropTypes.number,
    lastname: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      path: PropTypes.string,
      minimalRole: PropTypes.number,
    })
  ).isRequired,
};

export default Nav;
