import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Contraintes from './Contraintes';
import Cooperative from './Cooperative';
import Tools from './Tools/Tools';

const Administration = () => {
  return (
    <div className="Administration">
      <Routes>
        <Route path="/" element={<Navigate to="Cooperative" />} />
        <Route path="Cooperative" element={<Cooperative />} />
        <Route path="Tools" element={<Tools />} />
        <Route path="Contraintes" element={<Contraintes />} />
        <Route element={<Navigate to="/*" />} />
      </Routes>
    </div>
  );
};

export default Administration;
