import React, { useEffect, useState } from 'react';

import Select from 'components/generics/Select';

const ExploitationTableRow = ({ data, handleData, deleteData }) => {
  const [selectedSecteur, setSelectedSecteur] = useState('');
  const [selectedExploitation, setSelectedExploitation] = useState('');
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (data.exploitation.sectorId) {
      setSelectedSecteur(data.exploitation.sectorId);
    }
    if (data.exploitation.exploitationTypeName) {
      let found = data.type.filter(
        (d) => d.exploitationTypeName === data.exploitation.exploitationTypeName
      );
      if (found.length > 0) {
        setSelectedExploitation(found[0].id);
      } else {
        setSelectedExploitation(data.type.filter((d) => d.isDefaultType === true)[0]?.id);
      }
    } else {
      setSelectedExploitation(data.type.filter((d) => d.isDefaultType === true)[0]?.id);
    }
  }, [data]);

  const handleChange = (e, from) => {
    let dataToHandle;
    switch (from) {
      case 'secteur':
        setSelectedSecteur(e);
        dataToHandle = {
          exploitationId: +data.exploitation.wiuzExploitationId,
          sectorId: +e,
          exploitationTypeId: +selectedExploitation,
        };
        handleData(dataToHandle);
        break;
      case 'exploitation':
        setSelectedExploitation(e);
        dataToHandle = {
          exploitationId: +data.exploitation.wiuzExploitationId,
          sectorId: +selectedSecteur,
          exploitationTypeId: +e,
        };
        handleData(dataToHandle);
        break;
      case 'checkbox':
        setChecked(!checked);
        if (checked) {
          deleteData(data.exploitation.wiuzExploitationId);
        } else {
          dataToHandle = {
            exploitationId: +data.exploitation.wiuzExploitationId,
            sectorId: +selectedSecteur,
            exploitationTypeId: +selectedExploitation,
          };
          handleData(dataToHandle);
        }
        break;
      default:
        break;
    }
  };

  return (
    <tr>
      <td>
        <input type={'checkbox'} checked={checked} onChange={(e) => handleChange(e, 'checkbox')} />
      </td>
      <td>{data.exploitation.wiuzExploitationName}</td>
      <td>
        <Select
          className="reduce"
          name="secteur"
          options={[{ id: 0, name: 'Secteurs Assolia' }, ...data.secteur]}
          value={selectedSecteur}
          itemLabel="name"
          itemValue="id"
          disabled={!checked || !!data.exploitation.sectorId}
          onChange={(e) => handleChange(e, 'secteur')}
        />
      </td>
      <td>
        <Select
          className="reduce"
          name="exploitation"
          options={[{ id: 0, exploitationTypeName: "Types d'exploitation" }, ...data.type]}
          value={selectedExploitation}
          itemLabel="exploitationTypeName"
          itemValue="id"
          disabled={!checked || !!data.exploitation.exploitationTypeName}
          onChange={(e) => handleChange(e, 'exploitation')}
        />
      </td>
    </tr>
  );
};

export default ExploitationTableRow;
