import { useContext } from 'react';
import './constraint.style.scss';
import '../Tables/table.style.scss';
import { PdfContext } from 'utils/context';
import gouteletteLogo from 'assets/images/laissez-tomber-min.png';
import fleche from 'assets/images/fleche.png';
import cowIcon from 'assets/images/cowIcon.png';
import { lengthInInterval, truncateTab } from '../Generics/utilsFunctions';

const Constraint = () => {
  const { constraints } = useContext(PdfContext);
  const numberOfRowPerPage = 10;
  let toDuplicate = true;
  let repetition = 1;
  let coProductsConstraintPages = [];
  let isLast = false;

  if (constraints?.typeCoProduit.length > 10) {
    while (toDuplicate) {
      let truncatedResult = truncateTab(constraints?.typeCoProduit, numberOfRowPerPage, repetition);
      const toRepeat = lengthInInterval(constraints?.typeCoProduit, repetition, numberOfRowPerPage);
      !toRepeat && (isLast = true);
      coProductsConstraintPages.push(
        buildConstraintsTable(constraints, isLast, truncatedResult, repetition > 1 ? '(Suite)' : '')
      );
      toRepeat ? repetition++ : (toDuplicate = false);
    }

    return coProductsConstraintPages;
  }

  return buildConstraintsTable(constraints);
};

const buildConstraintsTable = (
  constraints,
  isLast = true,
  truncatedResult = null,
  message = ''
) => {
  const winterCulture = constraints?.autres?.find(
    (constraint) => constraint?.displayName === '% culture hiver (%)'
  );
  const winterCultureValue = winterCulture ? Object.entries(winterCulture) : null;
  const irrigability = constraints?.autres.filter(
    (constraint) => constraint?.displayName !== '% culture hiver (%)'
  );
  const pacConstraint = constraints?.pac ? Object.entries(constraints?.pac) : null;

  const isNotFullScreen =
    constraints?.typeCoProduit.length + constraints?.autres.length + (pacConstraint ? 1 : 0) <= 5;

  return (
    <article className="pdf-page">
      <div className="tableau_center constraints_list_wrapper">
        <h6 className="title_h6">CONTRAINTES {message}</h6>
        <table
          className="table constraints_table"
          style={{ height: isNotFullScreen ? 'auto' : '' }}
        >
          <thead className="table_head">
            <tr className="table_tr" key={'rowHead'}>
              <th className="column_name" key={0} scope="col"></th>
              <th className="column_name" key={1} scope="col">
                Minimum
              </th>
              <th className="column_name" key={2} scope="col">
                Maximum
              </th>
            </tr>
          </thead>
          <tbody>
            {constraints?.typeCoProduit.length > 0 && (
              <tr className="table_tr" key={'animalNeeds'}>
                <th className="head_row" scope="row" colSpan="3">
                  <div className="inline_icon_cell">
                    Besoins Animaux (t)
                    <img className="cell-icon" src={cowIcon} alt="Logo animaux" />
                  </div>
                </th>
              </tr>
            )}
            {truncatedResult
              ? truncatedResult.map((constraint, index) => (
                  <tr className="table_tr grey_row" key={index}>
                    {Object.entries(constraint)?.map(([key, value], index) =>
                      constraintClassicCell(value, index, key)
                    )}
                  </tr>
                ))
              : constraints?.typeCoProduit?.map((constraint, index) => (
                  <tr className="table_tr grey_row" key={index}>
                    {Object.entries(constraint)?.map(([key, value], index) =>
                      constraintClassicCell(value, index, key)
                    )}
                  </tr>
                ))}
            {isLast && pacConstraint && (
              <tr className="table_tr" key={'pacConstraint'}>
                {pacConstraint?.map(([key, value], index) => (
                  <td className={`value_table ${index === 0 ? 'text-left' : ''}`} key={key}>
                    {value}
                  </td>
                ))}
              </tr>
            )}
            {isLast && irrigability.length > 0 && (
              <tr className="table_tr" key={'irrigability'}>
                <th className="head_row" scope="row" colSpan="3">
                  <div className="inline_icon_cell constraint_word_spacing">
                    Irrigabilité (m³)
                    <img
                      className="cell-icon goutelette_img"
                      src={gouteletteLogo}
                      alt="Logo irrigation"
                    />
                  </div>
                </th>
              </tr>
            )}
            {isLast &&
              irrigability?.map((constraint, index) => (
                <tr className="table_tr grey_row" key={index}>
                  {Object.entries(constraint)?.map(([key, value], index) =>
                    constraintClassicCell(value, index, key)
                  )}
                </tr>
              ))}
            {isLast && winterCultureValue && (
              <tr className="table_tr" key={'winterCulture'}>
                {winterCultureValue?.map(([key, value]) => {
                  if (key === 'displayName') {
                    return (
                      <th className="head_row constraint_word_spacing" key={key}>
                        Culture d'hiver (%)
                      </th>
                    );
                  } else {
                    return (
                      <td className="value_table" key={key}>
                        {value}
                      </td>
                    );
                  }
                })}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </article>
  );
};

const constraintClassicCell = (value, index, key) => {
  if (index > 2) {
    return;
  }
  if (value == '9223372036854776000') {
    value = '0';
  }
  if (index === 0) {
    return (
      <td className="value_table " key={key}>
        <div className="inline_icon_cell">
          <img className="cell-icon" src={fleche} alt="Logo fleche" />
          <span>{value}</span>
        </div>
      </td>
    );
  } else {
    return (
      <td className="value_table" key={key}>
        <span>{value}</span>
      </td>
    );
  }
};

export default Constraint;
