import { useRef, useState } from 'react';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import Button from 'components/generics/Button';
import { SwatchesPicker } from 'react-color';
import { toast } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import ClearIcon from '@mui/icons-material/Clear';
import { Box } from '@mui/material';
import { customDialogColorTheme } from 'assets/styles/themes/Dialog/generic_dialog_color_mui';

const useCustomEditColorCell = (cultures) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const checkUniqueColor = (newColor) => {
    const existingColorCulture = cultures?.find((culture) => culture.color === newColor?.hex);
    if (existingColorCulture) {
      toast.error(
        `Cette couleur est déjà attribuée à la culture "${existingColorCulture?.name}". Veuillez en sélectionner une autre.`
      );
      return false;
    }
    return true;
  };

  const ColorEditCell = (params) => {
    const { id, value, field, hasFocus } = params;
    const apiRef = useGridApiContext();
    const ref = useRef();

    const handleChange = (newColor) => {
      const isUniqueColor = checkUniqueColor(newColor);
      if (isUniqueColor) {
        apiRef.current.setEditCellValue({ id, field, value: newColor?.hex });
        handleClose();
      }
    };

    useEnhancedEffect(() => {
      if (hasFocus && ref.current) {
        const input = ref.current.querySelector(`input[value="${value}"]`);
        input?.focus();
      }
    }, [hasFocus, value]);

    return (
      <div className="colorContainer cellColorMuiContainer">
        {!value ? (
          <Button
            text="Ajouter"
            onClick={handleClick}
            defaultStyle="add-color-button add-color-mui"
          />
        ) : (
          <>
            <div className="colorBlockContainer">
              <div className="colorBlock" onClick={handleClick}>
                <div
                  className="colorStyleMui"
                  style={{
                    background: `${value ?? undefined}`,
                  }}
                />
              </div>
            </div>
            <Button
              text="Supprimer "
              defaultStyle="add-color-button add-color-mui"
              onClick={() => apiRef.current.setEditCellValue({ id, field, value: null })}
            />
          </>
        )}
        {displayColorPicker ? (
          <ThemeProvider theme={customDialogColorTheme}>
            <Dialog open={true} ref={ref} PaperProps={{ ref: ref }}>
              <Box>
                <DialogTitle>Choix de la couleur</DialogTitle>
                <ClearIcon onClick={handleClose} />
              </Box>
              <div className="colorPicker">
                <div className="colorPickerCover" onClick={handleClose} />
                <SwatchesPicker
                  className="colorPickerPosition__modal colorPickerPositionMui"
                  width={600}
                  height={600}
                  color={value ?? undefined}
                  onChangeComplete={(newColor) => {
                    handleChange(newColor);
                  }}
                />
              </div>
            </Dialog>
          </ThemeProvider>
        ) : null}
      </div>
    );
  };

  const renderColorEditCell = (params) => {
    return <ColorEditCell {...params} />;
  };

  return renderColorEditCell;
};

export default useCustomEditColorCell;
