import { useEffect, useState } from 'react';
import { ROLE_ADMIN, ROLE_FARMER, ROLE_SUPER_ADMIN, ROLE_TECHNICIAN } from 'utils/const';
import useAuth from 'providers/Auth/useAuth';
import { ICulture } from 'components/generics/Interface/ICulture';
import { IGroundType } from 'components/generics/Interface/Api/Response/GroundType/IGroundType';

export type selectAllType = {
  rows: boolean;
  columns: boolean;
};

export type filtersType = {
  rows?: Record<number, boolean>;
  columns?: Record<number, boolean>;
};

type filteredRotationsType = {
  role: number;
  maskedRotationsByLevel: maskedRotationsByLevelType[];
};

type maskedRotationsByLevelType = {
  structure?: number;
  sector?: number;
  exploitation?: number;
  maskedRows: filtersType;
};

const useFilteredRotationsLocalStorage = (
  ownerId: number,
  ownerLevel: string,
  cultures: ICulture[],
  from: string,
  groundTypes?: IGroundType[]
) => {
  const { user } = useAuth();
  const [toggleFilter, setToggleFilter] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<filtersType>({});

  const filterNameForLocalStorage =
    from === 'groundTypeEffect' ? 'filteredGroundTypeEffects' : 'filteredRotationEffects';

  const isAllSelected = (type: keyof filtersType): boolean => {
    if (selectedFilters?.[type]) {
      const filters = selectedFilters[type];
      if (filters && typeof filters === 'object') {
        return Object.values(filters).every((value) => Boolean(value));
      }
    }
    return true;
  };
  const [selectAllFilters, setSelectAllFilters] = useState<selectAllType>({
    rows: isAllSelected('rows'),
    columns: isAllSelected('columns'),
  });

  const isActiveFilters = (): boolean => {
    if (selectedFilters && Object.keys(selectedFilters).length > 0) {
      let isActive = false;
      Object.values(selectedFilters).forEach((filter) => {
        if (Object.values(filter).some((value) => value === false)) {
          isActive = true;
        }
      });
      return isActive;
    }
    return false;
  };

  const checkFiltersEquality = (newFilters: filtersType, oldFilters: filtersType): boolean => {
    for (const filterKey in newFilters) {
      for (const key in newFilters[filterKey as keyof filtersType] as Record<number, boolean>) {
        if (
          newFilters[filterKey as keyof filtersType]?.[key] !==
          oldFilters[filterKey as keyof filtersType]?.[key]
        ) {
          return false;
        }
      }
    }
    return true;
  };

  let filteredRotationEffects: filteredRotationsType | null = null;
  const filteredRotationEffectsFromLS: string | null =
    localStorage.getItem(filterNameForLocalStorage);
  if (typeof filteredRotationEffectsFromLS === 'string')
    filteredRotationEffects = JSON.parse(filteredRotationEffectsFromLS);

  const setFilteredRotationsLocalStorage = (object: filteredRotationsType) =>
    localStorage.setItem(filterNameForLocalStorage, JSON.stringify(object));

  let userMaskedRotationsIndex: number = -1;

  if (filteredRotationEffects) {
    switch (ownerLevel) {
      case 'cooperatives':
        userMaskedRotationsIndex = filteredRotationEffects.maskedRotationsByLevel.findIndex(
          (obj: maskedRotationsByLevelType) => obj?.structure === ownerId
        );
        break;
      case 'sectors':
        userMaskedRotationsIndex = filteredRotationEffects.maskedRotationsByLevel.findIndex(
          (obj: maskedRotationsByLevelType) => obj?.sector === ownerId
        );
        break;
      case 'exploitations':
        userMaskedRotationsIndex = filteredRotationEffects.maskedRotationsByLevel.findIndex(
          (obj: maskedRotationsByLevelType) => obj?.exploitation === ownerId
        );
        break;
    }
  }

  const updateMaskedRotationsObject: maskedRotationsByLevelType = {
    ...(ownerLevel === 'cooperatives' && {
      structure: ownerId,
    }),
    ...(ownerLevel === 'sectors' && { sector: ownerId }),
    ...(ownerLevel === 'exploitations' && { exploitation: ownerId }),
    maskedRows: selectedFilters,
  };

  useEffect(() => {
    if (cultures && !selectedFilters?.rows && !selectedFilters?.columns) {
      const initialSelectedFilters: {
        rows: Record<number, boolean>;
        columns: Record<number, boolean>;
      } = { rows: {}, columns: {} };

      if (from === 'groundTypeEffect') {
        cultures?.map((culture) => {
          initialSelectedFilters.rows[culture.id] = true;
        });
        groundTypes?.map((culture) => {
          initialSelectedFilters.columns[culture.id] = true;
        });
      } else {
        cultures?.map((culture) => {
          initialSelectedFilters.rows[culture.id] = true;
          initialSelectedFilters.columns[culture.id] = true;
        });
      }

      if (userMaskedRotationsIndex === -1) {
        setSelectedFilters(initialSelectedFilters);
      } else if (userMaskedRotationsIndex !== -1 && filteredRotationEffects) {
        setSelectedFilters(
          filteredRotationEffects?.maskedRotationsByLevel[userMaskedRotationsIndex].maskedRows
        );
        setToggleFilter(true);
      }

      setSelectAllFilters({ rows: true, columns: true });
    }
  }, [cultures, groundTypes]);

  useEffect(() => {
    if (
      filteredRotationEffects &&
      Object.keys(selectedFilters).length &&
      !isActiveFilters() &&
      userMaskedRotationsIndex !== -1
    ) {
      if (filteredRotationEffects?.maskedRotationsByLevel.length === 1) {
        localStorage.removeItem(filterNameForLocalStorage);
      } else {
        filteredRotationEffects.maskedRotationsByLevel.splice(userMaskedRotationsIndex, 1);
        setFilteredRotationsLocalStorage(filteredRotationEffects);
      }
    }
  }, [selectedFilters, filteredRotationEffects]);

  if (filteredRotationEffects) {
    if (
      userMaskedRotationsIndex !== -1 &&
      !checkFiltersEquality(
        selectedFilters,
        filteredRotationEffects?.maskedRotationsByLevel?.[userMaskedRotationsIndex]?.maskedRows
      )
    ) {
      filteredRotationEffects.maskedRotationsByLevel[userMaskedRotationsIndex].maskedRows =
        selectedFilters;
    } else if (userMaskedRotationsIndex === -1 && isActiveFilters()) {
      filteredRotationEffects.maskedRotationsByLevel.push(updateMaskedRotationsObject);
    }
    setFilteredRotationsLocalStorage(filteredRotationEffects);
  } else if (userMaskedRotationsIndex === -1 && isActiveFilters()) {
    let maskedRotationsByUser: filteredRotationsType = { role: 0, maskedRotationsByLevel: [] };
    switch (user?.role) {
      case 'SUPER_ADMIN':
        maskedRotationsByUser.role = ROLE_SUPER_ADMIN;
        break;
      case 'ADMIN':
        maskedRotationsByUser.role = ROLE_ADMIN;
        break;
      case 'TECHNICIAN':
        maskedRotationsByUser.role = ROLE_TECHNICIAN;
        break;
      case 'FARMER':
        maskedRotationsByUser.role = ROLE_FARMER;
        break;
    }
    maskedRotationsByUser.maskedRotationsByLevel = [updateMaskedRotationsObject];
    setFilteredRotationsLocalStorage(maskedRotationsByUser);
  }

  return {
    toggleFilter,
    setToggleFilter,
    selectedFilters,
    setSelectedFilters,
    selectAllFilters,
    setSelectAllFilters,
  };
};

export default useFilteredRotationsLocalStorage;
