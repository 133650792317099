export const emptyCulture = { 
  id: 'EMPTY',
  name: 'Pas de culture associée', 
  color: 'f3f3f3',
  isArable : false,
  irrigationWaterNeeded : 0
};

export const culturesColorsArray = [
  '800000',
  '008000',
  '808000',
  '000080',
  '800080',
  '008080',
  'c0c0c0',
  '808080',
  'ff0000',
  '00ff00',
  'ffff00',
  '0000ff',
  'ff00ff',
  '00ffff',
  '00af00',
  '5f00ff',
  '5f5f5f',
  '870087',
  '8700ff',
  '8787ff',
  '87d700',
  '87d7ff',
  'af0000',
  'af5f00',
  'af5fff',
  'd7d700',
  'd7ff00',
  'ff5f00',
  'ff8700',
  'ffaf00',
  'ffff5f',
  'FF9400',
  'FF2800',
  'FFCB00',
  '0786F0',
  '57F800',
  '0C0CEF',
  'FB0036',
  'AF01D4',
  'BBB1BD',
];


export const getRandomColor = (userCulturesColors) => {
  const randomColor = Math.floor(Math.random()*16777215).toString(16);
  if(!checkColor(randomColor,userCulturesColors)) {
    return getRandomColor(userCulturesColors);
  }
  return randomColor; 
}


const checkColor = (random,userCulturesColors) => {
  if(userCulturesColors.includes('#'+random)) { return false; }
  userCulturesColors.forEach((c) => {
    if(getColorsScore(c,'#'+random) < 15) { return false; }
  });
  return true;
}


const hexToRGB = (hex) => {
  hex = hex.replace("#", "");
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  return [r, g, b];
}

const getColorsScore = (sourceColor,targetColor) => {
  const [r1, g1, b1] = hexToRGB(targetColor);
  const [r2, g2, b2] = hexToRGB(sourceColor);
  return Math.sqrt(
    (r1 - r2) ** 2 +
    (g1 - g2) ** 2 +
    (b1 - b2) ** 2
  );
}

export function getColorByName(name, culturesColors) {
  let color = 'f3f3f3';
  culturesColors.forEach((obj) => {
    if (obj.name === name && obj.color !== null) { color = obj.color.replace('#',''); }
  });

  if(!color || !/^[0-9A-F]{6}$/i.test(color)) { color = 'f3f3f3'; }
  
  return color;
}

export function getColorById(id, culturesColors) {
  let color = 'f3f3f3';
  culturesColors.forEach((culture) => {
    if (culture.id === id && culture.color !== null) {
       color = culture.color.replace('#',''); 
      }
  });

  if(!color || !/^[0-9A-F]{6}$/i.test(color)) { color = 'f3f3f3'; }
  
  return color;
}

export function getisArableByName(name, culturesColors) {
  let isArable = false;
  culturesColors.forEach((obj) => {
    if(obj.name === name) { isArable = obj.isArable; }
  });
  return isArable;
}

export function getIrrigationWaterNeededByName(name, culturesColors) {
  let irrigationWaterNeeded = false;
  culturesColors.forEach((obj) => {
    if(obj.name === name) { irrigationWaterNeeded = obj.irrigationWaterNeeded; }
  });
  return irrigationWaterNeeded;
}