import React, { createContext, useState } from 'react';

const SimulationContext = createContext();

function SimulationProvider({ children }) {
  const [limit, setLimit] = useState([]);
  const [selectedParcels, setSelectedParcels] = useState([]);
  const [cultures, setCultures] = useState([]);
  const [selectedCultures, setSelectedCultures] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [selectedObjectives, setSelectedObjectives] = useState([]);
  const [nbYears, setNbYears] = useState(4);
  const [constraints, setConstraints] = useState([]);
  const [selectedConstraints, setSelectedConstraints] = useState([]);
  const [obligationAndRestriction, setObligationAndRestriction] = useState([]);

  const resetContextSimulation = () => {
    setLimit([]);
    setSelectedParcels([]);
    setCultures([]);
    setSelectedCultures([]);
    setObjectives([]);
    setSelectedObjectives([]);
    setNbYears(4);
    setConstraints([]);
    setSelectedConstraints([]);
    setObligationAndRestriction([]);
  };

  return (
    <SimulationContext.Provider
      value={{
        limit: limit,
        setLimit: (arr) => setLimit(arr),
        selectedCultures: selectedCultures,
        setSelectedCultures: (arr) => setSelectedCultures(arr),
        // setSelectedCultures: setSelectedCultures,
        cultures: cultures,
        setCultures: setCultures,
        setSimulationContext: resetContextSimulation,
        selectedParcels: selectedParcels,
        setSelectedParcels: setSelectedParcels,
        objectives: objectives,
        setObjectives: setObjectives,
        selectedObjectives: selectedObjectives,
        setSelectedObjectives: setSelectedObjectives,
        nbYears: nbYears,
        setNbYears: setNbYears,
        constraints: constraints,
        setConstraints: setConstraints,
        selectedConstraints: selectedConstraints,
        setSelectedConstraints: setSelectedConstraints,
        obligationAndRestriction: obligationAndRestriction,
        setObligationAndRestriction: setObligationAndRestriction,
      }}
    >
      {children}
    </SimulationContext.Provider>
  );
}
export { SimulationContext, SimulationProvider };
