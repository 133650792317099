import React from 'react';
import { createRoot } from 'react-dom/client'
import 'normalize.css';
import 'assets/styles/index.scss';
import App from 'components/App';
import { SimulationProvider } from 'components/pages/Exploitation/SimulationContext';
import { ImportProvider } from 'providers/ImportStatus/importStatus.providers';

createRoot(document.getElementById('root')).render(
  <ImportProvider>
    <SimulationProvider>
      <App />
    </SimulationProvider>
  </ImportProvider>
)