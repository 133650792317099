// REACT
import React from 'react';

// YARN
import {
  Box,
  ClickAwayListener,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';

// SERVICES
import dropletLogo from 'assets/images/laissez-tomber.png';
import logoCover from 'assets/images/logo-cover.png';
import logoCoverTransparent from 'assets/images/logo-cover-transparent.png';
import logoCoverDarkGreen from 'assets/images/logo-cover-dark-green.png';
import { StyledTooltip } from 'utils/Datagrid/CustomGenericDataGridUtils';

// INTERFACES
import { IParcelCulture } from 'components/generics/Interface/Api/Response/Exploitation/Parcels/IParcelCulture';
import { GridRowModel, GridValidRowModel, useGridApiContext } from '@mui/x-data-grid-pro';

export const CustomSimulationCell = (
  params: GridValidRowModel,
  editedSynthesisCellsRef: React.MutableRefObject<string[]>,
  setSelectIndex: React.Dispatch<React.SetStateAction<number | null>>
): React.JSX.Element => {
  const { isParcelDivision, cultures } = params?.value;

  const isSplittedCellEdited = (idx: number) =>
    editedSynthesisCellsRef.current.includes(`${params?.id.toString()}-${params?.field}-${idx}`);

  return cultures?.map((culture: IParcelCulture, idx: number) => {
    const isCover =
      culture?.cover === 'POSSIBLE' || culture?.cover === 'LEGUME' || culture?.cover === 'OTHER';
    return isParcelDivision ? (
      <Box
        className={`cell-box ${isParcelDivision ? 'cell-box-division' : ''} ${
          isSplittedCellEdited(idx) ? 'MuiCell--editing' : ''
        }`}
        onClick={() => {
          setSelectIndex(idx);
        }}
        key={idx}
      >
        <div className="cell-main-content content-with-surface">
          <div className="cell-main-content__infos">
            <div className="cell-main-content__infos__surface">
              <span>{Number(culture.surface.toFixed(2))}</span>
            </div>
            <div>{culture.name}</div>
          </div>
          <div className="cultures-icons-wrapper">
            {culture.irrigation > 0 && (
              <div className="culture-infos-icon">
                <img src={dropletLogo} alt="Logo irrigation" className="dropletLogo" />
              </div>
            )}
            {isCover && (
              <div className="culture-infos-icon">
                <StyledTooltip
                  title={`${
                    culture?.cover === 'POSSIBLE'
                      ? 'Autre couvert possible avant la culture'
                      : culture?.cover === 'LEGUME'
                      ? 'Couvert à base légumineuse avant la culture'
                      : culture?.cover === 'OTHER'
                      ? 'Couvert à base non légumineuse avant la culture'
                      : ''
                  }`}
                  placement="top"
                >
                  <img
                    src={
                      culture?.cover === 'POSSIBLE'
                        ? logoCoverTransparent
                        : culture?.cover === 'LEGUME'
                        ? logoCover
                        : culture?.cover === 'OTHER'
                        ? logoCoverDarkGreen
                        : null
                    }
                    alt="Logo couvert"
                    className="dropletLogo"
                  />
                </StyledTooltip>
              </div>
            )}
          </div>
        </div>
        <EditIcon
          sx={{
            padding: '0px !important',
          }}
        />
      </Box>
    ) : (
      <Box
        key={idx}
        className={`cell-box ${isSplittedCellEdited(idx) ? 'MuiCell--editing' : ''}`}
        onClick={() => {
          setSelectIndex(idx);
        }}
      >
        <div className="cell-main-content">
          <div>{culture.name}</div>
          <div className="cultures-icons-wrapper">
            {culture.irrigation > 0 && (
              <div className="culture-infos-icon">
                <img src={dropletLogo} alt="Logo irrigation" className="dropletLogo" />
              </div>
            )}
            {isCover && (
              <div className="culture-infos-icon">
                <StyledTooltip
                  title={`${
                    culture?.cover === 'POSSIBLE'
                      ? 'Autre couvert possible avant la culture'
                      : culture?.cover === 'LEGUME'
                      ? 'Couvert à base légumineuse avant la culture'
                      : culture?.cover === 'OTHER'
                      ? 'Couvert à base non légumineuse avant la culture'
                      : ''
                  }`}
                  placement="top"
                >
                  <img
                    src={
                      culture?.cover === 'POSSIBLE'
                        ? logoCoverTransparent
                        : culture?.cover === 'LEGUME'
                        ? logoCover
                        : culture?.cover === 'OTHER'
                        ? logoCoverDarkGreen
                        : null
                    }
                    alt="Logo couvert"
                    className="dropletLogo"
                  />
                </StyledTooltip>
              </div>
            )}
          </div>
        </div>
        <EditIcon sx={{ padding: '0px !important' }} />
      </Box>
    );
  });
};

type CustomEditSelectCellParams = {
  params: GridRowModel;
  culturesList: IParcelCulture[];
  selectIndex: number;
  isNitrogenASelectedConstraint: boolean;
};

export const CustomEditSelectCell = ({
  params,
  culturesList,
  selectIndex,
  isNitrogenASelectedConstraint,
}: CustomEditSelectCellParams): React.JSX.Element => {
  const { id, field, value } = params;
  const { cultures } = value;
  const apiRef = useGridApiContext();
  const cover = params.value?.cultures[selectIndex]?.cover;
  const isCover = cover === 'POSSIBLE' || cover === 'LEGUME' || cover === 'OTHER';
  const [open, setOpen] = React.useState(false);

  const handleTooltipToggle = () => {
    setOpen((open) => !open);
  };

  const handleCultureChange = (e: SelectChangeEvent<string>) => {
    const updatedValueObject = structuredClone(params.value);

    const {
      target: { value },
    } = e;

    const newValue = {
      id: value,
      name: culturesList.find((culture) => culture.id === Number(value))?.name,
      surface: cultures[selectIndex]?.surface,
      irrigation: cultures[selectIndex]?.irrigation,
      isMain: cultures[selectIndex]?.isMain,
      cover: cultures[selectIndex]?.cover,
      cellId: `${id.toString()}-${field}-${selectIndex}`,
      isEdited: true,
    };

    updatedValueObject.cultures[selectIndex] = newValue;

    apiRef.current.setEditCellValue({ id, field, value: updatedValueObject });
    apiRef.current.stopCellEditMode({ id, field });
  };

  const handleCoverChange = (newCover: string) => {
    const newValue = {
      ...value,
      cultures: value.cultures.map((culture: IParcelCulture, index: number) => {
        if (index === selectIndex) {
          return {
            ...culture,
            cover: newCover,
            cellId: `${id.toString()}-${field}-${selectIndex}`,
            isEdited: true,
          };
        }
        return culture;
      }),
    };

    apiRef.current.setEditCellValue({ id, field, value: newValue });
    setOpen(false);
  };

  return (
    <Box className="parcel-synthesis-select-wrapper">
      <Select
        defaultOpen={false}
        value={cultures[selectIndex]?.id}
        onChange={(e) => handleCultureChange(e)}
        className="parcel-synthesis-select"
        IconComponent={KeyboardArrowDownIcon}
      >
        {culturesList?.map((culture) => (
          <MenuItem key={culture.id} value={culture.id}>
            {culture.name}
          </MenuItem>
        ))}
      </Select>
      {cover !== 'IMPOSSIBLE' && (
        <>
          {isCover && (
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <div className="wrapper-cover">
                <Tooltip
                  id="custom-tooltip"
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  open={open}
                  onClose={() => setOpen(false)}
                  title={
                    <Box className="tooltip-cover-icon">
                      <StyledTooltip title="Couvert possible" placement="top">
                        <img
                          src={logoCoverTransparent}
                          alt="POSSIBLE"
                          className="dropletLogo"
                          onClick={() => handleCoverChange('POSSIBLE')}
                        />
                      </StyledTooltip>
                      {isNitrogenASelectedConstraint && (
                        <StyledTooltip title="Couvert à base légumineuse" placement="top">
                          <img
                            src={logoCover}
                            alt="LEGUME"
                            className="dropletLogo"
                            onClick={() => handleCoverChange('LEGUME')}
                          />
                        </StyledTooltip>
                      )}
                      <StyledTooltip title="Couvert à base non légumineuse" placement="top">
                        <img
                          src={logoCoverDarkGreen}
                          alt="OTHER"
                          className="dropletLogo"
                          onClick={() => handleCoverChange('OTHER')}
                        />
                      </StyledTooltip>
                    </Box>
                  }
                  placement="bottom"
                >
                  <Box className="wrapper-cover-icon">
                    <img
                      src={
                        cover === 'POSSIBLE'
                          ? logoCoverTransparent
                          : cover === 'LEGUME'
                          ? logoCover
                          : cover === 'OTHER'
                          ? logoCoverDarkGreen
                          : null
                      }
                      alt="Logo couvert"
                      className="dropletLogo"
                      onClick={handleTooltipToggle}
                    />
                  </Box>
                </Tooltip>
              </div>
            </ClickAwayListener>
          )}
        </>
      )}
    </Box>
  );
};
