import DoubleLeftArrow from 'components/icons/DoubleLeftArrow';
import DoubleRightArrow from 'components/icons/DoubleRightArrow';
import React, { useEffect, useState } from 'react';
import './CulturesMapTab.style.scss';

const YearsSelect = ({  data, mapYear, setMapYear }) => {
    const [minYearStep, setminYearStep] = useState(4000);
    const [maxYearStep, setmaxYearStep] = useState(0);
   
    useEffect(() => {
        if (data) {
          loadData();
        }
    }, [data]);
    
    const loadData = () => {
        let compareMinYear = minYearStep;
        let compareMaxYear = maxYearStep;
        data.forEach((year) => {
            const yearToCompare = Number(year.id);
            if(yearToCompare > compareMaxYear) { compareMaxYear = yearToCompare; }
            if(yearToCompare < compareMinYear) { compareMinYear = yearToCompare; }
        });
        setmaxYearStep(compareMaxYear);
        setminYearStep(compareMinYear); 
    };

    const next = () => {
        if (mapYear < new Date().getFullYear() + 7) {
            setMapYear(mapYear + 1);
        }
    };
    
    const previous = () => {
        setMapYear(mapYear - 1);
    };

return (
    <div className="years-map-tab-container">
    {mapYear > minYearStep ?
      <button
        style={{
          backgroundColor: 'transparent',
          borderRadius: 0,
          paddingLeft: 0,
          paddingRight: 10,
          flex: '50%',
        }}
        onClick={previous}
        >
        <DoubleLeftArrow />
      </button>
      :
      <button
        style={{
          backgroundColor: 'transparent',
          borderRadius: 0,
          paddingLeft: 0,
          paddingRight: 10,
          flex: '50%',
          opacity: 0.3,
        }}
        disabled="disabled"
        >
        <DoubleLeftArrow />
      </button>
    }
    <div className="years-map-tab">
      {mapYear - 1} - {mapYear} 
    </div>
    {mapYear < maxYearStep ?
      <button
        style={{
          backgroundColor: 'transparent',
          color: 'black',
          borderRadius: 0,
          paddingRight: 10,
          paddingLeft: 0,
          flex: '50%',
        }}
        onClick={next}
      >
        <DoubleRightArrow />
      </button>
      :
      <button
        style={{
          backgroundColor: 'transparent',
          color: 'black',
          borderRadius: 0,
          paddingRight: 10,
          paddingLeft: 0,
          flex: '50%',
          opacity: 0.3,
        }}
        disabled="disabled"
        >
        <DoubleRightArrow />
      </button>
    }
  </div>
 );
};

export default YearsSelect;