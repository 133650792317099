// REACT
import React, { useMemo, useState } from 'react';

// YARN
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
  ThemeProvider,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import { toast } from 'react-toastify';

// SERVICES
import {
  copyCurrentValueToFuture,
  CustomNitrogenSupplyByCover,
  StyledTooltipErrorInputCostModal,
} from 'utils/Datagrid/CustomCultureDataGridUtils';
import {
  checkDecimalRegexTwoDigitsDatagridValue,
  checkEmptyFieldAndDecimalRegexDatagridValue,
  checkNoDecimalRegexTwoDigitsDatagridValue,
  getOnlyAttributeUpdated,
} from 'utils/tools_functions';
import { validateFormData } from 'components/generics/Validators/form.validate';
import { updateStructure } from 'services/API/Administration';
import { updateExploitation } from 'services/API/Secteur';
import { updateSecteur } from 'services/API/Cooperative';

// INTERFACES
import { IFormErrorObjects, IFormErrors } from 'components/generics/Interface/Commons/IErrorForm';
import { IOffCropSettingsValues } from 'components/generics/Interface/Commons/Culture/IOffCropSettingsValues';
import ISector from 'components/generics/Interface/Api/Response/Structures/ISector';
import { IExploitation } from 'components/generics/Interface/Api/Response/Structures/IExploitation';
import { IErrorResponse } from 'components/generics/Interface/Commons/IErrorResponse';

// THEMES
import { customDialogCommonTheme } from 'assets/styles/themes/Dialog/generic_dialog_add_culture';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';

type OffCropSettingsModalParams = {
  openOffCropSettingsDialog: boolean;
  setOpenOffCropSettingsDialog: React.Dispatch<React.SetStateAction<boolean>>;
  owner: null | { parent: string; id: number };
  nitrogenPriceValue: IOffCropSettingsValues;
  getOffCropSettings: () => void;
  errorsFormOffCropSettings: IFormErrors;
  setErrorsFormOffCropSettings: React.Dispatch<React.SetStateAction<IFormErrors | undefined>>;
  cooperativeId: number;
  cooperativeName: string;
  nitrogenCover: IOffCropSettingsValues;
  from: string;
  sector: ISector;
  exploitation: IExploitation;
  biomassSupplyByCover: IOffCropSettingsValues;
};

const OffCropSettingsModal = ({
  openOffCropSettingsDialog,
  setOpenOffCropSettingsDialog,
  owner,
  nitrogenPriceValue,
  getOffCropSettings,
  errorsFormOffCropSettings,
  setErrorsFormOffCropSettings,
  cooperativeId,
  cooperativeName,
  nitrogenCover,
  from,
  sector,
  exploitation,
  biomassSupplyByCover,
}: OffCropSettingsModalParams): React.JSX.Element => {
  const [nitrogenValueClone, setNitrogenValueClone] =
    useState<IOffCropSettingsValues>(nitrogenPriceValue);
  const [nitrogenCoverClone, setNitrogenCoverClone] =
    useState<IOffCropSettingsValues>(nitrogenCover);
  const [biomassSupplyByCoverClone, setBiomassSupplyByCoverClone] =
    useState<IOffCropSettingsValues>(biomassSupplyByCover);

  const handleChangeNitrogenPrice = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    const { value } = e.target;

    setNitrogenValueClone((prev) => ({
      ...prev,
      [field]: checkDecimalRegexTwoDigitsDatagridValue(value),
    }));
  };

  const handleChangeNitrogenSupplyByCover = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;

    setNitrogenCoverClone((prev) => ({
      ...prev,
      nitrogenSupplyByCover: checkNoDecimalRegexTwoDigitsDatagridValue(value),
    }));
  };

  const handleChangeBiomassSupplyByCover = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;

    setBiomassSupplyByCoverClone((prev) => ({
      ...prev,
      biomassSupplyByCover: checkEmptyFieldAndDecimalRegexDatagridValue(value, 2),
    }));
  };

  const handleOffCropSettingsClick = async () => {
    const toUpdate = {
      ...getOnlyAttributeUpdated(nitrogenPriceValue, nitrogenValueClone),
      ...getOnlyAttributeUpdated(nitrogenCover, nitrogenCoverClone),
      ...getOnlyAttributeUpdated(biomassSupplyByCover, biomassSupplyByCoverClone),
    };

    errorsFormOffCropSettings.formError = false;
    if (Object.keys(toUpdate).length > 0) {
      setErrorsFormOffCropSettings({
        ...validateFormData(toUpdate, errorsFormOffCropSettings),
      });
    }

    if (!errorsFormOffCropSettings.formError) {
      if (Object.keys(toUpdate).length > 0) {
        let formData = new FormData();
        if (toUpdate.hasOwnProperty('nitrogenSupplyByCover')) {
          delete toUpdate.nitrogenSupplyByCover;

          toUpdate.nitrogenSupplyByCover =
            toUpdate.nitrogenSupplyByCover ?? nitrogenCoverClone.nitrogenSupplyByCover;

          formData.append(
            'nitrogenSupplyByCover',
            toUpdate.nitrogenSupplyByCover ?? nitrogenCoverClone.nitrogenSupplyByCover
          );
        }

        if (
          toUpdate.hasOwnProperty('nitrogenPrice') ||
          toUpdate.hasOwnProperty('nitrogenPriceN5')
        ) {
          toUpdate.economicAttributeCollection = {
            nitrogenPrice: {
              currentEconomicValue: toUpdate.nitrogenPrice ?? nitrogenValueClone.nitrogenPrice,
              futureEconomicValue: toUpdate.hasOwnProperty('nitrogenPriceN5')
                ? String(
                    copyCurrentValueToFuture(
                      toUpdate.nitrogenPriceN5,
                      nitrogenValueClone.nitrogenPriceN5
                    )
                  )
                : nitrogenValueClone.nitrogenPriceN5,
            },
          };

          formData.append(
            'economicAttributeCollection[nitrogenPrice][currentEconomicValue]',
            toUpdate.nitrogenPrice ?? nitrogenValueClone.nitrogenPrice
          );

          formData.append(
            'economicAttributeCollection[nitrogenPrice][futureEconomicValue]',
            toUpdate.hasOwnProperty('nitrogenPriceN5')
              ? String(
                  copyCurrentValueToFuture(
                    toUpdate.nitrogenPriceN5,
                    nitrogenValueClone.nitrogenPriceN5
                  )
                )
              : String(nitrogenValueClone.nitrogenPriceN5)
          );

          toUpdate.hasOwnProperty('nitrogenPrice') && delete toUpdate.nitrogenPrice;
          toUpdate.hasOwnProperty('nitrogenPriceN5') && delete toUpdate.nitrogenPriceN5;
        }

        if (toUpdate.economicAttributeCollection) {
          setNitrogenValueClone((prev) => ({
            ...prev,
            economicAttributeCollection: toUpdate.economicAttributeCollection,
          }));
        }

        if (toUpdate.hasOwnProperty('biomassSupplyByCover')) {
          toUpdate.biomassSupplyByCover =
            toUpdate.biomassSupplyByCover ?? biomassSupplyByCoverClone;

          formData.append(
            'biomassSupplyByCover',
            toUpdate.biomassSupplyByCover ?? biomassSupplyByCoverClone
          );
        }

        try {
          if (!owner) {
            updateStructure(formData, cooperativeId);
            toast.success(`Cooperative ${cooperativeName} mis à jour`);
            setErrorsFormOffCropSettings((prev) => {
              if (
                prev?.nitrogenSupplyByCover &&
                typeof prev.nitrogenSupplyByCover === 'object' &&
                prev?.nitrogenPrice &&
                typeof prev.nitrogenPrice === 'object' &&
                prev?.nitrogenPriceN5 &&
                typeof prev.nitrogenPriceN5 === 'object' &&
                prev?.biomassSupplyByCover &&
                typeof prev.biomassSupplyByCover === 'object'
              ) {
                return {
                  ...prev,
                  formError: false,
                  nitrogenPrice: {
                    ...prev.nitrogenPrice,
                    message: '',
                  },
                  nitrogenPriceN5: {
                    ...prev.nitrogenPriceN5,
                    message: '',
                  },
                  nitrogenSupplyByCover: {
                    ...prev.nitrogenSupplyByCover,
                    message: '',
                  },
                  biomassSupplyByCover: {
                    ...prev.biomassSupplyByCover,
                    message: '',
                  },
                };
              }
            });
          } else if (owner.parent === 'fromSector') {
            toUpdate.economicAttributeCollection = {
              id: owner.id,
              ...toUpdate,
            };
            await updateSecteur(toUpdate.economicAttributeCollection);
            toast.success(`Secteur ${sector?.name} mis à jour`);
          } else {
            await updateExploitation(toUpdate, owner.id);
            toast.success(`Exploitation ${exploitation?.name} mis à jour`);
          }
          getOffCropSettings();
        } catch (error) {
          const apiError = error as IErrorResponse;
          toast.error(apiError?.response?.data?.message);
        }
      }
      setOpenOffCropSettingsDialog(false);
    }
  };

  const handleCloseOffCropSettingsDialog = () => {
    setOpenOffCropSettingsDialog(false);

    setErrorsFormOffCropSettings((prev) => {
      if (
        prev?.nitrogenSupplyByCover &&
        typeof prev.nitrogenSupplyByCover === 'object' &&
        prev?.nitrogenPrice &&
        typeof prev.nitrogenPrice === 'object' &&
        prev?.nitrogenPriceN5 &&
        typeof prev.nitrogenPriceN5 === 'object' &&
        prev?.biomassSupplyByCover &&
        typeof prev.biomassSupplyByCover === 'object'
      ) {
        return {
          ...prev,
          formError: false,
          nitrogenPrice: {
            ...prev.nitrogenPrice,
            message: '',
          },
          nitrogenPriceN5: {
            ...prev.nitrogenPriceN5,
            message: '',
          },
          nitrogenSupplyByCover: {
            ...prev.nitrogenSupplyByCover,
            message: '',
          },
          biomassSupplyByCover: {
            ...prev.biomassSupplyByCover,
            message: '',
          },
        };
      }
    });
  };

  const isOpen = useMemo(() => errorsFormOffCropSettings.formError, [errorsFormOffCropSettings]);

  return (
    <ThemeProvider theme={customDialogCommonTheme}>
      <Dialog
        maxWidth="md"
        fullWidth
        open={openOffCropSettingsDialog}
        onClose={handleCloseOffCropSettingsDialog}
        id="off_crop_settings_dialog"
      >
        <div className="add_culture_container">
          <Box>
            <DialogTitle>Paramétrage hors culture</DialogTitle>
            <Clear className="close-icon" onClick={handleCloseOffCropSettingsDialog} />
          </Box>
          <DialogContent>
            <Box id="custom-box-content">
              <Box className="wrapper-box-off-crop-settings">
                <h4 className="title-off-crop-settings">Azote</h4>
                <Box>
                  <InputLabel id="nitrogenPriceLabel" htmlFor="nitrogenPrice">
                    Coût de l'azote (€/u)
                  </InputLabel>
                  {(
                    errorsFormOffCropSettings?.[
                      'nitrogenPrice' as keyof IFormErrors
                    ] as IFormErrorObjects
                  ).message && (
                    <>
                      <StyledTooltipErrorInputCostModal
                        open={isOpen}
                        title={
                          (
                            errorsFormOffCropSettings?.[
                              'nitrogenPrice' as keyof IFormErrors
                            ] as IFormErrorObjects
                          ).message
                        }
                        placement="top"
                      >
                        <span></span>
                      </StyledTooltipErrorInputCostModal>
                    </>
                  )}
                  <TextField
                    id="nitrogenPrice"
                    value={nitrogenValueClone?.nitrogenPrice}
                    onChange={(e) => handleChangeNitrogenPrice(e, 'nitrogenPrice')}
                    className={
                      (
                        errorsFormOffCropSettings?.[
                          'nitrogenPrice' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                        ? 'MuiErrorForm'
                        : ''
                    }
                    placeholder="Entrez un coût de l'azote"
                    autoFocus={true}
                  />
                </Box>

                <Box>
                  <InputLabel id="nitrogenPriceN5Label" htmlFor="nitrogenPriceN5">
                    Coût de l'azote N+5 (€/u)
                  </InputLabel>
                  {(
                    errorsFormOffCropSettings?.[
                      'nitrogenPriceN5' as keyof IFormErrors
                    ] as IFormErrorObjects
                  ).message && (
                    <>
                      <StyledTooltipErrorInputCostModal
                        open={isOpen}
                        title={
                          (
                            errorsFormOffCropSettings?.[
                              'nitrogenPriceN5' as keyof IFormErrors
                            ] as IFormErrorObjects
                          ).message
                        }
                        placement="top"
                      >
                        <span></span>
                      </StyledTooltipErrorInputCostModal>
                    </>
                  )}
                  <TextField
                    id="nitrogenPriceN5"
                    value={nitrogenValueClone?.nitrogenPriceN5}
                    onChange={(e) => handleChangeNitrogenPrice(e, 'nitrogenPriceN5')}
                    className={
                      (
                        errorsFormOffCropSettings?.[
                          'nitrogenPriceN5' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                        ? 'MuiErrorForm'
                        : ''
                    }
                    placeholder="Entrez un coût de l'azote N+5"
                  />
                </Box>
                <CustomNitrogenSupplyByCover
                  errorsFormOffCropSettings={errorsFormOffCropSettings}
                  owner={
                    from !== 'fromCooperative'
                      ? { parent: from, id: sector ? sector.id : exploitation.id }
                      : null
                  }
                  nitrogenCoverClone={nitrogenCoverClone}
                  handleChangeNitrogenSupplyByCover={handleChangeNitrogenSupplyByCover}
                  isOpen={isOpen}
                />
              </Box>
              <Box className="wrapper-box-off-crop-settings">
                <h4 className="title-off-crop-settings">Matière organique</h4>
                <Box>
                  <InputLabel id="biomassSupplyByCoverLabel" htmlFor="biomassSupplyByCover">
                    Qté MS d'un couvert <br />
                    (t MS/ha)
                  </InputLabel>
                  {(
                    errorsFormOffCropSettings?.[
                      'biomassSupplyByCover' as keyof IFormErrors
                    ] as IFormErrorObjects
                  ).message && (
                    <>
                      <StyledTooltipErrorInputCostModal
                        open={isOpen}
                        title={
                          (
                            errorsFormOffCropSettings?.[
                              'biomassSupplyByCover' as keyof IFormErrors
                            ] as IFormErrorObjects
                          ).message
                        }
                        placement="top"
                      >
                        <span></span>
                      </StyledTooltipErrorInputCostModal>
                    </>
                  )}
                  <TextField
                    id="biomassSupplyByCover"
                    value={biomassSupplyByCoverClone?.biomassSupplyByCover}
                    onChange={(e) => handleChangeBiomassSupplyByCover(e)}
                    disabled={owner ? true : false}
                    className={
                      (
                        errorsFormOffCropSettings?.[
                          'biomassSupplyByCover' as keyof IFormErrors
                        ] as IFormErrorObjects
                      ).message
                        ? 'MuiErrorForm'
                        : ''
                    }
                    placeholder="Entrez une quantité de matière sèche d'un couvert"
                  />
                </Box>
              </Box>
              <ThemeProvider theme={customButtonTheme}>
                <Box>
                  <Button onClick={handleOffCropSettingsClick}>Enregistrer</Button>
                </Box>
              </ThemeProvider>
            </Box>
          </DialogContent>
        </div>
      </Dialog>
    </ThemeProvider>
  );
};

export default OffCropSettingsModal;
