import { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Select, MenuItem, Checkbox } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';
import { mediaQuery } from './CustomGenericDataGridUtils';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { customCommonDataGridTheme } from 'assets/styles/themes/DataGrid/common_datagrid';

export const RenderEditForecastCell = (props) => {
  const {
    id,
    value,
    field,
    exploitationContext,
    row: { forecast },
  } = props;
  const apiRef = useGridApiContext();
  const [open, setOpen] = useState(true);

  const handleChange = async (event) => {
    await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
    apiRef.current.stopCellEditMode({ id, field });
  };

  const handleClose = () => {
    setOpen(false);
    apiRef.current.stopCellEditMode({ id, field });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const options = culturesSelectValues(forecast, exploitationContext);

  return (
    <Select
      value={value}
      onChange={handleChange}
      size="small"
      autoFocus
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      className="forecast_select"
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

export const CustomForecastCell = (params, stackedRequestsRef = null) => {
  const { id } = params;
  let hasBeenUpdated = false;

  if (stackedRequestsRef?.current.length) {
    stackedRequestsRef?.current.forEach((request) => {
      if (request?.rowId === id && request?.forecast) {
        hasBeenUpdated = true;
      }
    });
  }

  return (
    <Box className={`cellBox ${hasBeenUpdated ? 'cellHasBeenUpdated' : ''}`}>
      <div>{params.value}</div>
      {params.isEditable && <ArrowDropDownIcon />}
    </Box>
  );
};

export const CustomForecastHeader = (currentYear, type) => {
  return (
    <p>
      <span>{`${currentYear}-${currentYear + 1}`}</span> ({type})
    </p>
  );
};

export const customFixedCell = (params, stackedRequestsRef = null) => {
  const { id, value, isEditable } = params;
  let hasBeenUpdated = false;

  if (stackedRequestsRef?.current.length) {
    stackedRequestsRef?.current.forEach((request) => {
      if (request?.rowId === id && 'updatedFixed' in request) {
        hasBeenUpdated = true;
      }
    });
  }
  return value ? (
    <div className={`forecast_checkbox ${hasBeenUpdated ? 'cellHasBeenUpdated' : ''}`}>
      <CheckIcon /> {isEditable && <ArrowDropDownIcon />}
    </div>
  ) : (
    <div className={`forecast_checkbox ${hasBeenUpdated ? 'cellHasBeenUpdated' : ''}`}>
      <CloseIcon /> {isEditable && <ArrowDropDownIcon />}
    </div>
  );
};

export const CustomEditFixedCell = (props) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();
  const [checked, setChecked] = useState(value);

  const handleChange = async (event) => {
    setChecked(event.target.checked);
    await apiRef.current.setEditCellValue({ id, field, value: event.target.checked });
    apiRef.current.stopCellEditMode({ id, field });
  };

  const label = { inputProps: { 'aria-label': 'Fixed Culture Checkbox' } };

  return <Checkbox {...label} checked={checked} onClick={handleChange} size="small" />;
};

export const culturesSelectValues = (forecast, exploitationContext) => {
  if (exploitationContext.cultures) {
    if (forecast === 'Aucun forecast') {
      return ['Aucun forecast', ...exploitationContext?.cultures?.map((culture) => culture.name)];
    }
    return [...exploitationContext?.cultures?.map((culture) => culture.name)];
  }
};

export const updateExploitationContext = (setExploitationContext, data) =>
  setExploitationContext((prevExploitationContext) => {
    const filterDeletedForecast = prevExploitationContext?.forecasts?.filter(
      (prevForecast) => !data.some((datum) => prevForecast?.parcel?.id === datum?.parcel?.id)
    );
    return {
      ...prevExploitationContext,
      forecasts: [...filterDeletedForecast, ...data],
    };
  });

export const themeFilters = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          m: 1,
          width: 300,
          border: '1px solid #e69636 !important',
          borderRadius: '5px',
          maxHeight: '50px !important',
          margin: '5px',
          '.MuiOutlinedInput-root.Mui-focused fieldset': {
            borderColor: '#e69636 !important',
            top: '-2px !important',
          },
          '.MuiOutlinedInput-root.Mui-focused legend': {
            display: 'none !important',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#e69636 !important',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          maxHeight: '50px !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87) !important',
          maxHeight: '50px !important',
          '.MuiSelect-select': {
            padding: '15px 25px 10px 13px !important',
          },
          '.MuiSelect-select.Mui-disabled': {
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.65) !important',
          },
          '.MuiSvgIcon-root.MuiSelect-icon': {
            color: '#277134 !important',
          },
          'legend span': {
            display: 'none !important',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '.MuiSvgIcon-root.MuiSelect-icon': {
            color: '#FFF !important',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#e69636 !important',
          '&.Mui-checked': {
            color: '#277134 !important',
          },
        },
      },
    },
  },
});

export const customDataGridResultTheme = createTheme(customCommonDataGridTheme, {
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: '1px solid #277134',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          borderBottomLeftRadius: '0px !important',
          borderBottomRightRadius: '0px !important',
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            border: 'none',
            outline: 'none',
          },
          '& .MuiDataGrid-scrollbar': {
            zIndex: 1,
          },
          '& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
            border: 'none',
            outline: 'none',
            '& :focus-visible': {
              border: 'none',
              outline: 'none',
            },
          },
          '& .MuiDataGrid-virtualScroller': {
            maxHeight: '100vh',
            overflowY: 'auto !important',
            overflowX: 'hidden !important',
            [`@media (max-width: ${mediaQuery.breakpoints.values.lg}px)`]: {
              maxHeight: '51vh',
            },
          },
          '& .MuiDataGrid-columnHeadersInner': { width: '100%' },
          '& .MuiDataGrid-columnHeadersInner .css-yrdy0g-MuiDataGrid-columnHeaderRow': {
            width: '100%',
          },
          '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
            boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
            backgroundColor: 'transparent ',
          },
        },
        main: {
          fontFamily: 'Rubik-Medium',
          fontSize: '16px',
          color: '#707070',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          borderBottomLeftRadius: '0px !important',
          borderBottomRightRadius: '0px !important',
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#e69636',
            color: 'white',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #e69636',
          borderLeft: '1px solid #e69636',
          borderRight: '1px solid #e69636',
          fontFamily: 'Rubik',
          fontSize: '14px',
          color: '#707070',
          ':hover': {
            color: '#e69636',
            fontFamily: 'Rubik-Medium',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingBottom: '1px',
          paddingTop: '1px',
          '& li:nth-of-type(1)': {
            borderTop: '1px solid #e69636',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: '#e69636 !important',
          '&.Mui-checked': {
            color: '#277134 !important',
            '&.MuiDataGrid-columnHeaderTitleContainer': {
              color: '#277134 !important',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'unset !important',
          borderWidth: '0px !important',
        },
      },
    },
  },
});

export const parcelSynthesisTheme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: '1px solid #277134',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          borderBottomLeftRadius: '0px !important',
          borderBottomRightRadius: '0px !important',
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            border: 'none',
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeadersInner': { width: '100%' },
          '& .MuiDataGrid-columnHeadersInner .css-yrdy0g-MuiDataGrid-columnHeaderRow': {
            width: '100%',
          },
          '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
            boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
            backgroundColor: 'transparent ',
          },
          '& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
            border: 'none',
            outline: 'none',
            '& :focus-visible': {
              border: 'none',
              outline: 'none',
            },
          },
          '& .MuiDataGrid-virtualScroller': {
            maxHeight: '100vh',
            overflowY: 'auto !important',
            overflowX: 'hidden !important',
            [`@media (max-width: ${mediaQuery.breakpoints.values.lg}px)`]: {
              maxHeight: '51vh',
            },
          },
          fontFamily: 'Rubik-Medium',
          fontSize: '16px',
          color: '#707070',
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#277134',
            color: 'white',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingBottom: '1px',
          paddingTop: '1px',
          '& li:nth-of-type(1)': {
            borderTop: '1px solid #e69636',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #e69636',
          borderLeft: '1px solid #e69636',
          borderRight: '1px solid #e69636',
          fontFamily: 'Rubik',
          fontSize: '14px',
          color: '#707070',
          ':hover': {
            color: '#e69636',
            fontFamily: 'Rubik-Medium',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'unset !important',
          borderWidth: '0px !important',
        },
      },
    },
  },
});

export const customDataGridResultSimulation = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: '1px solid #277134',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          borderBottomLeftRadius: '0px !important',
          borderBottomRightRadius: '0px !important',
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            border: 'none',
            outline: 'none',
          },
        },
        main: {
          fontFamily: 'Rubik-Medium',
          fontSize: '16px',
          color: '#707070',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          borderBottomLeftRadius: '0px !important',
          borderBottomRightRadius: '0px !important',
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#277134',
            color: 'white',
          },
        },
      },
    },
  },
});

export const customForecastDataGridTheme = createTheme(
  customCommonDataGridTheme,
  {
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: '1px solid #277134 !important',
            '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
              border: 'none',
              outline: 'none',
            },
            '& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
              border: 'none',
              outline: 'none',
              '& :focus-visible': {
                border: 'none',
                outline: 'none',
              },
            },
            '& .MuiDataGrid-virtualScroller': {
              maxHeight: '100vh',
              overflowY: 'auto !important',
              overflowX: 'hidden !important',
              [`@media (max-width: ${mediaQuery.breakpoints.values.lg}px)`]: {
                maxHeight: '43vh',
              },
            },
            '& .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-root': {
              color: '#277134 !important',
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'pre-wrap !important',
              textAlign: 'center',
            },
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { padding: '5px' },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { padding: '9px' },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { padding: '14px' },
            '& .MuiDataGrid-ParcelChild--row': {
              backgroundColor: '#d6d6d6 !important',
            },
          },
          main: {
            fontFamily: 'Rubik-Medium',
            fontSize: '16px',
            color: '#707070',
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#e69636',
              color: 'white',
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            border: '1px solid #e69636',
            fontFamily: 'Rubik',
            fontSize: '14px',
            color: '#707070',
            ':hover': {
              color: '#e69636',
              fontFamily: 'Rubik-Medium',
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          colorPrimary: {
            color: '#e69636 !important',
            '&.Mui-checked': {
              color: '#277134 !important',
            },
            '&.Mui-disabled': {
              display: 'none',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'unset !important',
            borderWidth: '0px !important',
          },
        },
      },
    },
  },
  frFR
);

export const isSameAsOriginal = (field, parcels, updatedRow) =>
  parcels.some((parcel) => {
    if (field === 'surface') {
      return parcel.id === updatedRow.id && Number(parcel?.[field]) === Number(updatedRow[field]);
    }
    if (field === 'isIrrigable' || field === 'name') {
      return parcel.id === updatedRow.id && parcel?.[field] === updatedRow[field];
    }

    return parcel.id === updatedRow.id && parcel?.[field]?.name === updatedRow[field];
  });
