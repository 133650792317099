import Select from 'components/generics/Select';

const ExploitationTableRowGeofolia = ({
  data,
  index,
  handleData,
  deleteData,
  checked,
  setChecked,
  selectedExploitationsTypes,
  setSelectedExploitationsTypes,
  selectedSectors,
  setSelectedSectors,
}) => {
  const isChecked = checked?.find(
    (checkedExploitation) => checkedExploitation.code === data.exploitation.code
  ).checked;

  const getExploitationType = selectedExploitationsTypes?.find(
    (selectedExploitation) => selectedExploitation.code === data.exploitation.code
  ).exploitationType;

  const getExploitationSector = selectedSectors?.find(
    (selectedSector) => selectedSector.code === data.exploitation.code
  ).sectorId;

  const handleChange = (e, from) => {
    let dataToHandle;
    switch (from) {
      case 'secteur':
        setSelectedSectors((prevSelectedSectors) =>
          prevSelectedSectors.map((prevSelectedSector) =>
            prevSelectedSector.code === data.exploitation.code
              ? { ...prevSelectedSector, sectorId: e }
              : { ...prevSelectedSector }
          )
        );
        dataToHandle = {
          exploitationId: +data.exploitation.id,
          sectorId: +e,
          exploitationTypeId: +getExploitationType,
          code: data.exploitation.code,
        };
        handleData(dataToHandle, index);
        break;
      case 'exploitation':
        setSelectedExploitationsTypes((prevSelectedExploitationsTypes) =>
          prevSelectedExploitationsTypes.map((prevSelectedExploitation) =>
            prevSelectedExploitation.code === data.exploitation.code
              ? { ...prevSelectedExploitation, exploitationType: e }
              : { ...prevSelectedExploitation }
          )
        );
        dataToHandle = {
          exploitationId: +data.exploitation.id,
          sectorId: +getExploitationSector,
          exploitationTypeId: +e,
          code: data.exploitation.code,
        };
        handleData(dataToHandle, index);
        break;
      case 'checkbox':
        setChecked((prevChecked) =>
          prevChecked.map((prevCheckedExploitation) =>
            prevCheckedExploitation.code === data.exploitation.code
              ? { ...prevCheckedExploitation, checked: !isChecked }
              : { ...prevCheckedExploitation }
          )
        );
        if (isChecked) {
          deleteData(data.exploitation.code, index);
        } else {
          dataToHandle = {
            exploitationId: +data.exploitation.id,
            sectorId: +getExploitationSector,
            exploitationTypeId: +getExploitationType,
            code: data.exploitation.code,
          };
          handleData(dataToHandle, index);
        }
        break;
      default:
        break;
    }
  };

  return (
    <tr>
      <td>
        <input
          type={'checkbox'}
          checked={isChecked}
          onChange={(e) => handleChange(e, 'checkbox')}
        />
      </td>
      <td>{data.exploitation.name}</td>
      <td>
        <Select
          className="reduce"
          name="secteur"
          options={[
            { id: 0, name: 'Secteurs Assolia' },
            ...data.secteur.sort((a, b) => a.name.localeCompare(b.name)),
          ]}
          value={getExploitationSector}
          itemLabel="name"
          itemValue="id"
          disabled={!isChecked || !!data.exploitation.sectorId || !!data.exploitation?.sector?.id}
          onChange={(e) => handleChange(e, 'secteur')}
        />
      </td>
      <td>
        <Select
          className="reduce"
          name="exploitation"
          options={[{ id: 0, exploitationTypeName: "Types d'exploitation" }, ...data.type]}
          value={getExploitationType}
          itemLabel="exploitationTypeName"
          itemValue="id"
          disabled={!isChecked || !!data.exploitation.exploitationTypeName}
          onChange={(e) => handleChange(e, 'exploitation')}
        />
      </td>
    </tr>
  );
};

export default ExploitationTableRowGeofolia;
