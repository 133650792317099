// REACT
import { useState } from 'react';
// YARN
import { GridValidRowModel } from '@mui/x-data-grid-pro';
import { Select, MenuItem, ThemeProvider, Button, FormControl } from '@mui/material';
import More from 'components/icons/More';
import { toast } from 'react-toastify';
// SERVICES
import useAuth from 'providers/Auth/useAuth';
import AddEditTechnician from './form/AddEditTechnician';
import { StructureTechniciansDataGrid } from './StructureTechniciansDataGrid';
import { linkUserToSector } from 'services/API/Cooperative';
import { displayErrorMessage } from 'utils/tools_functions';
// INTERFACES
import BasicObject from 'components/generics/Interface/Api/Common/BasicObject';
import { ITechnician } from 'components/generics/Interface/Api/Response/User/ITechnician';
// THEMES
import { MultiSelectTheme } from 'assets/styles/themes/multi_select_mui';
import { customInvertedButtonTheme } from 'assets/styles/themes/generic_button_mui';
import './structureTechnicians.scss';

type StructureTechniciansParams = {
  sectors: BasicObject[];
  technicians: ITechnician[];
  refreshData: () => void;
};

const StructureTechnicians = ({
  sectors,
  technicians,
  refreshData,
}: StructureTechniciansParams): JSX.Element => {
  const { user } = useAuth();
  const [modal, setModal] = useState<boolean>(false);
  const [editData, setEditData] = useState<GridValidRowModel | null | undefined>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedSector, setSelectedSector] = useState<number>(0);
  const [selectedTechnician, setSelectedTechnician] = useState<number | null>(null);

  const filterBySelectedSector =
    selectedSector !== 0
      ? technicians.filter((technician) => {
          if (technician.sectors) {
            return technician?.sectors?.filter((sector) => sector.id === selectedSector).length > 0;
          }
        })
      : technicians;

  const techniciansNotLinkedToSector = technicians?.filter((technician) => {
    if (technician.sectors) {
      return !(technician?.sectors?.filter((sector) => sector.id === selectedSector).length > 0);
    }
  });

  const findFirstTechnicianToLinkToSector = (selectedSector: number) => {
    return technicians?.filter((technician) => {
      if (technician.sectors) {
        return !(technician?.sectors?.filter((sector) => sector.id === selectedSector).length > 0);
      }
    })[0]?.id;
  };

  const formSuccess = () => {
    setModal(false);
    setEditData(null);
    setIsEdit(false);
    refreshData();
  };

  const handleLinkTechnicianToSector = async () => {
    if (selectedSector === 0) {
      return;
    }
    let selectedTechnicianToLink = selectedTechnician;
    if (selectedTechnicianToLink === null) {
      selectedTechnicianToLink = findFirstTechnicianToLinkToSector(selectedSector);
    }
    let linkTechnicianToSectorBody: {
      id: number;
      sectors: [number];
      cooperative?: number;
    } = {
      id: selectedTechnicianToLink,
      sectors: [selectedSector],
    };
    if (user?.role === 'SUPER_ADMIN') {
      linkTechnicianToSectorBody.cooperative = Number(localStorage.getItem('cooperative'));
    }

    try {
      await linkUserToSector(linkTechnicianToSectorBody);
      toast.success('Technicien ajouté au secteur');
      refreshData();
      setSelectedTechnician(null);
    } catch (error) {
      toast.error(displayErrorMessage('ERR_ADD_LINK_USER_SECTOR'));
    }
  };

  return (
    <div className="section technician-container">
      <div className="main_container">
        <ThemeProvider theme={MultiSelectTheme}>
          <h1 className="title_section">Liste des Techniciens</h1>
          <div className="contain-header">
            {sectors && (
              <FormControl className="formControl">
                <Select
                  label="name"
                  id="sector-select"
                  value={selectedSector}
                  onChange={(e) => setSelectedSector(Number(e.target.value))}
                >
                  <MenuItem value={0} key={0}>
                    Structure
                  </MenuItem>
                  {sectors.map((sector) => (
                    <MenuItem value={sector.id} key={sector.id}>
                      {sector.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <div className="add_button_container">
              <ThemeProvider theme={customInvertedButtonTheme}>
                <Button
                  onClick={() => {
                    setModal(!modal), setIsEdit(false);
                  }}
                >
                  Ajouter
                  <More height={13} width={13} />
                </Button>
              </ThemeProvider>
            </div>
          </div>
          <StructureTechniciansDataGrid
            technicians={filterBySelectedSector}
            refreshData={refreshData}
            setModal={setModal}
            setIsEdit={setIsEdit}
            setEditData={setEditData}
            sector={selectedSector}
          />
          <div className="add-sector-technician-container">
            {selectedSector !== 0 && techniciansNotLinkedToSector.length !== 0 && (
              <>
                <Select
                  label="linkSector"
                  id="link-technician-sector"
                  value={selectedTechnician ?? findFirstTechnicianToLinkToSector(selectedSector)}
                  onChange={(e) => setSelectedTechnician(Number(e.target.value))}
                >
                  {techniciansNotLinkedToSector.map((technician) => (
                    <MenuItem value={technician.id} key={technician.id}>
                      {technician.email}
                    </MenuItem>
                  ))}
                </Select>
                <ThemeProvider theme={customInvertedButtonTheme}>
                  <Button onClick={handleLinkTechnicianToSector}>Ajouter au secteur</Button>
                </ThemeProvider>
              </>
            )}
          </div>
        </ThemeProvider>
      </div>
      {modal && (
        <AddEditTechnician
          setEditData={setEditData}
          modal={modal}
          setModal={setModal}
          onSubmitSuccess={formSuccess}
          edit={editData}
          isEdit={isEdit}
          sector={selectedSector}
        />
      )}
    </div>
  );
};

export default StructureTechnicians;
