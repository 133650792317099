import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getSecteurCultures, getSecteurInformations } from 'services/API/Secteur';

import { useNavigate } from 'react-router-dom';
import { sendEvent } from 'utils/Event';
import DataGridRotateEffects from '../Cooperative/DataGridRotateEffects';
import Sector from 'components/icons/Cooperative';

const RotateEffectsDataGridSector = ({ sectorFromParent }) => {
  const [cultures, setCultures] = useState(null);
  const navigate = useNavigate();
  const [sector, setSector] = useState(sectorFromParent);
  const [rotations, setRotations] = useState([]);

  useEffect(() => {
    if (!sector) {
      if (!sector && localStorage.getItem('sector')) {
        getSecteurInformations(+localStorage.getItem('sector'))
          .then((resp) => {
            setSector(resp.data.data);
          })
          .catch((err) => toast.error(err.data));
      } else {
        toast.info('Selectionnez un secteur');
        navigate('/sector');
      }
    }
    // Dismount
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  useEffect(() => {
    if (sector) {
      refreshData();
    }
  }, [sector]);

  useEffect(() => {
    if (cultures?.length) {
      setRotations(cultures[0]?.rotationEffects);
    }
  }, [cultures]);

  const refreshData = () =>
    getSecteurCultures(sector.id)
      .then((resp) => {
        setCultures(resp?.data?.data?.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch(() => toast.error);

  return (
    <>
      <p className="title_spacing">
        <span className="subtitle_spacing">
          <Sector width={30.0} height={30} fill="$secondary" />
        </span>
        <span className="green_text subtitle_spacing">{sector?.name} </span>
      </p>
      <DataGridRotateEffects
        from={'sectors'}
        cultures={cultures}
        ownerId={sector?.id}
        refreshData={refreshData}
      />
    </>
  );
};

export default RotateEffectsDataGridSector;
