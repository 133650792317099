import React from 'react';

import logo from 'assets/images/logo.png';
import './Login.style.scss';

import ResetForm from 'components/pages/ResetPassword/form';

const ForgotPassword = () => (
  <div className="Login">
    <div className="form">
      <div className="logoContainer">
        <div className="recenterLogo logo m-auto d-block">
          <img className="resizeLogo" src={logo} alt="logo assolia" />
        </div>
      </div>
      <div className="container">
        <p className="text-center title">Nouveau mot de passe</p>
        <ResetForm />
      </div>
    </div>
    <div className="img" />
  </div>
);

export default ForgotPassword;
