import React from 'react';
import { v1 } from 'uuid';
import PropTypes from 'prop-types';

import actionsProps from 'components/propTypes/actions';
import userProps from 'components/propTypes/user';

import TableHead from './TableHead';

import './table.style.scss';

const Table = ({
  header,
  data,
  RowElement,
  actions,
  dataSelectRow,
  irrigationInformation,
  style,
  classCustom,
  handleData,
  deleteData,
  checked,
  setChecked,
  selectedExploitationsTypes,
  setSelectedExploitationsTypes,
  selectedSectors,
  setSelectedSectors,
}) => (
  <div className="table-scroll">
    <table className={`table ${classCustom}`} style={style}>
      <TableHead items={header} />
      <tbody>
        {data?.map((row, idx) => (
          <RowElement
            key={v1()}
            data={row}
            index={idx}
            actions={actions}
            cultures={dataSelectRow}
            irrigationInformation={irrigationInformation?.map((i) => i.irrigationDistribution[idx])}
            handleData={handleData}
            deleteData={deleteData}
            checked={checked}
            setChecked={setChecked}
            selectedExploitationsTypes={selectedExploitationsTypes}
            setSelectedExploitationsTypes={setSelectedExploitationsTypes}
            selectedSectors={selectedSectors}
            setSelectedSectors={setSelectedSectors}
          />
        ))}
      </tbody>
    </table>
  </div>
);

Table.propTypes = {
  header: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(userProps),
  dataSelectRow: PropTypes.arrayOf(PropTypes.any),
  irrigationInformation: PropTypes.arrayOf(PropTypes.any),
  // RowElement: PropTypes.func.isRequired,
  actions: actionsProps,
  style: PropTypes.any,
  classCustom: PropTypes.string,
};

export default Table;
