import { useEffect } from 'react';
import { ROLE_ADMIN, ROLE_FARMER, ROLE_SUPER_ADMIN, ROLE_TECHNICIAN } from 'utils/const';

const useFilteredCulturesLocalStorage = (
  user,
  from,
  filteredCultures,
  userMaskedCulturesIndex,
  lastSelectedRows,
  setLastSelectedRows,
  toggleVisibilityFilter,
  setToggleVisibilityFilter,
  selectedRows,
  structureId,
  sectorId,
  exploitationId
) => {
  const updateMaskedCulturesObject = {
    ...(from === 'fromCooperative' && {
      structure: structureId,
    }),
    ...(from === 'fromSector' && { sector: sectorId }),
    ...(from === 'fromExploitation' && { exploitation: exploitationId }),
    maskedRows: lastSelectedRows,
  };

  const setFilteredCulturesLocalStorage = (array) =>
    localStorage.setItem('filteredCultures', JSON.stringify(array));

  useEffect(() => {
    setLastSelectedRows(
      filteredCultures && userMaskedCulturesIndex !== -1
        ? filteredCultures?.maskedCulturesByLevel[userMaskedCulturesIndex].maskedRows
        : []
    );

    if (filteredCultures && userMaskedCulturesIndex !== -1) {
      setToggleVisibilityFilter(
        filteredCultures?.maskedCulturesByLevel[userMaskedCulturesIndex].maskedRows.length
          ? true
          : false
      );
    }
  }, []);

  useEffect(() => {
    if (toggleVisibilityFilter === false && filteredCultures && selectedRows.length) {
      if (
        selectedRows.length <
        filteredCultures.maskedCulturesByLevel[userMaskedCulturesIndex]?.maskedRows.length
      ) {
        setLastSelectedRows(selectedRows);
        filteredCultures.maskedCulturesByLevel[userMaskedCulturesIndex].maskedRows = selectedRows;
        setFilteredCulturesLocalStorage(filteredCultures);
      }
    } else if (
      toggleVisibilityFilter === false &&
      filteredCultures &&
      !selectedRows.length &&
      userMaskedCulturesIndex !== -1
    ) {
      if (filteredCultures?.maskedCulturesByLevel.length === 1) {
        setLastSelectedRows([]);
        localStorage.removeItem('filteredCultures');
      } else {
        filteredCultures.maskedCulturesByLevel.splice(userMaskedCulturesIndex, 1);
        setFilteredCulturesLocalStorage(filteredCultures);
        setLastSelectedRows(selectedRows);
      }
    }
  }, [selectedRows, filteredCultures, toggleVisibilityFilter]);

  if (filteredCultures) {
    if (
      userMaskedCulturesIndex !== -1 &&
      lastSelectedRows.length >
        filteredCultures?.maskedCulturesByLevel?.[userMaskedCulturesIndex]?.maskedRows.length
    ) {
      filteredCultures.maskedCulturesByLevel[userMaskedCulturesIndex].maskedRows = lastSelectedRows;
    } else if (userMaskedCulturesIndex === -1 && lastSelectedRows.length) {
      filteredCultures.maskedCulturesByLevel.push(updateMaskedCulturesObject);
    }
    setFilteredCulturesLocalStorage(filteredCultures);
  } else if (userMaskedCulturesIndex === -1 && lastSelectedRows.length && selectedRows.length) {
    let maskedCulturesByUser = {};
    switch (user?.role) {
      case 'SUPER_ADMIN':
        maskedCulturesByUser.role = ROLE_SUPER_ADMIN;
        break;
      case 'ADMIN':
        maskedCulturesByUser.role = ROLE_ADMIN;
        break;
      case 'TECHNICIAN':
        maskedCulturesByUser.role = ROLE_TECHNICIAN;
        break;
      case 'FARMER':
        maskedCulturesByUser.role = ROLE_FARMER;
        break;
    }
    maskedCulturesByUser.maskedCulturesByLevel = [updateMaskedCulturesObject];
    setFilteredCulturesLocalStorage(maskedCulturesByUser);
  }
};

export default useFilteredCulturesLocalStorage;
