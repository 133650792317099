// SERVICE
import DataGridGroundTypeEffects from './DataGridGroundTypeEffects';

// INTERFACES
import { ICulture } from 'components/generics/Interface/ICulture';
import { IGroundType } from 'components/generics/Interface/Api/Response/GroundType/IGroundType';

type GroundTypeEffectsStructureParams = {
  groundTypes: IGroundType[];
  cultures: ICulture[];
  ownerId: number;
  refreshData: Function;
};
const GroundTypeEffectsStructure = ({
  groundTypes,
  cultures,
  ownerId,
  refreshData,
}: GroundTypeEffectsStructureParams) => {
  return (
    <DataGridGroundTypeEffects
      from={'cooperatives'}
      cultures={cultures}
      ownerId={ownerId}
      refreshData={refreshData}
      groundTypes={groundTypes}
    />
  );
};

export default GroundTypeEffectsStructure;
