import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import useAuth from 'providers/Auth/useAuth';

import { ROLE_ADMIN } from 'utils/const';
import { displayErrorMessage } from 'utils/tools_functions';
import {
  getConstraintCategories,
  getCoProducts,
  linkCoProductToConstraint,
} from 'services/API/Cooperative';
import { sendEvent } from 'utils/Event';

import CoProductForm from 'components/pages/Cooperative/form/CoProduct';
import CoProductTableRow from 'components/pages/Cooperative/table/CoProductTableRow';

import constraintCoProductProp from 'components/propTypes/constraintCoProduct';

import TableCoProducts from 'components/generics/Table/TableCoProducts';
import IconButton from 'components/generics/Button';
import More from 'components/icons/More';
import ConstraintCategoryForm from './form/ConstraintCategory';
import { FormModal } from 'components/generics/Modals/FormModal';

const ConstraintCoProducts = ({ cooperativeId }) => {
  const { user } = useAuth();
  const [modal, setModal] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [editData, setEditData] = useState(null);
  const [constraintCategories, setConstraintCategories] = useState([]);
  const [coProducts, setCoProducts] = useState([]);
  const [coProductsPerCategory, setCoProductsPerCategory] = useState([]);
  const [selectedFilter] = useState(0);

  useEffect(() => {
    // Dismount
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  useEffect(() => {
    refreshData(cooperativeId);
  }, [cooperativeId]);

  const refreshData = (cooperativeId) => {
    getConstraintCategories(cooperativeId)
      .then((cc) => {
        setConstraintCategories(cc.data.data);
      })
      .catch(() => toast.error(displayErrorMessage('ERR_GET_CONSTRAINT_CAT')));
    getCoProducts()
      .then((v) => {
        setCoProducts(v.data.data);
      })
      .catch((err) => {
        if (err.message !== 'canceled') {
          toast.error(displayErrorMessage('ERR_GET_COPRODUCT'));
        }
      });
  };

  useEffect(() => {
    let tempCoproductsPerCategory = [];
    constraintCategories.map((item) => {
      if (
        !tempCoproductsPerCategory?.find((element) => {
          return element.category.id === item.id;
        })
      ) {
        tempCoproductsPerCategory.push({
          category: { id: item.id, name: item.name, displayName: item.displayName },
          coProducts: [],
        });
      }
      item.coProducts.map((coProduct) => {
        tempCoproductsPerCategory
          .filter((elt) => elt.category.id === item.id)[0]
          .coProducts.push({
            id: coProduct.id,
            name: coProduct.name,
            displayName: coProduct.displayName,
          });
        return tempCoproductsPerCategory;
      });
      return tempCoproductsPerCategory;
    });
    setCoProductsPerCategory(tempCoproductsPerCategory);
  }, [constraintCategories]);

  const formSuccess = () => {
    setModal(false);
    setEditData(null);
    refreshData(cooperativeId);
  };

  const actions = () => {
    const list = [
      {
        label: 'Associer',
        func: (e) => {
          setEditData({
            constraintCategory: e.category,
            coProducts: e.coProducts,
          });
          setModal(true);
        },
        minimalRole: ROLE_ADMIN,
      },
      {
        label: 'Modifier',
        func: (e) => {
          setEditData({
            constraintCategory: e.category,
          });
          setEditCategory(true);
          setModal(true);
        },
        minimalRole: ROLE_ADMIN,
      },
    ];
    if (selectedFilter !== 0) {
      list.push({
        label: 'Supprimer de votre coopérative',
        func: (e) => {
          let cooperativeId = -1;
          if (user?.role === 'SUPER_ADMIN')
            cooperativeId = Number(localStorage.getItem('cooperative'));

          const userToUnlink = {
            id: e.id,
            sectorsToRemove: [selectedFilter],
            cooperative: cooperativeId,
          };
          linkCoProductToConstraint(userToUnlink)
            .then(() => {
              toast.success('Co-produit supprimé de la contrainte');
              refreshData();
            })
            .catch(() => toast.error(displayErrorMessage('ERR_DELETE_LINK_COPRODUCT_CONSTRAINT')));
        },
        minimalRole: ROLE_ADMIN,
      });
    }
    return list;
  };

  return (
    <div className="section">
      <div className="main_container">
        <div className="contain-header">
          <h1 className="title_section">Liste des Co-Produits</h1>
          <div className="add_button_container">
            {!!(
              selectedFilter !== 0 &&
              coProducts?.filter(
                (user) => !(user.sectors?.filter((sect) => sect.id === selectedFilter).length > 0)
              ).length
            ) && (
              <>
                <IconButton
                  text="Ajouter au secteur"
                  defaultStyle="spacing"
                  onClick={() => {
                    let userInEdit = { id: 1, sectors: [selectedFilter] };
                    if (user?.role === 'SUPER_ADMIN') {
                      userInEdit.cooperative = Number(localStorage.getItem('cooperative'));
                    }
                    linkCoProductToConstraint(userInEdit)
                      .then(() => {
                        toast.success('Technicien ajouté au secteur');
                        refreshData();
                      })
                      .catch(() =>
                        toast.error(displayErrorMessage('ERR_ADD_LINK_COPRODUCT_CONSTRAINT'))
                      );
                  }}
                />
              </>
            )}
            <IconButton
              text="Ajouter"
              onClick={() => setModal(!modal)}
              icon={<More width={13} height={13} />}
            />
          </div>
        </div>
        <div className="flex-row"></div>
        <TableCoProducts
          header={['Catégorie', 'Co-Produit', '']}
          data={coProductsPerCategory}
          RowElement={CoProductTableRow}
          actions={actions()}
        />
      </div>
      {modal && (
        <FormModal
          onClick={() => {
            setEditData(null);
            setModal(false);
            setEditCategory(false);
          }}
          customTemplate={
            <div className="content">
              {!editCategory && (
                <CoProductForm
                  onSubmitSuccess={formSuccess}
                  secteur={selectedFilter}
                  edit={editData}
                />
              )}
              {editCategory && (
                <ConstraintCategoryForm
                  onSubmitSuccess={formSuccess}
                  secteur={selectedFilter}
                  edit={editData}
                />
              )}
            </div>
          }
        ></FormModal>
      )}
    </div>
  );
};

ConstraintCoProducts.propTypes = {
  constraintCoProducts: PropTypes.arrayOf(constraintCoProductProp),
  refreshData: PropTypes.func.isRequired,
};

export default ConstraintCoProducts;
