import { useEffect, useState } from 'react';
import useAuth from 'providers/Auth/useAuth';
import { useNavigate } from 'react-router-dom';
import {
  importParcelsResult,
  importParcelsStatus,
  importZipGeofolia,
} from 'services/API/Cooperative';
import { displayErrorMessage, formatFileSize } from 'utils/tools_functions';
import Loader from 'components/generics/Loader';
import Button from 'components/generics/Button';
import { toast } from 'react-toastify';
import './geofolia.scss';

function Geofolia() {
  const [file, setFile] = useState(null);
  const { user } = useAuth();
  const [cooperativeId, setCooperativeId] = useState(null);
  const navigate = useNavigate();
  const [locationRoute, setLocationRoute] = useState('cooperative');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);

  useEffect(() => {
    setLocationRoute(window.location.pathname.split('/')[1]);
  }, []);

  useEffect(() => {
    const cooperativeId = localStorage.getItem('cooperative') ?? user.cooperative.id;
    if (!cooperativeId) {
      toast.info('Selectionnez une cooperative');
      navigate('/cooperative');
    }
    setCooperativeId(cooperativeId);
  }, [user]);

  const importDataFromFile = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      const result = await importZipGeofolia(formData, cooperativeId);
      checkStatus(result.data.jobId);
    } catch (error) {
      toast.error(displayErrorMessage('ERR_IMPORT_GEOFOLIA'));
      setLoading(false);
    }
  };

  const checkStatus = (jobId) => {
    const interval = setInterval(async () => {
      try {
        const response = await importParcelsStatus(jobId);
        const { message } = response.data;
        if (message !== 'En cours') {
          getJobResult(jobId);
          clearInterval(interval);
          if (message === 'Fini sans erreur') {
            toast.success(`Les données du fichier ${file.name} ont été importées.`);
            navigate(`/${locationRoute}/import/geofolia/process`, { state: { jobId } });
          } else {
            toast.error(displayErrorMessage('ERR_COMPLETE_IMPORT'));
          }
        }
      } catch (err) {
        console.log(err);
      }
    }, 3000);
  };

  const getJobResult = async (jobId) => {
    try {
      const response = await importParcelsResult(jobId);
      setResult(response.data);
    } catch (error) {
      toast.error(displayErrorMessage('ERR_COMPLETE_IMPORT'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="section">
      <div className="main_container full-height">
        <h1 className="title_section">Import du fichier .zip Geofolia</h1>
        <div className="geofoliaImportContainer">
          {file ? (
            <div className="information-file">
              <span className="importDetailsTitle">
                Nom du fichier : <span className="importDetailsDescription">{file.name}</span>
              </span>
              <span className="importDetailsTitle" style={{ marginTop: 20 }}>
                Taille du fichier :{' '}
                <span className="importDetailsDescription">{formatFileSize(file.size)}</span>
              </span>
              {loading ? <Loader /> : <></>}
              <div className="buttonUpload">
                <div className="buttonContainer">
                  <Button
                    text="Importer les données"
                    onClick={() => importDataFromFile()}
                    disabled={loading || !!result}
                  />
                </div>
                <div className="buttonContainer">
                  <Button
                    text="Importer un nouveau fichier"
                    onClick={() => {
                      setFile(null);
                      setResult(null);
                    }}
                    backgroundColor="#e74c3c"
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <input
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                accept="application/zip, application/x-7z-compressed, application/x-tar, application/x-rar-compressed"
                id="file"
                className="input-file"
              />
              <label htmlFor="file" style={{ margin: 'auto' }}>
                Parcourir
              </label>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Geofolia;
