import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import API from 'services/API';
import RoutesList from './Routes';
import { subscribe } from 'utils/Event';
import { LoadingProvider, NavProvider } from 'utils/context';

const Router = () => {
  useEffect(() => {
    let controller = new AbortController();

    // Interceptors axios to allow some cancel http request
    API.interceptors.request.use((config) => {
      subscribe('cancelHttpRequest', () => {
        controller.abort();
        controller = new AbortController();
      });
      return {
        ...config,
        signal: controller.signal,
      };
    });
  }, []);

  return (
    <BrowserRouter>
      <NavProvider>
        <LoadingProvider>
          <RoutesList />
        </LoadingProvider>
      </NavProvider>
    </BrowserRouter>
  );
};

export default Router;
