import { Box, Tabs, Tab, Button as MButton, ThemeProvider } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from 'components/generics/Button';
import MapParcels from 'components/generics/Map/MapParcels';
import More from 'components/icons/More';
import SaveIcon from '@mui/icons-material/Save';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addForecast,
  getArableSurface,
  getCulturesByExploitationId,
  getForecastsByExploitation,
  getGroundTypeByExploitationId,
  getParcelsByExploitation,
  updateParcel,
} from 'services/API/Exploitation';
import { sendEvent } from 'utils/Event';
import { ArableContext, ExploitationContext } from 'utils/context';
import { displayErrorMessage } from 'utils/tools_functions';
import ParcelsForecast from './ParcelsForecast';
import { emptyCulture } from 'utils/culturesColors';
import DataGridParcels from './DataGridParcels';
import GroupParcelModal from './GroupParcelModal';
import AddEditParcelModal from './form/AddEditParcelModal';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';
import { updateExploitationContext } from 'utils/Datagrid/CustomForecastDataGridUtils';

const Parcels = ({ exploitation, setExploitation }) => {
  const [exploit, setExploit] = useState(exploitation);
  const [parcelUpdated, setParcelUpdated] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalGroup, setModalGroup] = useState(false);
  const [editData, setEditData] = useState(null);
  const [coordsExist, setCoordsExist] = useState(false);
  const [mapTab, setMap] = useState(false);
  const [dataGridLoading, setDataGridLoading] = useState(false);
  const [loadingComplete, setloadingComplete] = useState(false);
  const [value, setValue] = useState(0);
  const [isAllowedSubmitForecasts, setIsAllowedSubmitForecasts] = useState(false);
  const [isAllowedSubmitParcels, setIsAllowedSubmitParcels] = useState(false);
  const { setArableSurface } = useContext(ArableContext);
  const { exploitationContext, setExploitationContext, setParcelsInformations } =
    useContext(ExploitationContext);
  const currentYear = exploitationContext?.currentYear;
  const navigate = useNavigate();
  const stackedForecastRequestsRef = useRef([]);
  const stackedParcelsRequestsRef = useRef([]);
  const formSuccess = async () => {
    setModal(false);
    setModalGroup(false);
    setEditData(null);
    loadData();
  };

  useEffect(() => {
    if (!exploit) {
      if (!exploit && localStorage.getItem('exploitation')) {
        setExploitation(+localStorage.getItem('exploitation'))
          .then((resp) => setExploit(resp.data.data))
          .catch((err) => toast.error(err.data));
      } else {
        toast.info('Selectionnez une exploitation');
        navigate('/exploitation');
      }
    }
    // Dismount
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  useEffect(() => {
    if (exploit) {
      loadData();
    }
  }, [exploit]);

  const loadData = async () => {
    try {
      setDataGridLoading(true);

      const results = await Promise.all([
        getParcelsByExploitation(exploit.id),
        getCulturesByExploitationId(exploit.id),
        getForecastsByExploitation(exploit.id),
        getGroundTypeByExploitationId(exploit.id),
        getArableSurface(exploit.id),
      ]);

      setExploitationContext((prevExploitationContext) => ({
        ...prevExploitationContext,
        parcels: results[0].data.data,
        cultures: results[1].data.data.sort((a, b) => a.name.localeCompare(b.name)),
        forecasts: results[2].data.data,
        currentYear: +localStorage.getItem('currentYear'),
        groundTypes: results[3].data.data,
      }));

      setloadingComplete(true);
      setCoordsExist(results[0].data.data.find((p) => p.coordinates?.length > 0));
      setParcelsInformations(results[0].data.data);
      setArableSurface(results[4].data.data);
      setParcelUpdated(!parcelUpdated);
      setDataGridLoading(false);
    } catch (error) {
      if (error.message !== 'canceled') {
        toast.error(displayErrorMessage('ERR_GET_PARCEL'));
      }
    }
  };

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const showMap = (bool) => {
    setMap(bool);
  };

  const updateMapParcel = (parcelId) => {
    const parcelToUpdate = exploitationContext?.parcels.find((parcel) => parcel.id === parcelId);
    const parcelForecast = exploitationContext?.forecasts?.find(
      (forecast) =>
        forecast.parcel.id === parcelToUpdate.id &&
        forecast.year.year === exploitationContext?.currentYear + 1
    );
    setEditData({
      name: parcelToUpdate.name,
      surface: parcelToUpdate.surface,
      isIrrigable: parcelToUpdate.isIrrigable,
      groundType: parcelToUpdate.groundType.id || null,
      cultureN: parcelToUpdate?.cultureN.id || `EMPTY`,
      cultureN1: parcelToUpdate?.cultureN1?.id || `EMPTY`,
      forecast: parcelForecast?.culture.id || 0,
      id: parcelToUpdate.id,
      parentId: parcelToUpdate.parent,
      isParent: parcelToUpdate.isParent,
    });
    setModal(true);
  };

  const ExploitationParcelsMetadata = [
    {
      field: 'name',
      position: 1,
      editable: true,
    },
    {
      field: 'surface',
      position: 2,
      editable: true,
    },
    {
      field: 'isIrrigable',
      position: 3,
      editable: true,
    },
    {
      field: 'groundType',
      position: 4,
      editable: true,
    },
    {
      field: 'cultureN1',
      position: 5,
      editable: true,
    },
    {
      field: 'cultureN',
      position: 6,
      editable: true,
    },
  ];

  const findIdByName = (data, key) => {
    if (key === 'Pas de culture associée') {
      return null;
    }
    return exploitationContext[data].find((datum) => datum.name === key).id;
  };

  const updateParcelsExploitationContext = (data) => {
    const updatedParcels = data
      .map((parcel) => {
        if (parcel.children) {
          const clone = structuredClone(parcel);
          delete parcel.children;
          return [...clone?.children, parcel];
        }

        return parcel;
      })
      .flat();

    const allParcels = exploitationContext?.parcels.map((parcel) => {
      const updatedParcel = updatedParcels.find((updatedParcel) => updatedParcel.id === parcel.id);
      if (updatedParcel && parcel.id === updatedParcel.id) {
        return updatedParcel;
      }
      return parcel;
    });

    setExploitationContext((prevExploitationContext) => ({
      ...prevExploitationContext,
      parcels: allParcels,
    }));
  };

  const handleSubmit = async () => {
    if (stackedParcelsRequestsRef.current.length) {
      stackedParcelsRequestsRef.current.map((parcelToUpdate) => {
        if (parcelToUpdate.hasOwnProperty('groundType')) {
          parcelToUpdate.groundType = findIdByName('groundTypes', parcelToUpdate['groundType']);
        }
        if (parcelToUpdate.hasOwnProperty('surface')) {
          parcelToUpdate.surface = Number(parcelToUpdate.surface);
        }

        const cultureKeysToUpdate = ['cultureN', 'cultureN1'];
        let parcelCultures = [];
        cultureKeysToUpdate.map((key) => {
          if (parcelToUpdate.hasOwnProperty(key)) {
            parcelCultures = [
              ...parcelCultures,
              ...(parcelToUpdate[key]
                ? [
                    {
                      year: key === 'cultureN1' ? currentYear - 1 : currentYear,
                      culture: findIdByName('cultures', parcelToUpdate[key]),
                    },
                  ]
                : []),
            ].filter((object) => object);

            parcelToUpdate.parcelCultures = parcelCultures;
            delete parcelToUpdate[key];
          }
        });
      });

      try {
        const result = await updateParcel(exploit.id, stackedParcelsRequestsRef.current);
        const { data } = result.data;
        toast.success('Les parcelles ont été mises à jour avec succès.');
        updateParcelsExploitationContext(data);
      } catch (error) {
        toast.error(
          error?.response?.data?.message ??
            'Une erreur est survenue : impossible de mettre à jour les parcelles.'
        );
      }
      stackedParcelsRequestsRef.current = [];
      setIsAllowedSubmitParcels(false);
    }

    if (stackedForecastRequestsRef.current.length) {
      const forecastParcelsCulturesToUpdate = stackedForecastRequestsRef.current.map((forecast) => {
        return {
          parcel: forecast.parcel,
          culture: forecast.culture,
          fixed: forecast.fixed,
        };
      });

      const body = {
        parcels: forecastParcelsCulturesToUpdate,
        year: exploitationContext?.currentYear + 1,
      };

      try {
        const result = await addForecast(exploit.id, body);
        const { data } = result.data;

        updateExploitationContext(setExploitationContext, data);
        toast.success('Les prévisionnels ont été mis à jour avec succès.');
      } catch (error) {
        toast.error(
          error?.response?.data?.message ??
            'Une erreur est survenue : impossible de mettre à jour le prévisionnel.'
        );
      }
      stackedForecastRequestsRef.current = [];
      setIsAllowedSubmitForecasts(false);
    }
  };

  return (
    <div className="section">
      <div className="main_container">
        <div className="contain-header">
          <div className="map_button_container">
            {!coordsExist && <h1>Parcelles</h1>}
            {!!coordsExist && (
              <>
                <Button
                  defaultStyle="green_button"
                  text="Parcelles"
                  backgroundColor={mapTab ? 'white' : '#277134'}
                  textColor={mapTab ? '#277134' : 'white'}
                  onClick={() => {
                    showMap(false);
                  }}
                />
                <Button
                  defaultStyle="green_button"
                  text="Carte"
                  textColor={mapTab ? 'white' : '#277134'}
                  backgroundColor={mapTab ? '#277134' : 'white'}
                  onClick={() => {
                    showMap(true);
                  }}
                />
              </>
            )}
          </div>

          <div className="parcels_button_container">
            <ThemeProvider theme={customButtonTheme}>
              {!mapTab && (
                <MButton
                  onClick={handleSubmit}
                  disabled={!isAllowedSubmitParcels && !isAllowedSubmitForecasts}
                >
                  Enregistrer les modifications <SaveIcon sx={{ width: '18px' }} />
                </MButton>
              )}

              <MButton
                onClick={() => {
                  setModal(!modal), setEditData(null);
                }}
              >
                Ajouter
                <More height={13} width={13} />
              </MButton>
              <MButton
                onClick={() => {
                  setModalGroup(!modalGroup), setEditData(null);
                }}
              >
                Regrouper
              </MButton>
            </ThemeProvider>
          </div>
        </div>

        {!!coordsExist && (
          <div style={{ display: mapTab ? 'block' : 'none' }}>
            <MapParcels
              updateParcel={updateMapParcel}
              parcelUpdated={parcelUpdated}
              centerParcels={mapTab}
              currentYear={exploitationContext.currentYear}
            ></MapParcels>
          </div>
        )}
        <div style={{ display: mapTab ? 'none' : 'block' }}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <StyledTabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <StyledTab label="Parcelles" {...a11yProps(0)} />
                <StyledTab label="Prévisionnel" {...a11yProps(1)} />
              </StyledTabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <DataGridParcels
                parcels={exploitationContext?.parcels}
                currentYear={exploitationContext?.currentYear}
                cultures={exploitationContext?.cultures}
                groundTypes={exploitationContext?.groundTypes}
                id={exploitationContext?.id}
                refreshData={loadData}
                dataGridLoading={dataGridLoading}
                setEditData={setEditData}
                setModalGroup={setModalGroup}
                columnsMetadata={ExploitationParcelsMetadata}
                stackedParcelsRequestsRef={stackedParcelsRequestsRef}
                setIsAllowedSubmit={setIsAllowedSubmitParcels}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ParcelsForecast
                exploitationContext={exploitationContext}
                dataGridLoading={dataGridLoading}
                setIsAllowedSubmit={setIsAllowedSubmitForecasts}
                stackedRequestsRef={stackedForecastRequestsRef}
              />
            </CustomTabPanel>
          </Box>
        </div>
      </div>
      {modal && (
        <AddEditParcelModal
          modal={modal}
          setModal={setModal}
          onSubmitSuccess={formSuccess}
          edit={editData}
          setExploitationContext={setExploitationContext}
        />
      )}

      {modalGroup && (
        <GroupParcelModal
          modalGroup={modalGroup}
          setModalGroup={setModalGroup}
          onSubmitSuccess={() => formSuccess()}
          loadingComplete={loadingComplete}
          allParcels={exploitationContext?.parcels}
          exploitation={exploit}
          edit={editData}
        />
      )}
    </div>
  );
};

export default Parcels;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const StyledTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#e69636',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: '#277134',
  '&:hover': {
    color: '#e69636',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#e69636',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));
